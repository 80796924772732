import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/Duty/CustomList"
export const DutyCustomList = async (query, forceUpdate = false) => {
    const cacheKey = `/api/Duty/CustomList?query=${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Duty/CustomList', { params: { query } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/CustomUpdate"
export const DutyCustomUpdate = async (duty) => {
    try {
        const response = await api_hr.post('/api/Duty/CustomUpdate', duty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/Delete"
export const DeleteDuty = async (duty) => {
    try {
        const response = await api_hr.delete('/api/Duty/Delete', { data: duty });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/Get/{id}"
export const GetDutyById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/Duty/Get/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/Duty/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/GetValue"
export const GetDutyValue = async (forceUpdate = false) => {
    const cacheKey = '/api/Duty/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Duty/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/Insert"
export const InsertDuty = async (duty) => {
    try {
        const response = await api_hr.post('/api/Duty/Insert', duty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/List"
export const DutyList = async (forceUpdate = false) => {
    const cacheKey = '/api/Duty/List';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Duty/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/ListView"
export const DutyListView = async (forceUpdate = false) => {
    const cacheKey = '/api/Duty/ListView';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Duty/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/PaginatedList"
export const PaginatedDutyList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/Duty/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Duty/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/Save"
export const SaveDuty = async (duty) => {
    try {
        const response = await api_hr.post('/api/Duty/Save', duty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Duty/Update"
export const UpdateDuty = async (duty) => {
    try {
        const response = await api_hr.post('/api/Duty/Update', duty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GetMyTasks, UpdateTasks, SaveTasks } from '../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import { TaskStatusList } from '../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import { Card, Row, Col, Button, Collapse } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faEye, faPlusCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { TextColor } from '../../../../utilities/colorUtils';
import TaskModal from '../tasks/new_task/TaskModal';
import {
  TaskCommentInsert,
  TaskCommentUpdate,
  TaskCommentDelete,
  TaskCommentCommentListView,
} from "../../../../services/ccms/taskManagement/taskComments/task_comment_endpoints";
import CommentModal from './Modals/CommentModal';

const ItemTypes = {
  TASK: 'task',
};

const MyTaskCard = ({ task }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TASK,
    item: { taskID: task.taskID, statusID: task.statusID },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleViewTask = () => {
    navigate('/app/view-task', { state: task });
  };

  const descriptionStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  };

  return (
    <Card className="mb-2 shadow" ref={drag} style={{ opacity: isDragging ? 0.5 : 1, backgroundColor: task.priorityColor }}>
      <Card.Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'grab',
          }}
          onClick={() => setOpen(!open)}
        >
          <h4>
            <TextColor bgColor={task.priorityColor} text={task.title} />
          </h4>
          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
        </div>
        <Collapse className="mt-1 mb-2" in={open}>
          <div>
            <Card.Text style={descriptionStyle}>
              <TextColor bgColor={task.priorityColor} text={task.description} />
            </Card.Text>
            <Card.Text>
              <strong>Due Date: </strong>
              <TextColor bgColor={task.priorityColor} text={new Date(task.dueDate).toLocaleString()} />
            </Card.Text>
            <Card.Text>
              <strong>Priority:</strong>{' '}
              <span className="badge badge-sm" style={{ color: task.priorityColor }}>
                <TextColor bgColor={task.priorityColor} text={task.priorityName} />
              </span>
            </Card.Text>
          </div>
        </Collapse>
      </Card.Body>
      <Card.Footer>
        <Button variant="primary" size="xxs" onClick={handleViewTask}>
          <FontAwesomeIcon icon={faEye} /> View
        </Button>
      </Card.Footer>
    </Card>
  );
};

const TaskColumn = ({ status, tasks, moveTask }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    drop: (item) => {
      if (item.statusID !== status.statusID) {
        moveTask(item.taskID, status);
      }
    },
  });

  return (
    <div ref={drop} style={{ borderWidth: '2px', borderColor: '#000000', borderRadius: '8px', padding: '10px', minHeight: '800px' }}>
      <h3>{status.name}</h3>
      <hr />
      {tasks.map((task) => (
        <MyTaskCard key={task.taskID} task={task} />
      ))}
    </div>
  );
};

const TaskBoard = () => {
  const [showModal, setShowModal] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [targetStatus, setTargetStatus] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  let hasCommented= false;
  // const [userDetails, setUserDetails] = useState({});

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // useEffect(() => {
  //   setUserDetails(JSON.parse(localStorage.getItem('userDetails')));
  // }, []);

  useEffect(() => {
    const fetchStatuses = async () => {
      const response = await TaskStatusList();
      const statusList = response.results.filter(status => status.statusID > -3 && status.statusID < 4 && status.statusID !== -1);
      setStatuses(statusList);
    };

    const fetchTasks = async () => {
      const taskFilters = {
        UserID: "" + userDetails.userId,
        startDate: "NULL",
        endDate: "NULL",
        search: "NULL",
        priorityID: "NULL",
        statusID: "NULL",
        locationID: "NULL",
        departmentID: "NULL",
      };

      setIsLoaded(false);
      const taskData = await GetMyTasks(taskFilters, false);
      setTasks(taskData.results);
      setIsLoaded(true);
    };

    fetchStatuses();
    fetchTasks();
  }, [userDetails.userId]);

  // Function to get tasks by status
  const getTasksByStatus = (statusID) => tasks.filter(task => task.statusID === statusID);

  const moveTask = async (taskID, newStatus) => {
    const task = tasks.find(task => task.taskID === taskID);
    if (!task || task.statusID === newStatus.statusID) {
      return;
    }

    if (newStatus.statusID < task.statusID) {
      Swal.fire({
        title: 'Error',
        text: `You cannot move the task back to a previous status.`,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }
    
    if (!hasCommented && newStatus.statusID === 3) {
      setSelectedTask(task);
      setTargetStatus(newStatus);
      setCommentModalVisible(true);
      return;
    }

    // Proceed with the status update
    const updatedTasks = tasks.map(task =>
      task.taskID === taskID ? { ...task, statusID: newStatus.statusID } : task
    );
    setTasks(updatedTasks);

    try {
      toast.info('Changing the status of the Task.');
      // await UpdateTasks({ ...task, statusID: newStatus.statusID });
      await SaveTasks({ ...task, statusID: newStatus.statusID });
      toast.success('Task status updated successfully');
      hasCommented = false;
    } catch (error) {
      toast.error('An error occurred while updating the task status');
    }
  };

  const handleCommentSubmit = async (comment) => {
    if (selectedTask && targetStatus) {
      try {
        const commentData = {
          commentID: 0,
          taskID: selectedTask.taskID,
          comment: comment,
          createdBy: userDetails.userId,
          commentDate: new Date()
        };

        const response = await TaskCommentInsert(commentData);
        if (response.status === 'Success') {   
          toast.success('Comment was added to the task.');     
          setCommentModalVisible(false);
          hasCommented = true;
          // Proceed to update the task status
          await moveTask(selectedTask.taskID, targetStatus);
        } else {
          toast.error('An error occurred while adding the comment');
        }
      } catch (error) {
        toast.error('An error occurred while adding the comment');
      }
    }
  };

  const handleRefresh = () => {
    const fetchTasks = async () => {
      const taskFilters = {
        UserID: "" + userDetails.userId,
        startDate: "NULL",
        endDate: "NULL",
        search: "NULL",
        priorityID: "NULL",
        statusID: "NULL",
        locationID: "NULL",
        departmentID: "NULL",
      };

      setIsLoaded(false);
      const taskData = await GetMyTasks(taskFilters, true);
      setTasks(taskData.results);
      setIsLoaded(true);
    };

    fetchTasks();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Button variant='secondary' size='xs' onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faPlusCircle} /> Create my Task
        </Button>
        <TaskModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onTaskCreated={handleRefresh} // Pass the callback function
        />
        <Button size='xs' onClick={handleRefresh}>
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="task-board">
          {!isLoaded ? (
            <Shimmer type="TaskBoard" />
          ) : (
            <Row>
              {statuses.map(status => (
                <Col key={status.statusID}>
                  <TaskColumn
                    status={status}
                    tasks={getTasksByStatus(status.statusID)} // Use getTasksByStatus here
                    moveTask={moveTask}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </DndProvider>
      <CommentModal
        show={commentModalVisible}
        onClose={() => setCommentModalVisible(false)}
        onSubmit={handleCommentSubmit}
      />
    </>
  );
};



export default TaskBoard;

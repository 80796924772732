import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/EmployeeDuty/CustomList"
export const EmployeeDutyCustomList = async (query, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeDuty/CustomList?query=${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeDuty/CustomList', { params: { query } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/Get/{userId}/{dutyID}"
export const GetEmployeeDutyById = async (userId, dutyID, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeDuty/Get/${userId}/${dutyID}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/EmployeeDuty/Get/${userId}/${dutyID}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/List"
export const EmployeeDutyList = async (forceUpdate = false) => {
    const cacheKey = '/api/EmployeeDuty/List';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeDuty/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/ListView/{userId}"
export const EmployeeDutyListView = async (userId, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeDuty/ListView/${userId}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/EmployeeDuty/ListView/${userId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/PaginatedList"
export const PaginatedEmployeeDutyList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeDuty/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeDuty/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/CustomUpdate"
export const EmployeeDutyCustomUpdate = async (employeeDuty) => {
    try {
        const response = await api_hr.post('/api/EmployeeDuty/CustomUpdate', employeeDuty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/Insert"
export const InsertEmployeeDuty = async (employeeDuty) => {
    try {
        const response = await api_hr.post('/api/EmployeeDuty/Insert', employeeDuty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/Save"
export const SaveEmployeeDuty = async (employeeDuty) => {
    try {
        const response = await api_hr.post('/api/EmployeeDuty/Save', employeeDuty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/Update"
export const UpdateEmployeeDuty = async (employeeDuty) => {
    try {
        const response = await api_hr.post('/api/EmployeeDuty/Update', employeeDuty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/Delete"
export const DeleteEmployeeDuty = async (employeeDuty) => {
    try {
        const response = await api_hr.delete('/api/EmployeeDuty/Delete', { data: employeeDuty });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeDuty/GetValue"
export const GetEmployeeDutyValue = async (forceUpdate = false) => {
    const cacheKey = '/api/EmployeeDuty/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeDuty/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../../../../components/Custom/Loading/Shimmer';
import {
  LeaveDutyListView,
  SaveLeaveDuty,
  UpdateLeaveDuty,
  DeleteLeaveDuty,
} from '../../../../../../../services/ccms/hrManagement/leaveDuty';
import { EmployeeListView } from '../../../../../../../services/ccms/hrManagement/employee';
import { EmployeeDutyListView } from '../../../../../../../services/ccms/hrManagement/employeeDuty';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const LeaveDuties = ({ leaveID, userId }) => {
  const [duties, setDuties] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showCustomDutyModal, setShowCustomDutyModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDutyID, setSelectedDutyID] = useState('');
  const [assignToUserID, setAssignToUserID] = useState('');
  const [editAssignToUserID, setEditAssignToUserID] = useState('');
  const [customDuty, setCustomDuty] = useState({
    DutyName: '',
    DutyDescription: '',
    assignedTo: '',
  });
  const [dutyToEdit, setDutyToEdit] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [dutiesResponse, employeesResponse, employeeDutiesResponse] = await Promise.all([
          LeaveDutyListView(leaveID),
          EmployeeListView(),
          EmployeeDutyListView(userId),
        ]);

        const existingAssignments = dutiesResponse.results || [];
        const applicantDuties = employeeDutiesResponse.results || [];

        // Map existingAssignments to include necessary fields
        const mappedExistingAssignments = existingAssignments.map((assign) => {
          return {
            ...assign,
            dutyID: assign.dutyID,
            name: assign.dutyName,
            description: assign.dutyDescription,
            assignedTo: assign.userId,
            assignedToName: `${assign.firstName} ${assign.surname}`,
            assignedToCellphone: assign.cellphone,
            assignedToEmail: assign.email,
            LeaveDutyID: assign.leaveDutyID,
          };
        });

        // Map applicantDuties to include assignment info
        const mappedApplicantDuties = applicantDuties.map((duty) => {
          const existingAssignment = existingAssignments.find(
            (assign) => assign.dutyID === duty.dutyID && assign.dutyID !== 0
          );
          return {
            ...duty,
            LeaveDutyID: existingAssignment ? existingAssignment.leaveDutyID : null,
            assignedTo: existingAssignment ? existingAssignment.userId : null,
            assignedToName: existingAssignment
              ? `${existingAssignment.firstName} ${existingAssignment.surname}`
              : null,
            assignedToCellphone: existingAssignment ? existingAssignment.cellphone : null,
            assignedToEmail: existingAssignment ? existingAssignment.email : null,
          };
        });

        // Combine custom duties (DutyID === 0) and mapped applicant duties
        setDuties([...mappedApplicantDuties, ...mappedExistingAssignments.filter(assign => assign.dutyID === 0)]);
        setEmployees(employeesResponse.results);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [leaveID, userId]);

  const handleAssignDuty = async () => {
    if (!selectedDutyID || !assignToUserID) {
      MySwal.fire({
        icon: 'warning',
        title: 'Incomplete Selection',
        text: 'Please select a duty and an employee to assign to.',
      });
      return;
    }

    try {
      setLoading(true);

      const dutyToAssign = duties.find((duty) => duty.dutyID === parseInt(selectedDutyID));
      const assignmentData = {
        LeaveID: leaveID,
        UserId: assignToUserID,
        DutyID: dutyToAssign.dutyID,
        DutyName: dutyToAssign.name,
        DutyDescription: dutyToAssign.description,
        CreatedDateTime: new Date().toISOString(),
        ModifiedDateTime: new Date().toISOString(),
      };

      await SaveLeaveDuty(assignmentData);

      MySwal.fire({
        icon: 'success',
        title: 'Duty Assigned',
        text: 'Duty assigned successfully.',
      });

      setShowAssignModal(false);

      // Refresh data
      const dutiesResponse = await LeaveDutyListView(leaveID);
      const existingAssignments = dutiesResponse.results || [];
      // Re-fetch the data to update the state
      const fetchData = async () => {
        try {
          const [employeesResponse, employeeDutiesResponse] = await Promise.all([
            EmployeeListView(),
            EmployeeDutyListView(userId),
          ]);

          const applicantDuties = employeeDutiesResponse.results || [];

          // Map existingAssignments to include necessary fields
          const mappedExistingAssignments = existingAssignments.map((assign) => {
            return {
              ...assign,
              dutyID: assign.dutyID,
              name: assign.dutyName,
              description: assign.dutyDescription,
              assignedTo: assign.userId,
              assignedToName: `${assign.firstName} ${assign.surname}`,
              assignedToCellphone: assign.cellphone,
              assignedToEmail: assign.email,
              LeaveDutyID: assign.leaveDutyID,
            };
          });

          // Map applicantDuties to include assignment info
          const mappedApplicantDuties = applicantDuties.map((duty) => {
            const existingAssignment = existingAssignments.find(
              (assign) => assign.dutyID === duty.dutyID && assign.dutyID !== 0
            );
            return {
              ...duty,
              LeaveDutyID: existingAssignment ? existingAssignment.leaveDutyID : null,
              assignedTo: existingAssignment ? existingAssignment.userId : null,
              assignedToName: existingAssignment
                ? `${existingAssignment.firstName} ${existingAssignment.surname}`
                : null,
              assignedToCellphone: existingAssignment ? existingAssignment.cellphone : null,
              assignedToEmail: existingAssignment ? existingAssignment.email : null,
            };
          });

          setDuties([...mappedApplicantDuties, ...mappedExistingAssignments.filter(assign => assign.dutyID === 0)]);
          setEmployees(employeesResponse.results);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    } catch (error) {
      console.error('Error assigning duty:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Assignment Failed',
        text: 'Failed to assign duty.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddCustomDuty = async () => {
    if (validateCustomDuty()) {
      try {
        setLoading(true);

        const assignedEmployee = employees.find(
          (emp) => emp.userId === customDuty.assignedTo
        );
        const newCustomDuty = {
          LeaveID: leaveID,
          UserId: customDuty.assignedTo,
          DutyID: 0,
          DutyName: customDuty.DutyName,
          DutyDescription: customDuty.DutyDescription,
          CreatedDateTime: new Date().toISOString(),
          ModifiedDateTime: new Date().toISOString(),
        };

        await SaveLeaveDuty(newCustomDuty);

        MySwal.fire({
          icon: 'success',
          title: 'Custom Duty Added',
          text: 'Custom duty added and assigned successfully.',
        });

        setShowCustomDutyModal(false);
        setCustomDuty({
          DutyName: '',
          DutyDescription: '',
          assignedTo: '',
        });
        setErrors({});

        // Refresh data
        const dutiesResponse = await LeaveDutyListView(leaveID);
        const existingAssignments = dutiesResponse.results || [];
        const fetchData = async () => {
          try {
            const [employeesResponse, employeeDutiesResponse] = await Promise.all([
              EmployeeListView(),
              EmployeeDutyListView(userId),
            ]);

            const applicantDuties = employeeDutiesResponse.results || [];

            // Map existingAssignments to include necessary fields
            const mappedExistingAssignments = existingAssignments.map((assign) => {
              return {
                ...assign,
                dutyID: assign.dutyID,
                name: assign.dutyName,
                description: assign.dutyDescription,
                assignedTo: assign.userId,
                assignedToName: `${assign.firstName} ${assign.surname}`,
                assignedToCellphone: assign.cellphone,
                assignedToEmail: assign.email,
                LeaveDutyID: assign.leaveDutyID,
              };
            });

            // Map applicantDuties to include assignment info
            const mappedApplicantDuties = applicantDuties.map((duty) => {
              const existingAssignment = existingAssignments.find(
                (assign) => assign.dutyID === duty.dutyID && assign.dutyID !== 0
              );
              return {
                ...duty,
                LeaveDutyID: existingAssignment ? existingAssignment.leaveDutyID : null,
                assignedTo: existingAssignment ? existingAssignment.userId : null,
                assignedToName: existingAssignment
                  ? `${existingAssignment.firstName} ${existingAssignment.surname}`
                  : null,
                assignedToCellphone: existingAssignment ? existingAssignment.cellphone : null,
                assignedToEmail: existingAssignment ? existingAssignment.email : null,
              };
            });

            setDuties([...mappedApplicantDuties, ...mappedExistingAssignments.filter(assign => assign.dutyID === 0)]);
            setEmployees(employeesResponse.results);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      } catch (error) {
        console.error('Error adding custom duty:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Addition Failed',
          text: 'Failed to add custom duty.',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const validateCustomDuty = () => {
    let tempErrors = {};
    if (!customDuty.DutyName) {
      tempErrors.DutyName = 'Duty Name is required';
    }
    if (!customDuty.DutyDescription) {
      tempErrors.DutyDescription = 'Duty Description is required';
    }
    if (!customDuty.assignedTo) {
      tempErrors.assignedTo = 'Please assign this duty to an employee';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleRemoveDuty = async (leaveDutyID) => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to remove this duty assignment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it',
    });

    if (result.isConfirmed) {
      try {
        setLoading(true);

        await DeleteLeaveDuty(leaveDutyID);

        MySwal.fire({
          icon: 'success',
          title: 'Removed',
          text: 'Duty removed successfully.',
        });

        // Refresh data
        const dutiesResponse = await LeaveDutyListView(leaveID);
        const existingAssignments = dutiesResponse.results || [];
        const fetchData = async () => {
          try {
            const [employeesResponse, employeeDutiesResponse] = await Promise.all([
              EmployeeListView(),
              EmployeeDutyListView(userId),
            ]);

            const applicantDuties = employeeDutiesResponse.results || [];

            // Map existingAssignments to include necessary fields
            const mappedExistingAssignments = existingAssignments.map((assign) => {
              return {
                ...assign,
                dutyID: assign.dutyID,
                name: assign.dutyName,
                description: assign.dutyDescription,
                assignedTo: assign.userId,
                assignedToName: `${assign.firstName} ${assign.surname}`,
                assignedToCellphone: assign.cellphone,
                assignedToEmail: assign.email,
                LeaveDutyID: assign.leaveDutyID,
              };
            });

            // Map applicantDuties to include assignment info
            const mappedApplicantDuties = applicantDuties.map((duty) => {
              const existingAssignment = existingAssignments.find(
                (assign) => assign.dutyID === duty.dutyID && assign.dutyID !== 0
              );
              return {
                ...duty,
                LeaveDutyID: existingAssignment ? existingAssignment.leaveDutyID : null,
                assignedTo: existingAssignment ? existingAssignment.userId : null,
                assignedToName: existingAssignment
                  ? `${existingAssignment.firstName} ${existingAssignment.surname}`
                  : null,
                assignedToCellphone: existingAssignment ? existingAssignment.cellphone : null,
                assignedToEmail: existingAssignment ? existingAssignment.email : null,
              };
            });

            setDuties([...mappedApplicantDuties, ...mappedExistingAssignments.filter(assign => assign.dutyID === 0)]);
            setEmployees(employeesResponse.results);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      } catch (error) {
        console.error('Error removing duty:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Removal Failed',
          text: 'Failed to remove duty.',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleShowEditModal = (duty) => {
    setDutyToEdit(duty);
    setEditAssignToUserID(duty.assignedTo);
    setShowEditModal(true);
  };

  const handleEditDuty = async () => {
    if (!editAssignToUserID) {
      MySwal.fire({
        icon: 'warning',
        title: 'Incomplete Selection',
        text: 'Please select an employee to assign the duty to.',
      });
      return;
    }

    try {
      setLoading(true);

      const updatedDuty = {
        LeaveDutyID: dutyToEdit.LeaveDutyID,
        LeaveID: leaveID,
        UserId: editAssignToUserID,
        DutyID: dutyToEdit.dutyID || dutyToEdit.DutyID,
        DutyName: dutyToEdit.name || dutyToEdit.DutyName,
        DutyDescription: dutyToEdit.description || dutyToEdit.DutyDescription,
        ModifiedDateTime: new Date().toISOString(),
      };

      await UpdateLeaveDuty(updatedDuty);

      MySwal.fire({
        icon: 'success',
        title: 'Duty Updated',
        text: 'Duty assignment updated successfully.',
      });

      setShowEditModal(false);

      // Refresh data
      const dutiesResponse = await LeaveDutyListView(leaveID);
      const existingAssignments = dutiesResponse.results || [];
      const fetchData = async () => {
        try {
          const [employeesResponse, employeeDutiesResponse] = await Promise.all([
            EmployeeListView(),
            EmployeeDutyListView(userId),
          ]);

          const applicantDuties = employeeDutiesResponse.results || [];

          // Map existingAssignments to include necessary fields
          const mappedExistingAssignments = existingAssignments.map((assign) => {
            return {
              ...assign,
              dutyID: assign.dutyID,
              name: assign.dutyName,
              description: assign.dutyDescription,
              assignedTo: assign.userId,
              assignedToName: `${assign.firstName} ${assign.surname}`,
              assignedToCellphone: assign.cellphone,
              assignedToEmail: assign.email,
              LeaveDutyID: assign.leaveDutyID,
            };
          });

          // Map applicantDuties to include assignment info
          const mappedApplicantDuties = applicantDuties.map((duty) => {
            const existingAssignment = existingAssignments.find(
              (assign) => assign.dutyID === duty.dutyID && assign.dutyID !== 0
            );
            return {
              ...duty,
              LeaveDutyID: existingAssignment ? existingAssignment.leaveDutyID : null,
              assignedTo: existingAssignment ? existingAssignment.userId : null,
              assignedToName: existingAssignment
                ? `${existingAssignment.firstName} ${existingAssignment.surname}`
                : null,
              assignedToCellphone: existingAssignment ? existingAssignment.cellphone : null,
              assignedToEmail: existingAssignment ? existingAssignment.email : null,
            };
          });

          setDuties([...mappedApplicantDuties, ...mappedExistingAssignments.filter(assign => assign.dutyID === 0)]);
          setEmployees(employeesResponse.results);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    } catch (error) {
      console.error('Error updating duty assignment:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'Failed to update duty assignment.',
      });
    } finally {
      setLoading(false);
    }
  };

  const mobileColumns = [
    {
      name: 'Duty Name',
      selector: (row) => row.name || row.DutyName,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Assigned To',
      selector: (row) => row.assignedToName || 'Not Assigned',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          {row.assignedTo && (
            <>
              <Button
                variant="warning"
                size="sm"
                className="me-1"
                onClick={() => handleShowEditModal(row)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveDuty(row.LeaveDutyID)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          )}
        </>
      ),
      ignoreRowClick: true,
    },
  ];

  const desktopColumns = [
    {
      name: 'Duty Name',
      selector: (row) => row.name || row.DutyName,
      sortable: true,
    },
    {
      name: 'Duty Description',
      selector: (row) => row.description || row.DutyDescription,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Assigned To',
      selector: (row) => row.assignedToName || 'Not Assigned',
      sortable: true,
    },
    {
      name: 'Cellphone',
      selector: (row) => row.assignedToCellphone || 'N/A',
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.assignedToEmail || 'N/A',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          {row.assignedTo && (
            <>
              <Button
                variant="warning"
                size="sm"
                className="me-1"
                onClick={() => handleShowEditModal(row)}
              >
                <FontAwesomeIcon icon={faEdit} /> Edit
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveDuty(row.LeaveDutyID)}
              >
                <FontAwesomeIcon icon={faTrash} /> Remove
              </Button>
            </>
          )}
        </>
      ),
      ignoreRowClick: true,
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p>
        <strong>Duty Description:</strong> {data.description || data.DutyDescription}
      </p>
      <p>
        <strong>Assigned To:</strong> {data.assignedToName || 'Not Assigned'}
      </p>
      <p>
        <strong>Cellphone:</strong> {data.assignedToCellphone || 'N/A'}
      </p>
      <p>
        <strong>Email:</strong> {data.assignedToEmail || 'N/A'}
      </p>
    </div>
  );

  if (!isLoaded) {
    return <Shimmer type="table" width={'100%'} />;
  }

  return (
    <>
      <h4 className='text-primary'>Leave Duties</h4>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" size="sm" className="me-2" onClick={() => setShowAssignModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Assign Duty
        </Button>
        <Button variant="info" size="sm" onClick={() => setShowCustomDutyModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add Custom Duty
        </Button>
      </div>
      {loading ? (
        <Shimmer type="table" width={'100%'} />
      ) : (
        <DataTable
          columns={isMobile ? mobileColumns : desktopColumns}
          data={duties}
          pagination
          expandableRows={isMobile}
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked={isMobile}
          noHeader
        />
      )}

      {/* Modal for Assigning Duty */}
      <Modal show={showAssignModal} onHide={() => setShowAssignModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Duty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label>Select Duty</label>
            <select
              className="form-control"
              value={selectedDutyID}
              onChange={(e) => setSelectedDutyID(e.target.value)}
            >
              <option value="">Select Duty</option>
              {duties
                .filter((duty) => !duty.assignedTo && duty.dutyID !== 0)
                .map((duty) => (
                  <option key={duty.dutyID} value={duty.dutyID}>
                    {duty.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group mb-3">
            <label>Assign To</label>
            <select
              className="form-control"
              value={assignToUserID}
              onChange={(e) => setAssignToUserID(e.target.value)}
            >
              <option value="">Select Employee</option>
              {employees.map((emp) => (
                <option key={emp.userId} value={emp.userId}>
                  {emp.name} {emp.surname}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAssignModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAssignDuty}>
            Assign Duty
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Adding Custom Duty */}
      <Modal show={showCustomDutyModal} onHide={() => setShowCustomDutyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Custom Duty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label>Duty Name</label>
            <input
              type="text"
              className="form-control"
              name="DutyName"
              value={customDuty.DutyName}
              onChange={(e) => setCustomDuty({ ...customDuty, DutyName: e.target.value })}
            />
            {errors.DutyName && <small className="text-danger">{errors.DutyName}</small>}
          </div>
          <div className="form-group mb-3">
            <label>Duty Description</label>
            <textarea
              className="form-control"
              name="DutyDescription"
              value={customDuty.DutyDescription}
              onChange={(e) => setCustomDuty({ ...customDuty, DutyDescription: e.target.value })}
            />
            {errors.DutyDescription && <small className="text-danger">{errors.DutyDescription}</small>}
          </div>
          <div className="form-group mb-3">
            <label>Assign To</label>
            <select
              className="form-control"
              name="assignedTo"
              value={customDuty.assignedTo}
              onChange={(e) => setCustomDuty({ ...customDuty, assignedTo: e.target.value })}
            >
              <option value="">Select Employee</option>
              {employees.map((emp) => (
                <option key={emp.userId} value={emp.userId}>
                  {emp.name} {emp.surname}
                </option>
              ))}
            </select>
            {errors.assignedTo && <small className="text-danger">{errors.assignedTo}</small>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCustomDutyModal(false)}>
            Close
          </Button>
          <Button variant="success" onClick={handleAddCustomDuty}>
            Add Duty
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Editing Duty Assignment */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Duty Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dutyToEdit && (
            <>
              <div className="form-group mb-3">
                <label>Duty Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={dutyToEdit.name || dutyToEdit.DutyName}
                  readOnly
                />
              </div>
              <div className="form-group mb-3">
                <label>Assign To</label>
                <select
                  className="form-control"
                  value={editAssignToUserID}
                  onChange={(e) => setEditAssignToUserID(e.target.value)}
                >
                  <option value="">Select Employee</option>
                  {employees.map((emp) => (
                    <option key={emp.userId} value={emp.userId}>
                      {emp.name} {emp.surname}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditDuty}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LeaveDuties;

import { getApiDepartments } from '../../APIServiceSetup';

const api_departments = await getApiDepartments();

// Cache setup
let departmentListCache = null;
let departmentListViewCache = null;
let departmentListCacheTimestamp = null;
let paginatedDepartmentListCache = {};
let paginatedDepartmentListCacheTimestamp = {};
let designationsListCache = null;
let designationsListCacheTimestamp = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Component for "/api/Department/List"
export const DepartmentList = async () => {
    const now = Date.now();

    if (departmentListCache && (now - departmentListCacheTimestamp) < CACHE_DURATION) {
        // Return cached data if it's still valid
        return departmentListCache;
    }

    try {
        const response = await api_departments.get('/api/Department/List');
        // Store response data in cache
        departmentListCache = response.data;
        departmentListCacheTimestamp = now;
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/{id}"
export const DepartmentById = async (id) => {
    try {
        const response = await api_departments.get(`/api/Department/${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/Insert"
export const InsertDepartment = async (department) => {
    try {
        const response = await api_departments.post('/api/Department/Insert', department);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/Update"
export const UpdateDepartment = async (department) => {
    try {
        const response = await api_departments.post('/api/Department/Update', department);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/Delete"
export const DeleteDepartment = async (department) => {
    try {
        const response = await api_departments.post('/api/Department/Delete', { data: department });
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/Search"
export const SearchDepartment = async (expression) => {
    try {
        const response = await api_departments.post('/api/Department/Search', expression);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/Count"
export const DepartmentCount = async () => {
    try {
        const response = await api_departments.get('/api/Department/Count');
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/GetPaginatedList"
export const PaginatedDepartmentList = async (page, pageSize) => {
    const now = Date.now();
    const cacheKey = `${page}_${pageSize}`;

    if (paginatedDepartmentListCache[cacheKey] && (now - paginatedDepartmentListCacheTimestamp[cacheKey]) < CACHE_DURATION) {
        // Return cached data if it's still valid
        return paginatedDepartmentListCache[cacheKey];
    }

    try {
        const response = await api_departments.get('/api/Department/GetPaginatedList', { params: { page, pageSize } });
        // Store response data in cache
        paginatedDepartmentListCache[cacheKey] = response.data;
        paginatedDepartmentListCacheTimestamp[cacheKey] = now;
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department"
export const Department = async () => {
    try {
        const response = await api_departments.get('/api/Department');
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/ListView"
export const DepartmentListView = async () => {
    const now = Date.now();

    if (departmentListViewCache && (now - departmentListCacheTimestamp) < CACHE_DURATION) {
        // Return cached data if it's still valid
        return departmentListViewCache;
    }

    try {
        const response = await api_departments.get('/api/Department/ListView');
        // Store response data in cache
        departmentListViewCache = response.data;
        departmentListCacheTimestamp = now;
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Department/DepartmentView/{departmentID}"
export const DepartmentItemView = async (departmentID) => {
    try {
        const response = await api_departments.get('/api/Department/DepartmentView/' + departmentID);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Designation/List"
export const DesignationsList = async () => {
    const now = Date.now();

    if (designationsListCache && (now - designationsListCacheTimestamp) < CACHE_DURATION) {
        // Return cached data if it's still valid
        return designationsListCache;
    }

    try {
        const response = await api_departments.get('/api/Designation/List');
        // Store response data in cache
        designationsListCache = response.data;
        designationsListCacheTimestamp = now;
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Designation/GetByDepartmentID/{departmentID}"
export const GetByDepartmentID = async (departmentID) => {
    try {
        const response = await api_departments.get('/api/Designation/GetByDepartmentID/' + departmentID);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Designation/Insert"
export const InsertDesignation = async (designation) => {
    try {
        const response = await api_departments.post('/api/Designation/Insert', designation);
        return response.data;
    } catch (error) {
        return error;
    }
};



// Usage:
// const departmentList = await DepartmentList();
// const departmentById = await DepartmentById(1);
// const insertedDepartment = await InsertDepartment({ name: 'New Department' });
// const updatedDepartment = await UpdateDepartment({ id: 1, name: 'Updated Department' });
// const deletedDepartment = await DeleteDepartment({ id: 1 });
// const searchedDepartments = await SearchDepartment({ name: 'Department' });
// const departmentCount = await DepartmentCount();
// const paginatedDepartmentList = await PaginatedDepartmentList(1, 10);
// const department = await Department();
import { getApiTasks } from "../../APIServiceSetup";

const api_tasks = await getApiTasks();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get the task attachment list with caching
export const TaskAttachmentList = async (forceUpdate = false) => {
  const cacheKey = 'taskAttachmentList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAttachment/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get the task attachment list view by task ID with caching
export const TaskAttachmentListView = async (taskID, forceUpdate = false) => {
  const cacheKey = `taskAttachmentListView_${taskID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/TaskAttachment/ListView/${taskID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task attachment by ID with caching
export const TaskAttachmentById = async (id, forceUpdate = false) => {
  const cacheKey = `taskAttachmentById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/TaskAttachment/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task attachment with caching
export const TaskAttachmentGet = async (forceUpdate = false) => {
  const cacheKey = 'taskAttachmentGet';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAttachment/Get');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert task attachment
export const TaskAttachmentInsert = async (data) => {
  try {
    const response = await api_tasks.post('/api/TaskAttachment/Insert', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to update task attachment
export const TaskAttachmentUpdate = async (data) => {
  try {
    const response = await api_tasks.post('/api/TaskAttachment/Update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert or update task attachment
export const TaskAttachmentInsertOrUpdate = async (data) => {
  try {
    const response = await api_tasks.post('/api/TaskAttachment/InsertOrUpdate', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to delete task attachment
export const TaskAttachmentDelete = async (id) => {
  try {
    const response = await api_tasks.delete(`/api/TaskAttachment/Delete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task attachment count with caching
export const TaskAttachmentCount = async (forceUpdate = false) => {
  const cacheKey = 'taskAttachmentCount';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAttachment/Count');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to search task attachments with caching
export const TaskAttachmentSearch = async (query, forceUpdate = false) => {
  const cacheKey = `taskAttachmentSearch_${JSON.stringify(query)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAttachment/Search', { params: { query } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get paginated task attachment list with caching
export const TaskAttachmentPaginatedList = async (page, size, forceUpdate = false) => {
  const cacheKey = `taskAttachmentPaginatedList_${page}_${size}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAttachment/PaginatedList', { params: { page, size } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom task attachment list with caching
export const TaskAttachmentCustomList = async (params, forceUpdate = false) => {
  const cacheKey = `taskAttachmentCustomList_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAttachment/CustomList', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom task attachment with caching
export const TaskAttachmentCustom = async (params, forceUpdate = false) => {
  const cacheKey = `taskAttachmentCustom_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAttachment/Custom', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

import React from "react";
import { Routes, Route } from "react-router-dom";

import MainLayout from "./layouts/MainLayout"; // Ensure correct import path
import Setting from "./layouts/Setting";
import ScrollToTop from "./layouts/ScrollToTop";
import LockScreen from "./pages/auth/LockScreen";
import Error400 from "./pages/error/Error400";
import Error403 from "./pages/error/Error403";
import Error404 from "./pages/error/Error404";
import Error500 from "./pages/error/Error500";
import Error503 from "./pages/error/Error503";
import Dashboard from "./pages/app/dashboard";
import Tasks from "./pages/app/tasks";
import NewTask from "./pages/app/tasks/new_task";
import MyTasks from "./pages/app/my_tasks";
import TeamTasks from "./pages/app/team_tasks";
import CalendarPage from "./pages/app/calendar";
import Monitoring from "./pages/app/monitoring";
import Notifications from "./pages/app/notifications";
import Reports from "./pages/app/reports";
import Profile from "./pages/app/profile";
import Departments from "./pages/app/departments";
import Department from "./pages/app/departments/department";
import Lookups from "./pages/app/lookups";
import TaskStatus from "./pages/app/lookups/task_status";
import EmailTemplates from "./pages/app/lookups/email_template";
import SMSTemplates from "./pages/app/lookups/sms_template";
import TaskStages from "./pages/app/lookups/taskStages";
import Settings from "./pages/app/settings";
import ProtectedRoute from './ProtectedRoute'; // Ensure correct import path
import ViewTask from "./pages/app/tasks/view_task";

// Task Management Reports
import TaskReportsPage from "./pages/app/reports/taskReports/tasks";
import UserReportsPage from "./pages/app/reports/taskReports/users";
import DepartmentReportsPage from "./pages/app/reports/taskReports/departments";
import LocationReportsPage from "./pages/app/reports/taskReports/locations";

// HR Management
import MyDetails from "./pages/app/hr/myDetails";
import Leaves from "./pages/app/hr/leaves";
import ViewLeave from "./pages/app/hr/leaves/viewLeave";
import LeaveRequest from "./pages/app/hr/leaves/newLeave";

import Policies from "./pages/app/hr/policies";
import ViewPolicy from "./pages/app/hr/policies/viewPolicy";

// Feedback Management
import FeedbackCategory from "./pages/app/lookups/feedback_category";
import FeedbackType from "./pages/app/lookups/feedback_type";
import Feedback from "./pages/app/feedback";

const Markup = () => {
    const userID = localStorage.getItem('userID'); // Fetch the userID from localStorage

    const allRoutes = [
        { url: "/lock-screen", component: LockScreen },
        { url: "/error-400", component: Error400 },
        { url: "/error-403", component: Error403 },
        { url: "/error-404", component: Error404 },
        { url: "/error-500", component: Error500 },
        { url: "/error-503", component: Error503 },
        { url: "/auth/login", component: LockScreen },
        { url: "/auth/lock-screen", component: LockScreen },
        { url: "/auth/forgot-password", component: LockScreen },
        { url: "/app", component: Dashboard, rights: ["TaskManagementSystemAccess"] },
        { url: "/", component: Dashboard, rights: ["TaskManagementSystemAccess"] },
        { url: "/app/dashboard", component: Dashboard, rights: ["TaskManagementSystemAccess"] },
        { url: "/app/tasks", component: Tasks, rights: ["TaskManagementSystemAccess"] },
        { url: "/app/tasks/new-task", component: NewTask, rights: ["TaskManagementSystemAccess"] },
        { url: "/app/my-tasks", component: MyTasks, rights: ["TaskManagementSystemAccess"] },
        { url: "/app/view-task", component: ViewTask, rights: ["TaskManagementSystemAccess"] },
        { url: "/app/team-tasks", component: TeamTasks, rights: ["CampusLeader"] },
        { url: "/app/calendar", component: CalendarPage, rights: ["TaskManagementSystemAccess"] },
// HR Management
        { url: "/app/my-details", component: MyDetails, rights: ["TaskManagementAdmin", "StaffAccess"] },
        { url: "/app/leaves", component: Leaves, rights: ["TaskManagementAdmin", "StaffAccess"] },
        { url: "/app/leaves/view-leave", component: ViewLeave, rights: ["TaskManagementAdmin", "StaffAccess"] },
        { url: "/app/leaves/new-leave", component: LeaveRequest, rights: ["TaskManagementAdmin", "StaffAccess"] },
        { url: "/app/policies", component: Policies, rights: ["TaskManagementAdmin", "StaffAccess"] },
        { url: "/app/policies/view-policy", component: ViewPolicy, rights: ["TaskManagementAdmin", "StaffAccess"] },
// Feedback Management
        { url: "/app/feedback", component: Feedback, rights: ["TaskManagementAdmin"] },
        { url: "/app/lookups/feedback-category", component: FeedbackCategory, rights: ["TaskManagementAdmin"] },
        { url: "/app/lookups/feedback-type", component: FeedbackType, rights: ["TaskManagementAdmin"] },

        { url: "/app/monitoring", component: Monitoring, rights: ["TaskManagementAdmin"] },
        { url: "/app/notifications", component: Notifications, rights: ["TaskManagementSystemAccess"] },
        // { url: "/app/reports", component: Reports, rights: ["TaskManagementAdmin"] },
        { url: "/app/reports/task-reports", component: TaskReportsPage, rights: ["TaskManagementAdmin"] },
        { url: "/app/reports/user-reports", component: UserReportsPage, rights: ["TaskManagementAdmin"] },
        { url: "/app/reports/department-reports", component: DepartmentReportsPage, rights: ["TaskManagementAdmin"] },
        { url: "/app/reports/location-reports", component: LocationReportsPage, rights: ["TaskManagementAdmin"] },
        { url: "/app/profile", component: Profile, rights: ["TaskManagementSystemAccess"] },
        { url: "/app/departments", component: Departments, rights: ["TaskManagementAdmin"] },
        { url: "/app/department", component: Department, rights: ["TaskManagementAdmin"] },
        { url: "/app/lookups", component: Lookups, rights: ["TaskManagementAdmin"] },
        { url: "/app/lookups/task-status", component: TaskStatus, rights: ["TaskManagementAdmin"] },
        { url: "/app/lookups/email-template", component: EmailTemplates, rights: ["TaskManagementAdmin"] },
        { url: "/app/lookups/sms-template", component: SMSTemplates, rights: ["TaskManagementAdmin"] },
        { url: "/app/lookups/task-progress-stage", component: TaskStages, rights: ["TaskManagementAdmin"] },
        { url: "/app/settings", component: Settings, rights: ["TaskManagementSystemAccess"] },
    ];

    return (
        <>
            <Routes>
                <Route path="/lock-screen" element={<LockScreen />} />
                <Route path="/error-400" element={<Error400 />} />
                <Route path="/error-403" element={<Error403 />} />
                <Route path="/error-404" element={<Error404 />} />
                <Route path="/error-500" element={<Error500 />} />
                <Route path="/error-503" element={<Error503 />} />
                <Route element={<MainLayout />}>
                    {allRoutes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.url}
                            element={<ProtectedRoute element={route.component} userID={userID} requiredRights={route.rights || []} />}
                        />
                    ))}
                </Route>
                <Route path="*" element={<Error404 />} /> {/* Fallback route for unmatched paths */}
            </Routes>
            <Setting />
            <ScrollToTop />
        </>
    );
};

export default Markup;

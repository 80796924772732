import { getApiSystem } from '../../APIServiceSetup';

const api_system = await getApiSystem();
const CACHE_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
let cache = {};

const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// FeedbackType Endpoints
export const FeedbackTypeList = async (forceUpdate = false) => {
    const cacheKey = '/api/FeedbackType/List';
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get('/api/FeedbackType/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackTypeInsert = async (type) => {
    try {
        const response = await api_system.post('/api/FeedbackType/Insert', type);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackTypeUpdate = async (type) => {
    try {
        const response = await api_system.post('/api/FeedbackType/Update', type);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackTypeCustomUpdate = async (query) => {
    try {
        const response = await api_system.post('/api/FeedbackType/CustomUpdate', { query });
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackTypeGetById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/FeedbackType/Get/${id}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/FeedbackType/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

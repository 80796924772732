import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_KEY = 'policyCategoryCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in localStorage
const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

// Helper function to get data from cache
const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Component for "/api/PolicyCategory/CustomList"
export const PolicyCategoryCustomList = async (query, forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/PolicyCategory/CustomList', { params: { query } });
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/CustomUpdate"
export const PolicyCategoryCustomUpdate = async (policyCategory) => {
    try {
        const response = await api_hr.post('/api/PolicyCategory/CustomUpdate', policyCategory);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/Delete"
export const DeletePolicyCategory = async (policyCategory) => {
    try {
        const response = await api_hr.delete('/api/PolicyCategory/Delete', { data: policyCategory });
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on delete
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/Get/{id}"
export const GetPolicyCategoryById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get(`/api/PolicyCategory/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/GetValue"
export const GetPolicyCategoryValue = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/PolicyCategory/GetValue');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/Insert"
export const InsertPolicyCategory = async (policyCategory) => {
    try {
        const response = await api_hr.post('/api/PolicyCategory/Insert', policyCategory);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on insert
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/List"
export const PolicyCategoryList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/PolicyCategory/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/PaginatedList"
export const PaginatedPolicyCategoryList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get('/api/PolicyCategory/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/Save"
export const SavePolicyCategory = async (policyCategory) => {
    try {
        const response = await api_hr.post('/api/PolicyCategory/Save', policyCategory);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on save
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicyCategory/Update"
export const UpdatePolicyCategory = async (policyCategory) => {
    try {
        const response = await api_hr.post('/api/PolicyCategory/Update', policyCategory);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

import React, { createContext, useState, useContext, useEffect } from 'react';
import { DepartmentList, DepartmentListView, DesignationsList } from './departmentManagement/department/department_endpoints';
import { FilteredTaskViewList, GetMyTasks, GetAssignedUser } from './taskManagement/tasks/tasks_endpoints';
import { FeedbackCategoryList } from './system/feedbackCategory';
import { FeedbackTypeList } from './system/feedbackType';
import { FeedbackListView } from './system/feedback';
import { addTaskToQueue } from './taskQueue';
import useCacheRefresher from './useCacheRefresher';
import { getConfig } from '../../configs/pubConfig'; // Adjust path as necessary
import { getUserRights } from '../../services/ccms/userManagement/user/user_endpoints'; // Ensure this is the correct path

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [userRights, setUserRights] = useState([]);
  const [departmentList, setDepartmentList] = useState(null);
  const [departmentListView, setDepartmentListView] = useState(null);
  const [designationsList, setDesignationsList] = useState(null);
  const [feedbackCategoryList, setFeedbackCategoryList] = useState(null);
  const [feedbackTypeList, setFeedbackTypeList] = useState(null);
  const [feedbackListView, setFeedbackListView] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [myTasks, setMyTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [CACHE_DURATION, setCacheDuration] = useState(60000); // Default to 1 minute (60000 ms)
  

  const hasRequiredRights = (requiredRights) => {
    return requiredRights.every(right => userRights.includes(right));
};

  useEffect(() => {
    const fetchConfig = async () => {
      const config = await getConfig();
      const cacheDuration = config.CACHE_REFRESHER_DURATION || 2; // Default to 1 minute if not provided
      const cacheDurationInMs = cacheDuration * 60000; // Convert minutes to milliseconds
      setCacheDuration(cacheDurationInMs); // Convert minutes to milliseconds      
    };
    
    fetchConfig();
  }, []);

  const fetchData = async (forceUpdate = false) => {
    setIsLoading(true);
      try {
        const [
          departmentListData,
          departmentListViewData,
          designationsListData,
          filteredDataResponse,
          myTasksResponse,
          feedbackCategoryList,
          feedbackTypeList,
          feedbackListViewData,
        ] = await Promise.allSettled([
          DepartmentList(),
          DepartmentListView(),
          DesignationsList(),
          FilteredTaskViewList(),
          GetMyTasks(),
          FeedbackCategoryList(),
          FeedbackTypeList(),
          FeedbackListView(),
        ]);

        setDepartmentList(departmentListData.status === 'fulfilled' ? departmentListData.value : null);
        setDepartmentListView(departmentListViewData.status === 'fulfilled' ? departmentListViewData.value : null);
        setDesignationsList(designationsListData.status === 'fulfilled' ? designationsListData.value : null);
        setFilteredData(filteredDataResponse.status === 'fulfilled' ? filteredDataResponse.value : null);
        setMyTasks(myTasksResponse.status === 'fulfilled' ? myTasksResponse.value : null);
        setFeedbackCategoryList(feedbackCategoryList.status === 'fulfilled' ? feedbackCategoryList.value : null);
        setFeedbackTypeList(feedbackTypeList.status === 'fulfilled' ? feedbackTypeList.value : null);
        setFeedbackListView(feedbackListViewData.status === 'fulfilled' ? feedbackListViewData.value : null);

        if (filteredDataResponse.status === 'fulfilled' && filteredDataResponse.value && filteredDataResponse.value.results) {
          const taskIDs = filteredDataResponse.value.results.map(task => task.taskID);

          // Fetch assigned users for each task
          //const assignedUserPromises = taskIDs.map(taskID => GetAssignedUser(taskID, true));
          //const assignedUsers = await Promise.allSettled(assignedUserPromises);

          // Merge assigned users back into filtered data results
          const updatedFilteredData = filteredDataResponse.value.results.map((task, index) => ({
            ...task,
            //assignedUser: assignedUsers[index].status === 'fulfilled' ? assignedUsers[index].value : null
          }));

          setFilteredData({ ...filteredDataResponse.value, results: updatedFilteredData });
        }
      } catch (error) {
        console.error('Data fetch error:', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    fetchData(); // Initial data fetch

    const intervalId = setInterval(() => fetchData(), CACHE_DURATION); // Background data refresh

    return () => clearInterval(intervalId);
  }, []);

  useCacheRefresher(); // This will start the background task

  return (
    <DataContext.Provider value={{ departmentList, departmentListView, designationsList, filteredData, myTasks, isLoading }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);

import { getApiDepartments } from '../../APIServiceSetup';

const api_designationTask = await getApiDepartments();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get custom data by designation ID with caching
export const getDesignationTaskCustomData = async (designationID, forceUpdate = false) => {
  const cacheKey = `designationTaskCustomData_${designationID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_designationTask.get(`/api/DesignationTask/CustomData`, {
      params: { designationID },
    });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching custom data for designationID ${designationID}`, error);
    throw error;
  }
};

// Function to get custom list by designation name with caching
export const getDesignationTaskCustomList = async (designationName, forceUpdate = false) => {
  const cacheKey = `designationTaskCustomList_${designationName}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_designationTask.get(`/api/DesignationTask/CustomList`, {
      params: { designationName },
    });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching custom list for designationName ${designationName}`, error);
    throw error;
  }
};

// Function to update designation task with caching
export const updateDesignationTask = async (designationTaskData, forceUpdate = false) => {
  try {
    const response = await api_designationTask.post(`/api/DesignationTask/CustomUpdate`, designationTaskData);
    return response.data;
  } catch (error) {
    console.error("Error updating designation task", error);
    throw error;
  }
};

// Function to delete designation task with caching
export const deleteDesignationTask = async (designationTaskData, forceUpdate = false) => {
  try {
    const response = await api_designationTask.post(`/api/DesignationTask/Delete`, designationTaskData);
    return response.data;
  } catch (error) {
    console.error("Error deleting designation task", error);
    throw error;
  }
};

// Function to get a single designation task
export const getDesignationTask = async () => {
  try {
    const response = await api_designationTask.get(`/api/DesignationTask`);
    return response.data;
  } catch (error) {
    console.error("Error fetching designation task", error);
    throw error;
  }
};

// Function to get a designation task by ID
export const getDesignationTaskById = async (id) => {
  try {
    const response = await api_designationTask.get(`/api/DesignationTask/ById/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designation task with id ${id}`, error);
    throw error;
  }
};

// Function to insert a designation task
export const insertDesignationTask = async (designationTaskData) => {
  try {
    const response = await api_designationTask.post(`/api/DesignationTask/Insert`, designationTaskData);
    return response.data;
  } catch (error) {
    console.error("Error inserting designation task", error);
    throw error;
  }
};

// Function to list designation tasks
export const listDesignationTasks = async () => {
  try {
    const response = await api_designationTask.get(`/api/DesignationTask/List`);
    return response.data;
  } catch (error) {
    console.error("Error fetching designation tasks list", error);
    throw error;
  }
};

// Function to get paginated list of designation tasks with caching
export const getPaginatedDesignationTaskList = async (page, pageSize, forceUpdate = false) => {
  const cacheKey = `paginatedDesignationTaskList_${page}_${pageSize}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_designationTask.get(`/api/DesignationTask/PaginatedList`, {
      params: { page, pageSize },
    });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching paginated designation tasks list", error);
    throw error;
  }
};

// Function to save designation task with caching
export const saveDesignationTask = async (designationTaskData, forceUpdate = false) => {
  try {
    const response = await api_designationTask.post(`/api/DesignationTask/Save`, designationTaskData);
    return response.data;
  } catch (error) {
    console.error("Error saving designation task", error);
    throw error;
  }
};

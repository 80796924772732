import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { faBell, faCalendar, faCog, faSignOutAlt, faHamburger, faHome, faList, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import MobileMenuBar from './MobileMenuBar';

const mobileNavStyles = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#fff',
  borderTop: '1px solid #ccc',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '10px 0',
  boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
};

const mobileNavItemStyles = {
  textDecoration: 'none',
  color: '#000',
  padding: '5px 10px',
  textAlign: 'center',
  flexGrow: 1,
  transition: 'color 0.3s, background-color 0.3s',
};

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    width: '80%',
    maxWidth: '400px',
  },
};

const MobileNav = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const location = useLocation();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const getNavItemStyles = (path) => ({
    ...mobileNavItemStyles,
    color: location.pathname === path ? 'var(--bs-primary)' : mobileNavItemStyles.color,
    backgroundColor: location.pathname === path ? 'var(--bs-light)' : mobileNavItemStyles.backgroundColor,
    cursor: 'pointer',
  });

  const handleMouseEnter = (e) => {
    e.currentTarget.style.color = 'var(--bs-primary)';
    e.currentTarget.style.backgroundColor = 'var(--bs-light)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.color = location.pathname === e.currentTarget.pathname ? 'var(--bs-primary)' : '#000';
    e.currentTarget.style.backgroundColor = location.pathname === e.currentTarget.pathname ? 'var(--bs-light)' : '#fff';
  };

  return (
    <div>
      <div style={mobileNavStyles}>
        <Link to="/app/dashboard" style={getNavItemStyles("/app/dashboard")} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FontAwesomeIcon icon={faHome} /> <br /> Dashboard
        </Link>
        <Link to="/app/tasks" style={getNavItemStyles("/app/tasks")} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FontAwesomeIcon icon={faList} />
          <br /> Tasks
        </Link>
        <Link to="/app/calendar" style={getNavItemStyles("/app/calendar")} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FontAwesomeIcon icon={faCalendar} />
          <br /> Calendar
        </Link>
        <Link to="/app/profile" style={getNavItemStyles("/app/profile")} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FontAwesomeIcon icon={faUser} />
          <br /> Profile
        </Link>
        <div onClick={openModal} style={mobileNavItemStyles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FontAwesomeIcon icon={faHamburger} />
          <br /> More
        </div>
      </div>

      <Modal show={modalIsOpen} onHide={closeModal} style={modalStyles}>
        <Modal.Header closeButton>
          <Modal.Title>More Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MobileMenuBar onClose={closeModal} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MobileNav;

import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Shimmer from '../../../../../../components/Custom/Loading/Shimmer';
import { UpdateLeave } from '../../../../../../../services/ccms/hrManagement/leaveRequest';
import { LeaveStatusList } from '../../../../../../../services/ccms/hrManagement/leaveStatus';
import { LeaveTypeList } from '../../../../../../../services/ccms/hrManagement/leaveType';

const LeaveDetails = ({ leave, setLeave }) => {
  const [statusList, setStatusList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState({ ...leave });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statusResponse, typeResponse] = await Promise.all([
          LeaveStatusList(),
          LeaveTypeList(),
        ]);
        setStatusList(statusResponse.results);
        setTypeList(typeResponse.results);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdateLeave = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateLeave(formData);
      setLeave(response.data);
      alert('Leave updated successfully');
    } catch (error) {
      console.error('Error updating leave:', error);
      alert('Failed to update leave');
    }
  };

  if (!isLoaded) {
    return <Shimmer type="article" />;
  }

  return (
    <>
      <h4 className="text-primary">Leave Details</h4>
      <Form onSubmit={handleUpdateLeave}>
        <div className="row">
          <div className="form-group mb-3 col-md-6">
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              name="typeID"
              value={formData.typeID}
              onChange={handleInputChange}
              className="form-control"
            >
              {typeList.map((type) => (
                <option key={type.typeID} value={type.typeID}>
                  {type.name}
                </option>
              ))}
            </Form.Control>
          </div>
          <div className="form-group mb-3 col-md-6">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              name="statusID"
              disabled
              value={formData.statusID}
              onChange={handleInputChange}
              className="form-control"
            >
              {statusList.map((status) => (
                <option key={status.statusID} value={status.statusID}>
                  {status.name}
                </option>
              ))}
            </Form.Control>
          </div>
        </div>
        <div className="row">
            <div className="form-group mb-3 col-md-5">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                    type="date"
                    name="startDate"
                    value={formData.startDate ? formData.startDate.split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="form-control"
                />
            </div>
            <div className="form-group mb-3 col-md-5">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                    type="date"
                    name="endDate"
                    value={formData.endDate ? formData.endDate.split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="form-control"
                />
            </div>
            <div className="form-group mb-3 col-md-2">
                <Form.Label>Number of Days</Form.Label>
                <Form.Control
                type="number"
                name="numberOfDays"
                disabled
                value={formData.numberOfDays}
                onChange={handleInputChange}
                className="form-control"
                />
          </div>
        </div>
        <div className="form-group mb-3">
            <Form.Label>Reason for Leave</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="reasonForLeave"
              value={formData.reasonForLeave || ''}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        <div className="form-group mb-3">
          <Form.Label>HR Note</Form.Label>
          <Form.Control
            as="textarea"
              rows={3}
              disabled
            name="hrNote"
            value={formData.hrNote || ''}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <Button className="btn btn-primary" type="submit">
          <i className="fa fa-save"></i> Save Changes
        </Button>
      </Form>
    </>
  );
};

export default LeaveDetails;

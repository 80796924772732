// import { TaskReports, GetAssignedUser } from "../../../../../../services/ccms/taskManagement/tasks/tasks_endpoints";
// import { TaskStatusList } from "../../../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints";
// import { TaskPriorityList } from "../../../../../../services/ccms/taskManagement/taskPriority/task_priority_endpoints";
// import { LocationList } from "../../../../../../services/ccms/locationManagement/location/location_endpoints";
// import { DepartmentList } from "../../../../../../services/ccms/departmentManagement/department/department_endpoints";
// import { TaskProgressStageList } from "../../../../../../services/ccms/taskManagement/taskProgressStage";

// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";
// import { Button, Form, Row, Col, Card } from 'react-bootstrap';
// import DataTable from 'react-data-table-component';
// import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
// import LoadingText from '../../../../../components/Custom/Loading/LoadingText';
// import { UserRightsContext } from '../../../../../../context/UserRightsContext';
// import { useMediaQuery } from 'react-responsive';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
// import { DateTimeConverter } from '../../../../../../utilities/convertDateTime';
// import { TextColor } from '../../../../../../utilities/colorUtils';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, PieChart, Pie, Legend, ResponsiveContainer } from 'recharts';

// // User this from status
// // - effectiveStatusColor: "#808080"​​​
// // - effectiveStatusName: "Closed"​​​

// // Assign taskAssignedUsers using await GetAssignedUser(TaskReports.taskID)

// // Shimmer usage: <Shimmer type="article" />; <Shimmer type="table" width={'100%'} /> <Shimmer type="empty" text='Custom message here' />

// //<span className='badge' style={{ backgroundColor: row.priorityColor }}>
// //<TextColor bgColor={row.priorityColor} text={row.priorityName} />
// //</span>

import React, { useState, useEffect, useContext, Fragment } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
import LoadingText from '../../../../../components/Custom/Loading/LoadingText';
import { UserRightsContext } from '../../../../../../context/UserRightsContext';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { DateTimeConverter } from '../../../../../../utilities/convertDateTime';
import { TextColor } from '../../../../../../utilities/colorUtils';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// import ReactGantt from 'frappe-gantt-react'; // Import the Gantt chart component

import {
  TaskReports,
  GetAssignedUser,
} from '../../../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import { TaskStatusList } from '../../../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import { TaskPriorityList } from '../../../../../../services/ccms/taskManagement/taskPriority/task_priority_endpoints';
import { LocationList } from '../../../../../../services/ccms/locationManagement/location/location_endpoints';
import { DepartmentList } from '../../../../../../services/ccms/departmentManagement/department/department_endpoints';
import { TaskProgressStageList } from '../../../../../../services/ccms/taskManagement/taskProgressStage';

import PageUnderConstruction from '../../../../../components/Custom/Page/UnderConstruction';

const TaskReportsPage = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUnderConstruction, setIsUnderConstruction] = useState(true);

  const defaultFilters = {
    search: 'NULL',
    taskID: 'NULL',
    userID: 'NULL',
    startDate: 'NULL',
    endDate: 'NULL',
    priorityID: 'NULL',
    statusID: 'NULL',
    locationID: 'NULL',
    departmentID: 'NULL',
    assignedBy: 'NULL',
    createdBy: 'NULL',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [ganttData, setGanttData] = useState([]);

  const [statusList, setStatusList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const statuses = await TaskStatusList();
      const priorities = await TaskPriorityList();
      setStatusList(statuses.results);
      setPriorityList(priorities.results);
      fetchTaskReports(); // Fetch reports after loading filters
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const fetchTaskReports = async () => {
    setLoading(true);
    try {
      const taskReports = await TaskReports(filters);
      const tasksWithUsers = await Promise.all(
        taskReports.results.map(async (task) => {
          let assignedUsers = [];
          if (task.taskAssignedUsers && task.taskAssignedUsers.length > 0) {
            assignedUsers = task.taskAssignedUsers;
          } else {
            // If taskAssignedUsers is null, fetch assigned users
            var assignedUsersList = await GetAssignedUser(task.taskID);
            assignedUsers = assignedUsersList.results
          }
          return { ...task, assignedUsers };
        })
      );
      setTasks(tasksWithUsers);
      prepareChartData(tasksWithUsers);
    } catch (error) {
      console.error('Error fetching task reports:', error);
    } finally {
      setLoading(false);
    }
  };

  const prepareChartData = (tasks) => {
    const statusCounts = {};
    const priorityCounts = {};

    tasks.forEach((task) => {
      const status = task.effectiveStatusName;
      const priority = task.priorityName;

      statusCounts[status] = (statusCounts[status] || 0) + 1;
      priorityCounts[priority] = (priorityCounts[priority] || 0) + 1;
    });

    const barData = Object.keys(statusCounts).map((status) => ({
      name: status,
      count: statusCounts[status],
    }));

    const pieData = Object.keys(priorityCounts).map((priority) => ({
      name: priority,
      value: priorityCounts[priority],
    }));

    setBarChartData(barData);
    setPieChartData(pieData);

    // Prepare Gantt data
    const ganttTasks = prepareGanttData(tasks);
    setGanttData(ganttTasks);
  };

  const convertModifiedDateTime = (modifiedDateTime) => {
    const dateTimeStr = modifiedDateTime.toString();

    // Ensure the string has at least 14 characters
    if (dateTimeStr.length < 14) {
      console.warn('Invalid modifiedDateTime format:', modifiedDateTime);
      return null;
    }

    const year = dateTimeStr.substring(0, 4);
    const month = dateTimeStr.substring(4, 6);
    const day = dateTimeStr.substring(6, 8);
    const hour = dateTimeStr.substring(8, 10);
    const minute = dateTimeStr.substring(10, 12);
    const second = dateTimeStr.substring(12, 14);

    // Return the date in 'YYYY-MM-DD' format
    return `${year}-${month}-${day}`;
  };

  const prepareGanttData = (tasks) => {
    return tasks
      .map((task) => {
        // Convert modifiedDateTime
        const startDate = convertModifiedDateTime(task.modifiedDateTime);
        // Ensure dueDate is in 'YYYY-MM-DD' format
        const dueDate = task.dueDate ? task.dueDate.substring(0, 10) : null;

        // If startDate or dueDate is null, skip this task
        if (!startDate || !dueDate) {
          return null;
        }

        return {
          id: task.taskID.toString(),
          name: task.title, // Updated field name
          start: startDate,
          end: dueDate,
          progress: 0,//task.completionPercentage || 0,
          dependencies: '', // Add dependencies if applicable
          custom_class: '', // Optional: Custom CSS classes
        };
      })
      .filter((task) => task !== null); // Remove null tasks
  };



  return (
    <Fragment>
      {loading && isUnderConstruction ? (
        // <Shimmer type="table" width="100%" />
        <PageUnderConstruction />
      ) : (
        <>
          {/* Refresh Button */}
          <Button variant="secondary" onClick={fetchTaskReports} className="mb-3">
            <FontAwesomeIcon icon={faSyncAlt} /> Refresh
          </Button>

          {/* Filters Form */}
          <Form>
            <Row>
              {/* Status Filter */}
              <Col md={3}>
                <Form.Group controlId="statusFilter">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.statusID}
                    onChange={(e) =>
                      setFilters({ ...filters, statusID: e.target.value || 'NULL' })
                    }
                  >
                    <option value="">All</option>
                    {statusList.map((status) => (
                      <option key={status.statusID} value={status.statusID}>
                        {status.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              {/* Priority Filter */}
              <Col md={3}>
                <Form.Group controlId="priorityFilter">
                  <Form.Label>Priority</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.priorityID}
                    onChange={(e) =>
                      setFilters({ ...filters, priorityID: e.target.value || 'NULL' })
                    }
                  >
                    <option value="">All</option>
                    {priorityList.map((priority) => (
                      <option key={priority.priorityID} value={priority.priorityID}>
                        {priority.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              {/* Date Range Picker */}
              <Col md={4}>
                <Form.Group controlId="dateRange">
                  <Form.Label>Date Range</Form.Label>
                  <DateRangePicker
                    onApply={(event, picker) => {
                      setFilters({
                        ...filters,
                        startDate: picker.startDate.format('YYYY-MM-DD'),
                        endDate: picker.endDate.format('YYYY-MM-DD'),
                      });
                    }}
                    onCancel={() => {
                      setFilters({
                        ...filters,
                        startDate: 'NULL',
                        endDate: 'NULL',
                      });
                    }}
                  >
                    <Button variant="outline-primary" style={{ width: '100%' }}>
                      {filters.startDate !== 'NULL' && filters.endDate !== 'NULL'
                        ? `${filters.startDate} - ${filters.endDate}`
                        : 'Select Date Range'}
                    </Button>
                  </DateRangePicker>
                </Form.Group>
              </Col>

              {/* Apply Filters Button */}
              <Col md={2} className="d-flex align-items-end">
                <Button variant="primary" onClick={fetchTaskReports} block>
                  Apply Filters
                </Button>
              </Col>
            </Row>
          </Form>

          {/* DataTable */}
          <DataTable
            columns={[
              { name: 'Task ID', selector: row => row.taskID, sortable: true },
              { name: 'Title', selector: row => row.title, sortable: true }, // Updated field name
              {
                name: 'Status',
                cell: (row) => (
                  <span
                    className="badge"
                    style={{ backgroundColor: row.effectiveStatusColor }}
                  >
                    {row.effectiveStatusName}
                  </span>
                ),
                sortable: true,
              },
              {
                name: 'Priority',
                cell: (row) => (
                  <TextColor bgColor={row.priorityColor} text={row.priorityName} />
                ),
                sortable: true,
              },
              {
                name: 'Due Date',
                selector: row => row.dueDate,
                sortable: true,
                cell: (row) => DateTimeConverter(row.dueDate),
              },
              {
                name: 'Assigned Users',
                cell: (row) =>
                  row.assignedUsers && row.assignedUsers.length > 0
                    ? row.assignedUsers.map((user) => user.userName).join(', ')
                    : 'Unassigned',
              },
            ]}
            data={tasks}
            pagination
            progressPending={loading}
            progressComponent={<Shimmer type="table" width="100%" />}
          />

          {/* Charts */}
          <Row className="mt-4">
            <Col md={6}>
              <Card>
                <Card.Header>Tasks by Status</Card.Header>
                <Card.Body>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={barChartData}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Header>Tasks by Priority</Card.Header>
                <Card.Body>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#82ca9d"
                        label
                      />
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Gantt Chart */}
          <Card className="mt-4">
            <Card.Header>Task Timeline</Card.Header>
            <Card.Body>
              <div className="gantt-container">
                {/* {ganttData.length > 0 ? (
                  <ReactGantt
                    tasks={ganttData}
                    viewMode="Week"
                    customPopupHtml={(task) => {
                      return `
                        <div class="popup">
                          <h5>${task.name}</h5>
                          <p>Start: ${task.start}</p>
                          <p>End: ${task.end}</p>
                          <p>Progress: ${task.progress}%</p>
                        </div>
                      `;
                    }}
                  />
                ) : (
                  <Shimmer type="empty" text="No tasks to display on the timeline." />
                )} */}
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </Fragment>
  );
};

export default TaskReportsPage;

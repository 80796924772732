import { getApiCommunications } from '../../APIServiceSetup';

const api = await getApiCommunications();

// Component for "/api/EmailTemplate/ListForApp"
export const EmailTemplateListForApp = async () => {
    try {
        const response = await api.get(`/api/AppEmailTemplate/ListForApp/1`);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmailTemplate/List"
export const EmailTemplateList = async () => {
    try {
        const response = await api.get('/api/AppEmailTemplate/List');
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmailTemplate/{id}"
export const EmailTemplateById = async (id) => {
    try {
        const response = await api.get(`/api/AppEmailTemplate/${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmailTemplate/Insert"
export const InsertEmailTemplate = async (emailTemplate) => {
    try {
        const response = await api.post('/api/AppEmailTemplate/Insert', emailTemplate);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmailTemplate/Update"
export const UpdateEmailTemplate = async (emailTemplate) => {
    try {
        const response = await api.post('/api/AppEmailTemplate/Update', emailTemplate);
        return response.data;
    } catch (error) {
        return error;
    }
};
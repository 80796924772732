import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Card, Col, Row, Form } from "react-bootstrap";
import {
  FilteredTaskViewList,
  UpdateTasks,
  GetAssignedUser,
  SaveTasks, 
  Change
} from "../../../../../services/ccms/taskManagement/tasks/tasks_endpoints";
import { TextColor } from "../../../../../utilities/colorUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faMapMarkerAlt,
  faBuilding,
  faUser,
  faPencil,
  faClose,
  faSave,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { LocationList } from "../../../../../services/ccms/locationManagement/location/location_endpoints";
import { DepartmentList } from "../../../../../services/ccms/departmentManagement/department/department_endpoints";
import { getMembersOfDepartment } from "../../../../../services/ccms/departmentManagement/member_in_designation/memberInDesignation_endpoints";
import { insertTaskAssignment, removeTaskAssignment } from "../../../../../services/ccms/taskManagement/taskAssignment/task_assignment_endpoints";
import { TaskPriorityList } from "../../../../../services/ccms/taskManagement/taskPriority/task_priority_endpoints";
import { TaskStatusList } from "../../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints";
import PageTitle from "../../../../layouts/PageTitle";
import Shimmer from "../../../../components/Custom/Loading/Shimmer";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { UserRightsContext } from '../../../../../context/UserRightsContext'; 
import LoadingText from '../../../../components/Custom/Loading/LoadingText';
import ReassignModal from './modals/ReassignModal'; // Import ReassignModal

const TaskCard = ({ task }) => {
  const [showModal, setShowModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [taskData, setTaskData] = useState(task);
  const [priorities, setPriorities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departMembers, setDepartMembers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const userRights = useContext(UserRightsContext);
  const [userDetails, setUserDetails] = useState(null);
  const [loadingAssignees, setLoadingAssignees] = useState(false);

  // Fetch userDetails from localStorage
  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (storedUserDetails) {
      setUserDetails(storedUserDetails);
    }
  }, []);

  const hasRequiredRights = (requiredRights) => {
    return requiredRights.every(right => userRights.includes(right));
  };

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      setLoadingAssignees(true);
      try {
        const [
          prioritiesData, 
          statusesData, 
          locationsData, 
          departmentsData, 
          assignedUsersData,
          departMembersData
        ] = await Promise.all([
          TaskPriorityList(),
          TaskStatusList(),
          LocationList(),
          DepartmentList(),
          GetAssignedUser(taskData.taskID, true),
          getMembersOfDepartment(taskData.departmentID),
        ]);

        setPriorities(prioritiesData.results);
        setStatuses(statusesData.results);
        setLocations(locationsData.results);
        setDepartments(departmentsData.results);
        setAssignedUsers(assignedUsersData.results || []);
        setDepartMembers(departMembersData.results);

        setTaskData((prevData) => ({
          ...prevData,
          assignedUsers: assignedUsersData.results,
        }));
      } catch (error) {
        console.error("Failed to fetch initial data", error);
      } finally {
        setIsLoading(false);
        setLoadingAssignees(false); 
      }
    };

    fetchInitialData();
  }, [taskData.taskID]);

  const loadAssignedMembers = async () => {
    setIsLoading(false);
    try {
      const response = await GetAssignedUser(task.taskID, true);
      setAssignedUsers(response.results || []);
    }
    catch(error) {
      console.error('Failed to load assigned members', error);
    }
    setIsLoading(false);
  };

  const foreceUpdate = async () => {
    // setIsLoading(true);
    try {
      // Update AssignedUsers, TaskData, etc
      const updatedTaskData = await FilteredTaskViewList({
        taskID: "" + taskData.taskID,
        startDate: "NULL",
        endDate: "NULL",
        search: "NULL",
        priorityID: "NULL",
        statusID: "NULL",
        locationID: "NULL",
        departmentID: "NULL",
        assignedBy: "NULL",
        createdBy: "NULL",
      });

      setTaskData(updatedTaskData.results[0]);

      const response = await GetAssignedUser(task.taskID, true);
      setAssignedUsers(response.results || []);
    }
    catch(error) {
      console.error('Failed to load assigned members', error);
    }
    setIsLoading(false);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowReassignModal = () => setShowReassignModal(true);
  const handleCloseReassignModal = () => setShowReassignModal(false);

  const handleUpdate = async (updatedTask) => {
    setIsUpdating(true);
    try {
      const success = await SaveTasks(updatedTask);
      if (success.status === "Success") {
        const returned_updatedTask = success.results;
        setTaskData(returned_updatedTask);
      }
    } finally {
      setIsUpdating(false);
      handleCloseModal();
    }
  };

  const handleReassign = async () => {
    if (selectedMember) {
      const reAssignData = {
        taskID: taskData.taskID,
        userId: selectedMember,
        isRemoved: false,
        assignedDate: new Date().toISOString(),
      };

      try {
        const response = await insertTaskAssignment(reAssignData);
        if (response.status === "Success") {
          const taskFilters = {
            taskID: "" + taskData.taskID,
            startDate: "NULL",
            endDate: "NULL",
            search: "NULL",
            priorityID: "NULL",
            statusID: "NULL",
            locationID: "NULL",
            departmentID: "NULL",
            assignedBy: "NULL",
            createdBy: "NULL",
          };
          const updatedTask = await FilteredTaskViewList(taskFilters);
          setTaskData(updatedTask.results[0]);
          handleCloseReassignModal();
          Swal.fire(response.title, response.message, 'success');
        } else {
          Swal.fire({ title: response.title, text: response.message, icon: 'error' });
        }
      } catch (error) {
        console.error("Failed to reassign task", error);
      }
    }
  };

  const handleAddMember = async () => {
    setIsAssigning(true);
    if (selectedMember) {
      const response = await insertTaskAssignment({ taskID: task.taskID, userId: selectedMember });

      if (response.status === "Success") {         
        setSelectedMember("");
        loadAssignedMembers(); // Refresh the assigned members list
      }
    }
    setIsAssigning(false);
  };

  const handleRemoveMember = async (userId) => {
    setIsRemoving(true);
    try {
      const response = await removeTaskAssignment(task.taskID, userId);
      if (response.status === "Success") {
        loadAssignedMembers(); // Refresh the assigned members list
      }
    } catch (error) {
      console.error('Failed to remove member', error);
    }
    setIsRemoving(false);
  };

  const handleCloseTask = async () => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to close this task? This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, close it!',
        cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
        setIsUpdating(true);
        toast.info('Closing the task...');

        try {
            taskData.statusID = 4; // Assuming status 4 is the 'Closed' status
            const success = await SaveTasks(taskData);

            if (success.status === 'Success') {
                const returned_updatedTask = success.results;
                setTaskData(returned_updatedTask);

                Swal.fire({
                    title: 'Closed!',
                    text: 'The task has been successfully closed.',
                    icon: 'success',
                });

                toast.success('Task closed successfully.');
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: success.message || 'Failed to close the task.',
                    icon: 'error',
                });
                toast.error('Failed to close the task.');
            }
        } catch (error) {
            console.error('Error closing the task:', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred while closing the task.',
                icon: 'error',
            });
            toast.error('An error occurred while closing the task.');
        } finally {
            setIsUpdating(false);
            handleCloseModal();
        }
    } else {
        toast.info('Task close action canceled.');
    }
};

  return isLoading ? (
    <Shimmer type="sections" />
  ) : (
    <>
      <PageTitle
        motherMenu="Tasks"
        motherMenuPath="/app/tasks"
        activeMenu="View Task"
        button={
          userDetails && (hasRequiredRights(['TaskManagementAdmin']) || taskData.createdBy === userDetails.userId) ? (
            <Button variant="primary" size="md" onClick={handleShowModal}>
              <FontAwesomeIcon icon={faPencil} /> Edit
            </Button>
          ) : null
        }
      />
      <Card className="shadow">
        <Card.Body>
          <Row>
            <Col md={10}>
              <h1>{taskData.title}</h1>
            </Col>
            {userDetails && (hasRequiredRights(['TaskManagementAdmin']) || taskData.createdBy === userDetails.userId) ? (
              <Col className="text-end" md={2}>
                <Button
                style={{ width: "10em", marginTop: '1em' }}
                  variant="outline-info"
                  size="sm"
                  onClick={handleShowReassignModal}
                >
                  <FontAwesomeIcon icon={faSync} /> Reassign
                </Button>
                {taskData.statusID === 3 ? (
                <Button
                style={{ width: "10em", marginTop: '1em' }}
                 variant="success" size='sm'
                 onClick={handleCloseTask}>
                  <FontAwesomeIcon icon={faCheckCircle} /> Close Task
                 </Button>) : null}
              </Col>
            ) : null}
          </Row>
          <Card.Text
            className="text-danger p-0 m-0"
            style={{ fontSize: "0.9em", fontStyle: "italic" }}
          >
            Due Date {new Date(taskData.dueDate).toLocaleString()}
          </Card.Text>
          <Card.Text style={{ fontWeight: "200" }}>
            {taskData.description}
            <br />
            <span className="text-muted">Created by: <span className="badge badge-secondary ms-2">{taskData.createdByName}</span></span>
          </Card.Text>
          <Row>
            <Col>
              <div className="widget-stat card shadow-sm" style={{ backgroundColor: taskData.statusColor }}>
                <div className="card-body p-4">
                  <div className="media">
                    <span className="me-3">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <div className="media-body text-end">
                      <h5 style={{ fontWeight: "900" }}>
                        <TextColor bgColor={taskData.statusColor} text="Status" />
                      </h5>
                      <p className="mb-1" style={{ fontSize: "0.8em" }}>
                        <TextColor bgColor={taskData.statusColor} text={taskData.statusName} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="widget-stat card shadow" style={{ backgroundColor: taskData.priorityColor }}>
                <div className="card-body p-4">
                  <div className="media">
                    <span className="me-3">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                    <div className="media-body text-end">
                      <h5 style={{ fontWeight: "900" }}>
                        <TextColor bgColor={taskData.priorityColor} text="Priority" />
                      </h5>
                      <p className="mb-1" style={{ fontSize: "0.8em" }}>
                        <TextColor bgColor={taskData.priorityColor} text={taskData.priorityName} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="widget-stat card shadow" style={{ backgroundColor: "#cfe2ff" }}>
                <div className="card-body p-4">
                  <div className="media">
                    <span className="me-3">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </span>
                    <div className="media-body text-end">
                      <h5 style={{ fontWeight: "900" }}>
                        <TextColor bgColor="#cfe2ff" text="Campus" />
                      </h5>
                      <p className="mb-1" style={{ fontSize: "0.8em" }}>
                        <TextColor bgColor="#cfe2ff" text={taskData.locationName} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="widget-stat card shadow" style={{ backgroundColor: "#d1e7dd" }}>
                <div className="card-body p-4">
                  <div className="media">
                    <span className="me-3">
                      <FontAwesomeIcon icon={faBuilding} />
                    </span>
                    <div className="media-body text-end">
                      <h5 style={{ fontWeight: "900" }}>
                        <TextColor bgColor="#d1e7dd" text="Department" />
                      </h5>
                      <p className="mb-1" style={{ fontSize: "0.8em" }}>
                        <TextColor bgColor="#d1e7dd" text={taskData.departmentName} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="widget-stat card shadow" style={{ backgroundColor: "#f8d7da" }}>
                <div className="card-body p-4">
                  <div className="media">
                    <span className="me-3">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <div className="media-body text-end">
                      <h5 style={{ fontWeight: "900" }}>
                        <TextColor bgColor="#f8d7da" text="Assigned" />
                      </h5>

                      {loadingAssignees ? (
                        <LoadingText text='Loading' />
                      ) : assignedUsers !== null && assignedUsers.length > 0 ? (
                        assignedUsers.map((user) => (
                          <span
                            style={{ fontSize: "0.8em" }}
                            className="badge badge-success"
                            key={user.userId}
                          >
                            <TextColor bgColor="#f8d7da" text={user.firstName} />
                          </span>
                        ))
                      ) : (
                        <span style={{ fontSize: "0.8em" }} className="badge badge-warning">
                          No members assigned
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formTitle">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={taskData.title}
                    onChange={(e) =>
                      setTaskData({ ...taskData, title: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formDueDate">
                  <Form.Label>Due Date</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="dueDate"
                    value={new Date(taskData.dueDate).toISOString().slice(0, 16)}
                    onChange={(e) =>
                      setTaskData({ ...taskData, dueDate: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formLocation">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    as="select"
                    name="locationID"
                    value={taskData.locationID}
                    onChange={(e) =>
                      setTaskData({ ...taskData, locationID: e.target.value })
                    }
                  >
                    {locations.map((location) => (
                      <option
                        key={location.locationID}
                        value={location.locationID}
                      >
                        {location.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formDepartment">
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    as="select"
                    name="departmentID"
                    value={taskData.departmentID}
                    onChange={(e) =>
                      setTaskData({ ...taskData, departmentID: e.target.value })
                    }
                  >
                    {departments.map((department) => (
                      <option
                        key={department.departmentID}
                        value={department.departmentID}
                      >
                        {department.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formStatus">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="statusID"
                    value={taskData.statusID}
                    onChange={(e) =>
                      setTaskData({ ...taskData, statusID: e.target.value })
                    }
                  >
                    {statuses.map((status) => (
                      <option key={status.statusID} value={status.statusID}>
                        {status.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formPriority">
                  <Form.Label>Priority</Form.Label>
                  <Form.Control
                    as="select"
                    name="priorityID"
                    value={taskData.priorityID}
                    onChange={(e) =>
                      setTaskData({ ...taskData, priorityID: e.target.value })
                    }
                  >
                    {priorities.map((priority) => (
                      <option key={priority.priorityID} value={priority.priorityID}>
                        {priority.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="description"
                    value={taskData.description}
                    onChange={(e) =>
                      setTaskData({ ...taskData, description: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faClose} /> Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdate(taskData)}
            disabled={isUpdating}
          >
            <FontAwesomeIcon icon={faSave} />
            {isUpdating ? " Updating..." : " Update"}
          </Button>
        </Modal.Footer>
      </Modal>

      <ReassignModal
        show={showReassignModal}
        handleClose={handleCloseReassignModal}
        departMembers={departMembers}
        selectedMember={selectedMember}
        setSelectedMember={setSelectedMember}
        handleReassign={handleReassign}
        assignedMembers={assignedUsers}
        handleAddMember={handleAddMember}
        handleRemoveMember={handleRemoveMember}
        isAssigning={isAssigning}
        isRemoving={isRemoving}
        isLoading={isLoading}
      />
    </>
  );
};

export default TaskCard;

import axios from 'axios';
import swal from "sweetalert";
import { login as ccmsLogin, refresh } from '../services/ccms/userManagement/login/login_endpoint';
import {
    loginConfirmedAction,
    logoutAndClearLocalStorage,
} from '../redux/actions/AuthActions';

export async function refreshToken() {
    const refreshToken = localStorage.getItem('token'); // Get the refresh token from local storage
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }
    try {
        const response = await refresh(refreshToken); // Pass token as a string
        if (response.status === 'Success') {
            const newTokenDetails = response.results;
            saveTokenInLocalStorage(newTokenDetails); // Save the new token details
            return newTokenDetails.ApiKey; // Return the new token
        } else {
            throw new Error('Failed to refresh token');
        }
    } catch (error) {
        throw new Error('Failed to refresh token');
    }
}

export function signUp(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export async function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    const response = await ccmsLogin(postData);
    return response;
}

export function formatError(errorResponse) {
    swal("Oops, " + errorResponse.title, errorResponse.message, "error");
}

export function saveTokenInLocalStorage(tokenDetails) {
    const expiryDate = new Date(new Date().getTime() + tokenDetails.expiresIn * 1000);

    const userDetails = {
        userId: tokenDetails.userId,
        apiKey: tokenDetails.apiKey,
        startDate: tokenDetails.startDate,
        expiryDate: expiryDate.toISOString(),
        memberID: tokenDetails.memberID,
        isActive: tokenDetails.isActive,
        firstName: tokenDetails.firstName,
        surname: tokenDetails.surname,
        cellphone: tokenDetails.cellphone,
        email: tokenDetails.email,
        avatar: tokenDetails.avatar
    };

    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    localStorage.setItem('token', tokenDetails.apiKey);
    localStorage.setItem('userID', tokenDetails.userId);
    // if(tokenDetails.rights && tokenDetails.rights.length > 0)
    // {
    //     localStorage.setItem('userRights', JSON.stringify(tokenDetails.rights));
    // }
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(logoutAndClearLocalStorage(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logoutAndClearLocalStorage(navigate));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expiryDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logoutAndClearLocalStorage(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));
    
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

import React from 'react';

export const FriendlyDuration = ({ duration }) => {
  const minutesInAnHour = 60;
  const hoursInADay = 8;
  const daysInAWeek = 5;
  const daysInAMonth = 4 * daysInAWeek;

  const totalHours = Math.floor(duration / minutesInAnHour);
  const minutes = duration % minutesInAnHour;

  const months = Math.floor(totalHours / (hoursInADay * daysInAMonth));
  const weeks = Math.floor((totalHours % (hoursInADay * daysInAMonth)) / (hoursInADay * daysInAWeek));
  const days = Math.floor((totalHours % (hoursInADay * daysInAWeek)) / hoursInADay);
  const hours = totalHours % hoursInADay;

  return (
    <span>
      {months > 0 && `${months} month${months > 1 ? 's' : ''} `}
      {weeks > 0 && `${weeks} week${weeks > 1 ? 's' : ''} `}
      {days > 0 && `${days} day${days > 1 ? 's' : ''} `}
      {hours > 0 && `${hours} hr${hours > 1 ? 's' : ''} `}
      {minutes > 0 && `${minutes} min${minutes > 1 ? 's' : ''}`}
    </span>
  );
};


// import axios from 'axios';
// import { getConfig } from '../../configs/pubConfig';
// import { store } from '../../redux/store';
// import { refreshToken, logoutAndClearLocalStorage } from '../../redux/actions/AuthActions'; // Import the correct actions

// const createApiInstance = async (baseUrl) => {
//   const config = await getConfig();
//   const instance = axios.create({ baseURL: baseUrl });

//   // Refresh token handling variables
//   let isRefreshing = false;
//   let refreshSubscribers = [];

//   // Handle token refreshed
//   const onTokenRefreshed = (newToken) => {
//     refreshSubscribers.map((callback) => callback(newToken));
//     refreshSubscribers = [];
//   };

//   // Add subscriber for token refresh
//   const addRefreshSubscriber = (callback) => {
//     refreshSubscribers.push(callback);
//   };

//   // Request interceptor to add Authorization header
//   instance.interceptors.request.use(request => {
//     const token = localStorage.getItem('token');
//     const userDetails = JSON.parse(localStorage.getItem('userDetails'));
//     if (token) {
//       request.headers['Authorization'] = `Bearer ${token}`; // Use Bearer token
//       request.headers['fullName'] = `${userDetails.firstName} ${userDetails.surname}`;
//       request.headers['userID'] = userDetails.userId;
//     }
//     return request;
//   }, error => {
//     return Promise.reject(error);
//   });

//   // Response interceptor to handle 401 Unauthorized errors
//   instance.interceptors.response.use(response => {
//     return response;
//   }, async (error) => {
//     const originalRequest = error.config;
//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       if (!isRefreshing) {
//         isRefreshing = true;
//         try {
//           const newToken = await store.dispatch(refreshToken());
//           isRefreshing = false;
//           onTokenRefreshed(newToken);
//         } catch (refreshError) {
//           isRefreshing = false;
//           store.dispatch(logoutAndClearLocalStorage());
//           return Promise.reject(refreshError);
//         }
//       }
//       return new Promise((resolve) => {
//         addRefreshSubscriber((newToken) => {
//           originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
//           resolve(axios(originalRequest));
//         });
//       });
//     } else {
//       const errorMessage = error.response?.data?.message || 'An error occurred. Please try again later.';
//       return Promise.reject({
//         status: 'Failed',
//         title: 'Error',
//         message: errorMessage
//       });
//     }
//   });

//   return instance;
// };

// // Initialize Axios instances
// let api_users, api_members, api_departments, api_locations, api_tasks, api_communications;

// export const initializeApiUsers = async () => {
//   const config = await getConfig();
//   api_users = await createApiInstance(config.API_CCMS_USER_MANAGEMENT_URL);
//   return api_users;
// };

// export const initializeApiMembers = async () => {
//   const config = await getConfig();
//   api_members = await createApiInstance(config.API_CCMS_MEMBERS_MANAGEMENT_URL);
//   return api_members;
// };

// export const initializeApiDepartments = async () => {
//   const config = await getConfig();
//   api_departments = await createApiInstance(config.API_CCMS_DEPARTMENTS_MANAGEMENT_URL);
//   return api_departments;
// };

// export const initializeApiLocations = async () => {
//   const config = await getConfig();
//   api_locations = await createApiInstance(config.API_CCMS_LOCATIONS_MANAGEMENT_URL);
//   return api_locations;
// };

// export const initializeApiTasks = async () => {
//   const config = await getConfig();
//   api_tasks = await createApiInstance(config.API_CCMS_TASK_MANAGEMENT_URL);
//   return api_tasks;
// };

// export const initializeApiCommunications = async () => {
//   const config = await getConfig();
//   api_communications = await createApiInstance(config.API_CCMS_COMMUNICATION_MANAGEMENT_URL);
//   return api_communications;
// };

// export const getApiUsers = async () => {
//   if (!api_users) {
//     await initializeApiUsers(); // Initialize api_users if it's not already initialized
//   }
//   return api_users; // Return the Axios instance
// };

// export const getApiMembers = async () => {
//   if (!api_members) {
//     await initializeApiMembers(); // Initialize api_members if it's not already initialized
//   }
//   return api_members; // Return the Axios instance
// };

// export const getApiDepartments = async () => {
//   if (!api_departments) {
//     await initializeApiDepartments(); // Initialize api_departments if it's not already initialized
//   }
//   return api_departments; // Return the Axios instance
// };

// export const getApiLocations = async () => {
//   if (!api_locations) {
//     await initializeApiLocations(); // Initialize api_locations if it's not already initialized
//   }
//   return api_locations; // Return the Axios instance
// };

// export const getApiTasks = async () => {
//   if (!api_tasks) {
//     await initializeApiTasks(); // Initialize api_tasks if it's not already initialized
//   }
//   return api_tasks; // Return the Axios instance
// };

// export const getApiCommunications = async () => {
//   if (!api_communications) {
//     await initializeApiCommunications(); // Initialize api_communications if it's not already initialized
//   }
//   return api_communications; // Return the Axios instance
// };

// export { api_users, api_members, api_departments, api_locations, api_tasks, api_communications };

import axios from 'axios';
import { getConfig } from '../../configs/pubConfig';
import { store } from '../../redux/store';
import { refreshToken, logoutAndClearLocalStorage } from '../../redux/actions/AuthActions'; // Import the correct actions

// Initialize token and user details from localStorage
let token = null;
let userDetails = null;

// Axios instances for each API
let api_system = null;
let api_users = null;
let api_members = null;
let api_departments = null;
let api_locations = null;
let api_tasks = null;
let api_communications = null;
let api_cpcs = null;
let api_hr = null;
let api_file = null;

// Cache timeout
const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes
let cache = {};

// Helper functions for cache handling
const isCacheValid = (cacheKey) => {
  const cachedData = cache[cacheKey];
  if (!cachedData) return false;
  const now = new Date().getTime();
  return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = {
    data,
    timestamp: new Date().getTime(),
  };
};

// Function to clear all caches or specific ones if needed
const clearRelevantCache = () => {
  cache = {}; // Clear all caches or selectively clear based on your requirements
};

// Token refresh handling variables
let isRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (newToken) => {
  refreshSubscribers.map((callback) => callback(newToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// Create Axios instance
const createAxiosInstance = async (baseURL) => {
  const apiInstance = axios.create({ baseURL });

  // Add request interceptor
  apiInstance.interceptors.request.use(request => {
    token = localStorage.getItem('token');
    userDetails = JSON.parse(localStorage.getItem('userDetails'));

    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
      if (userDetails) {
        request.headers['fullName'] = `${userDetails.firstName} ${userDetails.surname}`;
        request.headers['userID'] = userDetails.userId;
      }
    }
    return request;
  }, error => {
    return Promise.reject(error);
  });

  // Add response interceptor
  apiInstance.interceptors.response.use(response => {
    return response;
  }, async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newToken = await store.dispatch(refreshToken());
          isRefreshing = false;
          onTokenRefreshed(newToken);
        } catch (refreshError) {
          isRefreshing = false;
          store.dispatch(logoutAndClearLocalStorage());
          return Promise.reject(refreshError);
        }
      }
      return new Promise((resolve) => {
        addRefreshSubscriber((newToken) => {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(axios(originalRequest));
        });
      });
    } else {
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again later.';
      return Promise.reject({
        status: 'Failed',
        title: 'Error',
        message: errorMessage
      });
    }
  });

  return apiInstance;
};

// Generic initialize function for all APIs
const initializeApiService = async (serviceInstance, configUrl, serviceName) => {
  if (!serviceInstance) {
    const config = await getConfig();
    serviceInstance = await createAxiosInstance(config[configUrl]);
    // Assign the created instance back to the corresponding service variable
    switch (serviceName) {
      case 'api_system':
        api_system = serviceInstance;
        break;
      case 'api_users':
        api_users = serviceInstance;
        break;
      case 'api_members':
        api_members = serviceInstance;
        break;
      case 'api_departments':
        api_departments = serviceInstance;
        break;
      case 'api_locations':
        api_locations = serviceInstance;
        break;
      case 'api_tasks':
        api_tasks = serviceInstance;
        break;
      case 'api_communications':
        api_communications = serviceInstance;
        break;
      case 'api_cpcs':
        api_cpcs = serviceInstance;
        break;
      case 'api_hr':
        api_hr = serviceInstance;
        break;
      case 'api_file':
        api_file = serviceInstance;
        break;
      default:
        break;
    }
  }
  return serviceInstance;
};

// Export API initializers and getters
export const getApiSystem = async () => {
  return await initializeApiService(api_system, 'API_CCMS_SYSTEM_MANAGEMENT_URL', 'api_system');
};

export const getApiUsers = async () => {
  return await initializeApiService(api_users, 'API_CCMS_USER_MANAGEMENT_URL', 'api_users');
};

export const getApiMembers = async () => {
  return await initializeApiService(api_members, 'API_CCMS_MEMBERS_MANAGEMENT_URL', 'api_members');
};

export const getApiDepartments = async () => {
  return await initializeApiService(api_departments, 'API_CCMS_DEPARTMENTS_MANAGEMENT_URL', 'api_departments');
};

export const getApiLocations = async () => {
  return await initializeApiService(api_locations, 'API_CCMS_LOCATIONS_MANAGEMENT_URL', 'api_locations');
};

export const getApiTasks = async () => {
  return await initializeApiService(api_tasks, 'API_CCMS_TASK_MANAGEMENT_URL', 'api_tasks');
};

export const getApiCommunications = async () => {
  return await initializeApiService(api_communications, 'API_CCMS_COMMUNICATION_MANAGEMENT_URL', 'api_communications');
};

export const getApiCpcs = async () => {
  return await initializeApiService(api_cpcs, 'API_CCMS_CPC_REPORT_MANAGEMENT_URL', 'api_cpcs');
};

export const getApiHr = async () => {
  return await initializeApiService(api_hr, 'API_CCMS_HR_MANAGEMENT_URL', 'api_hr');
};

export const getApiFile = async () => {
  return await initializeApiService(api_file, 'API_CCMS_FILE_MANAGEMENT_URL', 'api_file');
};

export { api_system, api_users, api_members, api_departments, api_locations, api_tasks, api_communications, api_cpcs, api_hr, api_file };

import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Row, Button, Form, Spinner, Alert, Dropdown } from "react-bootstrap";
import Shimmer from "../../../../components/Custom/Loading/Shimmer";
import {
  TaskCommentInsert,
  TaskCommentUpdate,
  TaskCommentDelete,
  TaskCommentCommentListView,
} from "../../../../../services/ccms/taskManagement/taskComments/task_comment_endpoints";
import PerfectScrollbar from "react-perfect-scrollbar";
import profile from "../../../../../images/empty_user.jpg";
import { FriendlyDateTime } from "../../../../../utilities/convertDateTime";
import { useAutoGrow } from "../../../../components/Custom/Text/useAutoGrow";
import Swal from "sweetalert2";
import "react-perfect-scrollbar/dist/css/styles.css";
import TaskProgressBar from '../../../../components/Custom/Select/TaskProgressBar';

const TaskComments = ({ taskID, currentStage, stages, onStageChange }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [editingComment, setEditingComment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const textareaRef = useAutoGrow(null);
  const [rows, setRows] = useState(4);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const commentBoxRef = useRef(null);

  useEffect(() => {
    const fetchComments = async () => {
        try {
            const commentListView = await TaskCommentCommentListView(taskID);
            setComments(commentListView.results);
        } catch (err) {
            setError("Failed to load comments");
        } finally {
            setLoading(false);
        }
    };

    // // Wrap the fetchComments function in a task and add it to the queue
    // const task = async () => {
    //     setLoading(true);
    //     await fetchComments();
    // };
    // addTaskToQueue(task, 'high'); // Add to queue with low priority since it's a background fetch
    
    fetchComments();

}, [taskID]);

  const handleEditComment = (comment) => {
    setEditingComment(comment);
    setNewComment(comment.comment);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleCommentSubmit = async () => {
    if (newComment.trim() === "") return; // || hasNewerComment()

    setSubmitting(true);
        const commentData = {
            commentID: 0,
            taskID: taskID,
            comment: newComment,
            commentDate: new Date(),
            createdBy: userDetails.userId,
        };

        try {
            const insertResponse = await TaskCommentInsert(commentData);
            if (insertResponse.status === "Success") {
                const commentListView = await TaskCommentCommentListView(taskID, true);
                setComments(commentListView.results);
                setNewComment("");
            } else {
                setError("Failed to add comment");
            }
        } catch (err) {
            setError("Failed to add comment");
        } finally {
            setSubmitting(false);
        }
};

const handleUpdateComment = async () => {
    if (newComment.trim() === "") return;
    setSubmitting(true);
    const commentData = {
        ...editingComment,
        comment: newComment,
    };

    try {
        const updateResponse = await TaskCommentUpdate(commentData);
        if (updateResponse.status === "Success") {
            const commentListView = await TaskCommentCommentListView(taskID, true);
            setComments(commentListView.results);
            setEditingComment(null);
            setNewComment("");
        } else {
            setError("Failed to update comment");
        }
    } catch (err) {
        setError("Failed to update comment");
    } finally {
        setSubmitting(false);
    }
};

const handleDeleteComment = async (commentData) => {
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "Do you really want to delete this comment?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
});

if (result.isConfirmed) {
    setSubmitting(true);
    try {
        const deleteResponse = await TaskCommentDelete(commentData);
        if (deleteResponse.status === "Success") {
            const commentListView = await TaskCommentCommentListView(taskID, true);
            setComments(commentListView.results);
            Swal.fire(
                'Deleted!',
                'Your comment has been deleted.',
                'success'
            );
        } else {
            setError("Failed to delete comment");
        }
    } catch (err) {
        setError("Failed to delete comment");
    } finally {
        setSubmitting(false);
    }
};
};

  const canEditOrDelete = (comment) => {
    const commentDate = new Date(comment.commentDate);
    const now = new Date();
    const diffMinutes = (now - commentDate) / (1000 * 60);
    return diffMinutes <= 10 && comment.createdBy === userDetails.userId;
  };

  const hasNewerComment = () => {
    const latestComment = comments[comments.length - 1];
    return latestComment && latestComment.createdBy !== userDetails.userId;
  };

  return (
    <Card className="mt-0">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center border-bottom  flex-wrap">
          <div className="d-flex align-items-center pb-3">
            <div className="fillow-design">
              <a href="#">
                <i className="fas fa-user-friends"></i>
              </a>
            </div>
            <div className="ms-3">
              <h4 className="fs-20 font-w700">Comments</h4>
            </div>
          </div>
          <div className="">
            <TaskProgressBar
              currentStage={currentStage}
              taskID={taskID}
              onChange={onStageChange}
              stages={stages}
              shadow={true}
              textColor="#FFFFFF"
            />
          </div>
        </div>
        <div id="chartBox">
          <PerfectScrollbar className="chat-window"
            style={{
              padding: "2em 0",
              maxHeight: "50vh",
              minHeight: comments.length > 0 ? "20vh" : "auto",
              overflowY: comments.length > 3 ? "auto" : "scroll",
              overflowX: "hidden",
            }}>
              <div className="chat-box-area">
                {/* <PerfectScrollbar className="dlab-scroll"> */}
                <div id="ChatWindowInner" className="dz-scroll" ref={commentBoxRef}>
                  {loading ? (
                    <Shimmer type="item" />
                  ) : (
                    comments.map((comment) => (
                      <Row
                        key={comment.commentID}
                        className={`mb-3 ${
                          comment.createdBy === userDetails.userId
                            ? "text-end"
                            : ""
                        }`}
                      >
                        {comment.createdBy === userDetails.userId ? (
                          <div className="media mb-4 justify-content-end align-items-end">
                            <div className="message-sent">
                              <span className="fs-12">Me</span>
                              <div className="d-flex align-items-center">
                                {canEditOrDelete(comment) && (
                                  <Dropdown className="me-2">
                                    <Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                        <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                        <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                      </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right" alignRight>
                                      <Dropdown.Item onClick={() => handleEditComment(comment)}>Edit</Dropdown.Item>
                                      <Dropdown.Item onClick={() => handleDeleteComment(comment)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}

                                <div style={{ flex: 1 }}>
                                  <p
                                    className="mb-1"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {comment.comment}
                                  </p>
                                </div>
                              </div>
                              <span className="fs-12">
                                <FriendlyDateTime
                                  dateInput={comment.commentDate}
                                />
                              </span>
                            </div>
                            <div className="image-box ms-sm-4 ms-2 mb-4">
                              <img
                                src={comment.createdByAvatar || profile}
                                alt=""
                                className="rounded-circle img-1"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="media my-4 justify-content-start align-items-start">
                            <div className="image-box me-sm-4 me-2">
                              <img
                                src={comment.createdByAvatar || profile}
                                alt=""
                                className="rounded-circle img-1"
                              />
                            </div>
                            <div className="message-received">
                              <span className="fs-12">
                                {comment.createdByFirstName +
                                  " " +
                                  comment.createdByLastName}
                              </span>
                              <p
                                className="mb-1 me-5"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                {comment.comment}
                              </p>
                              <span className="fs-12">
                                <FriendlyDateTime
                                  dateInput={comment.commentDate}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </Row>
                    ))
                  )}
                  {error && <Alert variant="danger">{error}</Alert>}
                </div>
                {/* </PerfectScrollbar> */}
              </div>
            </PerfectScrollbar>
          </div>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col xs={10} md={10} className="text-center">
            <Form.Group className="border-0 type-massage mt-3">
              <Form.Control
                as="textarea"
                rows={rows}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
                // ref={textareaRef}
                style={{ resize: "none", overflow: "hidden" }}
                // disabled={hasNewerComment()}
              />
            </Form.Group>
          </Col>
          <Col
            xs={2}
            md={2}
            className="d-flex align-items-end justify-content-end align-items-center"
          >
            <Button
              variant="primary"
              disabled={submitting } // || hasNewerComment()
              onClick={editingComment ? handleUpdateComment : handleCommentSubmit}
            >
              <i className="far fa-paper-plane me-2"></i>
              {submitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {editingComment ? " Updating..." : " Sending..."}
                </>
              ) : (
                editingComment ? "Update" : "Send"
              )}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default TaskComments;

import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { formatDistanceToNow, formatDistance } from 'date-fns';
import { TextColor } from '../../../../utilities/colorUtils';
import { Button, FormControl, FormSelect, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const TasksTable = ({ tasks }) => {
  const [departmentFilter, setDepartmentFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  // Get distinct filter options from tasks
  const distinctDepartments = [...new Set(tasks.map(task => task.departmentName))];
  const distinctLocations = [...new Set(tasks.map(task => task.locationName))];
  const distinctStatuses = [...new Set(tasks.map(task => task.statusName))];

  // Extract distinct users from TaskAssignedUsers
  const distinctUsers = [...new Set(tasks.flatMap(task => 
    task.TaskAssignedUsers.map(user => `${user.firstName} ${user.surname}`)
  ))];

  const filteredTasks = useMemo(() => {
    return tasks.filter(task => {
      return (
        (departmentFilter === '' || task.departmentName === departmentFilter) &&
        (locationFilter === '' || task.locationName === locationFilter) &&
        (statusFilter === '' || task.statusName === statusFilter) &&
        (userFilter === '' || task.TaskAssignedUsers.some(user => `${user.firstName} ${user.surname}` === userFilter)) &&
        (searchText === '' || task.title.toLowerCase().includes(searchText.toLowerCase()))
      );
    });
  }, [departmentFilter, locationFilter, statusFilter, userFilter, searchText, tasks]);

  const columns = useMemo(() => [
    { 
      name: 'Task ID', 
      selector: row => row.taskID, 
      sortable: true, 
      width: '100px' 
    },
    { 
      name: 'Title', 
      selector: row => row.title, 
      sortable: true, 
      wrap: true, // Enable wrapping for the title
    },
    {
      name: 'Priority',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.priorityColor }}>
          <TextColor bgColor={row.priorityColor} text={row.priorityName} />
        </span>,
      sortable: true,
      width: '100px',
    },
    { 
      name: 'Due Date', 
      selector: row => new Date(row.dueDate), // Use Date object for sorting
      sortable: true,
      format: row => new Date(row.dueDate).toLocaleDateString(),
      width: '120px' 
    },
    { 
      name: 'Days Due', 
      selector: row => new Date(row.dueDate), // Use Date object for sorting
      sortable: true,
      format: row => {
        const dueDate = new Date(row.dueDate);
        const now = new Date();
        return dueDate < now
          ? `${formatDistanceToNow(dueDate)} ago` // Show time ago for past due tasks
          : `in ${formatDistanceToNow(dueDate)}`; // Show time remaining for upcoming tasks
      },
      width: '150px'
    },
    { 
      name: 'Created By', 
      selector: row => row.createdByName, 
      sortable: true, 
      width: '150px' 
    },
    { 
      name: 'Assigned To', 
      selector: row => row.TaskAssignedUsers ? row.TaskAssignedUsers.map(user => (
        <span  className='badge me-1 badge-primary'>
            {user.firstName}
          </span>
      ))
       : '', 
      sortable: true, 
      wrap: true, // Enable wrapping for assigned users
      width: '200px' 
    },
    {
      name: 'Status',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.statusColor }}>
          <TextColor bgColor={row.statusColor} text={row.statusName} />
        </span>,
      sortable: true,
      width: '100px',
    },
    {
      name: '',
      cell: row => (
        <Button 
          variant="outline-primary" 
          size='xxs'
          onClick={() => navigate('/app/view-task', { state: row })}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '100px',
    },
  ], [navigate]);

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredTasks}
        pagination
        highlightOnHover
        defaultSortField="dueDate"
        sortFunction={(rows, field, direction) => {
          return rows.sort((a, b) => {
            const aDate = new Date(a.dueDate).getTime();
            const bDate = new Date(b.dueDate).getTime();
            return direction === 'asc' ? aDate - bDate : bDate - aDate;
          });
        }}
        subHeader
        subHeaderComponent={
          <Row className="w-100 mb-3 mt-4">
            <Col md={2}>
              <FormSelect
                value={departmentFilter}
                onChange={e => setDepartmentFilter(e.target.value)}
                size='sm'
                style={{ width: '100%', height: '35px' }}
              >
                <option value=''>All Departments</option>
                {distinctDepartments.map((dept, index) => (
                  <option key={index} value={dept}>{dept}</option>
                ))}
              </FormSelect>
            </Col>
            <Col md={2}>
              <FormSelect
                value={locationFilter}
                onChange={e => setLocationFilter(e.target.value)}
                size='sm'
                style={{ width: '100%', height: '35px' }}
              >
                <option value=''>All Locations</option>
                {distinctLocations.map((loc, index) => (
                  <option key={index} value={loc}>{loc}</option>
                ))}
              </FormSelect>
            </Col>
            <Col md={2}>
              <FormSelect
                value={statusFilter}
                onChange={e => setStatusFilter(e.target.value)}
                size='sm'
                style={{ width: '100%', height: '35px' }}
              >
                <option value=''>All Statuses</option>
                {distinctStatuses.map((status, index) => (
                  <option key={index} value={status}>{status}</option>
                ))}
              </FormSelect>
            </Col>
            <Col md={2}>
              <FormSelect
                value={userFilter}
                onChange={e => setUserFilter(e.target.value)}
                size='sm'
                style={{ width: '100%', height: '35px' }}
              >
                <option value=''>All Users</option>
                {distinctUsers.map((user, index) => (
                  <option key={index} value={user}>{user}</option>
                ))}
              </FormSelect>
            </Col>
            <Col md={4}>
              <FormControl
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                style={{ width: '100%', height: '35px' }}
                size='sm'
              />
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default TasksTable;

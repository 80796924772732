import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Card, FormControl, FormGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";
import { FeedbackListView, FeedbackMarkAsResolved, FeedbackMarkAsUnresolved } from "../../../../services/ccms/system/feedback";
import { FeedbackCategoryList } from "../../../../services/ccms/system/feedbackCategory";
import { FeedbackTypeList } from "../../../../services/ccms/system/feedbackType";
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const MySwal = withReactContent(Swal);

const sortedFeedbacksByDate = (feedbacks) => {
  return feedbacks.sort((a, b) => new Date(b.created) - new Date(a.created));
};


const Feedback = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('ALL');
  const [selectedType, setSelectedType] = useState('ALL');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null); // To store the selected feedback for viewing/editing
  const [isSaving, setIsSaving] = useState(false); // For save button loading state
  // Sort feedbacks by date before passing to DataTable
  const sortedFeedbacks = useMemo(() => sortedFeedbacksByDate(filteredFeedbacks), [filteredFeedbacks]);

  useEffect(() => {
    fetchInitialData();
  }, []);
  
  const fetchInitialData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const [feedbacks, categories, types] = await Promise.all([
        FeedbackListView(),
        FeedbackCategoryList(),
        FeedbackTypeList()
      ]);
      setFeedbackList(feedbacks.results);
      setFilteredFeedbacks(feedbacks.results);
      setCategoryList(categories.results);
      setTypeList(types.results);
      setIsLoaded(true);
    } catch (error) {
      toast.error('Failed to load feedbacks. Please try again later.');
      setIsLoaded(true);
    }
  }, []);
  

  // Handle filtering
  const filterFeedbacks = (searchValue, categoryValue, typeValue) => {
    let filteredList = feedbackList;

    if (categoryValue !== 'ALL') {
      filteredList = filteredList.filter(feedback => feedback.categoryID === parseInt(categoryValue));
    }

    if (typeValue !== 'ALL') {
      filteredList = filteredList.filter(feedback => feedback.typeID === parseInt(typeValue));
    }

    if (searchValue) {
      filteredList = filteredList.filter(feedback =>
        feedback.title?.toLowerCase().includes(searchValue) ||
        feedback.message?.toLowerCase().includes(searchValue) ||
        feedback.categoryName?.toLowerCase().includes(searchValue) ||
        feedback.typeName?.toLowerCase().includes(searchValue)
      );
    }

    // Sort the filtered list by date (descending) before updating state
    const sortedFilteredList = sortedFeedbacksByDate(filteredList);
    
    setFilteredFeedbacks(sortedFilteredList);
  };


  // Debounced search handler
  const debouncedFilter = useMemo(
    () =>
      debounce((searchValue, categoryValue, typeValue) => {
        filterFeedbacks(searchValue, categoryValue, typeValue);
      }, 300),
    [feedbackList]
  );

  // Handlers for search, category, and type changes
  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    debouncedFilter(searchValue, selectedCategory, selectedType);
  };

  const handleCategoryChange = (e) => {
    const categoryValue = e.target.value;
    setSelectedCategory(categoryValue);
    filterFeedbacks(searchTerm, categoryValue, selectedType);
  };

  const handleTypeChange = (e) => {
    const typeValue = e.target.value;
    setSelectedType(typeValue);
    filterFeedbacks(searchTerm, selectedCategory, typeValue);
  };

  // Open the modal to view/edit feedback
  const handleViewClick = (feedback) => {
    setSelectedFeedback(feedback);
    setShowModal(true);
  };

  // Close the modal
  const handleModalClose = () => {
    setShowModal(false);
    setSelectedFeedback(null);
  };

  // Handle feedback update
  const handleSaveFeedback = async (feedbackID) => {
    try {
      const result = await MySwal.fire({
        title: 'Are you sure?',
        text: 'Do you want to mark this feedback as resolved?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, mark it as resolved!',
        cancelButtonText: 'Cancel'
      });
  
      if (result.isConfirmed) {
        setIsSaving(true);
  
        const response = await FeedbackMarkAsResolved(feedbackID);
        if (response.status === 'Success') {
          // Update the feedback in feedbackList and filteredFeedbacks
          setFeedbackList((prevFeedbackList) =>
            prevFeedbackList.map((feedback) =>
              feedback.feedbackID === feedbackID
                ? { ...feedback, isResolved: true } // Update the isResolved to true for the specific feedback
                : feedback
            )
          );
          
          setFilteredFeedbacks((prevFilteredFeedbacks) =>
            prevFilteredFeedbacks.map((feedback) =>
              feedback.feedbackID === feedbackID
                ? { ...feedback, isResolved: true } // Update the isResolved in filtered list too
                : feedback
            )
          );

          await fetchUpdatedFeedbackList(); // Fetch the updated feedback list
  
          toast.success('Feedback marked as resolved successfully!');
        } else {
          toast.error('Failed to update feedback.');
        }
      } else {
        // toast.info('Action cancelled.');
      }
    } catch (error) {
      toast.error('Error occurred while updating feedback.');
    } finally {
      setIsSaving(false);
      handleModalClose();
    }
  };
  

  // Function to fetch only the updated feedback list while showing existing data
const fetchUpdatedFeedbackList = useCallback(async () => {
  // Keep the existing data visible, do not set loading to false here
  try {
    // Show a toast indicating that data is being fetched
    toast.info('Fetching updated feedback list, please wait...');

    // Fetch the updated feedback list only
    const feedbacks = await FeedbackListView();

    // Update the feedback list and filtered list once the new data is fetched
    setFeedbackList(feedbacks.results);
    setFilteredFeedbacks(feedbacks.results);

    // Dismiss the fetching toast and show success message
    toast.dismiss();
  } catch (error) {
    toast.error('Failed to fetch updated feedback list. Please try again later.');
  }
}, []);

// Function to handle marking feedback as resolved or unresolved
const handleFeedbackToggle = async (feedbackID, isResolved) => {
  try {
    const actionText = isResolved
      ? 'mark this feedback as unresolved'
      : 'mark this feedback as resolved';

    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${actionText}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${isResolved ? 'mark as unresolved' : 'mark as resolved'}!`,
      cancelButtonText: 'Cancel'
    });

    if (result.isConfirmed) {
      setIsSaving(true);

      const response = isResolved
        ? await FeedbackMarkAsUnresolved(feedbackID)
        : await FeedbackMarkAsResolved(feedbackID);

      if (response.status === 'Success') {
        // Update the feedback status locally
        setFeedbackList((prevFeedbackList) =>
          prevFeedbackList.map((feedback) =>
            feedback.feedbackID === feedbackID
              ? { ...feedback, isResolved: !isResolved } // Toggle isResolved based on the action
              : feedback
          )
        );

        setFilteredFeedbacks((prevFilteredFeedbacks) =>
          prevFilteredFeedbacks.map((feedback) =>
            feedback.feedbackID === feedbackID
              ? { ...feedback, isResolved: !isResolved } // Toggle isResolved in the filtered list
              : feedback
          )
        );

        toast.success(
          `Feedback marked as ${isResolved ? 'unresolved' : 'resolved'} successfully!`
        );
      } else {
        toast.error(`Failed to mark feedback as ${isResolved ? 'unresolved' : 'resolved'}.`);
      }
    }
  } catch (error) {
    toast.error('Error occurred while updating feedback.');
  } finally {
    setIsSaving(false);
  }
};


  // Columns for DataTable
  const columns = useMemo(() => [
    {
      name: 'Title',
      selector: row => row.title || 'N/A',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Date',
      selector: row => new Date(row.created).toISOString().slice(0, 16).replace('T', ' ') || 'N/A',
      sortable: true,
      wrap: true
    },
    {
      name: 'Category',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.categoryColor }}>
          {row.categoryName}
        </span>,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.typeColor }}>
          {row.typeName}
        </span>,
      sortable: true,
    },
    {
      name: 'Is Resolved',
      selector: row => row.isResolved ? 'Yes' : 'No',
      sortable: true,
      center: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => handleViewClick(row)}
          aria-label={`View feedback ${row.title}`}
        >
          View
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ], []);

  return (
    <Fragment>
      <ToastContainer /> {/* Toast notifications container */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div></div>
        <Button size='xs' onClick={fetchInitialData}>
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      </div>
      <div className="mt-1">
        <Card className="shadow">
          <Card.Body>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Form.Label>Search</Form.Label>
                  <FormControl
                    placeholder="Search by title, message, or category"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    aria-label="Search feedbacks"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Form.Label>Filter by Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    aria-label="Filter feedbacks by category"
                  >
                    <option value="ALL">All Categories</option>
                    {categoryList.map(category => (
                      <option key={category.categoryID} value={category.categoryID}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Control>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Form.Label>Filter by Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedType}
                    onChange={handleTypeChange}
                    aria-label="Filter feedbacks by type"
                  >
                    <option value="ALL">All Types</option>
                    {typeList.map(type => (
                      <option key={type.typeID} value={type.typeID}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Control>
                </FormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <Card className="shadow mt-3">
        <Card.Body>
          <div className="table-responsive">
            {isLoaded ? (
              <DataTable
                columns={columns}
                data={sortedFeedbacks}
                pagination
                highlightOnHover
                pointerOnHover
                responsive
                noHeader
                striped
                
              />
            ) : (
              <Shimmer type="table" width={'100%'} />
            )}
          </div>
        </Card.Body>
      </Card>

      {/* Feedback Details Modal */}
{/* Feedback Details Modal */}
<Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered size="lg">
  <Modal.Header closeButton>
    <Modal.Title>Feedback Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedFeedback && (
      <div className="pt-3">
        <div className="settings-form">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">Title</label>
                <input type="text" className="form-control" value={selectedFeedback.title} readOnly />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">Category</label>
                <input type="text" className="form-control" value={selectedFeedback.categoryName} readOnly />
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">Type</label>
                <input type="text" className="form-control" value={selectedFeedback.typeName} readOnly />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">Is Anonymous</label>
                <input type="text" className="form-control" value={selectedFeedback.isAnonymous ? "Yes" : "No"} readOnly />
              </div>
            </div>
            {/* New fields added here */}
            {!selectedFeedback.isAnonymous && (
              <>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">First Name</label>
                    <input type="text" className="form-control" value={selectedFeedback.firstName || 'N/A'} readOnly />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Surname</label>
                    <input type="text" className="form-control" value={selectedFeedback.surname || 'N/A'} readOnly />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Email</label>
                    <input type="email" className="form-control" value={selectedFeedback.email || 'N/A'} readOnly />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Cellphone</label>
                    <input type="text" className="form-control" value={selectedFeedback.cellphone || 'N/A'} readOnly />
                  </div>
                </div>
              </>
            )}
            {/* End of new fields */}
            <div className="row">
              <div className="form-group mb-3 col-md-12">
                <label className="form-label">Message</label>
                <textarea className="form-control" rows={5} value={selectedFeedback.message} readOnly></textarea>
              </div>

              {/* Updated Resolved field with a switch */}
              <div className="form-group mb-3 col-md-6">
                <FormGroup>
                  <Form.Check
                    type="switch"
                    id="resolved-switch"
                    label="Resolved"
                    checked={selectedFeedback.isResolved}
                    onChange={(e) => handleFeedbackToggle(selectedFeedback.feedbackID, selectedFeedback.isResolved)}
                  />
                </FormGroup>
              </div>
            </div>
          </form>
        </div>
      </div>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleModalClose} disabled={isSaving}>
      Close
    </Button>
    {/* <Button variant="primary" onClick={handleSaveFeedback} disabled={isSaving}>
      {isSaving ? 'Saving...' : 'Save Changes'}
    </Button> */}
  </Modal.Footer>
</Modal>


    </Fragment>
  );
};

export default Feedback;

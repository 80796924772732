import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import DepartmentView from './departmentView';
import { DepartmentItemView } from '../../../../../services/ccms/departmentManagement/department/department_endpoints';
import MembersCard from './membersView';
import DesignationsCard from './designationsView';

const Department = () => {
  const location = useLocation();
  const { department } = location.state || {};
  const [departmentData, setDepartmentData] = useState(department);

  const fetchDepartment = async (departmentID) => {
    try {
      const response = await DepartmentItemView(departmentID);
      if (response.results) {
        setDepartmentData(response.results);
      } else {
        console.error('Error: response.results is undefined');
      }
    } catch (error) {
      console.error('Error fetching department:', error);
    }
  };

  useEffect(() => {
    if (department?.departmentID) {
      fetchDepartment(department.departmentID);
    }
  }, [department]);

  return (
    <Fragment>
      {departmentData ? (
        <>
          <DepartmentView department={departmentData} fetchDepartment={() => fetchDepartment(departmentData.departmentID)} />
          <Row>
            <Col md={6}>
              <MembersCard departmentID={departmentData.departmentID} />
            </Col>
            <Col md={6}>
                <DesignationsCard departmentID={departmentData.departmentID} />
            </Col>
          </Row>
        </>
      ) : (
        <p>No department data available.</p>
      )}
    </Fragment>
  );
};

export default Department;

import { getApiDepartments } from '../../APIServiceSetup';

const api_departments = await getApiDepartments();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get the list of designations with caching
export const getDesignationList = async (forceUpdate = false) => {
  const cacheKey = 'designationList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/Designation/List`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching designation list", error);
    throw error;
  }
};

// Function to get a paginated list of designations with caching
export const getPaginatedDesignationList = async (page, pageSize, forceUpdate = false) => {
  const cacheKey = `paginatedDesignationList_${page}_${pageSize}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/Designation/PaginatedList`, {
      params: { page, pageSize },
    });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching paginated designation list", error);
    throw error;
  }
};

// Function to search designations with caching
export const searchDesignations = async (searchCriteria, forceUpdate = false) => {
  const cacheKey = `searchDesignations_${JSON.stringify(searchCriteria)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.post(`/api/Designation/Search`, searchCriteria);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error("Error searching designations", error);
    throw error;
  }
};

// Function to count designations with caching
export const countDesignations = async (forceUpdate = false) => {
  const cacheKey = 'countDesignations';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/Designation/Count`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error("Error counting designations", error);
    throw error;
  }
};

// Function to get designations by department ID with caching
export const getDesignationsByDepartmentId = async (departmentID, forceUpdate = false) => {
  const cacheKey = `getDesignationsByDepartmentId_${departmentID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/Designation/GetByDepartmentID/${departmentID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with departmentID ${departmentID}`, error);
    throw error;
  }
};

// Other functions (without caching)
export const getDesignationById = async (id) => {
  try {
    const response = await api_departments.get(`/api/Designation/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designation with id ${id}`, error);
    throw error;
  }
};

export const getSingleDesignation = async () => {
  try {
    const response = await api_departments.get(`/api/Designation/Single`);
    return response.data;
  } catch (error) {
    console.error("Error fetching single designation", error);
    throw error;
  }
};

export const InsertDesignation = async (designationData) => {
  try {
    const response = await api_departments.post(`/api/Designation/Insert`, designationData);
    return response.data;
  } catch (error) {
    console.error("Error inserting designation", error);
    throw error;
  }
};

export const UpdateDesignation = async (designationData) => {
  try {
    const response = await api_departments.post(`/api/Designation/Update`, designationData);
    return response.data;
  } catch (error) {
    console.error("Error updating designation", error);
    throw error;
  }
};

export const deleteDesignation = async (designationData) => {
  try {
    const response = await api_departments.post(`/api/Designation/Delete`, designationData);
    return response.data;
  } catch (error) {
    console.error("Error deleting designation", error);
    throw error;
  }
};

export const getDesignationByName = async (name) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByName/${name}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with name ${name}`, error);
    throw error;
  }
};

export const getDesignationsByDepartmentName = async (departmentName) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByDepartmentName/${departmentName}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with departmentName ${departmentName}`, error);
    throw error;
  }
};

export const getDesignationsByMemberId = async (memberID) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByMemberID/${memberID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with memberID ${memberID}`, error);
    throw error;
  }
};

export const getDesignationsByMemberIdInDepartment = async (memberID, departmentID) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByMemberIDInDepartment/${memberID}/${departmentID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with memberID ${memberID} in departmentID ${departmentID}`, error);
    throw error;
  }
};

export const getDesignationsByMemberName = async (memberName) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByMemberName/${memberName}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with memberName ${memberName}`, error);
    throw error;
  }
};

export const getDesignationsByMemberEmail = async (email) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByMemberEmail/${email}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with email ${email}`, error);
    throw error;
  }
};

export const getDesignationsByMemberCellNumber = async (cellNumber) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByMemberCellNumber/${cellNumber}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with cellNumber ${cellNumber}`, error);
    throw error;
  }
};

export const getDesignationsByMemberUserId = async (userId) => {
  try {
    const response = await api_departments.get(`/api/Designation/GetByMemberUserID/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching designations with userId ${userId}`, error);
    throw error;
  }
};

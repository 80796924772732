import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowRight,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { EmployeeDutyListView } from "../../../../../../../services/ccms/hrManagement/employeeDuty";
import { useMediaQuery } from 'react-responsive';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../../../../components/Custom/Loading/Shimmer';
import { DateTimeConverter } from '../../../../../../../utilities/convertDateTime';
import { TextColor } from '../../../../../../../utilities/colorUtils';

const StepFour = ({
  leaveApplication,
  duties,
  employees,
  handleDutyAssignment,
  currentStep,
  handlePreviousStep,
  handleNextStep,
}) => {
  const [dutyAssignments, setDutyAssignments] = useState(leaveApplication.LeaveDuties || []);
  const [loading, setLoading] = useState(false);
  const [showCustomDutyForm, setShowCustomDutyForm] = useState(false);
  const [customDuty, setCustomDuty] = useState({
    DutyID: 0,
    DutyName: '',
    DutyDescription: '',
    assignedTo: '',
  });
  const [errors, setErrors] = useState({});
  const stepRef = useRef(null);

  // Use media query to detect mobile devices
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (!duties.length) {
      fetchDuties();
    }
  }, []);

  const fetchDuties = async () => {
    setLoading(true);
    try {
      const response = await EmployeeDutyListView(leaveApplication.UserId);
      setDutyAssignments([
        ...response.results.map(duty => ({
          ...duty,
          DutyID: duty.dutyID,
          DutyName: duty.name,
          DutyDescription: duty.description,
          assignedTo: dutyAssignments.find(d => d.DutyID === duty.dutyID)?.assignedTo || '',
          UserId: dutyAssignments.find(d => d.DutyID === duty.dutyID)?.UserId || '', // Assigned to
        })),
        // Include any custom duties already added
        ...dutyAssignments.filter(d => d.DutyID === 0),
      ]);
    } catch (error) {
      console.error("Error fetching duties:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignmentChange = (dutyID, userId) => {
    const updatedDuties = dutyAssignments.map(duty => {
      if (duty.DutyID === dutyID) {
        return {
          ...duty,
          assignedTo: userId,
          UserId: userId, // Set UserId to the person assigned
        };
      }
      return duty;
    });
    setDutyAssignments(updatedDuties);
    handleDutyAssignment(updatedDuties);
  };

  const handleCustomDutyChange = (e) => {
    const { name, value } = e.target;
    setCustomDuty(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateCustomDuty = () => {
    let tempErrors = {};
    if (!customDuty.DutyName) {
      tempErrors.DutyName = 'Duty Name is required';
    }
    if (!customDuty.DutyDescription) {
      tempErrors.DutyDescription = 'Duty Description is required';
    }
    if (!customDuty.assignedTo) {
      tempErrors.assignedTo = 'Please assign this duty to an employee';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const addCustomDuty = () => {
    if (validateCustomDuty()) {
      const newCustomDuty = {
        ...customDuty,
        LeaveDutyID: 0,
        UserId: customDuty.assignedTo, // Set UserId to the person assigned
      };
      setDutyAssignments(prev => [
        ...prev,
        newCustomDuty,
      ]);
      handleDutyAssignment([
        ...dutyAssignments,
        newCustomDuty,
      ]);
      setCustomDuty({
        DutyID: 0,
        DutyName: '',
        DutyDescription: '',
        assignedTo: '',
      });
      setShowCustomDutyForm(false);
      setErrors({});
    }
  };

  // Define columns for DataTable
  const mobileColumns = [
    {
      name: 'Duty Name',
      selector: row => row.DutyName,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Assigned To',
      cell: row => (
        <select
          className="form-control"
          value={row.assignedTo}
          onChange={(e) => handleAssignmentChange(row.DutyID, e.target.value)}
        >
          <option value="">Select Employee</option>
          {employees.map(emp => (
            <option key={emp.userId} value={emp.userId}>
              {emp.firstName} {emp.lastName}
            </option>
          ))}
        </select>
      ),
    },
  ];

  const desktopColumns = [
    {
      name: 'Duty Name',
      selector: row => row.DutyName,
      sortable: true,
    },
    {
      name: 'Duty Description',
      selector: row => row.DutyDescription,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Assigned To',
      cell: row => (
        <select
          className="form-control"
          value={row.assignedTo}
          onChange={(e) => handleAssignmentChange(row.DutyID, e.target.value)}
        >
          <option value="">Select Employee</option>
          {employees.map(emp => (
            <option key={emp.userId} value={emp.userId}>
              {emp.name} {emp.surname}
            </option>
          ))}
        </select>
      ),
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p><strong>Duty Name:</strong> {data.DutyName}</p>
      <p><strong>Duty Description:</strong> {data.DutyDescription}</p>
      <p><strong>Assigned To:</strong> {
        employees.find(emp => emp.userId === data.assignedTo)
          ? `${employees.find(emp => emp.userId === data.assignedTo).name} ${employees.find(emp => emp.userId === data.assignedTo).surname}`
          : 'Not Assigned'
      }</p>
    </div>
  );

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Assign Duties to Other Employees</h4>
          {currentStep > 4 ? (
            <div>
              <p>Duties have been assigned.</p>
              <button className="btn btn-secondary btn-sm mt-3" onClick={() => handlePreviousStep(4)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
            </div>
          ) : (
            <div>
              {loading ? (
                <Shimmer type="table" width={'100%'} />
              ) : (
                <>
                  <div className="table-responsive">
                    <DataTable
                      columns={isMobile ? mobileColumns : desktopColumns}
                      data={dutyAssignments}
                      pagination
                      expandableRows={isMobile}
                      expandableRowsComponent={ExpandedComponent}
                      expandOnRowClicked={isMobile}
                      noHeader
                    />
                  </div>
                  <button
                    className="btn btn-info btn-sm mt-3"
                    onClick={() => setShowCustomDutyForm(!showCustomDutyForm)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Custom Duty
                  </button>
                  {showCustomDutyForm && (
                    <div className="mt-3">
                      <h5>Add Custom Duty</h5>
                      <div className="form-group">
                        <label>Duty Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="DutyName"
                          value={customDuty.DutyName}
                          onChange={handleCustomDutyChange}
                          onInput={handleCustomDutyChange}
                        />
                        {errors.DutyName && <small className="text-danger">{errors.DutyName}</small>}
                      </div>
                      <div className="form-group">
                        <label>Duty Description</label>
                        <textarea
                          className="form-control"
                          name="DutyDescription"
                          value={customDuty.DutyDescription}
                          onChange={handleCustomDutyChange}
                          onInput={handleCustomDutyChange}
                        />
                        {errors.DutyDescription && <small className="text-danger">{errors.DutyDescription}</small>}
                      </div>
                      <div className="form-group">
                        <label>Assign To</label>
                        <select
                          className="form-control"
                          name="assignedTo"
                          value={customDuty.assignedTo}
                          onChange={handleCustomDutyChange}
                          onInput={handleCustomDutyChange}
                        >
                          <option value="">Select Employee</option>
                          {employees.map(emp => (
                            <option key={emp.userId} value={emp.userId}>
                              {emp.name} {emp.surname}
                            </option>
                          ))}
                        </select>
                        {errors.assignedTo && <small className="text-danger">{errors.assignedTo}</small>}
                      </div>
                      <button className="btn btn-success btn-sm" onClick={addCustomDuty}>
                        Add Duty
                      </button>
                    </div>
                  )}
                </>
              )}
              <div className="mt-3">
                <button className="btn btn-primary btn-sm" onClick={handleNextStep}>
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default StepFour;

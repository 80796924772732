import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faClose } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CommentModal = ({ show, onClose, onSubmit }) => {
  const [comment, setComment] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const MIN_CHAR_LIMIT = 10;

  const handleSubmit = async () => {
    const trimmedComment = comment.trim();

    if (!isValidComment(trimmedComment)) {
      return;
    }

    setIsSending(true);
    try {
      await onSubmit(trimmedComment);
      setComment(''); // Clear the comment after sending
      setErrorMessage(''); // Clear error message
    } catch (error) {
      setErrorMessage('Failed to send comment. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  const handleCommentChange = (e) => {
    const newComment = e.target.value;
    setComment(newComment);

    // Clear error if the user corrects the text or hasn't reached the minimum character limit yet
    if (newComment.length < MIN_CHAR_LIMIT) {
      setErrorMessage(''); // Clear the error while the user is still typing
    } else if (isValidComment(newComment)) {
      setErrorMessage(''); // Clear error if the comment becomes valid
    }
  };

  const isValidComment = (text) => {
    const trimmedText = text.trim();

    // Ensure minimum length
    if (trimmedText.length < MIN_CHAR_LIMIT) {
      return false;
    }

    // Disallow excessive repeated characters
    if (/(.)\1{3,}/.test(trimmedText)) {
      if (errorMessage !== 'Your comment contains too many repeated characters.') {
        setErrorMessage('Your comment contains too many repeated characters.');
      }
      return false;
    }

    // Ensure that it's not just numbers or repetitive sequences
    if (/^\d+$/.test(trimmedText) || /^([a-zA-Z])\1+$/.test(trimmedText)) {
      if (errorMessage !== 'Your comment appears to be invalid. Please enter a meaningful sentence.') {
        setErrorMessage('Your comment appears to be invalid. Please enter a meaningful sentence.');
      }
      return false;
    }

    return true;
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Add a Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="comment">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={comment}
            onChange={handleCommentChange}
            disabled={isSending}
            isInvalid={errorMessage !== ''}
          />
          <Form.Text className={`text-${errorMessage ? 'danger' : 'success'}`}>
            {errorMessage || `You have entered ${comment.length} characters.`}
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isSending}>
          <FontAwesomeIcon icon={faClose} /> Close
        </Button>
        <Button 
          variant="primary" 
          onClick={handleSubmit} 
          disabled={isSending || !isValidComment(comment)}
        >
          <FontAwesomeIcon icon={faPaperPlane} /> {isSending ? 'Sending...' : 'Send'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommentModal;

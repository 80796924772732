import { getApiDepartments } from '../../APIServiceSetup';

const api_departments = await getApiDepartments();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Component for "/api/MemberInDepartment/List"
export const MemberInDepartmentList = async (forceUpdate = false) => {
  const cacheKey = 'MemberInDepartmentList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get('/api/MemberInDepartment/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment/Get"
export const MemberInDepartmentGet = async (id) => {
  try {
    const response = await api_departments.get('/api/MemberInDepartment/Get', { params: { id } });
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment/Insert"
export const MemberInDepartmentInsert = async (data) => {
  try {
    const response = await api_departments.post('/api/MemberInDepartment/Insert', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment/Update"
export const MemberInDepartmentUpdate = async (data) => {
  try {
    const response = await api_departments.post('/api/MemberInDepartment/Update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment/Delete"
export const MemberInDepartmentDelete = async (data) => {
  try {
    const response = await api_departments.post('/api/MemberInDepartment/Delete', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment"
export const MemberInDepartment = async () => {
  try {
    const response = await api_departments.get('/api/MemberInDepartment');
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment/MakeHead/{memberID}/{departmentID}"
export const MemberInDepartment_MakeHead = async (memberID, departmentID) => {
  try {
    const response = await api_departments.post(`/api/MemberInDepartment/MakeHead/${memberID}/${departmentID}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment/ChangeHead"
export const MemberInDepartmentMakeHead = async (data) => {
  try {
    const response = await api_departments.post('/api/MemberInDepartment/ChangeHead', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/MemberInDepartment/Members/{departmentID}"
export const DepartmentMembers = async (departmentID, forceUpdate = false) => {
  const cacheKey = `DepartmentMembers_${departmentID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/MemberInDepartment/Members/${departmentID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};


// Usage:
// const memberInDepartmentListData = await MemberInDepartmentList();
// const memberInDepartmentGetData = await MemberInDepartmentGet(id);
// const memberInDepartmentInsertData = await MemberInDepartmentInsert(data);
// const memberInDepartmentUpdateData = await MemberInDepartmentUpdate(data);
// const memberInDepartmentDeleteData = await MemberInDepartmentDelete(data);
// const memberInDepartmentData = await MemberInDepartme
import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';

const StepThree = ({
  leaveApplication,
  handleLeaveDetailChange,
  currentStep,
  handlePreviousStep,
  handleNextStep,
}) => {
  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleLeaveDetailChange(name, value);
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Enter Additional Leave Details</h4>
          {currentStep > 3 ? (
            <div>
              <p>Application Date: {format(new Date(leaveApplication.ApplicationDate), 'yyyy-MM-dd')}</p>
              <p>Reason for Leave: {leaveApplication.ReasonForLeave}</p>
              <p>Employee Comment: {leaveApplication.EmployeeComment}</p>
              <button className="btn btn-secondary btn-sm mt-3" onClick={() => handlePreviousStep(3)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
            </div>
          ) : (
            <div>
              <div className="form-group mb-3">
                <label>Application Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="ApplicationDate"
                  value={leaveApplication.ApplicationDate}
                  onChange={handleChange}
                  onInput={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <label>Reason for Leave</label>
                <textarea
                  className="form-control"
                  name="ReasonForLeave"
                  value={leaveApplication.ReasonForLeave}
                  onChange={handleChange}
                  onInput={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <label>Employee Comment</label>
                <textarea
                  className="form-control"
                  name="EmployeeComment"
                  value={leaveApplication.EmployeeComment}
                  onChange={handleChange}
                  onInput={handleChange}
                />
              </div>
              <div className="mt-3">
                <button className="btn btn-primary btn-sm" onClick={handleNextStep}>
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default StepThree;

import React, { Fragment, useEffect, useRef } from 'react';
import PageUnderConstruction from '../../../../../components/Custom/Page/UnderConstruction';

import { TaskReports } from '../../../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import todoReducers from '../../../../../../redux/reducers/Reducers';

const LocationReportsPage = () => {
  
    return (
      <Fragment>
        <PageUnderConstruction />
      </Fragment>
    );
  };
  

export default LocationReportsPage;
import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import MultiFileUploader from '../../../../../components/Custom/Uploader/MultiFileUploader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { numericDatetime } from '../../../../../../utilities/DateTimeManipulator';

const StepFive = ({
  uploadedFiles,
  setUploadedFiles,
  currentStep,
  handlePreviousStep,
  handleNextStep,
}) => {
  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleFilesChange = (name, files) => {
    setUploadedFiles(files);
  };

  const validateAndProceed = () => {
    handleNextStep();
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Upload Attachments</h4>
          <MultiFileUploader
            userId="" // Provide userId if necessary
            storageLocation="TaskFiles"
            onSuccess={handleFilesChange}
            holderText={numericDatetime(new Date())}
          />
          <div className="mt-3">
            <button className="btn btn-secondary btn-sm" onClick={() => handlePreviousStep(4)}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="btn btn-primary btn-sm ml-2" onClick={validateAndProceed}>
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default StepFive;

import React from "react";
import { Modal, Button, Form, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import profileImg from '../../../../../../images/empty_user.jpg'; 
import Shimmer from "../../../../../components/Custom/Loading/Shimmer";

const ReassignModal = ({ 
  show, 
  handleClose, 
  departMembers, 
  selectedMember, 
  setSelectedMember, 
  handleReassign, 
  assignedMembers, 
  handleAddMember, 
  handleRemoveMember,
  isAssigning,
  isRemoving,
  isLoading
}) => {

  const confirmRemove = (member) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to remove ${member.firstName} ${member.surname} from this task?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Removing member', member);
        handleRemoveMember(member.userId);
      }
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reassign Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Select Member to Add */}
        <Form.Group controlId="formDepartmentMembers" className="mt-1">
          <Form.Label>Select Member within the department</Form.Label>
          <div className="d-flex">
            <Form.Control
              as="select"
              value={selectedMember}
              onChange={(e) => setSelectedMember(e.target.value)}
            >
              <option value="">Select a member in the department</option>
              {departMembers.map((member) => (
                <option key={member.userId} value={member.userId}>
                  {member.firstName} {member.surname}
                </option>
              ))}
            </Form.Control>
            <Button 
            disabled={isAssigning}
              variant="success" 
              className="ms-2"
              size="sm"
              onClick={handleAddMember}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="lg" /> {isAssigning ? 'Assigning...' : ''}
            </Button>
          </div>
        </Form.Group>
        <hr />
        <br />

        {/* Currently Assigned Members */}
        <h6>Currently Assigned Members</h6>
        <br />
        <Row>
            {isLoading ? <Shimmer type='listWithAvatar' /> : 
            assignedMembers.length === 0 ? <Shimmer type='empty' text='There are no assigned member for this task!' /> :
           assignedMembers.map((member) => (
            <Col xs={12} sm={6} md={6} key={member.userId} className="mb-3">
              <Card className="shadow position-relative">
                <Button 
                disabled={isRemoving}
                  variant="outline-danger" 
                  size="xxs" 
                  className="position-absolute" 
                  style={{ top: '-10px', right: '-10px' }} 
                  onClick={() => confirmRemove(member)}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" /> {isRemoving ? 'Removing...' : ''}
                </Button>
                <Card.Body className="d-flex align-items-center" style={{ padding: '0px 0px 0px 0px' }}>
                  <img 
                    src={member.avatar || profileImg} 
                    alt='' 
                    className="rounded-circle me-3" 
                    style={{ width: '50px', height: '50px', top: '-60px', left: '-60px' }} 
                  />
                  <div className="text-truncate">
                    <span className="mb-0 text-truncate"><strong>{member.firstName} {member.surname}</strong></span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
          }
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon={faClose} /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReassignModal;

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/LeaveDuty/Delete"
export const DeleteLeaveDuty = async (LeaveDuty) => {
    try {
        const response = await api_hr.delete('/api/LeaveDuty/Delete', { data: LeaveDuty });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/Get/{id}"
export const GetLeaveDutyById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/LeaveDuty/Get/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/LeaveDuty/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/GetValue"
export const GetLeaveDutyValue = async (forceUpdate = false) => {
    const cacheKey = '/api/LeaveDuty/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/LeaveDuty/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/Insert"
export const InsertLeaveDuty = async (LeaveDuty) => {
    try {
        const response = await api_hr.post('/api/LeaveDuty/Insert', LeaveDuty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/List"
export const LeaveDutyList = async (forceUpdate = false) => {
    const cacheKey = '/api/LeaveDuty/List';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/LeaveDuty/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/ListView/{leaveID}"
export const LeaveDutyListView = async (leaveID, forceUpdate = false) => {
    const cacheKey = `/api/LeaveDuty/ListView/${leaveID}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/LeaveDuty/ListView/${leaveID}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/PaginatedList"
export const PaginatedLeaveDutyList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/LeaveDuty/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/LeaveDuty/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/Save"
export const SaveLeaveDuty = async (LeaveDuty) => {
    try {
        const response = await api_hr.post('/api/LeaveDuty/Save', LeaveDuty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDuty/Update"
export const UpdateLeaveDuty = async (LeaveDuty) => {
    try {
        const response = await api_hr.post('/api/LeaveDuty/Update', LeaveDuty);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import profileImg from '../../../../images/empty_user.jpg'; 
import { TextColor } from '../../../../utilities/colorUtils';
import { formatDistanceToNow, formatDistance } from 'date-fns';

const MembersTable = ({ members, tasks }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [filterText, setFilterText] = useState('');
  const navigate = useNavigate();

  const handleTasksClick = (member, filterType) => {
    let tasksToShow = [];
    
    if (filterType === 'totalTasks') {
      tasksToShow = tasks.filter(task =>
        task.TaskAssignedUsers.some(user => user.userId === member.userId)
      );
      setModalTitle(`All Tasks for ${member.firstName} ${member.surname}`);
    } else if (filterType === 'totalDue') {
      tasksToShow = tasks.filter(task =>
        task.TaskAssignedUsers.some(user => user.userId === member.userId) &&
        new Date(task.dueDate) < new Date()
      );
      setModalTitle(`Due Tasks for ${member.firstName} ${member.surname}`);
    }

    setSelectedTasks(tasksToShow);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const filteredMembers = useMemo(() => {
    if (!filterText) return members;
    return members.filter(
      member =>
        member.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
        member.surname.toLowerCase().includes(filterText.toLowerCase())
    );
  }, [filterText, members]);

  const columns = useMemo(() => [
    {
      name: 'Avatar',
      selector: row => <img src={row.avatar || profileImg} alt='' className="rounded-circle" width="50" />,
    },
    { name: 'First Name', selector: row => row.firstName, sortable: true },
    { name: 'Surname', selector: row => row.surname, sortable: true },
    {
      name: 'Total Tasks',
      selector: row => row.totalTasks,
      cell: row => (
        <a href="#" onClick={() => handleTasksClick(row, 'totalTasks')} className='badge bg-primary btn-outline'>
          {row.totalTasks}
        </a>
      ),
      sortable: true
    },
    {
      name: 'Total Due',
      selector: row => row.totalDue,
      cell: row => (
        <a href="#" onClick={() => handleTasksClick(row, 'totalDue')} className='badge bg-danger btn-outline'>
          {row.totalDue}
        </a>
      ),
      sortable: true
    },
  ], [handleTasksClick]);

  const taskColumns = [
    { name: 'Task ID', selector: task => task.taskID, sortable: true, wrap: true, width: '100px'  },
    { name: 'Title', selector: task => task.title, sortable: true, wrap: true },
    {
      name: 'Priority',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.priorityColor }}>
          <TextColor bgColor={row.priorityColor} text={row.priorityName} />
        </span>,
      sortable: true, wrap: true, width: '100px'
    },
    {
      name: 'Status',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.statusColor }}>
          <TextColor bgColor={row.statusColor} text={row.statusName} />
        </span>,
      sortable: true, wrap: true, width: '100px' 
    },
    { name: 'Due Date', selector: task => new Date(task.dueDate).toLocaleDateString(), sortable: true, wrap: true, width: '120px'  },
    { 
      name: 'Days Due', 
      selector: row => new Date(row.dueDate), // Use Date object for sorting
      sortable: true,
      format: row => {
        const dueDate = new Date(row.dueDate);
        const now = new Date();
        return dueDate < now
          ? `${formatDistanceToNow(dueDate)} ago` // Show time ago for past due tasks
          : `in ${formatDistanceToNow(dueDate)}`; // Show time remaining for upcoming tasks
      },
      width: '175px'
    },
    {
      name: '',
      selector: task => (
        <Button 
          variant="outline-primary" 
          size="xxs"
          onClick={() => navigate('/app/view-task', { state: task })}
        >
          <FontAwesomeIcon icon={faEye} size='lg' />
        </Button>
      ), wrap: true, width: '90px' 
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredMembers}
        pagination
        highlightOnHover
        defaultSortField="firstName"
        defaultSortAsc={true}
        subHeader
        subHeaderComponent={
          <FormControl
            type="text"
            size='xxs'
            placeholder="Search..."
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            style={{ width: '350px', height: '35px' }}
          />
        }
      />
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={taskColumns}
            data={selectedTasks}
            pagination
            highlightOnHover
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MembersTable;

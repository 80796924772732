import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';

const StepTwo = ({
  leaveTypes,
  leaveApplication,
  handleLeaveDetailChange,
  currentStep,
  handlePreviousStep,
  handleNextStep,
}) => {
  const stepRef = useRef(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleLeaveDetailChange(name, value);
  };

  const validateAndProceed = () => {
    let tempErrors = {};
    if (!leaveApplication.TypeID) {
      tempErrors.TypeID = 'Please select a leave type';
    }
    if (!leaveApplication.StartDate) {
      tempErrors.StartDate = 'Start date is required';
    }
    if (!leaveApplication.EndDate) {
      tempErrors.EndDate = 'End date is required';
    }
    if (leaveApplication.StartDate && leaveApplication.EndDate) {
      const startDate = new Date(leaveApplication.StartDate);
      const endDate = new Date(leaveApplication.EndDate);
      if (endDate < startDate) {
        tempErrors.EndDate = 'End date cannot be before start date';
      }
    }
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) {
      handleNextStep();
    }
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Enter Basic Leave Details</h4>
          {currentStep > 2 ? (
            <div>
              <p>Leave Type: {leaveTypes.find(type => type.leaveTypeID === parseInt(leaveApplication.TypeID))?.name}</p>
              <p>Start Date: {format(new Date(leaveApplication.StartDate), 'yyyy-MM-dd')}</p>
              <p>End Date: {format(new Date(leaveApplication.EndDate), 'yyyy-MM-dd')}</p>
              <button className="btn btn-secondary btn-sm mt-3" onClick={() => handlePreviousStep(2)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
            </div>
          ) : (
            <div>
              <div className="form-group mb-3">
                <label>Leave Type</label>
                <select
                  className="form-control"
                  name="TypeID"
                  value={leaveApplication.TypeID}
                  onChange={handleChange}
                  onInput={handleChange}
                >
                  <option value="">Select Leave Type</option>
                  {leaveTypes.map(type => (
                    <option key={type.leaveTypeID} value={type.leaveTypeID}>{type.name}</option>
                  ))}
                </select>
                {errors.leaveTypeID && <small className="text-danger">{errors.leaveTypeID}</small>}
              </div>
              <div className="form-group mb-3">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="StartDate"
                  value={leaveApplication.StartDate}
                  onChange={handleChange}
                  onInput={handleChange}
                />
                {errors.StartDate && <small className="text-danger">{errors.StartDate}</small>}
              </div>
              <div className="form-group mb-3">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="EndDate"
                  value={leaveApplication.EndDate}
                  onChange={handleChange}
                  onInput={handleChange}
                />
                {errors.EndDate && <small className="text-danger">{errors.EndDate}</small>}
              </div>
              <div className="mt-3">
                <button className="btn btn-primary btn-sm" onClick={validateAndProceed}>
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default StepTwo;

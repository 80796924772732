import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 60 * 60 * 1000; // 5 minutes in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/Policy/CustomList"
export const PolicyCustomList = async (query, forceUpdate = false) => {
    const cacheKey = `/api/Policy/CustomList?query=${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Policy/CustomList', { params: { query } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/Get/{id}"
export const GetPolicyById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/Policy/Get/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/Policy/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/List"
export const PolicyList = async (forceUpdate = false) => {
    const cacheKey = '/api/Policy/List';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Policy/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/ListView"
export const PolicyListView = async (forceUpdate = false) => {
    const cacheKey = '/api/Policy/ListView';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Policy/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// ListViewByPolicyID
export const PolicyViewByPolicyID = async (id, forceUpdate = false) => {
    const cacheKey = `/api/Policy/PolicyViewByPolicyID/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/Policy/PolicyViewByPolicyID/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/PaginatedList"
export const PaginatedPolicyList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/Policy/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Policy/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/CustomUpdate"
export const PolicyCustomUpdate = async (policy) => {
    try {
        const response = await api_hr.post('/api/Policy/CustomUpdate', policy);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/Insert"
export const InsertPolicy = async (policy) => {
    try {
        const response = await api_hr.post('/api/Policy/Insert', policy);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/Save"
export const SavePolicy = async (policy) => {
    try {
        const response = await api_hr.post('/api/Policy/Save', policy);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/Update"
export const UpdatePolicy = async (policy) => {
    try {
        const response = await api_hr.post('/api/Policy/Update', policy);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/Delete"
export const DeletePolicy = async (policy) => {
    try {
        const response = await api_hr.delete('/api/Policy/Delete', { data: policy });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Policy/GetValue"
export const GetPolicyValue = async (forceUpdate = false) => {
    const cacheKey = '/api/Policy/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Policy/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserRightsContext } from '../context/UserRightsContext';

const ProtectedRoute = ({ element: Component, requiredRights, ...rest }) => {
  const userRights = useContext(UserRightsContext);
  const [hasAccess, setHasAccess] = useState(null);

  useEffect(() => {
    const checkUserRights = () => {
      // const hasRequiredRights = requiredRights.every(right => userRights.includes(right));
      const hasRequiredRights = requiredRights.some(right => userRights.includes(right));
      setHasAccess(hasRequiredRights);
    };

    const token = localStorage.getItem('token');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    if (!token || !userDetails) {
      setHasAccess(false);
    } else {
      // Check if the user session has expired
      const expiryDate = new Date(userDetails.expiryDate);
      const currentDate = new Date();

      if (expiryDate < currentDate) {
        // If expired, redirect to login
        setHasAccess(false);
        localStorage.removeItem('token'); // Optionally remove the token
        localStorage.removeItem('userDetails'); // Optionally remove userDetails
      } else if (userRights.length > 0) {
        checkUserRights();
      }
    }
  }, [userRights, requiredRights]);

  if (hasAccess === null) {
    const popupStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    };

    const popupContentStyle = {
      background: 'white',
      padding: '20px',
      borderRadius: '5px',
      textAlign: 'center'
    };

    return (
      <div style={popupStyle}>
        <div style={popupContentStyle}>
          <p>Please wait while validating your profile...</p>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return hasAccess ? <Component {...rest} /> : <Navigate to="/auth/login" />;
};

export default ProtectedRoute;

// src/components/Custom/Uploader/PDFSigner.js

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas';
import Draggable from 'react-draggable';
import { PDFDocument } from 'pdf-lib';
import { Button, Modal, Spinner } from 'react-bootstrap';

// Configure pdfjs to use the worker from the public folder
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const PDFSigner = ({ fileURL, onSignComplete }) => {
  const [numPages, setNumPages] = useState(null);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 });
  const [isSigning, setIsSigning] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const sigCanvasRef = useRef(null);
  const pdfWrapperRef = useRef(null);

  // Handle PDF load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Capture signature as data URL
  const handleSaveSignature = () => {
    const dataURL = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
    setSignatureDataURL(dataURL);
    setIsSigning(false);
  };

  // Clear the signature pad
  const handleClearSignature = () => {
    sigCanvasRef.current.clear();
  };

  // Embed the signature into the PDF
  const handleSaveSignedPDF = async () => {
    setLoading(true);
    try {
      const existingPdfBytes = await fetch(fileURL).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const pngImageBytes = await fetch(signatureDataURL).then((res) => res.arrayBuffer());
      const pngImage = await pdfDoc.embedPng(pngImageBytes);

      const pages = pdfDoc.getPages();
      const page = pages[currentPage - 1];

      // Calculate the signature position relative to the PDF page
      const { width, height } = page.getSize();
      const pdfWrapperRect = pdfWrapperRef.current.getBoundingClientRect();

      // Scale factors
      const scaleX = width / pdfWrapperRect.width;
      const scaleY = height / pdfWrapperRect.height;

      // Position adjustments
      const signatureWidth = pngImage.width / 4;
      const signatureHeight = pngImage.height / 4;

      const x = signaturePosition.x * scaleX;
      const y = (pdfWrapperRect.height - signaturePosition.y - (signatureHeight / scaleY)) * scaleY;

      page.drawImage(pngImage, {
        x,
        y,
        width: signatureWidth,
        height: signatureHeight,
      });

      const pdfBytes = await pdfDoc.save();
      const signedFile = new File([pdfBytes], 'signed_policy.pdf', { type: 'application/pdf' });

      // Provide the signed PDF file to the parent component
      if (onSignComplete) onSignComplete(signedFile);

      setLoading(false);
      alert('Signature added and PDF is ready for upload.');
    } catch (error) {
      console.error('Error signing PDF:', error);
      setLoading(false);
      alert('Failed to sign the PDF.');
    }
  };

  // Handle drag stop
  const handleDragStop = (e, data) => {
    setSignaturePosition({ x: data.x, y: data.y });
  };

  // Render signature pad modal
  const renderSignaturePad = () => (
    <Modal show={isSigning} onHide={() => setIsSigning(false)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Draw Your Signature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SignatureCanvas
          ref={sigCanvasRef}
          penColor="black"
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
        />
        <div style={{ marginTop: '10px' }}>
          <Button variant="secondary" onClick={handleClearSignature}>
            Clear
          </Button>{' '}
          <Button variant="primary" onClick={handleSaveSignature}>
            Save Signature
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      {loading && (
        <div style={{
          position: 'absolute',
          top: 0, left: 0, right: 0, bottom: 0,
          zIndex: 10,
          backgroundColor: 'rgba(255,255,255,0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Spinner animation="border" />
        </div>
      )}
      <div ref={pdfWrapperRef} style={{ display: 'inline-block', position: 'relative' }}>
        <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={currentPage} />
        </Document>
        {signatureDataURL && (
          <Draggable onStop={handleDragStop} position={signaturePosition}>
            <img
              src={signatureDataURL}
              alt="Signature"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '150px',
                cursor: 'move',
              }}
            />
          </Draggable>
        )}
      </div>
      <div style={{ marginTop: '10px' }}>
        {numPages > 1 && (
          <div>
            <Button variant="secondary" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
              Previous Page
            </Button>{' '}
            <Button variant="secondary" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, numPages))}>
              Next Page
            </Button>
          </div>
        )}
        <div style={{ marginTop: '10px' }}>
          {!signatureDataURL ? (
            <Button variant="primary" onClick={() => setIsSigning(true)}>
              Sign Document
            </Button>
          ) : (
            <>
              <Button variant="success" onClick={handleSaveSignedPDF}>
                Save Signed PDF
              </Button>{' '}
              <Button variant="danger" onClick={() => setSignatureDataURL(null)}>
                Remove Signature
              </Button>
            </>
          )}
        </div>
      </div>
      {renderSignaturePad()}
    </div>
  );
};

PDFSigner.propTypes = {
  fileURL: PropTypes.string.isRequired,
  onSignComplete: PropTypes.func.isRequired,
};

export default PDFSigner;

import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner, faCheck, faExclamationCircle, faTasks } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import { getTextColor } from '../../../../utilities/colorUtils';

const DashboardStats = ({ tasks, statusList }) => {
  const [statusCounts, setStatusCounts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const calculateStatusCounts = () => {
      try {
        // Filter statusList to only include statusID 0, 1, 2 and 3
        const filteredStatusList = statusList.filter(status => [0, 1, 2, 3].includes(status.statusID));

        // Calculate the count of tasks for each status
        const counts = filteredStatusList.map(status => ({
          ...status,
          count: tasks.filter(task => task.statusID === status.statusID).length
        }));

        // Calculate the count of overdue tasks
        const now = new Date();
        const overdueCount = tasks.filter(task => {
          const dueDate = new Date(task.dueDate);
          return dueDate < now && ![-1, 3, 4].includes(task.statusID);
        }).length;

        // Add overdue count to the status counts
        counts.push({
          statusID: 'overdue',
          name: 'Over Due',
          color: '#FF0000',
          count: overdueCount
        });

        setStatusCounts(counts);
        setLoading(false);
      } catch (error) {
        console.error('Failed to calculate task statuses', error);
      }
    };

    calculateStatusCounts();
  }, [tasks, statusList]);

  if (loading) {
    return <Shimmer type="sections" />;
  }

  const getIcon = (statusID) => {
    switch (statusID) {
      case 0:
        return faPlus;
      case 1:
        return faSpinner;
      case 2:
        return faCheck;
      case 'overdue':
        return faExclamationCircle;
      default:
        return faTasks;
    }
  };

  return (
    <Row>
      {statusCounts.map(status => (
        status.statusID === 'overdue' ? (
          <Col key={status.statusID} xs={12} sm={12} md={12} lg={12}>
          <div className="widget-stat card shadow">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 d-none d-md-inline-block"  style={{backgroundColor: status.color, color: getTextColor(status.color)}}>
                  <FontAwesomeIcon className='p-3' icon={getIcon(status.statusID)} />
                </span>
                <div className="media-body">
                  <p className="mb-1">{status.name}</p>
                  <h4 className="mb-0">
                    <CountUp end={status.count} duration={2} />
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Col>
        ) : (
        <Col key={status.statusID} xs={6} sm={6} md={6} lg={6}>
          <div className="widget-stat card shadow">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 d-none d-md-inline-block"  style={{backgroundColor: status.color, color: getTextColor(status.color)}}>
                  <FontAwesomeIcon className='p-3' icon={getIcon(status.statusID)} />
                </span>
                <div className="media-body">
                  <p className="mb-1">{status.name}</p>
                  <h4 className="mb-0">
                    <CountUp end={status.count} duration={2} />
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Col>)
      ))}
    </Row>
  );
};

export default DashboardStats;

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_KEY = 'employeeFileTypeCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in localStorage
const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

// Helper function to get data from cache
const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Component for "/api/EmployeeFileType/CustomList"
export const EmployeeFileTypeCustomList = async (query, forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileType/CustomList', { params: { query } });
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/CustomUpdate"
export const EmployeeFileTypeCustomUpdate = async (employeeFileType) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileType/CustomUpdate', employeeFileType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/Delete"
export const DeleteEmployeeFileType = async (employeeFileType) => {
    try {
        const response = await api_hr.delete('/api/EmployeeFileType/Delete', { data: employeeFileType });
        localStorage.removeItem(CACHE_KEY); // Clear cache on delete
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/Get/{id}"
export const GetEmployeeFileTypeById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get(`/api/EmployeeFileType/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/GetValue"
export const GetEmployeeFileTypeValue = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileType/GetValue');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/Insert"
export const InsertEmployeeFileType = async (employeeFileType) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileType/Insert', employeeFileType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on insert
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/List"
export const EmployeeFileTypeList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileType/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/PaginatedList"
export const PaginatedEmployeeFileTypeList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileType/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/Save"
export const SaveEmployeeFileType = async (employeeFileType) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileType/Save', employeeFileType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on save
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileType/Update"
export const UpdateEmployeeFileType = async (employeeFileType) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileType/Update', employeeFileType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

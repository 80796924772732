import { getApiSystem } from '../../APIServiceSetup';

const api_system = await getApiSystem();
const CACHE_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Feedback Endpoints

// Component for "/api/Feedback/List"
export const FeedbackList = async (forceUpdate = false) => {
    const cacheKey = '/api/Feedback/List';
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get('/api/Feedback/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Feedback/Insert"
export const FeedbackInsert = async (feedback) => {
    try {
        const response = await api_system.post('/api/Feedback/Insert', feedback);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Feedback/Update"
export const FeedbackUpdate = async (feedback) => {
    try {
        const response = await api_system.post('/api/Feedback/Update', feedback);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for '/api/Feedback/MarkAsResolved/{id}'
export const FeedbackMarkAsResolved = async (id) => {
    try {
        const response = await api_system.post(`/api/Feedback/MarkAsResolved/${id}`);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for '/api/Feedback/MarkAsUnresolved/{id}'
export const FeedbackMarkAsUnresolved = async (id) => {
    try {
        const response = await api_system.post(`/api/Feedback/MarkAsUnresolved/${id}`);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Feedback/CustomUpdate"
export const FeedbackCustomUpdate = async (query) => {
    try {
        const response = await api_system.post('/api/Feedback/CustomUpdate', { query });
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Feedback/Get/{id}"
export const FeedbackGetById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/Feedback/Get/${id}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/Feedback/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Feedback/PaginatedList"
export const PaginatedFeedbackList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/Feedback/PaginatedList?page=${page}&pageSize=${pageSize}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/Feedback/PaginatedList`, { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Feedback/ListView"
export const FeedbackListView = async (forceUpdate = false) => {
    const cacheKey = '/api/Feedback/ListView';
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get('/api/Feedback/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Feedback/ListView/{userId}"
export const FeedbackListViewByUser = async (userId, forceUpdate = false) => {
    const cacheKey = `/api/Feedback/ListView/${userId}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/Feedback/ListView/${userId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

import { getApiTasks } from "../../APIServiceSetup";

const api_tasks = await getApiTasks();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get the assigned user with caching
export const getAssignedUser = async (taskID, forceUpdate = false) => {
  const cacheKey = `assignedUser_${taskID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/Task/AssignedUser/${taskID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching assigned user:', error);
    throw error;
  }
};

// Function to get the task assignment list with caching
export const getTaskAssignmentList = async (forceUpdate = false) => {
  const cacheKey = 'taskAssignmentList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAssignment/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching task assignment list:', error);
    throw error;
  }
};

// Function to get task assignment by ID with caching
export const getTaskAssignmentById = async (id, forceUpdate = false) => {
  const cacheKey = `taskAssignmentById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/TaskAssignment/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching task assignment by ID:', error);
    throw error;
  }
};

// Function to get task assignment with caching
export const getTaskAssignment = async (forceUpdate = false) => {
  const cacheKey = 'taskAssignment';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAssignment/Get');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching task assignment:', error);
    throw error;
  }
};

// Function to insert task assignment
export const insertTaskAssignment = async (taskAssignment) => {
  try {
    const response = await api_tasks.post('/api/TaskAssignment/Insert', taskAssignment);
    return response.data;
  } catch (error) {
    console.error('Error inserting task assignment:', error);
    throw error;
  }
};

// Function to update task assignment
export const updateTaskAssignment = async (taskAssignment) => {
  try {
    const response = await api_tasks.post('/api/TaskAssignment/Update', taskAssignment);
    return response.data;
  } catch (error) {
    console.error('Error updating task assignment:', error);
    throw error;
  }
};

// Function to insert or update task assignment
export const insertOrUpdateTaskAssignment = async (taskAssignment) => {
  try {
    const response = await api_tasks.post('/api/TaskAssignment/InsertOrUpdate', taskAssignment);
    return response.data;
  } catch (error) {
    console.error('Error inserting or updating task assignment:', error);
    throw error;
  }
};

// Function to delete task assignment
export const deleteTaskAssignment = async (taskAssignment) => {
  try {
    const response = await api_tasks.post('/api/TaskAssignment/Delete', taskAssignment);
    return response.data;
  } catch (error) {
    console.error('Error deleting task assignment:', error);
    throw error;
  }
};

//Remove
export const removeTaskAssignment = async (taskID, userID) => {
  try {
    const response = await api_tasks.post(`/api/TaskAssignment/Remove/${taskID}/${userID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting task assignment:', error);
    throw error;
  }
};

// Function to get task assignment count with caching
export const getTaskAssignmentCount = async (forceUpdate = false) => {
  const cacheKey = 'taskAssignmentCount';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAssignment/Count');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching task assignment count:', error);
    throw error;
  }
};

// Function to search task assignments with caching
export const searchTaskAssignments = async (searchCriteria, forceUpdate = false) => {
  const cacheKey = `searchTaskAssignments_${JSON.stringify(searchCriteria)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.post('/api/TaskAssignment/Search', searchCriteria);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error searching task assignments:', error);
    throw error;
  }
};

// Function to get paginated task assignment list with caching
export const getPaginatedTaskAssignmentList = async (page, pageSize, forceUpdate = false) => {
  const cacheKey = `paginatedTaskAssignmentList_${page}_${pageSize}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskAssignment/PaginatedList', {
      params: {
        page,
        pageSize,
      },
    });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching paginated task assignment list:', error);
    throw error;
  }
};



import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import LoadingText from '../../../components/Custom/Loading/LoadingText';
import { UserRightsContext } from '../../../../context/UserRightsContext';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

// Page title
import PageTitle from "../../../layouts/PageTitle";

// API Services
import { TaskStatusList } from '../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import { DepartmentList } from '../../../../services/ccms/departmentManagement/department/department_endpoints';
import { FilteredTaskViewList, GetAssignedUser, GetMyCreatedTasks } from '../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import { DateTimeConverter } from '../../../../utilities/convertDateTime';
import { TextColor } from '../../../../utilities/colorUtils';

var origanlList = [];
const Tasks = () => {
  const today = new Date();
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - 30);
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + 30);

  const [statusList, setStatusList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState({});
  const [isFetchingAssignedUsers, setIsFetchingAssignedUsers] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const userRights = useContext(UserRightsContext);
  const [userDetails, setUserDetails] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    startDate: pastDate.toISOString().split('T')[0],
    endDate: futureDate.toISOString().split('T')[0],
    statusID: 'NULL',
    departmentID: 'NULL',
  });

  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    setUserDetails(storedUserDetails);
  }, []);

  useEffect(() => {
    if (userDetails && (forceRefresh || filters)) {
      fetchInitialData();
    }
  }, [userDetails, filters, forceRefresh]);

  const fetchInitialData = useCallback(async () => {
    setIsLoaded(false);
    await fetchData();
    await fetchDepartmentData();
    if (hasRequiredRights(['TaskManagementAdmin'])) {
      await fetchTasks();
    } else {
      await fetchMyTasks();
    }
    setIsLoaded(true);
  }, [userDetails,filters, forceRefresh]);

  const fetchData = async () => {
    const response = await TaskStatusList();
    setStatusList(response.results);
  };

  const fetchDepartmentData = async () => {
    const response = await DepartmentList();
    setDepartmentList(response.results);
  };

  const fetchMyTasks = useCallback(async () => {
    const taskFilters = {
      taskID: "NULL",
      startDate: "NULL",
      endDate: "NULL",
      search: "NULL",
      priorityID: "NULL",
      statusID: "NULL",
      locationID: "NULL",
      departmentID: "NULL",
      assignedBy: "NULL",
      createdBy: '' + userDetails.userId
    };
    
    const taskData = await GetMyCreatedTasks(taskFilters, forceRefresh);
    setTaskList(taskData.results);
    setForceRefresh(false);    
    setIsLoaded(true);

    // Fetch assigned users for each task
    setIsFetchingAssignedUsers(true);
    const userPromises = taskData.results.map(task => GetAssignedUser(task.taskID));
    const users = await Promise.all(userPromises);
    const usersMap = {};
    users.forEach((userList, index) => {
      usersMap[taskData.results[index].taskID] = userList.results;
    });
    setAssignedUsers(usersMap);
    setIsFetchingAssignedUsers(false);
  }, [userDetails, filters, forceRefresh]);

  const fetchTasks = useCallback(async () => {
    const taskFilters = {
      taskID: "NULL",
      startDate: '' + filters.startDate,
      endDate: '' + filters.endDate,
      search: "NULL",
      priorityID: "NULL",
      statusID: '' + filters.statusID,
      locationID: "NULL",
      departmentID: '' + filters.departmentID,
      assignedBy: "NULL",
      createdBy: "NULL"
    };
    const taskData = await FilteredTaskViewList(taskFilters, false);
    setTaskList(taskData.results);
    setForceRefresh(false);
    setIsLoaded(true);

    // Fetch assigned users for each task
    setIsFetchingAssignedUsers(true);
    const userPromises = taskData.results.map(task => GetAssignedUser(task.taskID));
    const users = await Promise.all(userPromises);
    const usersMap = {};
    users.forEach((userList, index) => {
      usersMap[taskData.results[index].taskID] = userList.results;
    });
    
    setAssignedUsers(usersMap);
    setIsFetchingAssignedUsers(false);
  }, [filters, forceRefresh]);

  const hasRequiredRights = (requiredRights) => {
    return requiredRights.every(right => userRights.includes(right));
  };

  const toggleRow = (taskID) => {
    setExpandedRows((prev) => ({ ...prev, [taskID]: !prev[taskID] }));
  };

  const handleDateRangeChange = (event, picker) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: picker.startDate.format('YYYY-MM-DD'),
      endDate: picker.endDate.format('YYYY-MM-DD')
    }));
  };

  const handleStatusChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      statusID: event.target.value,
    }));
    // console.log('Status ID:', event.target.value);
    // console.log('Filters:', filters);
  };

  const handleDepartmentChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      departmentID: event.target.value
    }));
  };

  const handleFilterApply = () => {
    fetchInitialData(); // Trigger data fetch with the current filters
  };

  const handleNewTaskClick = () => {
    navigate('/app/tasks/new-task');
  };

  const handleOpenTask = (task) => {
    navigate('/app/view-task', { state: task });
  };

  const handleRefresh = async () => {
    setIsLoaded(false);
    setForceRefresh(true);
    await fetchInitialData();
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const mobileColumns = [
    {
      name: 'Due Date',
      selector: row => <DateTimeConverter dateInput={row.dueDate} dateFormat="yyyy/MM/dd" />,
      sortable: true,
      width: '110px',
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: '',
      width: '30px',
      cell: row => (
        <Button variant="outline-primary" size="xxs" onClick={() => handleOpenTask(row)}>
          <i className='fas fa-eye'></i>
        </Button>
      ),
      ignoreRowClick: true,
    },
  ];

  const desktopColumns = [
    {
      name: 'ID',
      selector: row => row.taskID,
      sortable: true,
    },
    {
      name: 'Priority',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.priorityColor }}>
          <TextColor bgColor={row.priorityColor} text={row.priorityName} />
        </span>,
      sortable: true,
    },
    {
      name: 'Due Date',
      selector: row => <DateTimeConverter dateInput={row.dueDate} dateFormat="yyyy/MM/dd HH:mm" />,
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.statusColor }}>
          <TextColor bgColor={row.statusColor} text={row.statusName} />
        </span>,
      sortable: true,
    },
    {
      name: 'Assigned To',
      selector: row => isFetchingAssignedUsers ? (
        <LoadingText text='Loading' isLoading={isFetchingAssignedUsers} />
      ) : (
        assignedUsers[row.taskID]?.map((user, index) => (
          <span key={index} className='badge me-1 badge-primary'>
            {user.firstName}
          </span>
        )) || []
      ),
      sortable: true,
    },
    {
      name: 'Department',
      selector: row => row.departmentName,
      sortable: true,
    },
    {
      name: '',
      cell: row => <Button variant="outline-primary" size="xs" onClick={() => handleOpenTask(row)}><i className='fas fa-eye'></i> Open</Button>,
      ignoreRowClick: true,
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p><strong>ID:</strong> {data.taskID}</p>
      <p><strong>Priority:</strong>
        <span className='badge' style={{ backgroundColor: data.priorityColor }}>
          <TextColor bgColor={data.priorityColor} text={data.priorityName} />
        </span>
      </p>
      <p><strong>Status:</strong>
        <span className='badge' style={{ backgroundColor: data.statusColor }}>
          <TextColor bgColor={data.statusColor} text={data.statusName} />
        </span>
      </p>
      <p><strong>Assigned To:</strong>
        {isFetchingAssignedUsers ? (
          <LoadingText text='Loading' isLoading={isFetchingAssignedUsers} />
        ) : (
          assignedUsers[data.taskID]?.map((user, index) => (
            <span key={index} className='badge me-1 badge-primary'>
              {user.firstName}
            </span>
          )) || []
        )}
      </p>
      <p><strong>Department:</strong> {data.departmentName}</p>
    </div>
  );

  if (!userDetails) {
    return <Shimmer type="article" />;
  }

  return (
    <Fragment>
    <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4></h4>
                            <Button size='xs' onClick={handleRefresh}>
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </Button>
                        </div>
      <PageTitle
        motherMenu="Tasks"
        motherMenuPath="/app/tasks"
        activeMenu="List"
        button={<Button variant="primary" size="sm" onClick={handleNewTaskClick}>
          <i className='fa fa-plus-circle'></i> New Task
        </Button>}
      />
      <div className="mt-1">
        <Card className="shadow">
          <Card.Body>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label htmlFor="input_dateRange">Date</Form.Label>
                  <DateRangePicker
                    id="input_dateRange"
                    initialSettings={{ startDate: filters.startDate, endDate: filters.endDate, locale: { format: 'YYYY/MM/DD' } }}
                    onApply={handleDateRangeChange}
                  >
                    <Form.Control type="text" className="input-daterange-timepicker" />
                  </DateRangePicker>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label htmlFor="input_status">Status</Form.Label>
                  <Form.Control as="select" id="input_status" value={filters.statusID} onChange={handleStatusChange}>
                    <option value="NULL">Any Status</option>
                    {statusList.map(status => (
                      <option key={status.statusID} value={status.statusID}>
                        {status.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label htmlFor="input_department">Department</Form.Label>
                  <Form.Control as="select" id="input_department" value={filters.departmentID} onChange={handleDepartmentChange}>
                    <option value="NULL">All Departments</option>
                    {departmentList.map(department => (
                      <option key={department.departmentID} value={department.departmentID}>
                        {department.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3} className="d-flex align-items-center justify-content-end">
                <Button variant="warning" size="sm" onClick={handleFilterApply}>
                  <i className='fas fa-filter'></i> Apply
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <Card className="shadow">
        <Card.Body>
          <div className="table-responsive">
            {isLoaded ? (
              <DataTable
                columns={isMobile ? mobileColumns : desktopColumns}
                data={taskList}
                pagination
                expandableRows={isMobile}
                expandableRowsComponent={ExpandedComponent}
                expandOnRowClicked={isMobile}
              />
            ) : (
              <Shimmer type="table" width={'100%'} />
            )}
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Tasks;

// NewLeave.js
import React, { useState, useEffect, Fragment, useRef } from "react";
import PageTitle from "../../../../../layouts/PageTitle";
import Swal from "sweetalert2";
import { format } from 'date-fns';
import { LeaveTypeList } from "../../../../../../services/ccms/hrManagement/leaveType";
import { LeaveDocumentTypeList } from "../../../../../../services/ccms/hrManagement/leaveDocumentType";
import { SaveLeaveApplication } from "../../../../../../services/ccms/hrManagement/leaveRequest";
import StepDisclaimer from "./Steps/StepDisclaimer"; // Import the new step
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import StepFive from "./Steps/StepFive";
import { useNavigate } from "react-router-dom";

const NewLeave = () => {
  const [userDetails, setUserDetails] = useState({});
  const [leaveApplication, setLeaveApplication] = useState({
    LeaveID: 0,
    UserId: '',
    TypeID: '',
    StartDate: '',
    EndDate: '',
    NumberOfDays: 0,
    StatusID: 0, // Pending
    ReasonForLeave: '',
    ApprovedBy: null,
    RejectedBy: null,
    ApplicationDate: '',
    DecisionDate: null,
    EmployeeComment: '',
    HRNote: null,
    _LeaveDocument: null,
    LeaveDuties: [],
  });
  const [currentStep, setCurrentStep] = useState(1); // Start from Step 1
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [duties, setDuties] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Refs for scrolling
  const stepRefs = {
    1: useRef(null), // Added Step 1
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
    5: useRef(null),
  };

  // Fetch data on component mount
  useEffect(() => {
    // Get user details from localStorage
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    setUserDetails(storedUserDetails);
    setLeaveApplication(prev => ({
      ...prev,
      UserId: storedUserDetails?.userId || '',
      ApplicationDate: format(new Date(), 'yyyy-MM-dd'), // Set today's date
    }));
    fetchLeaveTypes();
    fetchDocumentTypes();
    // Optionally fetch employees if needed for duty assignments
  }, []);

  useEffect(() => {
    // Scroll to the current step
    if (stepRefs[currentStep] && stepRefs[currentStep].current) {
      stepRefs[currentStep].current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep]);

  // Save progress to localStorage
  useEffect(() => {
    localStorage.setItem('leaveApplication', JSON.stringify(leaveApplication));
  }, [leaveApplication]);

  useEffect(() => {
    localStorage.setItem('leaveApplicationCurrentStep', currentStep.toString());
  }, [currentStep]);

  const fetchLeaveTypes = async () => {
    try {
      const response = await LeaveTypeList();
      setLeaveTypes(response.results);
    } catch (error) {
      console.error("Error fetching leave types:", error);
    }
  };

  const fetchDocumentTypes = async () => {
    try {
      const response = await LeaveDocumentTypeList();
      setDocumentTypes(response.results);
    } catch (error) {
      console.error("Error fetching document types:", error);
    }
  };

  const handleLeaveDetailChange = (key, value) => {
    setLeaveApplication((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePendingTasksCount = (count) => {
    // You can store the count in state if needed
    // For example:
    // setLeaveApplication((prev) => ({
    //   ...prev,
    //   pendingTasksCount: count,
    // }));
  };

  const handleDutyAssignment = (duties) => {
    setLeaveApplication((prev) => ({
      ...prev,
      LeaveDuties: duties,
    }));
  };

  const handleDocumentUpload = (document) => {
    setLeaveApplication((prev) => ({
      ...prev,
      _LeaveDocument: document,
    }));
  };

  const handlePreviousStep = (step) => {
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    // Prepare data
    const data = { ...leaveApplication };
    // Calculate NumberOfDays
    const startDate = new Date(data.StartDate);
    const endDate = new Date(data.EndDate);
    const diffTime = endDate - startDate;
    data.NumberOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

    // Set CreatedDateTime and ModifiedDateTime
    const now = new Date();
    const timestamp = format(now, 'yyyyMMddHHmmss');
    data.CreatedDateTime = timestamp;
    data.ModifiedDateTime = timestamp;

    // Set ApplicationDate to today's date if not already set
    data.ApplicationDate = data.ApplicationDate || format(now, 'yyyy-MM-dd');

    // StatusID is already set to 0 (Pending)
    data.StatusID = 0;
    data.DecisionDate = null;
    data.HRNote = null;

    // Set CreatedDateTime and ModifiedDateTime for LeaveDuties
    data.LeaveDuties = data.LeaveDuties.map(duty => ({
      LeaveDutyID: duty.LeaveDutyID || 0,
      LeaveID: 0, // Will be set by backend
      UserId: duty.UserId, // Assigned to
      DutyID: duty.DutyID,
      DutyName: duty.DutyName,
      DutyDescription: duty.DutyDescription,
      CreatedDateTime: timestamp,
      ModifiedDateTime: timestamp,
    }));

    // Set CreatedDateTime and ModifiedDateTime for _LeaveDocument
    if (data._LeaveDocument && data._LeaveDocument.FileURL !== '') {
      data._LeaveDocument.CreatedDateTime = timestamp;
      data._LeaveDocument.ModifiedDateTime = timestamp;
    } else {
      data._LeaveDocument = null;
    }

    try {
      const response = await SaveLeaveApplication(data);
      if (response.status === "Success") {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: "success",
        });
        localStorage.removeItem('leaveApplication');
        localStorage.removeItem('leaveApplicationCurrentStep');
        navigate("/app/leaves", { state: response.results });
      } else {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while saving the leave application",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="New Leave Application"
        motherMenu="Leaves"
        motherMenuPath="/app/leaves"
        pageContent="leaves"
      />

      <div className="form-validation">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="chat-conversation">
              {currentStep >= 1 && (
                <div ref={stepRefs[1]}>
                  <StepDisclaimer
                    handleNextStep={handleNextStep}
                    currentStep={currentStep}
                    leaveApplication={leaveApplication}
                    handlePendingTasksCount={handlePendingTasksCount}
                  />
                </div>
              )}
              {currentStep >= 2 && (
                <div ref={stepRefs[2]}>
                  <StepTwo
                    leaveTypes={leaveTypes}
                    leaveApplication={leaveApplication}
                    handleLeaveDetailChange={handleLeaveDetailChange}
                    currentStep={currentStep}
                    handlePreviousStep={() => handlePreviousStep(1)}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {currentStep >= 3 && (
                <div ref={stepRefs[3]}>
                  <StepThree
                    leaveApplication={leaveApplication}
                    handleLeaveDetailChange={handleLeaveDetailChange}
                    currentStep={currentStep}
                    handlePreviousStep={() => handlePreviousStep(2)}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {currentStep >= 4 && (
                <div ref={stepRefs[4]}>
                  <StepFour
                    leaveApplication={leaveApplication}
                    duties={duties}
                    employees={employees}
                    handleDutyAssignment={handleDutyAssignment}
                    currentStep={currentStep}
                    handlePreviousStep={() => handlePreviousStep(3)}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {currentStep >= 5 && (
                <div ref={stepRefs[5]}>
                  <StepFive
                    documentTypes={documentTypes}
                    leaveApplication={leaveApplication}
                    handleDocumentUpload={handleDocumentUpload}
                    currentStep={currentStep}
                    handlePreviousStep={() => handlePreviousStep(4)}
                    handleSubmit={handleSubmit} // Submit after Step 5
                    isSubmitting={isSubmitting}
                  />
                </div>
              )}
              {/* If you have more steps, continue here */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewLeave;

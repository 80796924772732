import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faStrikethrough, faHeading, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faListOl, faListUl, faUndo, faRedo, faImage, faCode } from '@fortawesome/free-solid-svg-icons';
import { html as beautifyHtml } from 'js-beautify';

const placeholders = [
  "FirstName", "MiddleName", "Surname", "DateOfBirth", "Campus", "City", "Zone", "Cellphone", "Telephone",
  "Email", "Username", "Password", "Message", "Subject", "Title", "Body", "Website", "EntryDateTime",
  "Link", "LinkName", "Description", "CreatedDate", "DueDate", "Date", "Status", "CreatedByName",
  "ModifiedByName", "Code", "StartDate", "EndDate", "Priority", "AssigneeList", "Assignee", "DaysDue", "TaskID", "Comment"
].sort(); // Sort alphabetically

const EditorComponent = ({ initialContent, onContentChange }) => {
  const [htmlContent, setHtmlContent] = useState(initialContent);
  const [isCodeView, setIsCodeView] = useState(false); // State to manage view mode
  const editorRef = useRef(null);

  useEffect(() => {
    setHtmlContent(initialContent);
  }, [initialContent]);

  const handleToggleView = () => {
    if (!isCodeView) {
      // Beautify HTML content before showing code view
      const beautifiedContent = beautifyHtml(htmlContent, {
        indent_size: 2,
        wrap_line_length: 80,
        preserve_newlines: true,
        max_preserve_newlines: 2,
        indent_inner_html: true,
      });
      setHtmlContent(beautifiedContent);
    }
    setIsCodeView(!isCodeView);
  };

  const handleContentChange = (event) => {
    setHtmlContent(event.target.value);
    onContentChange(event.target.value);
  };

  const handleContentEditableChange = () => {
    const updatedContent = editorRef.current.innerHTML;
    setHtmlContent(updatedContent);
    onContentChange(updatedContent);
  };

  const handleCommand = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const handleInsertPlaceholder = (event) => {
    const placeholder = `{${event.target.value}}`;
    document.execCommand('insertText', false, placeholder);
    event.target.selectedIndex = 0; // Reset dropdown
  };

  const handleInsertImage = () => {
    const url = prompt('Enter the image URL');
    if (url) {
      document.execCommand('insertImage', false, url);
    }
  };

  const styles = {
    editorContainer: {
      margin: '20px',
    },
    controls: {
      marginBottom: '10px',
      display: 'flex',
      gap: '5px',
      flexWrap: 'wrap',
      padding: '5px',
      border: '1px solid #ccc',
    },
    controlButton: {
      padding: '5px 10px',
      border: 'none',
      borderRadius: '3px',
      backgroundColor: '#4CAF50',
      color: '#fff',
      cursor: 'pointer',
      fontSize: '12px',
      transition: 'background-color 0.3s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    toggleButton: {
      padding: '5px 10px',
      border: 'none',
      borderRadius: '3px',
      backgroundColor: '#007bff',
      color: '#fff',
      cursor: 'pointer',
      fontSize: '12px',
      transition: 'background-color 0.3s',
      marginTop: '10px',
    },
    editorContent: {
      width: '100%',
    },
    textarea: {
      width: '100%',
      height: '400px',
      fontFamily: '"Courier New", Courier, monospace',
    },
    renderedView: {
      width: '100%',
      height: 'auto',
      border: '1px solid #ccc',
      padding: '10px',
      backgroundColor: '#f4f4f4',
    },
  };

  return (
    <div style={styles.editorContainer}>
      <div style={styles.controls}>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('bold')}
          title="Bold"
        >
          <FontAwesomeIcon icon={faBold} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('italic')}
          title="Italic"
        >
          <FontAwesomeIcon icon={faItalic} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('underline')}
          title="Underline"
        >
          <FontAwesomeIcon icon={faUnderline} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('strikeThrough')}
          title="Strikethrough"
        >
          <FontAwesomeIcon icon={faStrikethrough} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('formatBlock', 'h1')}
          title="Heading 1"
        >
          <FontAwesomeIcon icon={faHeading} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('justifyLeft')}
          title="Align Left"
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('justifyCenter')}
          title="Align Center"
        >
          <FontAwesomeIcon icon={faAlignCenter} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('justifyRight')}
          title="Align Right"
        >
          <FontAwesomeIcon icon={faAlignRight} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('justifyFull')}
          title="Justify"
        >
          <FontAwesomeIcon icon={faAlignJustify} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('insertOrderedList')}
          title="Ordered List"
        >
          <FontAwesomeIcon icon={faListOl} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('insertUnorderedList')}
          title="Unordered List"
        >
          <FontAwesomeIcon icon={faListUl} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('undo')}
          title="Undo"
        >
          <FontAwesomeIcon icon={faUndo} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={() => handleCommand('redo')}
          title="Redo"
        >
          <FontAwesomeIcon icon={faRedo} />
        </button>
        <button
          type="button"
          style={styles.controlButton}
          onClick={handleInsertImage}
          title="Insert Image"
        >
          <FontAwesomeIcon icon={faImage} />
        </button>
        <select
          style={styles.controlButton}
          onChange={handleInsertPlaceholder}
          defaultValue=""
        >
          <option value="" disabled>Insert Placeholder</option>
          {placeholders.map((placeholder) => (
            <option key={placeholder} value={placeholder}>
              {placeholder}
            </option>
          ))}
        </select>
      </div>
      <button
        type="button"
        style={styles.toggleButton}
        onClick={handleToggleView}
      >
        {isCodeView ? (
          <>
            <FontAwesomeIcon icon={faCode} /> Render View
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCode} /> Code View
          </>
        )}
      </button>
      <div style={styles.editorContent}>
        {isCodeView ? (
          <textarea
            value={htmlContent}
            onChange={handleContentChange}
            style={styles.textarea}
          />
        ) : (
          <div
            ref={editorRef}
            style={styles.renderedView}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            contentEditable
            suppressContentEditableWarning={true}
            onInput={handleContentEditableChange}
          />
        )}
      </div>
    </div>
  );
};

export default EditorComponent;

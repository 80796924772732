import React, { useRef } from 'react';
import { Card, Button } from 'react-bootstrap';
import TasksPerUserGraph from './TasksPerUserGraph';
import TaskStatusGraph from './TaskStatusGraph';
import TasksByPriorityGraph from './TasksByPriorityGraph';
import OverdueTasksGraph from './OverdueTasksGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const GraphsSection = ({ tasks, members }) => {
  const printableRef = useRef();

  // Calculate overdue tasks data
  const overdueTasks = tasks.filter(task => new Date(task.dueDate) < new Date());

  // Group overdue tasks by user
  const overdueTasksByUser = overdueTasks.reduce((acc, task) => {
    task.TaskAssignedUsers.forEach(user => {
      if (!acc[user.firstName]) {
        acc[user.firstName] = 0;
      }
      acc[user.firstName] += 1;
    });
    return acc;
  }, {});

  // Handle Print
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  // Handle Export to PDF
  const handleExportToPDF = async () => {
    const canvas = await html2canvas(printableRef.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save('graphs.pdf');
  };

  return (
    <Card style={{ padding: '20px' }}>
      <Card.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5>Graphical Representation</h5>
        <div>
          <Button size='xxs' variant="outline-primary" onClick={handlePrint} style={{ marginRight: '10px' }}>
            <FontAwesomeIcon icon={faPrint} /> 
          </Button>
          <Button size='xxs' variant="outline-danger" onClick={handleExportToPDF}>
            <FontAwesomeIcon icon={faFilePdf} />
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="printable-area" ref={printableRef}>
        <h3>Tasks Per User</h3>
        <div style={{ marginBottom: '30px' }}>
          <TasksPerUserGraph members={members} />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
          <div style={{ width: '48%' }}>
            <h3>Task Status Distribution</h3>
            <TaskStatusGraph tasks={tasks} />
          </div>
          <div style={{ width: '48%' }}>
            <h3>Tasks by Priority</h3>
            <TasksByPriorityGraph tasks={tasks} />
          </div>
        </div>

        <h3>Overdue Tasks</h3>
        <div style={{ marginBottom: '30px' }}>
          <OverdueTasksGraph overdueTasksByUser={overdueTasksByUser} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default GraphsSection;

import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import { getTextColor } from '../../../../../utilities/colorUtils';
import { TaskStatusList, UpdateTaskStatus } from '../../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const TaskStatus = () => {
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        fetchTaskStatuses();
    }, []);

    const fetchTaskStatuses = async () => {
        setLoading(true);
        try {
            const response = await TaskStatusList();
            setTaskStatuses(response.results);
        } catch (error) {
            console.error('Error fetching task statuses:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (status) => {
        setSelectedStatus(status);
        setShowModal(true);
    };

    const handleUpdate = async () => {
        setUpdating(true);
        toast.info('Updating task status...');
        try {
            const response = await UpdateTaskStatus(selectedStatus);
            if (response.status === 'Success') {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'success',
                });
                // Refresh the list after update
                await fetchTaskStatuses();
                toast.success('Task status updated successfully');
            } else {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error updating task status:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while updating the task status.',
                icon: 'error',
            });
        } finally {
            setUpdating(false);
            setShowModal(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedStatus(null);
    };

    const columns = [
        { name: 'Status ID', selector: row => row.statusID, sortable: true, width: '150px' },
        { name: 'Name', selector: row => row.name, sortable: true, width: '200px' },
        { name: 'Color', cell: row => <div style={{ backgroundColor: row.color, color: getTextColor(row.color), padding: '5px 10px', borderRadius: '5px' }}>{row.color}</div>, sortable: true, width: '120px' },
        { name: 'Icon', cell: row => <FontAwesomeIcon icon={row.icon} />, width: '80px' },
        { name: 'Description', selector: row => row.description, sortable: true },
        { name: '', cell: row => <Button variant="warning" size="sm" onClick={() => handleEditClick(row)}><FontAwesomeIcon icon={faPencilAlt} /> Edit</Button>, width: '120px' },
    ];

    if (loading) {
        return <Shimmer type="sections" />;
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <DataTable
                        columns={columns}
                        data={taskStatuses}
                        pagination
                        highlightOnHover
                    />
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Task Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedStatus && (
                        <Form>
                            <Form.Group controlId="statusID">
                                <Form.Label>Status ID</Form.Label>
                                <Form.Control type="text" readOnly value={selectedStatus.statusID} />
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={selectedStatus.name} onChange={(e) => setSelectedStatus({ ...selectedStatus, name: e.target.value })} />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" value={selectedStatus.description} onChange={(e) => setSelectedStatus({ ...selectedStatus, description: e.target.value })} />
                            </Form.Group>
                            <Form.Group controlId="color">
                                <Form.Label>Color</Form.Label>
                                <Form.Control type="color" value={selectedStatus.color} onChange={(e) => setSelectedStatus({ ...selectedStatus, color: e.target.value })} />
                            </Form.Group>
                            <Form.Group controlId="icon">
                                <Form.Label>Icon</Form.Label>
                                <Form.Control type="text" value={selectedStatus.icon} onChange={(e) => setSelectedStatus({ ...selectedStatus, icon: e.target.value })} />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdate} disabled={updating} size="sm">
                        {updating ? 'Updating...' : <><FontAwesomeIcon icon={faSave} /> Update</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default TaskStatus;

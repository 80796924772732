import React, { useState, useEffect, Fragment, useContext, useReducer  } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEye } from "@fortawesome/free-solid-svg-icons";
import Shimmer from "../../../../../components/Custom/Loading/Shimmer";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { TextColor } from '../../../../../../utilities/colorUtils';
import { UserRightsContext } from '../../../../../../context/UserRightsContext';
import PageTitle from "../../../../../layouts/PageTitle";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

//** Import Image */
import emptyProfile from "../../../../../../images/empty_user.jpg";

const EmployeeHeader = ({employee}) => {
    return ( 
        <div className="row">
			<div className="col-lg-12">
			  <div className="profile card card-body px-3 pt-3 pb-0">
				<div className="profile-head">
				  <div className="photo-content ">
					{/* <div className="cover-photo rounded"></div> */}
				  </div>
				  <div className="profile-info">
					<div className="profile-photo">
					  <img src={employee.avatar || emptyProfile} className="img-fluid rounded-circle" alt="profile"/>
					</div>
					<div className="profile-details">
					  <div className="profile-name px-3 pt-2">
						<h4 className="text-primary mb-0">{employee.name} {employee.surname}</h4>
						<p>{employee.jobTitle}</p>
					  </div>
					  <div className="profile-email px-2 pt-2">
						<h4 className="text-muted mb-0">{employee.email}</h4>
						<p>Email</p>
					  </div>
					  <div className="ms-auto">
						<span className="badge bg-primary">{employee.status}</span>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
        );
}

export default EmployeeHeader;
import React, { Fragment } from 'react';
// import PageUnderConstruction from '../../../components/Custom/Page/UnderConstruction';
import TaskBoard from './TaskBoard';

const MyTasks = () => {
    
    return (
        <Fragment>
            <TaskBoard />
        </Fragment>
    );
};

export default MyTasks;
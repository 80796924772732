import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Modal, Table, Form } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import swal from 'sweetalert';
import profile from "../../../../../images/empty_user.jpg";
import { toast } from 'react-toastify';

import { searchMember } from '../../../../../services/ccms/memberManagement/member/member_endpoint';
import { GetByDepartmentID } from '../../../../../services/ccms/departmentManagement/department/department_endpoints';
import { MemberInDepartmentInsert, MemberInDepartmentDelete as removeMemberFromDepartment, MemberInDepartmentMakeHead, DepartmentMembers} from '../../../../../services/ccms/departmentManagement/member_in_department/member_in_department_endpoints';
import { insertMemberDesignation, deleteMemberDesignationWithID } from '../../../../../services/ccms/departmentManagement/member_in_designation/memberInDesignation_endpoints';
import { getDesignationsByMemberIdInDepartment } from '../../../../../services/ccms/departmentManagement/designation/designation_endpoints';

const MembersCard = ({ departmentID, reloadMembers }) => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isMembersLoaded, setIsMembersLoaded] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [editingMember, setEditingMember] = useState({});
  const [memberDesignations, setMemberDesignations] = useState([]);

  const loadData = async () => {
    try {
      const response = await DepartmentMembers(departmentID, true);
      if (response.results) {
        setMembers(response.results);
      } else {
        console.error('Error: response.results is undefined');
      }
    } catch (error) {
      console.error('Error loading members:', error);
    } finally {
      setIsMembersLoaded(true);
    }
  };

  useEffect(() => {
    loadData();
  }, [departmentID]);

  const fetchDesignations = async () => {
    try {
      const response = await GetByDepartmentID(departmentID);
      if (response.results) {
        setDesignations(response.results);
      } else {
        console.error('Error: response.results is undefined');
      }
    } catch (error) {
      console.error('Error loading designations:', error);
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await DepartmentMembers(departmentID);
      if (response.results) {
        setMembers(response.results);
      } else {
        console.error('Error: response.results is undefined');
      }
    } catch (error) {
      console.error('Error loading members:', error);
    } finally {
      setIsMembersLoaded(true);
    }
  };

  useEffect(() => {
    fetchDesignations();
    fetchMembers();
  }, [departmentID]);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleEditClose = () => setEditModal(false);

  const openModal = () => {
    handleShow();
    fetchDesignations();
  };

  const loadMemberOptions = async (inputValue, callback) => {
    try {
      const results = await searchMember(inputValue);
      const options = results.results.map(member => ({
        value: member.memberID,
        label: `${member.firstName} ${member.lastName} - (${member.email})`,
      }));
      callback(options);
    } catch (error) {
      console.error('Error loading member options:', error);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const insertMemberInDepartment = {
        departmentID: departmentID,
        memberID: selectedMember.value,
        isActive: true,
        isHead: false,
      };
      const insertResponse = await MemberInDepartmentInsert(insertMemberInDepartment);
      if (insertResponse.status === 'Success') {
        const insertDesignations = selectedDesignations.map(designation => ({
          memberID: selectedMember.value,
          designationID: designation.value,
          isActive: true,
        }));

        let addedDesignations = 0;
        let responseMessage = 'Member added successfully to the department.';
        for (const item of insertDesignations) {
          const response = await insertMemberDesignation(item);
          if (response.status === 'Success') {
            addedDesignations++;
          } else {
            console.error('Error adding member designations:', response);
          }
        }

        if (addedDesignations > 0) {
          responseMessage += ` ${addedDesignations} designations added.`;
        }

        swal({
          title: "Success",
          text: responseMessage,
          icon: "success",
          button: "OK",
        });
      } else {
        swal({
          title: "Error",
          text: "Error adding member to the department.",
          icon: "error",
          button: "OK",
        });
      }

      await loadData();
      handleClose();
    } catch (error) {
      console.error('Error saving member:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const openEditModal = (member) => {
    setEditingMember(member);
    setEditModal(true);
    fetchMemberDesignations(member.memberID);
  };

  const fetchMemberDesignations = async (memberID) => {
    try {
      const response = await getDesignationsByMemberIdInDepartment(memberID, departmentID);
      if (response.results) {
        const memberDesignations = response.results.map(d => ({
          value: d.designationID,
          label: d.name,
        }));
        setMemberDesignations(memberDesignations);
      }
    } catch (error) {
      console.error('Error fetching member designations:', error);
    }
  };

  const handleAddDesignation = async (designation) => {
    setIsSaving(true);
    try {
      const newDesignation = {
        memberID: editingMember.memberID,
        designationID: designation.value,
        isActive: true,
      };
      const response = await insertMemberDesignation(newDesignation);
      if (response.status === 'Success') {
        setMemberDesignations([...memberDesignations, designation]);
        await loadData();
      } else {
        console.error('Error adding designation:', response);
      }
    } catch (error) {
      console.error('Error adding designation:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleRemoveDesignation = async (designationID) => {
    setIsSaving(true);
    try {
      console.log('designationID:', designationID);
      console.log('editingMember:', editingMember);
      const response = await deleteMemberDesignationWithID(editingMember.memberID, designationID);
      if (response.status === 'Success') {
        setMemberDesignations(memberDesignations.filter(d => d.value !== designationID));
        await loadData();
      } else {
        console.error('Error removing designation:', response);
      }
    } catch (error) {
      console.error('Error removing designation:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleRemoveMember = async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to remove this member from the department?",
      icon: "warning",
      buttons: true,
      confirmButtonText: 'Yes, remove!',
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setIsSaving(true);
        try {
          const removeMemberFromDepartmentData = {
            memberID: editingMember.memberID,
            departmentID: departmentID,
          };
          const response = await removeMemberFromDepartment(removeMemberFromDepartmentData);
          if (response.status === 'Success') {
            swal("Member removed successfully", {
              icon: "success",
            });
            setEditModal(false);
            await loadData();
          } else {
            swal("Error removing member", {
              icon: "error",
            });
          }
        } catch (error) {
          console.error('Error removing member:', error);
        } finally {
          setIsSaving(false);
        }
      }
    });
  };

  const handleChangeDepartmentHead = async (isHead) => {
    let newEditingMember = { ...editingMember, isHead: isHead };
    setEditingMember(newEditingMember);

    const ChangeHeadData = {
      memberID: newEditingMember.memberID,
      departmentID: departmentID,
      isHead: isHead,
    };

    try {
      if (isHead) {
        swal({
          title: "Are you sure?",
          text: "Do you want to make this member the head of the department?",
          icon: "warning",
          buttons: true,
          confirmButtonText: 'Yes, make head!',
          dangerMode: true,
        }).then(async (willMakeHead) => {
          if (willMakeHead) {
            await MemberInDepartmentMakeHead(ChangeHeadData);
          } else {
            newEditingMember.isHead = false;
            setEditingMember(newEditingMember);
          }
        });
      } else {
        swal({
          title: "Are you sure?",
          text: "Do you want to remove this member as the head of the department?",
          icon: "warning",
          buttons: true,
          confirmButtonText: 'Yes, remove head!',
          dangerMode: true,
        }).then(async (willRemoveHead) => {
          if (willRemoveHead) {
            await MemberInDepartmentMakeHead(ChangeHeadData);
          } else {
            newEditingMember.isHead = true;
            setEditingMember(newEditingMember);
          }
        });
      }
      await loadData();
    } catch (error) {
      console.error('Error changing department head:', error);
    }
  };

  return (
    <>
      <Card className="my-3 shadow">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h2>Members</h2>
          <Button size="sm" variant="outline-primary" onClick={openModal}>
            <i className="fas fa-plus"></i> Add
          </Button>
        </Card.Header>
        <Card.Body>
          <PerfectScrollbar style={{ maxHeight: '500px' }} options={{ suppressScrollX: true }}>
            {isMembersLoaded ? (
              members.length > 0 ? (
                members.map(member => (
                  <Row key={member.memberID} className="mb-3 hover">
                    <Col md={2} className="d-flex align-items-center">
                      <img
                        src={member.avatar ? member.avatar : profile}
                        alt="Avatar"
                        className="rounded-circle"
                        width="50"
                        height="50"
                      />
                    </Col>
                    <Col md={8}>
                      <p>
                        <h6>
                          {member.firstName} {member.lastName}{' '}
                          {member.isHead ? (
                            <span className="badge badge-success">Head</span>
                          ) : (
                            <></>
                          )}
                        </h6>
                        <small>{member.designations}</small>
                      </p>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end align-items-center">
                      <Button variant="outline-secondary" size="sm" onClick={() => openEditModal(member)}>
                        <i className="fas fa-edit"></i>
                      </Button>
                    </Col>
                    <Col md={12}>
                      <hr></hr>
                    </Col>
                  </Row>
                ))
              ) : (
                <Shimmer type="empty" text={'No members in the department'} />
              )
            ) : (
              <Shimmer type="listWithAvatar" />
            )}
          </PerfectScrollbar>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formMemberSelect">
              <Form.Label>Member</Form.Label>
              <AsyncSelect
                id="memberSelect"
                placeholder="Type to search a member"
                cacheOptions
                loadOptions={loadMemberOptions}
                defaultOptions
                isClearable
                onChange={setSelectedMember}
              />
            </Form.Group>
            <Form.Group controlId="formDesignationsSelect" className="mt-3">
              <Form.Label>Designations</Form.Label>
              <Select
                id="designationsSelect"
                closeMenuOnSelect={false}
                isMulti
                options={designations.map(designation => ({
                  value: designation.designationID,
                  label: designation.name,
                }))}
                onChange={selectedOptions => setSelectedDesignations(selectedOptions)}
                value={selectedDesignations}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isSaving}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editModal} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingMember && (
            <>
              <Row>
                <Col md={12} className="text-center">
                  <img src={editingMember.avatar ? editingMember.avatar : profile} alt="Avatar" className="img-fluid rounded-circle mb-3" width="100" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p><strong>First Name:</strong> {editingMember.firstName}</p>
                </Col>
                <Col md={6}>
                  <p><strong>Last Name:</strong> {editingMember.lastName}</p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p><strong>Email:</strong> {editingMember.email}</p>
                </Col>
                <Col md={6}>
                  <p><strong>Cell Number:</strong> {editingMember.cellNumber}</p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="formBasicSwitch">
                    <Form.Label><strong>Is Head:</strong></Form.Label>
                    <Form.Check 
                      type="switch"
                      id="custom-switch"
                      label={editingMember.isHead ? 'Yes' : 'No'}
                      checked={editingMember.isHead}
                      onChange={(e) => handleChangeDepartmentHead(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="editFormDesignationsSelect" className="mt-3">
                <Form.Label>Select to Add Designation</Form.Label>
                <Select
                  id="editDesignationsSelect"
                  options={designations.map(designation => ({
                    value: designation.designationID,
                    label: designation.name,
                  }))}
                  onChange={handleAddDesignation}
                />
                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th style={{width: '80%', fontSize: '1em'}}>Designation</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberDesignations.map(designation => (
                      <tr key={designation.value}>
                        <td>{designation.label}</td>
                        <td>
                          <Button variant="outline-danger" size="xs" onClick={() => handleRemoveDesignation(designation.value)}>
                            <i className="fas fa-times"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <hr />
        <div className='p-3'>
          <Row>
              <Col md={6}>              
                <Button className='btn btn-danger tp-btn' size='xs' onClick={handleRemoveMember} disabled={isSaving}>
                  Remove from Department
                </Button>
              </Col>
              <Col className='text-end' md={6}>
                <Button variant="secondary"  size='xs' onClick={handleEditClose} disabled={isSaving}>
                  Close
                </Button>
              </Col>
            </Row>
        </div>  
      </Modal>
    </>
  );
};

export default MembersCard;

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const loadingAnimation = keyframes`
  0% { content: ''; }
  25% { content: '.'; }
  50% { content: '..'; }
  75% { content: '...'; }
  100% { content: ''; }
`;

const colorChangeAnimation = keyframes`
  0% { color: blue; }
  50% { color: red; }
  100% { color: blue; }
`;

const LoadingTextSpan = styled.span`
  animation: ${colorChangeAnimation} 2s infinite,
    ${loadingAnimation} 1s infinite; /* Combine animations */
`;

function LoadingText({ text, isLoading }) {
  const [displayedText, setDisplayedText] = useState(text);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setDisplayedText((prevText) =>
          prevText.endsWith("...") ? text : prevText + "."
        );
      }, 300); // Adjust animation speed here
    } else {
      setDisplayedText(text); // Reset to original text
    }

    return () => clearInterval(interval); // Cleanup on unmount/isLoading change
  }, [isLoading, text]);

  return <LoadingTextSpan>{displayedText}</LoadingTextSpan>;
}

export default LoadingText;

import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import PageTitle from '../../../../layouts/PageTitle';
import { UpdateDepartment } from '../../../../../services/ccms/departmentManagement/department/department_endpoints';

const DepartmentView = ({ department, fetchDepartment }) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(department);
  const [isSaving, setIsSaving] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    setFormData(department);
  }, [department]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedDepartment = {
        ...formData,
        modifiedDateTime: new Date().toISOString(),
      };

      const updateResponse = await UpdateDepartment(updatedDepartment);
      if (updateResponse.status === 'Success') {
        Swal.fire({
          title: updateResponse.title,
          text: updateResponse.message,
          icon: 'success',
        });
        await fetchDepartment(); // Refresh the department data
        handleClose();
      } else {
        Swal.fire({
          title: updateResponse.title,
          text: updateResponse.message,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the department',
        icon: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
    <PageTitle
        motherMenu="Departments"
        motherMenuPath="/app/departments"
        activeMenu="Department"
        button={<Button size="sm" variant="outline-primary" onClick={handleShow}>
        <i className="fas fa-pen"></i> Edit
      </Button>}
      />
      <Card className="my-3 shadow">
        <Card.Body>
          <Row>
            <Col md={4} className="d-flex flex-column">
              <h1 className="text-primary">{department.name}</h1>
              <p className="text-muted">{department.description}</p>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={6}>
                  <div className="widget-stat card shadow">
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-primary text-primary">
                          <svg
                            id="icon-customers"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </span>
                        <div className="media-body">
                          <p className="mb-1">Members</p>
                          <h4 className="mb-0">{department.memberCount}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="widget-stat card shadow">
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-warning text-warning">
                          <svg
                            id="icon-orders"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-file-text"
                          >
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="16" y1="13" x2="8" y2="13"></line>
                            <line x1="16" y1="17" x2="8" y2="17"></line>
                            <polyline points="10 9 9 9 8 9"></polyline>
                          </svg>
                        </span>
                        <div className="media-body">
                          <p className="mb-1">Designations</p>
                          <h4 className="mb-0">{department.designations}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDepartmentName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formActive" className="mt-3">
              <Form.Label>Active</Form.Label>
              <Form.Check
                type="switch"
                name="isActive"
                label="Active"
                checked={formData.isActive}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formDepartmentDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Control type="hidden" name="departmentID" value={formData.departmentID} />
            <Form.Control type="hidden" name="createdDateTime" value={formData.createdDateTime} />
            <Form.Control type="hidden" name="modifiedDateTime" value={formData.modifiedDateTime} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isSaving}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Updating...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DepartmentView;

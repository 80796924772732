import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 60 * 60 * 1000; // 5 minutes in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/Leave/CustomList"
export const LeaveCustomList = async (query, forceUpdate = false) => {
    const cacheKey = `/api/Leave/CustomList?query=${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Leave/CustomList', { params: { query } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/Get/{id}"
export const GetLeaveById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/Leave/Get/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/Leave/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/List"
export const LeaveList = async (forceUpdate = false) => {
    const cacheKey = '/api/Leave/List';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Leave/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/ListView"
export const LeaveListView = async (forceUpdate = false) => {
    const cacheKey = '/api/Leave/ListView';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Leave/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/ListView"
export const LeaveListViewForUser = async (userId, forceUpdate = false) => {
    const cacheKey = `/api/Leave/ListView/${userId}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/Leave/ListView/${userId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/PaginatedList"
export const PaginatedLeaveList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/Leave/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Leave/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/CustomUpdate"
export const LeaveCustomUpdate = async (leave) => {
    try {
        const response = await api_hr.post('/api/Leave/CustomUpdate', leave);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/Insert"
export const InsertLeave = async (leave) => {
    try {
        const response = await api_hr.post('/api/Leave/Insert', leave);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/Save"
export const SaveLeave = async (leave) => {
    try {
        const response = await api_hr.post('/api/Leave/Save', leave);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// SaveLeaveApplication
export const SaveLeaveApplication = async (leave) => {
    try {
        const response = await api_hr.post('/api/Leave/SaveLeaveApplication', leave);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/Update"
export const UpdateLeave = async (leave) => {
    try {
        const response = await api_hr.post('/api/Leave/Update', leave);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/Delete"
export const DeleteLeave = async (leave) => {
    try {
        const response = await api_hr.delete('/api/Leave/Delete', { data: leave });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/Leave/GetValue"
export const GetLeaveValue = async (forceUpdate = false) => {
    const cacheKey = '/api/Leave/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/Leave/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

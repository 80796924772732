import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import FileUploader from '../../../../../../components/Custom/Uploader/FileUploader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowRight,
  faSave
} from "@fortawesome/free-solid-svg-icons";

const StepFive = ({
  documentTypes,
  leaveApplication,
  handleDocumentUpload,
  currentStep,
  handlePreviousStep,
  handleSubmit,
  isSubmitting
}) => {
  const [fileData, setFileData] = useState(leaveApplication._LeaveDocument || {
    TypeID: 0,
    FileName: '',
    FileURL: '',
    Description: '',
  });
  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleFileUploadSuccess = (fileURL, fileName) => {
    setFileData(prev => ({
      ...prev,
      FileURL: fileURL,
      FileName: fileName,
    }));
    handleDocumentUpload({
      ...fileData,
      FileURL: fileURL,
      FileName: fileName,
      UploadDate: new Date().toISOString(),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFileData(prev => ({
      ...prev,
      [name]: value,
    }));
    handleDocumentUpload({
      ...fileData,
      [name]: value,
    });
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Upload Leave Document</h4>
          {currentStep > 5 ? (
            <div>
              <p>Document has been uploaded.</p>
              <button className="btn btn-secondary btn-sm mt-3" onClick={() => handlePreviousStep(5)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
            </div>
          ) : (
            <div>
              <div className="form-group mb-3">
                <label>Document Type</label>
                <select
                  className="form-control"
                  name="TypeID"
                  value={fileData.TypeID}
                  onChange={handleChange}
                  onInput={handleChange}
                >
                  <option value="">Select Document Type</option>
                  {documentTypes.map(type => (
                    <option key={type.typeID} value={type.typeID}>{type.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label>Description</label>
                <textarea
                  className="form-control"
                  name="Description"
                  value={fileData.Description}
                  onChange={handleChange}
                  onInput={handleChange}
                />
              </div>
              <FileUploader
                // uploadFileApi={YourUploadFunction} // Replace with actual upload function
                userId={leaveApplication.UserId}
                name="fileURL"
                initialFileURL={fileData.FileURL}
                onSuccess={handleFileUploadSuccess}
                storageLocation="LeaveDocuments"
              />
              <div className="mt-3">
                <button className="btn btn-primary btn-sm" onClick={handlePreviousStep}>
                  Previous <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-success btn-sm"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : <><FontAwesomeIcon icon={faSave} /> Submit</>}
                </button>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default StepFive;

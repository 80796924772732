import { getApiUsers } from '../../APIServiceSetup';

const api_users = await getApiUsers();

export const getUsersList = async () => {
    try {
        const response = await api_users.get("/api/Users/List");
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserById = async (id) => {
    try {
        const response = await api_users.get(`/api/Users/byID${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUsers = async () => {
    try {
        const response = await api_users.get("/api/Users");
        return response.data;
    } catch (error) {
        return error;
    }
};

export const insertUser = async (userData) => {
    try {
        const response = await api_users.post("/api/Users/Insert", userData);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateUser = async (userData) => {
    try {
        const response = await api_users.put("/api/Users/Update", userData);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const userHasRight = async (userId, rightName) => {
    try {
        const response = await api_users.get(`/api/Users/UserHasRight/${userId}/${rightName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const userHasRole = async (userId, roleName) => {
    try {
        const response = await api_users.get(`/api/Users/UserHasRole/${userId}/${roleName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const countUsers = async () => {
    try {
        const response = await api_users.get("/api/Users/CountUsers");
        return response.data;
    } catch (error) {
        return error;
    }
};

export const countUsersByRole = async (roleName) => {
    try {
        const response = await api_users.get(`/api/Users/CountUsersByRole/${roleName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUsersByRole = async (roleName) => {
    try {
        const response = await api_users.get(`/api/Users/GetUsersByRole/${roleName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUsersByRight = async (rightName) => {
    try {
        const response = await api_users.get(`/api/Users/GetUsersByRight/${rightName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserRoles = async (userId) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserRoles/${userId}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserRights = async (userId) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserRights/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const deleteUserById = async (id) => {
    try {
        const response = await api_users.delete(`/api/Users/${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getByApiKey = async () => {
    try {
        const response = await api_users.get("/api/Users/GetByApiKey");
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserDetails = async (userId) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserDetails/${userId}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const insertUserDetails = async (details) => {
    try {
        const response = await api_users.post("/api/Users/InsertUserDetails", details);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateUserDetails = async (details) => {
    try {
        const response = await api_users.post("/api/Users/UpdateUserDetails", details);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const changeAvatar_old = async (userId, file) => {
    try {
        const formData = new FormData();
        const originalFileName = file.name;
        const fileExtension = originalFileName.substring(originalFileName.lastIndexOf('.') + 1);
        formData.append('file', file, `avatar.${fileExtension}`); // Use the original file extension

        const response = await api_users.post(`/api/users/ChangeAvatar/${userId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
            }
        }); 

        return response.data;
    } catch (error) {
        return error;}
  };

export const changeAvatar = async (userId, formData) => {
    try {
        const response = await api_users.post(`/api/Users/ChangeAvatar/${userId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const changeMyAvatar = async (formData) => {
    try {
        const response = await api_users.post(`/api/Users/ChangeAvatar`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserDetailsByUserId = async (userId) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserDetailsByUserId/${userId}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserDetailsByApiKey = async () => {
    try {
        const response = await api_users.get("/api/Users/GetUserDetailsByApiKey");
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserDetailsByEmail = async (email) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserDetailsByEmail/${email}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserDetailsByUserName = async (userName) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserDetailsByUserName/${userName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserDetailsByRole = async (roleName) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserDetailsByRole/${roleName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserDetailsByRight = async (rightName) => {
    try {
        const response = await api_users.get(`/api/Users/GetUserDetailsByRight/${rightName}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes, addMinutes, isWeekend, isSameDay, addDays } from 'date-fns';
import { motion } from 'framer-motion';
import ComboSelectBox from '../../../../../components/Custom/Select/ComboSelectBox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const publicHolidays = [
    new Date('2024-01-01'), // New Year's Day
    new Date('2024-03-21'), // Human Rights Day
    new Date('2024-03-29'), // Good Friday
    new Date('2024-04-01'), // Family Day
    new Date('2024-04-27'), // Freedom Day
    new Date('2024-05-01'), // Workers' Day
    new Date('2024-06-16'), // Youth Day
    new Date('2024-08-09'), // National Women's Day
    new Date('2024-09-24'), // Heritage Day
    new Date('2024-12-16'), // Day of Reconciliation
    new Date('2024-12-25'), // Christmas Day
    new Date('2024-12-26'), // Day of Goodwill
];

const generateTimes = (start, end, interval) => {
    const times = [];
    let currentTime = start;
    while (currentTime <= end) {
        times.push(new Date(currentTime));
        currentTime = new Date(currentTime.getTime() + interval);
    }
    return times;
};

const getNextValidDate = (date, daysToAdd) => {
    let validDaysAdded = 0;
    let nextDate = new Date(date);

    while (validDaysAdded < daysToAdd) {
        nextDate = addDays(nextDate, 1);
        if (!isWeekend(nextDate) && !publicHolidays.some(holiday => isSameDay(holiday, nextDate))) {
            validDaysAdded++;
        }
    }

    return nextDate;
};

const getValidDueDate = (date, durationInMinutes) => {
    const workingDayMinutes = 8 * 60; // 8 hours in minutes
    const maxDaysToAdd = 730; // Safety limit of 2 years

    let remainingMinutes = durationInMinutes;
    let validDate = setHours(setMinutes(new Date(date), 0), 8); // Start at 8 AM on the given date
    let daysAdded = 0; // Counter to track days added

    while (remainingMinutes > 0 && daysAdded < maxDaysToAdd) {
        // Skip weekends and public holidays
        if (isWeekend(validDate) || publicHolidays.some(holiday => isSameDay(holiday, validDate))) {
            validDate = addDays(validDate, 1);
            validDate = setHours(setMinutes(validDate, 0), 8); // Reset to start of working hours
            daysAdded++;
            // console.log(`Skipped non-working day: ${validDate}`);
            continue;
        }

        // Calculate the minutes left in the current working day
        const minutesUntilEndOfDay = (17 - validDate.getHours()) * 60 - validDate.getMinutes(); // 5 PM is end of day
        const minutesToAdd = Math.min(remainingMinutes, minutesUntilEndOfDay);

        // Add the calculated minutes to the validDate
        validDate = addMinutes(validDate, minutesToAdd);
        remainingMinutes -= minutesToAdd;

        // Log details for debugging
        // console.log(`Adding ${minutesToAdd} minutes on ${validDate}, remaining minutes: ${remainingMinutes}`);

        if (remainingMinutes > 0) {
            validDate = addDays(validDate, 1);
            validDate = setHours(setMinutes(validDate, 0), 8); // Reset to start of working hours
            daysAdded++;
        }
    }

    // Check if the loop terminated due to reaching the maxDaysToAdd limit
    if (daysAdded >= maxDaysToAdd) {
        // console.error("Exceeded maximum number of days to add. Potential infinite loop detected.");
        // console.error("Remaining minutes:", remainingMinutes);
        // console.error("Current validDate:", validDate);
        return null; // Handle error case
    }

    // Ensure the due date is within working hours (8 AM - 5 PM)
    if (validDate.getHours() < 8) {
        validDate = setHours(validDate, 8);
    } else if (validDate.getHours() >= 17) {
        validDate = addDays(validDate, 1);
        validDate = setHours(setMinutes(validDate, 0), 8); // Reset to start of working hours
    }

    return validDate;
};



const StepThree = ({
    designationTasks,
    taskDetails,
    handleTitleChange,
    handleDesignationTaskChange,
    startDate,
    setStartDate,
    currentStep,
    handlePreviousStep,
    handleNextStep,
  }) => {
    const stepRef = useRef(null);
  
    useEffect(() => {
      if (stepRef.current) {
        stepRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);
  
    const [items, setItems] = useState(designationTasks.map(task => task.name));
    const [selectedTask, setSelectedTask] = useState(null);
  
    useEffect(() => {
      setItems(designationTasks.map(task => task.name));
    }, [designationTasks]);
  
    const handleTaskChange = (value) => {
      handleDesignationTaskChange(value);
  
      const task = designationTasks.find(task => task.name === value);
      if (task) {
        setSelectedTask(task);
        handleTitleChange(task.name);
        const durationInMinutes = task.duration || 0;
        const newDueDate = getValidDueDate(new Date(), durationInMinutes);
        setStartDate(newDueDate);
      } else {
        setSelectedTask(null);
        handleTitleChange(value);
        const customDueDate = getNextValidDate(new Date(), 2);
        setStartDate(customDueDate);
      }
    };
  
    const includeTimes = generateTimes(
      setHours(setMinutes(new Date(), 0), 8),
      setHours(setMinutes(new Date(), 0), 17),
      15 * 60 * 1000
    );
  
    const excludeTimes = generateTimes(
      setHours(setMinutes(new Date(), 0), 0),
      setHours(setMinutes(new Date(), 0), 8),
      15 * 60 * 1000
    ).concat(
      generateTimes(
        setHours(setMinutes(new Date(), 0), 17),
        setHours(setMinutes(new Date(), 0), 24),
        15 * 60 * 1000
      )
    );
  
    const filterDate = (date) => {
      return !isWeekend(date) && !publicHolidays.some(holiday => isSameDay(holiday, date));
    };
  
    const validateAndProceed = () => {
      if (taskDetails.title) {
        handleNextStep();
      }
    };
  
    return (
      <div className="chat-message" ref={stepRef}>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="card shadow mb-4 mx-auto position-relative"
        >
          <div className="card-body">
            <h4>{designationTasks.length > 0 ? 'Select or Enter Task Title' : 'Enter Task Title and Due Date'}</h4>
            <ComboSelectBox
              label="Task Title"
              items={items}
              value={taskDetails.title}
              onChange={handleTaskChange}
              disabled={currentStep > 3}
            />
            <div className="form-group mt-3">
              <label>Please choose the due date</label>
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                className="form-control"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                filterDate={filterDate}
                dateFormat="yyyy-MM-dd HH:mm"
                minDate={getNextValidDate(new Date(), 2)}
                includeTimes={includeTimes}
                excludeTimes={excludeTimes}
                disabled={!!selectedTask || currentStep > 3}
              />
            </div>
            <div className="mt-3">
              <button className="btn btn-secondary btn-sm" onClick={() => handlePreviousStep(2)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
              <button className="btn btn-primary btn-sm ml-2" onClick={validateAndProceed}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };
  
  export default StepThree;
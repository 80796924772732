// MainLayout.js
import React, { useContext, useState, useEffect } from 'react';
import Nav from '../layouts/nav';
import Footer from '../layouts/Footer';
import { ThemeContext } from '../../context/ThemeContext';
import MobileNav from '../layouts/nav/MobileNav';
import SideBar from '../layouts/nav/SideBar';
import { Outlet } from 'react-router-dom';

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  const isMobile = window.innerWidth <= 768; // Define the breakpoint for mobile devices

  return (
    <div id="main-wrapper" className={`show ${menuToggle ? 'menu-toggle' : ''}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
      {isMobile && <MobileNav />} {/* Show the mobile navigation on mobile */}
      <SideBar  /> 
    </div>
  );
}

export default MainLayout;

import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import TaskModal from '../tasks/new_task/TaskModal';

const DashboardDueSoon = ({ tasks, userDetails, onRefreshData }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleViewTask = (task) => {
    navigate('/app/view-task', { state: task });
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const filteredTasks = tasks.filter(task => {
    const dueDate = new Date(task.dueDate);
    return dueDate >= today && ![-1, 4].includes(task.statusID);
  });

  filteredTasks.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

  const handleTaskCreated = () => {
    if (onRefreshData) {
      console.log('Refreshing data...');
      onRefreshData();
    }
    setShowModal(false); // Close the modal after task creation
  };

  return (
    <Card className="shadow" style={{ maxHeight: '33em', display: 'flex', flexDirection: 'column' }}>
      <Card.Body className="d-flex flex-column" style={{ overflow: 'hidden' }}>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="d-block">To-Do Tasks</h4>
          <Button size='xxs' variant="secondary" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faPlus} /> Add New
          </Button>
          <TaskModal show={showModal} onClose={() => setShowModal(false)} onTaskCreated={handleTaskCreated} />
        </div>
        <hr />
        <PerfectScrollbar className="flex-grow-1 px-3" style={{ maxHeight: '100%' }}>
          {filteredTasks.length === 0 ? (
            <Shimmer type='empty' text='No tasks due soon' />
          ) : (
            filteredTasks.map(task => (
              <div className="msg-bx d-flex justify-content-between align-items-center border-bottom" key={task.taskID}>
                <div className="msg d-flex align-items-center w-100">
                  <div className="w-100">
                    <h6 className="font-w600">
                      {task.title}
                      <span className='badge badge-sm' style={{ backgroundColor: task.statusColor, marginLeft: '1em' }}>
                        {task.statusName}
                      </span>
                      {task.createdBy === userDetails.userId &&
                        <span className='badge badge-sm bg-primary' style={{ marginLeft: '1em' }}>By you</span>}
                    </h6>
                    <div className="d-flex justify-content-between">
                      <span className="me-auto text-muted">{new Date(task.dueDate).toDateString()}</span>
                    </div>
                  </div>
                </div>
                <Button size='xxs' variant="outline-primary" onClick={() => handleViewTask(task)}>
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              </div>
            ))
          )}
        </PerfectScrollbar>
      </Card.Body>
    </Card>
  );
}

export default DashboardDueSoon;

import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FeedbackTypeInsert, FeedbackTypeUpdate, FeedbackTypeList } from '../../../../../services/ccms/system/feedbackType';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";
import Shimmer from '../../../../components/Custom/Loading/Shimmer';

const MySwal = withReactContent(Swal);

const FeedbackType = () => {
    const [feedbackTypes, setFeedbackTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedType, setSelectedType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // To differentiate between adding and editing

    // Load feedback types on component mount
    useEffect(() => {
        fetchFeedbackTypes();
    }, []);

    // Fetch feedback types
    const fetchFeedbackTypes = async () => {
        setLoading(true);
        try {
            const response = await FeedbackTypeList();
            setFeedbackTypes(response.results);
        } catch (error) {
            console.error('Error fetching feedback types:', error);
        } finally {
            setLoading(false);
        }
    };

    // Open modal for editing
    const handleEditClick = (type) => {
        setSelectedType(type);
        setIsEditMode(true);
        setShowModal(true);
    };

    // Open modal for adding a new type
    const handleAddClick = () => {
        setSelectedType(null);
        setIsEditMode(false);
        setShowModal(true);
    };

    // Handle form submission for both insert and update
    const handleSave = async () => {
        setIsSaving(true);
        toast.info(isEditMode ? 'Updating type...' : 'Saving type...');

        try {
            let response;
            if (isEditMode) {
                response = await FeedbackTypeUpdate(selectedType);
            } else {
                response = await FeedbackTypeInsert(selectedType);
            }

            if (response.status === 'Success') {
                MySwal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'success',
                });
                fetchFeedbackTypes(); // Refresh the list after save
                toast.success('Type saved successfully');
            } else {
                MySwal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error saving feedback type:', error);
            MySwal.fire({
                title: 'Error',
                text: 'An error occurred while saving the feedback type.',
                icon: 'error',
            });
        } finally {
            setIsSaving(false);
            setShowModal(false);
        }
    };

    // Close the modal
    const handleClose = () => {
        setShowModal(false);
        setSelectedType(null);
    };

    // Columns for DataTable
    const columns = [
        { name: 'Type ID', selector: row => row.typeID, sortable: true, width: '150px' },
        { name: 'Name', selector: row => row.name, sortable: true, width: '200px' },
        { name: 'Color', cell: row => <div style={{ backgroundColor: row.color, padding: '5px 10px', borderRadius: '5px' }}>{row.color}</div>, sortable: true, width: '120px' },
        { name: 'Icon', cell: row => <FontAwesomeIcon icon={row.icon} />, width: '80px' },
        { name: 'Description', selector: row => row.description, sortable: true },
        { name: '', cell: row => <Button variant="warning" size="sm" onClick={() => handleEditClick(row)}><FontAwesomeIcon icon={faPencilAlt} /> Edit</Button>, width: '120px' },
    ];

    if (loading) {
        return <Shimmer type="sections" />;
    }

    return (
        <Fragment>
            <Row className="mb-3">
                <Col>
                    <Button variant="success" onClick={handleAddClick}>
                        <FontAwesomeIcon icon={faPlus} /> Add New Type
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <DataTable
                        columns={columns}
                        data={feedbackTypes}
                        pagination
                        highlightOnHover
                    />
                </Col>
            </Row>

            {/* Add/Edit FeedbackType Modal */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Edit Feedback Type' : 'Add New Feedback Type'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedType?.name || ''}
                                onChange={(e) => setSelectedType({ ...selectedType, name: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedType?.description || ''}
                                onChange={(e) => setSelectedType({ ...selectedType, description: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="color">
                            <Form.Label>Color</Form.Label>
                            <Form.Control
                                type="color"
                                value={selectedType?.color || '#000000'}
                                onChange={(e) => setSelectedType({ ...selectedType, color: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="icon">
                            <Form.Label>Icon</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedType?.icon || ''}
                                onChange={(e) => setSelectedType({ ...selectedType, icon: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={isSaving} size="sm">
                        {isSaving ? 'Saving...' : <><FontAwesomeIcon icon={faSave} /> {isEditMode ? 'Update' : 'Save'}</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default FeedbackType;

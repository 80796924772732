import React, { Fragment, useEffect, useState, useContext, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { UserRightsContext } from '../../../../context/UserRightsContext'; 
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import DashboardStats from './DashboardStats';
import { FilteredTaskViewList, GetMyTasks } from '../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import { TaskStatusList } from '../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import DashboardTaskSummary from './DashboardTaskSummary';
import DashboardDueSoon from './DashboardDueSoon';

let OriginalTaskList = [];
const Dashboard = () => {
    const [statusList, setStatusList] = useState([]);
    const [taskLoading, setTaskLoading] = useState(true);
    const [statusLoading, setStatusLoading] = useState(true);
    const [taskList, setTaskList] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(false);
    const userRights = useContext(UserRightsContext);
    const [userDetails, setUserDetails] = useState({});

    const hasRequiredRights = (requiredRights) => {
        return requiredRights.every(right => userRights.includes(right));
    };

    useEffect(() => {
        const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
        setUserDetails(storedUserDetails);
    }, []);

    const fetchMyTasks = useCallback(async () => {
        setTaskLoading(true);
        const taskFilters = {
            userID: '' + userDetails.userID,
            startDate: 'NULL',
            endDate: 'NULL',
            search: "NULL",
            priorityID: "NULL",
            statusID: 'NULL',
            locationID: "NULL",
            departmentID: 'NULL'
        };
        try {
            const taskData = await GetMyTasks(taskFilters, forceRefresh);
            setTaskList(taskData.results);
            OriginalTaskList = taskData.results;
        } catch (error) {
            console.error('Failed to fetch tasks', error);
        } finally {
            setTaskLoading(false);
            setForceRefresh(false); // Reset force refresh after fetching data
        }
    }, [forceRefresh]);

    const fetchTasks = useCallback(async () => {
        setTaskLoading(true);
        const taskFilters = {
            taskID: "NULL",
            startDate: 'NULL',
            endDate: 'NULL',
            search: "NULL",
            priorityID: "NULL",
            statusID: 'NULL',
            locationID: "NULL",
            departmentID: 'NULL',
            assignedBy: "NULL",
            createdBy: "NULL"
        };
        try {
            const taskData = await FilteredTaskViewList(taskFilters, forceRefresh);            
            setTaskList(taskData.results);
            OriginalTaskList = taskData.results;
        } catch (error) {
            console.error('Failed to fetch my tasks', error);
        } finally {
            setTaskLoading(false);
            setForceRefresh(false); // Reset force refresh after fetching data
        }
    }, [forceRefresh]);

    const fetchStatusList = useCallback(async () => {
        try {
            const response = await TaskStatusList();
            setStatusList(response.results || []);
            setStatusLoading(false);
        } catch (error) {
            console.error('Failed to fetch task statuses', error);
            setStatusLoading(false);
        }
    }, []);

    useEffect(() => {
        const fetchInitialData = async () => {
            if (hasRequiredRights(['TaskManagementAdmin'])) {
                await fetchTasks();
            } else {
                await fetchMyTasks();
            }
            await fetchStatusList();
        };

        fetchInitialData();
    }, [fetchTasks, fetchMyTasks, fetchStatusList]);

    const handleRefresh = () => {
        setForceRefresh(true);
        setTaskLoading(true);
        setStatusLoading(true);
    };

    const getRandomGreeting = () => {
        const greetings = [
            // Motivational and Upbeat
            `Yo ${userDetails.firstName}! Let's crush those tasks! 💪`,
            `Rise and grind, ${userDetails.firstName}! It's time to shine. ✨`,
            `Get it done, ${userDetails.firstName}! You're unstoppable. 😎`,
            `Make it happen, ${userDetails.firstName}! The day is yours. 🌅`,
            `Carpe diem, ${userDetails.firstName}! Seize the tasks. 🏹`,
            `Fuel your focus, ${userDetails.firstName}! Let's go! 🔥`,
            `Level up, ${userDetails.firstName}! Your to-do list awaits. 🚀`,
            
            // Calm and Encouraging
            `Good to see you, ${userDetails.firstName}. Focus on progress, not perfection. 🧘`,
            `Welcome back, ${userDetails.firstName}. You've got this. 👍`,
            `Hello there, ${userDetails.firstName}. Take it one task at a time. 🐢`,
            `One step at a time, ${userDetails.firstName}. You're making a difference. 👣`,
            `Keep your head up, ${userDetails.firstName}. Every effort counts. 🌟`,
            `Take a deep breath, ${userDetails.firstName}. You're on the right track. 🌬️`,
        
            // Original Greetings (from the prompt)
            `Hi ${userDetails.firstName} 👋, stay on top of your work schedule! 🚀`,
            `${userDetails.firstName}, you're doing great! Keep it up! 💪`,
            `Hello ${userDetails.firstName}! Ready to conquer your tasks today? 🌟`,
            `Good day ${userDetails.firstName}! Let's make today productive! 💪`,
            `Hey ${userDetails.firstName}! Keep pushing, you're almost there! 🏆`,
        ];
        return greetings[Math.floor(Math.random() * greetings.length)];
    };

    return (
        <Fragment>
            
            {taskLoading && statusLoading ? 
                <Shimmer type="page" /> : 
                (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4>{getRandomGreeting()}</h4>
                            <Button size='xs' onClick={handleRefresh}>
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </Button>
                        </div>
                        <Row>
                            <Col md={6} lg={7} xl={7}>                            
                                <DashboardStats tasks={taskList} statusList={statusList} />
                            </Col>
                            <Col md={6} lg={5} xl={5}>
                                <DashboardDueSoon tasks={taskList} userDetails={userDetails} onRefreshData={handleRefresh} />
                            </Col>
                        </Row>
                        <DashboardTaskSummary tasks={taskList} statusList={statusList} />
                    </>
                )
            }
        </Fragment>
    );
};

export default Dashboard;

import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import LoadingText from '../../../components/Custom/Loading/LoadingText';
import { UserRightsContext } from '../../../../context/UserRightsContext';

// Page title
import PageTitle from "../../../layouts/PageTitle";

// API Services
import { TaskStatusList } from '../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import { DepartmentList } from '../../../../services/ccms/departmentManagement/department/department_endpoints';
import { FilteredCampusTaskViewList, GetAssignedUser, GetMyTasks } from '../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import { DateTimeConverter } from '../../../../utilities/convertDateTime';
import { GetByMemberId } from '../../../../services/ccms/locationManagement/location/location_endpoints';

import { TextColor } from '../../../../utilities/colorUtils';
import { addTaskToQueue } from '../../../../services/ccms/taskQueue';

const TeamTasks = () => {
  const today = new Date();
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - 30);
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + 30);

  const [statusList, setStatusList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState({});
  const [isFetchingAssignedUsers, setIsFetchingAssignedUsers] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const userRights = useContext(UserRightsContext);
  const [userDetails, setUserDetails] = useState({});
  const [locationID, setLocationID] = useState(null);

  const hasRequiredRights = (requiredRights) => {
    return requiredRights.every(right => userRights.includes(right));
  };

  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (storedUserDetails) {
      setUserDetails(storedUserDetails);
    }
  }, []);

  useEffect(() => {
    const fetchLocationID = async () => {
      if (userDetails?.memberID) {
        const response = await GetByMemberId(userDetails.memberID);
        if (response.results) {
          setLocationID(response.results.locationID);
        }
      }
    };

    fetchLocationID();
  }, [userDetails]);

  const [filters, setFilters] = useState({
    startDate: pastDate.toISOString().split('T')[0],
    endDate: futureDate.toISOString().split('T')[0],
    statusID: 'NULL',
    departmentID: 'NULL',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await TaskStatusList();
      setStatusList(response.results);
    };

    const fetchDepartmentData = async () => {
      const response = await DepartmentList();
      setDepartmentList(response.results);
    };

    fetchData();
    fetchDepartmentData();
  }, []);

  const fetchMyTasks = useCallback(async () => {
    setIsLoaded(false);
    const taskFilters = {
      userID: '' + userDetails.userID,
      startDate: 'NULL',
      endDate: 'NULL',
      search: "NULL",
      priorityID: "NULL",
      statusID: 'NULL',
      locationID: "NULL",
      departmentID: 'NULL'
    };
    const taskData = await GetMyTasks(taskFilters, forceRefresh);
    setTaskList(taskData.results);
    setForceRefresh(false);
    setIsLoaded(true);

    // Fetch assigned users for each task
    setIsFetchingAssignedUsers(true);
    const userPromises = taskData.results.map(task => GetAssignedUser(task.taskID));
    const users = await Promise.all(userPromises);
    const usersMap = {};
    users.forEach((userList, index) => {
      usersMap[taskData.results[index].taskID] = userList.results;
    });
    setAssignedUsers(usersMap);
    setIsFetchingAssignedUsers(false);
  }, [forceRefresh, userDetails]);

  const fetchTasks = useCallback(async () => {
    if (locationID) {
      setIsLoaded(false);
      const taskFilters = {
        taskID: "NULL",
        startDate: 'NULL',
        endDate: 'NULL',
        search: "NULL",
        priorityID: "NULL",
        statusID: 'NULL',
        locationID: '' + locationID,
        departmentID: 'NULL',
        assignedBy: "NULL",
        createdBy: "NULL"
      };
      const taskData = await FilteredCampusTaskViewList(taskFilters, forceRefresh);
      setTaskList(taskData.results);
      setForceRefresh(false);
      setIsLoaded(true);

      // Fetch assigned users for each task
      setIsFetchingAssignedUsers(true);
      const userPromises = taskData.results.map(task => GetAssignedUser(task.taskID));
      const users = await Promise.all(userPromises);
      const usersMap = {};
      users.forEach((userList, index) => {
        usersMap[taskData.results[index].taskID] = userList.results;
      });
      setAssignedUsers(usersMap);
      setIsFetchingAssignedUsers(false);
    }
  }, [forceRefresh, locationID]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (locationID) {
        if (hasRequiredRights(['TaskManagementAdmin'])) {
          await fetchTasks();
        } else {
          await fetchMyTasks();
        }
      }
    };

    fetchInitialData();
  }, [locationID, forceRefresh]);

  const handleDateRangeChange = (event, picker) => {
    setFilters({
      ...filters,
      startDate: picker.startDate.format('YYYY-MM-DD'),
      endDate: picker.endDate.format('YYYY-MM-DD')
    });
    setForceRefresh(true);
  };

  const handleStatusChange = (event) => {
    setFilters({
      ...filters,
      statusID: event.target.value
    });
  };

  const handleDepartmentChange = (event) => {
    setFilters({
      ...filters,
      departmentID: event.target.value
    });
  };

  const handleFilterApply = () => {
    setForceRefresh(true);
  };

  const handleNewTaskClick = () => {
    navigate('/app/tasks/new-task');
  };

  const handleOpenTask = (task) => {
    navigate('/app/view-task', { state: task });
  };

  const columns = [
    {
      name: 'ID',
      selector: row => row.taskID,
      sortable: true,
    },
    {
      name: 'Priority',
      selector: row => 
        <span className='badge' style={{ backgroundColor: row.priorityColor }}>
          <TextColor bgColor={row.priorityColor} text={row.priorityName} />
        </span>,
      sortable: true,
    },
    {
      name: 'Due Date',
      selector: row => <DateTimeConverter dateInput={row.dueDate} dateFormat="yyyy/MM/dd HH:mm" />,
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => 
      <span className='badge' style={{ backgroundColor: row.statusColor }}>
        <TextColor bgColor={row.statusColor} text={row.statusName} />
      </span>,
      sortable: true,
    },
    {
      name: 'Assigned To',
      selector: row => isFetchingAssignedUsers ? (
        <LoadingText text='Loading' isLoading={isFetchingAssignedUsers} />
      ) : (
        assignedUsers[row.taskID]?.map((user, index) => (
          <span key={index} className='badge me-1 badge-primary'>
            {user.firstName}
          </span>
        )) || []
      ),
      sortable: true,
    },
    {
      name: 'Department',
      selector: row => row.departmentName,
      sortable: true,
    },
    {
      name: '',
      cell: row => <Button variant="outline-primary" size="xs" onClick={() => handleOpenTask(row)}><i className='fas fa-eye'></i> Open</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Fragment>
      <PageTitle
        motherMenu="Tasks"
        motherMenuPath="/app/tasks"
        activeMenu="List"
        button={<Button variant="primary" size="sm" onClick={handleNewTaskClick}>
          <i className='fa fa-plus-circle'></i> New Task
        </Button>}
      />
      <div className="mt-1">
        <Card className="shadow">
          <Card.Body>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label htmlFor="input_dateRange">Date</Form.Label>
                  <DateRangePicker 
                    id="input_dateRange"
                    initialSettings={{ startDate: filters.startDate, endDate: filters.endDate, locale: { format: 'YYYY/MM/DD' } }}
                    onApply={handleDateRangeChange}
                  >
                    <Form.Control type="text" className="input-daterange-timepicker" />
                  </DateRangePicker>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label htmlFor="input_status">Status</Form.Label>
                  <Form.Control as="select" id="input_status" value={filters.statusID} onChange={handleStatusChange}>
                    <option value="NULL">Any Status</option>
                    {statusList.map(status => (
                      <option key={status.statusID} value={status.statusID}>
                        {status.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label htmlFor="input_department">Department</Form.Label>
                  <Form.Control as="select" id="input_department" value={filters.departmentID} onChange={handleDepartmentChange}>
                    <option value="NULL">All Departments</option>
                    {departmentList.map(department => (
                      <option key={department.departmentID} value={department.departmentID}>
                        {department.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3} className="d-flex align-items-center justify-content-end">                
                <Button variant="warning" size="sm" onClick={handleFilterApply}>
                  <i className='fas fa-filter'></i> Apply
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <Card className="shadow">
        <Card.Body>
          <div className="table-responsive">
            {isLoaded ? (
              <DataTable
                columns={columns}
                data={taskList}
                pagination
              />
            ) : (
              <Shimmer type="table" width={'100%'} />
            )}
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default TeamTasks;

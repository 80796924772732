import React, { useEffect, useRef } from 'react';
import '@vaadin/vaadin-combo-box/theme/material/vaadin-combo-box.js';

const ComboSelectBox = ({ label, items, value, onChange }) => {
    const comboBoxRef = useRef(null);

    useEffect(() => {
        const comboBox = comboBoxRef.current;

        // Initialize ComboBox items
        comboBox.items = items;

        // Handle value change
        const handleValueChange = (e) => {
            onChange(e.target.value);
        };

        comboBox.addEventListener('value-changed', handleValueChange);

        return () => {
            comboBox.removeEventListener('value-changed', handleValueChange);
        };
    }, [items, onChange]);

    return (
        <div className="combo-select-box">
            <label>{label}</label>
            <vaadin-combo-box
                ref={comboBoxRef}
                placeholder="Please choose or type a task"
                value={value}
                allow-custom-value
                style={{ width: '100%' }}
            ></vaadin-combo-box>
        </div>
    );
};

export default ComboSelectBox;

import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button, Tab, Nav } from 'react-bootstrap';
import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

// Components
import LeaveDetails from './parts/LeaveDetails';
import LeaveDocuments from './parts/LeaveDocuments';
import LeaveDuties from './parts/LeaveDuties';
import EmployeeLeaveHeader from './parts/EmployeeHeader';

// Context
import { UserRightsContext } from '../../../../../../context/UserRightsContext';

//** Import Image */
import emptyProfile from "../../../../../../images/empty_user.jpg";

const ViewLeave = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userRights = useContext(UserRightsContext);

  const [leave, setLeave] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Fetch initial data
  useEffect(() => {
    const fetchLeaveData = async () => {
      try {
        // Assume leave data is passed via location.state
        const leaveData = location.state;
        if (!leaveData) {
          navigate('/app/leaves'); // Redirect if no leave data is available
        } else {
          setLeave(leaveData);
          setIsLoaded(true);
        }
        console.log('Leave data:', leaveData);
      } catch (error) {
        console.error('Error fetching leave data:', error);
        // Handle error appropriately
      }
    };

    fetchLeaveData();
  }, [location.state, navigate]);

  if (!isLoaded) {
    return <Shimmer type="article" />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <Button size="xs" variant="outline-primary" onClick={() => navigate('/app/leaves')}>
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back
        </Button>
        <Button size="xs" onClick={() => window.location.reload()}>
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      </div>

      <EmployeeLeaveHeader
        name={`${leave.name} ${leave.surname}`}
        cellphone={leave.cellphone}
        email={leave.email}
        avatar={leave.avatar || emptyProfile}
        leaveStatus={leave.statusName}
      />

      <div className="profile-tab">
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="details">
            <Nav as="ul" className="nav nav-tabs">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="details" className="nav-link">Leave Details</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="documents" className="nav-link">Documents</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="duties" className="nav-link">Duties</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-3">
              <Tab.Pane eventKey="details">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LeaveDetails leave={leave} setLeave={setLeave} />
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="documents">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LeaveDocuments leaveID={leave.leaveID} />
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="duties">
                <Card className="shadow">
                  <Card.Body>
                    <LeaveDuties leaveID={leave.leaveID} userId={leave.userId} />
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default ViewLeave;

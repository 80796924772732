import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const StepTwo = ({
  designations,
  handleDesignationChange,
  taskDetails,
  currentStep,
  handlePreviousStep,
  handleNextStep,
}) => {
  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    handleDesignationChange(value);
  };

  const validateAndProceed = () => {
    if (taskDetails.designationID) {
      handleNextStep();
    }
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Choose a Designation</h4>
          <div className="form-group">
            <label>Please choose the designation</label>
            <select
              className="form-control"
              name="designationID"
              value={taskDetails.designationID || ''}
              onChange={handleChange}
              onInput={handleChange}
            >
              <option value="">Please choose a designation</option>
              {designations && designations.map(designation => (
                <option key={designation.designationID} value={designation.designationID}>
                  {designation.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <button className="btn btn-secondary btn-sm" onClick={() => handlePreviousStep(1)}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="btn btn-primary btn-sm ml-2" onClick={validateAndProceed}>
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default StepTwo;

import React, { useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';

const DurationPicker = ({ duration, onDurationChange }) => {
  const [selectedUnit, setSelectedUnit] = useState('minutes');

  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.value);
    onDurationChange(e.target.value, 0); // Reset the value for the selected unit
  };

  const handleValueChange = (e) => {
    const { value } = e.target;
    onDurationChange(selectedUnit, parseInt(value, 10) || 0);
  };

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Unit</Form.Label>
          <Form.Control as="select" value={selectedUnit} onChange={handleUnitChange}>
            <option value="months">Months</option>
            <option value="weeks">Weeks</option>
            <option value="days">Days</option>
            <option value="hours">Hours</option>
            <option value="minutes">Minutes</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>Value</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={duration[selectedUnit] || 0}
              onChange={handleValueChange}
              min={0}
            />
            <InputGroup.Text>{selectedUnit}</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DurationPicker;

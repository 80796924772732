import React, { createContext, useEffect, useState, useMemo } from 'react';
import { getUserRights } from '../services/ccms/userManagement/user/user_endpoints'; // Ensure this is the correct path
import loadingGif from '../images/logo_glow_infinite.gif';
import LoadingText from '../views/components/Custom/Loading/LoadingText';

export const UserRightsContext = createContext([]);

export const UserRightsProvider = ({ children }) => {
  const [userRights, setUserRights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetched, setIsFetched] = useState(false); // Flag to prevent multiple fetches

  const fetchUserRights = async (userID) => {
    try {
      setLoading(true);
      const rights = await getUserRights(userID);
      const userRights = rights.results.map(right => right.name);
      localStorage.setItem('userRights', JSON.stringify(userRights)); // Cache the rights
      setUserRights(userRights);
    } catch (error) {
      console.error('Error fetching user rights:', error);
      setUserRights([]); // Set empty array in case of error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userID = localStorage.getItem('userID');
    setLoading(true);
    if (userID) {
      const cachedRights = localStorage.getItem('userRights');
      if (cachedRights) {
        setUserRights(JSON.parse(cachedRights));
        setLoading(false);
      } else if (!isFetched) {
        setIsFetched(true); // Set flag to true
        fetchUserRights(userID);
      }
    } else {
      console.error('No userID found in localStorage');
      setLoading(false);
    }
  }, [isFetched]);

  const contextValue = useMemo(() => userRights, [userRights]);

  if (loading) {
    return (
      <div id="preloader" style={{ textAlign: 'center', padding: '3em' }}>
        <img src={loadingGif} alt="Loading..." style={{ width: '60px' }} />
        <LoadingText text='Loading' isLoading={true}  />
      </div>
    );
  }

  return (
    <UserRightsContext.Provider value={contextValue}>
      {children}
    </UserRightsContext.Provider>
  );
};

// src/services/ccms/fileManagement/file.js

import { getApiFile } from '../../APIServiceSetup';

/**
 * Upload a single employee file.
 * @param {string} userId - ID of the user uploading the file.
 * @param {FormData} formData - FormData containing the file.
 * @returns {Promise<Object>} - Response data or error.
 */
export const UploadEmployeeFile = async (userId, formData) => {
    try {
        const api_file = await getApiFile(); // Initialize within the function
        const response = await api_file.post(`/File/UploadEmployeeFile/${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error in UploadEmployeeFile:', error);
        throw error;
    }
};

/**
 * Initialize the multipart upload.
 * @param {string} fileName - Name of the file to upload.
 * @param {string} userId - ID of the user uploading the file.
 * @param {string} storageLocation - Storage location for the file.
 * @returns {Promise<string>} - Returns the UploadId.
 */
export const initiateMultipartUpload = async (fileName, userId, storageLocation, holderText = '') => {
    try {
        const api_file = await getApiFile(); // Initialize within the function
        const response = await api_file.post('/File/InitiateMultipartUpload', {
            FileName: fileName,
            UserId: userId || '',
            StorageLocation: storageLocation,
            HolderText: holderText || '',
        });
        console.log('InitiateMultipartUpload Response:', response); // Debugging
        return response.data.uploadId; // Correct field name
    } catch (error) {
        console.error('Error in initiateMultipartUpload:', error);
        throw error;
    }
};

/**
 * Upload a single part.
 * @param {string} uploadId - The UploadId obtained from initiation.
 * @param {number} partNumber - The part number (1-based index).
 * @param {Blob} filePart - The file chunk to upload.
 * @param {function} onUploadProgress - Callback for upload progress.
 * @returns {Promise<string>} - Returns the ETag of the uploaded part.
 */
export const uploadPart = async (uploadId, partNumber, filePart, onUploadProgress) => {
    try {
        const api_file = await getApiFile(); // Initialize within the function
        const formData = new FormData();
        formData.append('uploadId', uploadId); // Correct field name
        formData.append('partNumber', partNumber); // Correct field name
        formData.append('file', filePart); // Correct field name

        // Log FormData contents for debugging
        console.log('Uploading Part:', partNumber);
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}:`, pair[1]);
        }

        const response = await api_file.post('/File/UploadPart', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });

        console.log(`UploadPart Response for Part ${partNumber}:`, response.data); // Debugging

        return response.data.eTag;
    } catch (error) {
        if (error.response) {
            console.error(`Error in uploadPart for Part ${partNumber}:`, error.response.data);
        } else {
            console.error(`Error in uploadPart for Part ${partNumber}:`, error.message);
        }
        throw error;
    }
};

/**
 * Complete the multipart upload.
 * @param {string} uploadId - The UploadId obtained from initiation.
 * @param {Array} parts - Array of objects containing PartNumber and ETag.
 * @returns {Promise<string>} - Returns the URL of the uploaded file.
 */
export const completeMultipartUpload = async (uploadId, parts) => {
    try {
        const api_file = await getApiFile(); // Initialize within the function
        const response = await api_file.post('/File/CompleteMultipartUpload', {
            uploadId: uploadId, // Correct field name
            parts: parts,
        });
        console.log('Returned File Location:', response.data.fileUrl); // Debugging
        return response.data.fileUrl;
    } catch (error) {
        console.error('Error in completeMultipartUpload:', error);
        throw error;
    }
};

/**
 * Abort the multipart upload.
 * @param {string} uploadId - The UploadId obtained from initiation.
 * @returns {Promise<void>}
 */
export const abortMultipartUpload = async (uploadId) => {
    try {
        const api_file = await getApiFile(); // Initialize within the function
        await api_file.post('/File/AbortMultipartUpload', {
            uploadId: uploadId, // Correct field name
        });
    } catch (error) {
        console.error('Error in abortMultipartUpload:', error);
        throw error;
    }
};

/**
 * Upload a single part with retry logic.
 * @param {string} uploadId - The UploadId obtained from initiation.
 * @param {number} partNumber - The part number (1-based index).
 * @param {Blob} filePart - The file chunk to upload.
 * @param {number} retries - Number of retry attempts.
 * @returns {Promise<string>} - Returns the ETag of the uploaded part.
 */
export const uploadPartWithRetry = async (uploadId, partNumber, filePart, retries = 3) => {
    try {
        return await uploadPart(uploadId, partNumber, filePart, (event) => {
            // Optional: Handle individual part upload progress
        });
    } catch (error) {
        if (retries > 0) {
            console.warn(`Retrying part ${partNumber}. Attempts left: ${retries}`);
            return await uploadPartWithRetry(uploadId, partNumber, filePart, retries - 1);
        } else {
            console.error(`Failed to upload part ${partNumber} after multiple attempts.`);
            throw error;
        }
    }
};

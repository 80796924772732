import React, { useState, useEffect, Fragment } from "react";
import { Button, Modal, Form, Row, Col, Card, Alert } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faSave,
  faTimes,
  faPlus,
  faUserMinus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import {
  FeedbackCategoryList,
  FeedbackCategoryInsert,
  FeedbackCategoryUpdate,
} from "../../../../../services/ccms/system/feedbackCategory";
import {
  FeedbackCategoryRecipientListViewByCategory,
  FeedbackCategoryRecipientInsert,
  FeedbackCategoryRecipientDelete,
} from "../../../../../services/ccms/system/FeedbackCategoryRecipient";
import { searchMember } from "../../../../../services/ccms/memberManagement/member/member_endpoint";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Shimmer from "../../../../components/Custom/Loading/Shimmer";

import avatar from "../../../../../images/empty_user.jpg"

const MySwal = withReactContent(Swal);

const FeedbackCategories = () => {
  const [feedbackCategories, setFeedbackCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // To differentiate between adding and editing
  const [recipients, setRecipients] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [recipientError, setRecipientError] = useState(""); // To store recipient error message

  // Load feedback categories on component mount
  useEffect(() => {
    fetchFeedbackCategories();
  }, []);

  // Fetch feedback categories
  const fetchFeedbackCategories = async () => {
    setLoading(true);
    try {
      const response = await FeedbackCategoryList();
      setFeedbackCategories(response.results);
    } catch (error) {
      console.error("Error fetching feedback categories:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch recipients by category ID
  const fetchRecipients = async (categoryID) => {
    try {
      const response = await FeedbackCategoryRecipientListViewByCategory(
        categoryID
      );
      if (response && response.status === "Success") {
        setRecipients(response.results);
        setRecipientError(""); // Reset the error message if data exists
      } else {
        setRecipients([]);
        setRecipientError("No recipients found for this category."); // Show error if no recipients
      }
    } catch (error) {
      console.error("Error fetching recipients:", error);
      setRecipientError("Error fetching recipients. Please try again later.");
    }
  };

  // Open modal for editing
  const handleEditClick = async (category) => {
    setSelectedCategory(category);
    setIsEditMode(true);
    setShowModal(true);
    await fetchRecipients(category.categoryID); // Load recipients for the category
  };

  // Open modal for adding a new category
  const handleAddClick = () => {
    setSelectedCategory(null);
    setIsEditMode(false);
    setShowModal(true);
    setRecipients([]); // No recipients for a new category
    setRecipientError(""); // Reset error message for a new category
  };

  // Handle form submission for both insert and update
  const handleSave = async () => {
    setIsSaving(true);
    toast.info(isEditMode ? "Updating category..." : "Saving category...");

    try {
      let response;
      if (isEditMode) {
        response = await FeedbackCategoryUpdate(selectedCategory);
      } else {
        response = await FeedbackCategoryInsert(selectedCategory);
      }

      if (response.status === "Success") {
        MySwal.fire({
          title: response.title,
          text: response.message,
          icon: "success",
        });
        fetchFeedbackCategories(); // Refresh the list after save
        toast.success("Category saved successfully");
      } else {
        MySwal.fire({
          title: response.title,
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error saving feedback category:", error);
      MySwal.fire({
        title: "Error",
        text: "An error occurred while saving the feedback category.",
        icon: "error",
      });
    } finally {
      setIsSaving(false);
      setShowModal(false);
    }
  };

  // Close the modal
  const handleClose = () => {
    setShowModal(false);
    setSelectedCategory(null);
    setRecipients([]);
    setRecipientError(""); // Reset error message
  };

  // Remove recipient from the category
const handleRemoveRecipient = async (recipient) => {
    const confirmResult = await MySwal.fire({
        title: 'Are you sure?',
        text: `Do you want to remove ${recipient.firstName} ${recipient.surname} from the category?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, remove!',
        cancelButtonText: 'Cancel'
    });

    if (confirmResult.isConfirmed) {
        try {
            var response = await FeedbackCategoryRecipientDelete(recipient);
            if (response.status !== "Success") {
                toast.error("Error removing recipient");
                return;
            }
            setRecipients(recipients.filter((r) => r.categoryRecipientID !== recipient.categoryRecipientID)); // Update the list of recipients
            toast.success("Recipient removed successfully");
        } catch (error) {
            console.error("Error removing recipient:", error);
            toast.error("Error removing recipient");
        }
    }
};


  // Add member as recipient
  const handleAddRecipient = async () => {
    if (!selectedMember || !selectedCategory) return;

    // Show busy toast
    toast.info("Adding member as recipient...");

    const swalAddingMember = MySwal.fire({
        title: 'Please wait...',
        text: 'The system is processing your request to add the member.',
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false, // Hide confirm button until process completes
        didOpen: () => {
            MySwal.showLoading(); // Show loading spinner
        }
    });

    try {
        // Adding the member as a recipient
        await FeedbackCategoryRecipientInsert({
            categoryID: selectedCategory.categoryID,
            userID: selectedMember.value,
            createdDateTime: Date.now(), // Assuming it's required
            modifiedDateTime: Date.now(), // Assuming it's required
        });

        // Refresh recipient list
        fetchRecipients(selectedCategory.categoryID);
        setSelectedMember(null); // Reset selected member

        // Update toast and Swal upon success
        toast.success("Member added as recipient");
        MySwal.fire({
            title: 'Success!',
            text: 'The member has been added as a recipient successfully.',
            icon: 'success',
            timer: 2000, // Automatically close after 2 seconds
            showConfirmButton: false
        });
    } catch (error) {
        // Handle error with toast and Swal
        console.error("Error adding recipient:", error);
        toast.error("Error adding recipient");
        MySwal.fire({
            title: 'Error',
            text: 'There was an error adding the member as a recipient.',
            icon: 'error',
            showConfirmButton: true
        });
    }
};


  // Load members for the AsyncSelect component
  const loadMemberOptions = async (inputValue) => {
    const results = await searchMember(inputValue);
    return results.results.map((member) => ({
      label: `${member.firstName} ${member.lastName} (${member.email})`,
      value: member.userId,
    }));
  };

  // Columns for DataTable
  const columns = [
    {
      name: "Category ID",
      selector: (row) => row.categoryID,
      sortable: true,
      width: "150px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Color",
      cell: (row) => (
        <div
          style={{
            backgroundColor: row.categoryCOlor,
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          {row.categoryCOlor}
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    { name: "Description", selector: (row) => row.description, sortable: true },
    {
      name: "",
      cell: (row) => (
        <Button
          variant="warning"
          size="sm"
          onClick={() => handleEditClick(row)}
        >
          <FontAwesomeIcon icon={faPencilAlt} /> Edit
        </Button>
      ),
      width: "120px",
    },
  ];

  if (loading) {
    return <Shimmer type="sections" />;
  }

  return (
    <Fragment>
      <Row className="mb-3">
        <Col>
          <Button size="xs" variant="success" onClick={handleAddClick}>
            <FontAwesomeIcon icon={faPlus} /> Add New Category
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <DataTable
            columns={columns}
            data={feedbackCategories}
            pagination
            highlightOnHover
          />
        </Col>
      </Row>

      {/* Add/Edit FeedbackCategory Modal */}
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode
              ? "Edit Feedback Category"
              : "Add New Feedback Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedCategory?.name || ""}
                onChange={(e) =>
                  setSelectedCategory({
                    ...selectedCategory,
                    name: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={selectedCategory?.description || ""}
                onChange={(e) =>
                  setSelectedCategory({
                    ...selectedCategory,
                    description: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="color">
              <Form.Label>Color</Form.Label>
              <Form.Control
                type="color"
                value={selectedCategory?.color || "#000000"}
                onChange={(e) =>
                  setSelectedCategory({
                    ...selectedCategory,
                    color: e.target.value,
                  })
                }
              />
            </Form.Group>

            {/* Recipient Section */}
            {isEditMode ? (

<Form.Group>
<Form.Label>Recipients</Form.Label>
<Row className="mt-2">
  <Col xs={12} md={8}>
    <AsyncSelect
      id="memberSelect"
      placeholder="Type to search a member"
      cacheOptions
      loadOptions={loadMemberOptions}
      defaultOptions
      isClearable
      onChange={setSelectedMember}
    />
  </Col>
  <Col xs={12} md={4}>
    <Button
      variant="success"
      size="xs"
      onClick={handleAddRecipient}
    >
      <FontAwesomeIcon icon={faPlus} /> Add
    </Button>
  </Col>
</Row>
<Row className="mt-3">
  {recipients.length === 0 && recipientError && (
    <Alert variant="warning">{recipientError}</Alert>
  )}
  {recipients.map((recipient) => (
    
    <Col xs={12} md={6} lg={6}>
    <Card
      key={recipient.categoryRecipientID}
      className="mb-2"
      style={{ position: "relative", padding: "0em 0em 1em 0em" }}
    >
      <Card.Body style={{paddingLeft: 0}}>
        <Row>
          <Col xs={3}>
            <img
              src={recipient.avatar || avatar}
              alt="Avatar"
              className="img-fluid rounded-circle"
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
              }} // Adjust avatar size
            />
          </Col>
          <Col xs={7}>
            <div
              style={{ fontSize: "9pt", fontWeight: "700" }}
            >
              {recipient.firstName} {recipient.surname}
            </div>
            <div style={{ fontSize: "8pt", color: "#666" }}>
              {recipient.email}
            </div>
          </Col>
          <Col xs={2} className="text-right">
            {/* Make the button float to the top-right corner and reduce its size */}
            <Button
              variant="outline-danger"
              size="xs"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                margin: "5px",
              }} // Offset button
              onClick={() =>
                handleRemoveRecipient(
                  recipient
                )
              }
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
  ))}
</Row>
</Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} size="sm">
            <FontAwesomeIcon icon={faTimes} /> Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isSaving}
            size="sm"
          >
            {isSaving ? (
              "Saving..."
            ) : (
              <>
                <FontAwesomeIcon icon={faSave} />{" "}
                {isEditMode ? "Update" : "Save"}
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default FeedbackCategories;

import React, { useState, useEffect } from 'react';
import { Tab, Nav, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import {
  MemberByUserId,
  InsertMember,
  UpdateMember,
} from '../../../../../../services/ccms/memberManagement/member/member_endpoint';
import { MaritalStatusList } from '../../../../../../services/ccms/memberManagement/marital_status/marital_status_endpoints';
import {
  InsertMemberAddressWithUserID,
  UpdateMemberAddress,
} from '../../../../../../services/ccms/memberManagement/member_address/member_address_enpoint';
import { GetMemberAddressByUserId } from '../../../../../../services/ccms/memberManagement/member_in_address/member_in_address_endpoints';
import { format } from 'date-fns';

const DetailsTab = ({ userId }) => {
  const [activeKey, setActiveKey] = useState('Info');

  // State for Member Info
  const [memberInfo, setMemberInfo] = useState({
    memberID: null,
    userId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    maritalStatusID: '',
    email: '',
    cellNumber: '',
    altContact: '',
    telephone: '',
    isMember: true,
    isActive: true,
    avatar: '',
  });
  const [maritalStatuses, setMaritalStatuses] = useState([]);
  const [infoErrors, setInfoErrors] = useState({});
  const [isInfoSaving, setIsInfoSaving] = useState(false);

  // State for Member Address
  const [memberAddress, setMemberAddress] = useState({
    addressID: null,
    addressLine1: '',
    addressLine2: '',
    suburb: '',
    city: '',
    code: '',
    province: '',
    country: '',
  });
  const [addressErrors, setAddressErrors] = useState({});
  const [isAddressSaving, setIsAddressSaving] = useState(false);

  useEffect(() => {
    if (userId) {
      fetchMemberInfo();
      fetchMemberAddress();
      fetchMaritalStatuses();
    }
  }, [userId]);

  const fetchMemberInfo = async () => {
    try {
      const response = await MemberByUserId(userId);
      if (response.status === 'Success') {
        const member = response.results;
        setMemberInfo({
          memberID: member.memberID,
          userId: member.userId,
          firstName: member.firstName,
          middleName: member.middleName || '',
          lastName: member.lastName,
          dateOfBirth: member.dateOfBirth ? format(new Date(member.dateOfBirth), 'yyyy-MM-dd') : '',
          gender: member.gender || '',
          maritalStatusID: member.maritalStatusID ? member.maritalStatusID.toString() : '',
          email: member.email,
          cellNumber: member.cellNumber || '',
          altContact: member.altContact || '',
          telephone: member.telephone || '',
          isMember: member.isMember,
          isActive: member.isActive,
          avatar: member.avatar || '',
        });
      } else {
        // Member not found, allow user to enter information
        setMemberInfo((prev) => ({ ...prev, userId }));
      }
    } catch (error) {
      console.error('Error fetching member info:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while fetching member info',
        icon: 'error',
      });
    }
  };

  const fetchMemberAddress = async () => {
    try {
      const response = await GetMemberAddressByUserId(userId);
      if (response.status === 'Success') {
        const address = response.results;
        setMemberAddress({
          addressID: address.addressID,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          suburb: address.suburb,
          city: address.city,
          code: address.code,
          province: address.province,
          country: address.country,
        });
      } else {
        // Address not found, allow user to enter information
        setMemberAddress((prev) => ({ ...prev, userId }));
      }
    } catch (error) {
      console.error('Error fetching member address:', error);
      // Swal.fire({
      //   title: 'Error',
      //   text: 'An error occurred while fetching member address',
      //   icon: 'error',
      // });
    }
  };

  const fetchMaritalStatuses = async () => {
    try {
      const response = await MaritalStatusList();
      if (response.status === 'Success') {
        setMaritalStatuses(response.results);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while fetching marital statuses',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error fetching marital statuses:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while fetching marital statuses',
        icon: 'error',
      });
    }
  };

  /*** Handlers for Member Info ***/
  const handleInfoChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMemberInfo((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateInfo = () => {
    let tempErrors = {};
    if (!memberInfo.firstName) tempErrors.firstName = 'First Name is required';
    if (!memberInfo.lastName) tempErrors.lastName = 'Last Name is required';
    if (!memberInfo.email) tempErrors.email = 'Email is required';
    if (!memberInfo.gender) tempErrors.gender = 'Gender is required';
    if (!memberInfo.maritalStatusID) tempErrors.maritalStatusID = 'Marital Status is required';
    setInfoErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleInfoSave = async (e) => {
    e.preventDefault();
    if (!validateInfo()) return;
    setIsInfoSaving(true);
    try {
      const now = new Date();
      const formattedDateTime = format(now, 'yyyyMMddHHmmss');
      const data = {
        ...memberInfo,
        maritalStatusID: parseInt(memberInfo.maritalStatusID),
        dateOfBirth: memberInfo.dateOfBirth || null,
        modifiedDateTime: formattedDateTime,
      };
      let response;
      if (memberInfo.memberID) {
        // Update existing member
        response = await UpdateMember(data);
      } else {
        // Insert new member
        data.createdDateTime = formattedDateTime;
        response = await InsertMember(data);
      }
      if (response.status === 'Success') {
        Swal.fire({
          title: 'Success',
          text: 'Member information saved successfully',
          icon: 'success',
        });
        // Refresh member info
        fetchMemberInfo();
      } else {
        Swal.fire({
          title: 'Error',
          text: response.message || 'An error occurred while saving member info',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error saving member info:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving member info',
        icon: 'error',
      });
    } finally {
      setIsInfoSaving(false);
    }
  };

  /*** Handlers for Member Address ***/
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setMemberAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateAddress = () => {
    let tempErrors = {};
    if (!memberAddress.addressLine1) tempErrors.addressLine1 = 'Address Line 1 is required';
    if (!memberAddress.city) tempErrors.city = 'City is required';
    if (!memberAddress.province) tempErrors.province = 'Province is required';
    if (!memberAddress.country) tempErrors.country = 'Country is required';
    setAddressErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleAddressSave = async (e) => {
    e.preventDefault();
    if (!validateAddress()) return;
    setIsAddressSaving(true);
    try {
      const now = new Date();
      const formattedDateTime = format(now, 'yyyyMMddHHmmss');
      const data = {
        ...memberAddress,
        modifiedDateTime: formattedDateTime,
      };
      let response;
      if (memberAddress.addressID) {
        // Update existing address
        response = await UpdateMemberAddress(data);
      } else {
        // Insert new address
        data.createdDateTime = formattedDateTime;
        data.addressID = 0;
        console.log('data', data);
        response = await InsertMemberAddressWithUserID(data, userId);
      }
      if (response.status === 'Success') {
        Swal.fire({
          title: 'Success',
          text: 'Member address saved successfully',
          icon: 'success',
        });
        // Refresh member address
        fetchMemberAddress();
      } else {
        Swal.fire({
          title: 'Error',
          text: response.message || 'An error occurred while saving member address',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error saving member address:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving member address',
        icon: 'error',
      });
    } finally {
      setIsAddressSaving(false);
    }
  };

  return (
    <div className="row">
      <div className="col-md-2">
        <Nav variant="pills" className="mt-5 mb-3 justify-content-center flex-column" onSelect={(k) => setActiveKey(k)}>
          <Nav.Item>
            <Nav.Link eventKey="Info" className={activeKey === 'Info' ? 'bg-secondary text-white' : 'text-secondary'} style={{borderRadius: '0 100px 100px 0'}}>Info</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Address" className={activeKey === 'Address' ? 'bg-secondary text-white' : 'text-secondary'} style={{borderRadius: '0 100px 100px 0'}}>Address</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="col-md-10">
        <Tab.Content>
          <Tab.Pane eventKey="Info" active={activeKey === 'Info'}>
            <div className="pt-3">
              <div className="settings-form">
                <h4 className="text-primary">Member Information</h4>
                <Form onSubmit={handleInfoSave}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={memberInfo.firstName}
                        onChange={handleInfoChange}
                      />
                      {infoErrors.firstName && (
                        <small className="text-danger">{infoErrors.firstName}</small>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="middleName"
                        value={memberInfo.middleName}
                        onChange={handleInfoChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={memberInfo.lastName}
                        onChange={handleInfoChange}
                      />
                      {infoErrors.lastName && (
                        <small className="text-danger">{infoErrors.lastName}</small>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        name="dateOfBirth"
                        value={memberInfo.dateOfBirth}
                        onChange={handleInfoChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Gender</label>
                      <select
                        className="form-control"
                        name="gender"
                        value={memberInfo.gender}
                        onChange={handleInfoChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      {infoErrors.gender && (
                        <small className="text-danger">{infoErrors.gender}</small>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Marital Status</label>
                      <select
                        className="form-control"
                        name="maritalStatusID"
                        value={memberInfo.maritalStatusID}
                        onChange={handleInfoChange}
                      >
                        <option value="">Select Marital Status</option>
                        {maritalStatuses.map((status) => (
                          <option key={status.maritalStatusID} value={status.maritalStatusID}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                      {infoErrors.maritalStatusID && (
                        <small className="text-danger">{infoErrors.maritalStatusID}</small>
                      )}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={memberInfo.email}
                      onChange={handleInfoChange}
                    />
                    {infoErrors.email && (
                      <small className="text-danger">{infoErrors.email}</small>
                    )}
                  </div>

                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Cell Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="cellNumber"
                        value={memberInfo.cellNumber}
                        onChange={handleInfoChange}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Alternate Contact</label>
                      <input
                        type="text"
                        className="form-control"
                        name="altContact"
                        value={memberInfo.altContact}
                        onChange={handleInfoChange}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label">Telephone</label>
                    <input
                      type="text"
                      className="form-control"
                      name="telephone"
                      value={memberInfo.telephone}
                      onChange={handleInfoChange}
                    />
                  </div>

                  {/* Additional fields can be added here */}

                  <Button className="btn btn-primary" type="submit" disabled={isInfoSaving}>
                    {isInfoSaving ? 'Saving...' : 'Save Info'}
                  </Button>
                </Form>
              </div>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="Address" active={activeKey === 'Address'}>
            <div className="pt-3">
              <div className="settings-form">
                <h4 className="text-primary">Member Address</h4>
                <Form onSubmit={handleAddressSave}>
                  <div className="form-group mb-3">
                    <label className="form-label">Address Line 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addressLine1"
                      value={memberAddress.addressLine1}
                      onChange={handleAddressChange}
                    />
                    {addressErrors.addressLine1 && (
                      <small className="text-danger">{addressErrors.addressLine1}</small>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Address Line 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addressLine2"
                      value={memberAddress.addressLine2}
                      onChange={handleAddressChange}
                    />
                  </div>

                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Suburb</label>
                      <input
                        type="text"
                        className="form-control"
                        name="suburb"
                        value={memberAddress.suburb}
                        onChange={handleAddressChange}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={memberAddress.city}
                        onChange={handleAddressChange}
                      />
                      {addressErrors.city && (
                        <small className="text-danger">{addressErrors.city}</small>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Province</label>
                      <input
                        type="text"
                        className="form-control"
                        name="province"
                        value={memberAddress.province}
                        onChange={handleAddressChange}
                      />
                      {addressErrors.province && (
                        <small className="text-danger">{addressErrors.province}</small>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        value={memberAddress.country}
                        onChange={handleAddressChange}
                      />
                      {addressErrors.country && (
                        <small className="text-danger">{addressErrors.country}</small>
                      )}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label">Postal Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={memberAddress.code}
                      onChange={handleAddressChange}
                    />
                  </div>
                  <input type="hidden" name="addressID" value={memberAddress.addressID} />
                  

                  <Button className="btn btn-primary" type="submit" disabled={isAddressSaving}>
                    {isAddressSaving ? 'Saving...' : 'Save Address'}
                  </Button>
                </Form>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </div>
    </div>
  );
};

export default DetailsTab;

import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, Form, Badge, Container, Card, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import { getMyTaskNotifications, markTaskNotificationAsRead } from '../../../../services/ccms/taskManagement/taskNotification/task_notification_endpoints';

import { addTaskToQueue } from '../../../../services/ccms/taskQueue';

const decodeBase64 = (str) => {
  try {
    return atob(str);
  } catch (e) {
    console.error('Failed to decode base64 string:', e);
    return str;
  }
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await getMyTaskNotifications();
      setNotifications(response.results);
      setIsLoading(false);
    };

    fetchNotifications();
  }, []);

  const handleViewNotification = async (notification) => {
    setSelectedNotification(notification);
    setIsModalVisible(true);
    if (!notification.IsRead) {
      await markTaskNotificationAsRead(notification.notificationID);
      setNotifications(notifications.map(n =>
        n.notificationID === notification.notificationID ? { ...n, IsRead: true } : n
      ));
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredNotifications = notifications
    .filter(notification =>
      decodeBase64(notification.message).toLowerCase().includes(searchText.toLowerCase()) ||
      notification.notificationID.toString().includes(searchText.toLowerCase())
    )
    .sort((a, b) => new Date(b.notificationDate) - new Date(a.notificationDate));

  const columns = [
    {
      name: 'Date',
      selector: row => new Date(row.notificationDate).toLocaleString(),
      sortable: true,
      width: '200px'
    },
    {
      name: 'Message',
      selector: row => decodeBase64(row.message),
      sortable: true,
      grow: 2
    },
    {
      name: '',
      cell: row => (
        <Button className='p-2 m-2'
          variant="outline-primary"
          size="xxs"
          onClick={() => handleViewNotification(row)}
        >
          <FontAwesomeIcon icon={faEye} /> View
        </Button>
      ),
      right: true, // This ensures the button is at the end
      width: '100px'
    },
  ];

  const unreadCount = notifications.filter(notification => !notification.IsRead).length;

  return (
    <Fragment>
      <div className="page-header d-flex justify-content-between align-items-end"></div>
      
    <Badge pill bg="danger">
                Unread Notifications: {unreadCount}
              </Badge>
      {isLoading ? (
        <Shimmer type='table' />
      ) : (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <InputGroup className="mb-3" style={{ width: '300px' }}>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </InputGroup>
              <div className="ms-auto d-flex">
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <DataTable
              columns={columns}
              data={filteredNotifications}
              pagination
              highlightOnHover
              customStyles={{
                rows: {
                  style: row => ({
                    fontWeight: row.IsRead ? 'normal' : 'bold',
                    backgroundColor: row.IsRead ? '' : '',
                    height: '50px'
                  }),
                },
                headCells: {
                  style: {
                    fontWeight: 'bold',
                  },
                },
                cells: {
                  style: row => ({
                    fontWeight: row.IsRead ? 'normal' : 'bold',
                  }),
                },
              }}
            />
          </Card.Body>
        </Card>
      )}
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedNotification ? `Notification ${selectedNotification.notificationID}` : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedNotification ? decodeBase64(selectedNotification.message) : ''}</p>
          <p>{selectedNotification ? new Date(selectedNotification.notificationDate).toLocaleString() : ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Notifications;

import { getApiDepartments } from '../../APIServiceSetup';

const api_departments = await getApiDepartments();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// MemberDesignation functions

export const getMemberDesignationList = async (forceUpdate = false) => {
  const cacheKey = 'memberDesignationList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/MemberDesignation/List`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching member designation list", error);
    throw error;
  }
};

export const getMemberDesignationById = async (id) => {
  try {
    const response = await api_departments.get(`/api/MemberDesignation/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching member designation with id ${id}`, error);
    throw error;
  }
};

export const getSingleMemberDesignation = async () => {
  try {
    const response = await api_departments.get(`/api/MemberDesignation/Single`);
    return response.data;
  } catch (error) {
    console.error("Error fetching single member designation", error);
    throw error;
  }
};

export const insertMemberDesignation = async (memberDesignationData) => {
  try {
    const response = await api_departments.post(`/api/MemberDesignation/Insert`, memberDesignationData);
    return response.data;
  } catch (error) {
    console.error("Error inserting member designation", error);
    throw error;
  }
};

export const updateMemberDesignation = async (memberDesignationData) => {
  try {
    const response = await api_departments.post(`/api/MemberDesignation/Update`, memberDesignationData);
    return response.data;
  } catch (error) {
    console.error("Error updating member designation", error);
    throw error;
  }
};

export const deleteMemberDesignationWithID = async (memberID, departmentID) => {
  try {
    const response = await api_departments.post(`/api/MemberDesignation/Delete/${memberID}/${departmentID}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting member designation", error);
    throw error;
  }
};

export const deleteMemberDesignation = async (memberDesignationData) => {
  try {
    const response = await api_departments.post(`/api/MemberDesignation/Delete`, {data: memberDesignationData});
    return response.data;
  } catch (error) {
    console.error("Error deleting member designation", error);
    throw error;
  }
};

export const getMemberDesignation = async (memberID, designationID) => {
  try {
    const response = await api_departments.get(`/api/MemberDesignation/GetMemberDesignation/${memberID}/${designationID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching member designation with memberID ${memberID} and designationID ${designationID}`, error);
    throw error;
  }
};

export const getMemberDesignationsByMemberId = async (memberID) => {
  try {
    const response = await api_departments.get(`/api/MemberDesignation/GetMemberDesignations/${memberID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching member designations with memberID ${memberID}`, error);
    throw error;
  }
};

export const GetMainMemberDesignation = async (designationID) => {  try {
    const response = await api_departments.get(`/api/MemberDesignation/GetMainMember/${designationID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching main member with designationID ${designationID}`, error);
    throw error;
  }
};

export const getMembersByDesignation = async (designationID) => {
  try {
    const response = await api_departments.get(`/api/MemberDesignation/MembersByDesignation`, { params: { designationID } });
    return response.data;
  } catch (error) {
    console.error(`Error fetching members with designationID ${designationID}`, error);
    throw error;
  }
};

// MemberInDepartment functions

export const getMemberInDepartmentList = async (forceUpdate = false) => {
  const cacheKey = 'memberInDepartmentList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/MemberInDepartment/List`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching member in department list", error);
    throw error;
  }
};

export const getMemberInDepartmentById = async (id) => {
  try {
    const response = await api_departments.get(`/api/MemberInDepartment/Get`, { params: { id } });
    return response.data;
  } catch (error) {
    console.error(`Error fetching member in department with id ${id}`, error);
    throw error;
  }
};

export const insertMemberInDepartment = async (memberInDepartmentData) => {
  try {
    const response = await api_departments.post(`/api/MemberInDepartment/Insert`, memberInDepartmentData);
    return response.data;
  } catch (error) {
    console.error("Error inserting member in department", error);
    throw error;
  }
};

export const updateMemberInDepartment = async (memberInDepartmentData) => {
  try {
    const response = await api_departments.post(`/api/MemberInDepartment/Update`, memberInDepartmentData);
    return response.data;
  } catch (error) {
    console.error("Error updating member in department", error);
    throw error;
  }
};

export const deleteMemberInDepartment = async (memberInDepartmentData) => {
  try {
    const response = await api_departments.post(`/api/MemberInDepartment/Delete`, { data: memberInDepartmentData });
    return response.data;
  } catch (error) {
    console.error("Error deleting member in department", error);
    throw error;
  }
};

export const getMemberInDepartment = async (memberID, departmentID) => {
  try {
    const response = await api_departments.get(`/api/MemberInDepartment/${memberID}/${departmentID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching member in department with memberID ${memberID} and departmentID ${departmentID}`, error);
    throw error;
  }
};

export const getHeadOfDepartment = async (departmentID) => {
  try {
    const response = await api_departments.get(`/api/MemberInDepartment/GetHead/${departmentID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching head of department with departmentID ${departmentID}`, error);
    throw error;
  }
};

export const getHeadsOfDepartments = async () => {
  try {
    const response = await api_departments.get(`/api/MemberInDepartment/GetHeads`);
    return response.data;
  } catch (error) {
    console.error("Error fetching heads of departments", error);
    throw error;
  }
};

export const getMembersOfDepartment = async (departmentID, forceUpdate = false) => {
  const cacheKey = `MembersOfDepartment_${departmentID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_departments.get(`/api/MemberInDepartment/Members/${departmentID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching members of department with departmentID ${departmentID}`, error);
    throw error;
  }
};

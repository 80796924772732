import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

const TasksByPriorityGraph = ({ tasks }) => {
  const priorityData = tasks.reduce((acc, task) => {
    const priority = acc.find(item => item.name === task.priorityName);
    if (priority) {
      priority.tasks += 1;
    } else {
      acc.push({ name: task.priorityName, tasks: 1, color: task.priorityColor });
    }
    return acc;
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={priorityData}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="tasks">
          {priorityData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TasksByPriorityGraph;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import FileUploader from '../../../../../components/Custom/Uploader/FileUploader'; // Custom file uploader component
import FilePreview from '../../../../../components/Custom/Uploader/FilePreview';
import {
  EmployeeFileListByUserId,
  SaveEmployeeFile,
  UpdateEmployeeFile,
} from '../../../../../../services/ccms/hrManagement/employeeFile';
import { EmployeeFileCategoryList } from '../../../../../../services/ccms/hrManagement/employeeFileCategory';
import { EmployeeFileTypeList } from '../../../../../../services/ccms/hrManagement/employeeFileType';
// import { UploadEmployeeFile } from '../../../../../../services/ccms/fileManagement/file'; // Uncomment if needed

const FilesTab = ({ userId }) => {
  const [files, setFiles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [showUploaderModal, setShowUploaderModal] = useState(false);
  const [editingFile, setEditingFile] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [fileData, setFileData] = useState({
    fileName: '',
    description: '',
    categoryID: '',
    typeID: '',
    mediaType: '',
    hrOnly: false,
    keywords: '',
    fileURL: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null); // Changed from isPreviewOpen

  const loggedOnUserId = localStorage.getItem('userID');

  useEffect(() => {
    fetchFiles();
    fetchCategories();
    fetchTypes();
  }, [userId]);

  const fetchFiles = async () => {
    try {
      const response = await EmployeeFileListByUserId(userId);
      if (response.status === 'Success') {
        setFiles(response.results);
        setFilteredFiles(response.results || []);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while fetching employee files',
        icon: 'error',
      });
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await EmployeeFileCategoryList();
      if (response.status === 'Success') {
        setCategories(response.results);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await EmployeeFileTypeList();
      if (response.status === 'Success') {
        setTypes(response.results);
      }
    } catch (error) {
      console.error('Error fetching types:', error);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    filterFiles(term, selectedCategory, selectedType);
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    filterFiles(searchTerm, categoryId, selectedType);
  };

  const handleTypeChange = (e) => {
    const typeId = e.target.value;
    setSelectedType(typeId);
    filterFiles(searchTerm, selectedCategory, typeId);
  };

  const filterFiles = (term, categoryId, typeId) => {
    const filtered = files.filter((file) => {
      return (
        (file.fileName.toLowerCase().includes(term) ||
          (file.description && file.description.toLowerCase().includes(term))) &&
        (!categoryId || file.categoryID === parseInt(categoryId)) &&
        (!typeId || file.typeID === parseInt(typeId))
      );
    });
    setFilteredFiles(filtered || []);
  };

  const handleFileUploadSuccess = (fieldName, uploadedFileURL) => {
    setFileData((prevState) => ({
      ...prevState,
      [fieldName]: uploadedFileURL,
      mediaType: getMediaType(uploadedFileURL),
    }));
    setIsUploading(false);
  };

  const handleFileUploadStart = () => {
    setIsUploading(true);
  };

  const getMediaType = (fileURL) => {
    const extension = fileURL.split('.').pop().toLowerCase();
    const mimeTypes = {
      pdf: 'application/pdf',
      doc: 'application/msword',
      docx: 'application/msword',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ppt: 'application/vnd.ms-powerpoint',
      pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      txt: 'text/plain',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      bmp: 'image/bmp',
      mp4: 'video/mp4',
      webm: 'video/webm',
      ogg: 'video/ogg',
      mp3: 'audio/mpeg',
      wav: 'audio/wav',
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  const handlePreview = (file) => {
    setPreviewFile(file);
  };

  const handleClosePreview = () => {
    setPreviewFile(null);
  };

  const handleEditFile = (file) => {
    setEditingFile(file);
    setFileData({
      fileName: file.fileName || '',
      description: file.description || '',
      categoryID: file.categoryID || '',
      typeID: file.typeID || '',
      mediaType: file.mediaType || '',
      hrOnly: file.hrOnly || false,
      keywords: file.keywords || '',
      fileURL: file.fileURL || '',
    });
    setShowUploaderModal(true);
  };

  const handleAddFile = () => {
    setEditingFile(null);
    setFileData({
      fileName: '',
      description: '',
      categoryID: '',
      typeID: '',
      mediaType: '',
      hrOnly: false,
      keywords: '',
      fileURL: '',
    });
    setShowUploaderModal(true);
  };

  const handleSaveFile = async () => {
    // Validate required fields
    if (!fileData.fileURL) {
      setAlertMessage('Please upload a file.');
      return;
    }
    if (!fileData.fileName) {
      setAlertMessage('Please enter a file name.');
      return;
    }
    if (!fileData.categoryID) {
      setAlertMessage('Please select a category.');
      return;
    }
    if (!fileData.typeID) {
      setAlertMessage('Please select a type.');
      return;
    }

    setAlertMessage(null);
    setIsSaving(true);

    try {
      fileData.userId = userId;
      fileData.uploadedBy = loggedOnUserId;
      fileData.uploadDate = new Date().toISOString();
      fileData.hasExpired = false;
      const response = editingFile
        ? await UpdateEmployeeFile({ ...fileData, fileID: editingFile.fileID })
        : await SaveEmployeeFile(fileData);

      if (response.status === 'Success') {
        Swal.fire({
          title: 'Success',
          text: editingFile ? 'File updated successfully.' : 'File uploaded successfully.',
          icon: 'success',
        });
        setShowUploaderModal(false);
        setEditingFile(null);
        setFileData({
          fileName: '',
          description: '',
          categoryID: '',
          typeID: '',
          mediaType: '',
          hrOnly: false,
          keywords: '',
          fileURL: '',
        });
        fetchFiles();
      } else {
        Swal.fire({
          title: 'Error',
          text: response.message || 'An error occurred while saving the file.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error saving file:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the file.',
        icon: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFileData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleModalClose = () => {
    setShowUploaderModal(false);
    setEditingFile(null);
    setFileData({
      fileName: '',
      description: '',
      categoryID: '',
      typeID: '',
      mediaType: '',
      hrOnly: false,
      keywords: '',
      fileURL: '',
    });
    setAlertMessage(null);
  };

  // Define columns for DataTable
  const columns = [
    {
      name: 'File Name',
      selector: (row) => row.fileName,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description || '',
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => {
        const category = categories.find((cat) => cat.categoryID === row.categoryID);
        return category ? category.name : 'N/A';
      },
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => {
        const type = types.find((t) => t.typeID === row.typeID);
        return type ? type.name : 'N/A';
      },
      sortable: true,
    },
    {
      name: 'Upload Date',
      selector: (row) => new Date(row.uploadDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <Button variant="link" size="sm" onClick={() => handlePreview(row)}>
            <FontAwesomeIcon icon={faEye} /> Preview
          </Button>
          <Button variant="link" size="sm" onClick={() => handleEditFile(row)}>
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>
        </>
      ),
    },
  ];

  // Filtered data for DataTable
  const filteredData = filteredFiles || [];

  return (
    <div className="pt-3">
      <div className="settings-form">
        <h4 className="text-primary">Employee Files</h4>

        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="mb-0">Manage files uploaded by or for the employee.</p>
          <Button variant="primary" onClick={handleAddFile}>
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add File
          </Button>
        </div>

        <Form className="d-flex mb-4">
          <Form.Control
            type="text"
            placeholder="Search by filename or description"
            className="me-2"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Form.Select value={selectedCategory} onChange={handleCategoryChange} className="me-2">
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.categoryID} value={category.categoryID}>
                {category.name}
              </option>
            ))}
          </Form.Select>
          <Form.Select value={selectedType} onChange={handleTypeChange}>
            <option value="">All Types</option>
            {types.map((type) => (
              <option key={type.typeID} value={type.typeID}>
                {type.name}
              </option>
            ))}
          </Form.Select>
        </Form>

        {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}

        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            pointerOnHover
            noDataComponent="No files found."
          />
        </div>

        {/* File Preview Component */}
        {previewFile && (
          <FilePreview fileURL={previewFile.fileURL} onClose={handleClosePreview} />
        )}

        {/* File Uploader Modal */}
        <Modal show={showUploaderModal} onHide={handleModalClose} size="lg" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{editingFile ? 'Edit File' : 'Upload New File'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* File Uploader */}
              <Form.Group>
                <Form.Label>
                  File Upload <span className="text-danger">*</span>
                </Form.Label>
                <FileUploader
                  // uploadFileApi={UploadEmployeeFile}
                  userId={userId}
                  name="fileURL"
                  initialFileURL={fileData.fileURL}
                  onSuccess={handleFileUploadSuccess}
                  onStart={handleFileUploadStart}
                  storageLocation="EmployeeFiles"
                />
                {isUploading && (
                  <div className="mt-2">
                    <Spinner animation="border" size="sm" /> Uploading...
                  </div>
                )}
              </Form.Group>

              {/* Form Fields for File Metadata */}
              <Form.Group className="mt-4">
                <Form.Label>
                  File Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter file name"
                  name="fileName"
                  value={fileData.fileName}
                  onChange={handleInputChange}
                  isInvalid={!fileData.fileName && alertMessage === 'Please enter a file name.'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a file name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter description"
                  name="description"
                  value={fileData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>
                  Category <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="categoryID"
                  value={fileData.categoryID}
                  onChange={handleInputChange}
                  isInvalid={!fileData.categoryID && alertMessage === 'Please select a category.'}
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.categoryID} value={category.categoryID}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a category.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>
                  Type <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="typeID"
                  value={fileData.typeID}
                  onChange={handleInputChange}
                  isInvalid={!fileData.typeID && alertMessage === 'Please select a type.'}
                >
                  <option value="">Select a type</option>
                  {types.map((type) => (
                    <option key={type.typeID} value={type.typeID}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a type.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Check
                  type="checkbox"
                  label="HR Only"
                  name="hrOnly"
                  checked={fileData.hrOnly}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Keywords</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter keywords"
                  name="keywords"
                  value={fileData.keywords}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose} disabled={isSaving || isUploading}>
              <FontAwesomeIcon icon={faTimes} className="me-2" /> Close
            </Button>
            <Button
              variant="primary"
              onClick={handleSaveFile}
              disabled={
                isSaving ||
                isUploading ||
                !fileData.fileURL ||
                !fileData.fileName ||
                !fileData.categoryID ||
                !fileData.typeID
              }
            >
              {isSaving ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Saving...
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSave} className="me-2" /> {editingFile ? 'Save Changes' : 'Upload File'}
                </>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default FilesTab;

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/EmployeeFile/CustomList"
export const EmployeeFileCustomList = async (query, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeFile/CustomList?query=${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeFile/CustomList', { params: { query } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/Get/{id}"
export const GetEmployeeFileById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeFile/Get/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/EmployeeFile/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/List"
export const EmployeeFileList = async ( forceUpdate = false) => {
    const cacheKey = `/api/EmployeeFile/List`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeFile/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/List"
export const EmployeeFileListByUserId = async (userId, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeFile/List/${userId}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/EmployeeFile/List/${userId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/ListView"
export const EmployeeFileListView = async (forceUpdate = false) => {
    const cacheKey = '/api/EmployeeFile/ListView';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeFile/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/PaginatedList"
export const PaginatedEmployeeFileList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/EmployeeFile/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeFile/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/CustomUpdate"
export const EmployeeFileCustomUpdate = async (employeeFile) => {
    try {
        const response = await api_hr.post('/api/EmployeeFile/CustomUpdate', employeeFile);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/Insert"
export const InsertEmployeeFile = async (employeeFile) => {
    try {
        const response = await api_hr.post('/api/EmployeeFile/Insert', employeeFile);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/Save"
export const SaveEmployeeFile = async (employeeFile) => {
    try {
        const response = await api_hr.post('/api/EmployeeFile/Save', employeeFile);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/Update"
export const UpdateEmployeeFile = async (employeeFile) => {
    try {
        const response = await api_hr.post('/api/EmployeeFile/Update', employeeFile);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/Delete"
export const DeleteEmployeeFile = async (employeeFile) => {
    try {
        const response = await api_hr.delete('/api/EmployeeFile/Delete', { data: employeeFile });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFile/GetValue"
export const GetEmployeeFileValue = async (forceUpdate = false) => {
    const cacheKey = '/api/EmployeeFile/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/EmployeeFile/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

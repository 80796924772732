import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_KEY = 'leaveStatusCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in localStorage
const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

// Helper function to get data from cache
const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Component for "/api/LeaveStatus/CustomList"
export const LeaveStatusCustomList = async (query, forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveStatus/CustomList', { params: { query } });
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/CustomUpdate"
export const LeaveStatusCustomUpdate = async (leaveStatus) => {
    try {
        const response = await api_hr.post('/api/LeaveStatus/CustomUpdate', leaveStatus);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/Delete"
export const DeleteLeaveStatus = async (leaveStatus) => {
    try {
        const response = await api_hr.delete('/api/LeaveStatus/Delete', { data: leaveStatus });
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on delete
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/Get/{id}"
export const GetLeaveStatusById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get(`/api/LeaveStatus/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/GetValue"
export const GetLeaveStatusValue = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveStatus/GetValue');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/Insert"
export const InsertLeaveStatus = async (leaveStatus) => {
    try {
        const response = await api_hr.post('/api/LeaveStatus/Insert', leaveStatus);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on insert
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/List"
export const LeaveStatusList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveStatus/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/PaginatedList"
export const PaginatedLeaveStatusList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get('/api/LeaveStatus/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/Save"
export const SaveLeaveStatus = async (leaveStatus) => {
    try {
        const response = await api_hr.post('/api/LeaveStatus/Save', leaveStatus);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on save
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveStatus/Update"
export const UpdateLeaveStatus = async (leaveStatus) => {
    try {
        const response = await api_hr.post('/api/LeaveStatus/Update', leaveStatus);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

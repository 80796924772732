import React, { useState, useEffect, Fragment, useRef } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import Swal from "sweetalert2";
import {
  format,
  addDays,
  isWeekend,
  isSameDay,
  setHours,
  setMinutes,
  addMinutes,
} from "date-fns";
import { LocationList } from "../../../../../services/ccms/locationManagement/location/location_endpoints";
import {
  DepartmentList,
  GetByDepartmentID,
} from "../../../../../services/ccms/departmentManagement/department/department_endpoints";
import { TaskPriorityList } from "../../../../../services/ccms/taskManagement/taskPriority/task_priority_endpoints";
import { InsertTaskAndReturn } from "../../../../../services/ccms/taskManagement/tasks/tasks_endpoints";
import { listDesignationTasks } from "../../../../../services/ccms/departmentManagement/designationTask";

import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
import StepFive from "./steps/StepFive"; // New step for file upload
import StepSix from "./steps/StepSix"; // Renamed existing StepFive to StepSix
import Shimmer from "../../../../components/Custom/Loading/Shimmer";
import { useNavigate } from "react-router-dom";
import { GetMainMemberDesignation } from "../../../../../services/ccms/departmentManagement/member_in_designation/memberInDesignation_endpoints";

const publicHolidays = [
  new Date("2024-01-01"),
  new Date("2024-03-21"),
  new Date("2024-03-29"),
  new Date("2024-04-01"),
  new Date("2024-04-27"),
  new Date("2024-05-01"),
  new Date("2024-06-16"),
  new Date("2024-08-09"),
  new Date("2024-09-24"),
  new Date("2024-12-16"),
  new Date("2024-12-25"),
  new Date("2024-12-26"),
];

const generateTimes = (start, end, interval) => {
  const times = [];
  let currentTime = start;
  while (currentTime <= end) {
    times.push(new Date(currentTime));
    currentTime = new Date(currentTime.getTime() + interval);
  }
  return times;
};

const getNextValidDate = (date, daysToAdd) => {
  let validDaysAdded = 0;
  let nextDate = new Date(date);

  while (validDaysAdded < daysToAdd) {
    nextDate = addDays(nextDate, 1);
    if (
      !isWeekend(nextDate) &&
      !publicHolidays.some((holiday) => isSameDay(holiday, nextDate))
    ) {
      validDaysAdded++;
    }
  }

  return nextDate;
};

const NewTask = () => {
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [priorities, setPriorities] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [designationTasks, setDesignationTasks] = useState([]);
  const [startDate, setStartDate] = useState(getNextValidDate(new Date(), 2));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [taskDetails, setTaskDetails] = useState({
    title: "",
    dueDate: "",
    priorityID: "",
    locationID: "",
    departmentID: "",
    designationID: "",
    description: "",
    statusID: 0,
    assignedBy: "",
    createdBy: "",
    mainMemberUserID: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]); // New state for uploaded files
  const navigate = useNavigate();

  // Refs for scrolling
  const stepRefs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
    5: useRef(null),
    6: useRef(null),
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [locationResponse, departmentResponse, priorityResponse] =
          await Promise.all([
            LocationList(),
            DepartmentList(),
            TaskPriorityList(),
          ]);

        setLocations(locationResponse.results);
        setDepartments(departmentResponse.results);
        setPriorities(priorityResponse.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Scroll to the current step
    if (stepRefs[currentStep] && stepRefs[currentStep].current) {
      stepRefs[currentStep].current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentStep]);

  const handleDepartmentChange = async (selectedValue) => {
    setLoading(true);
    setSelectedDepartment(selectedValue);
    const response = await GetByDepartmentID(selectedValue);
    setDesignations(response.results);
    setTaskDetails((prev) => ({ ...prev, departmentID: selectedValue }));
    setLoading(false);
    setCurrentStep(2);
  };

  const handleDesignationChange = async (selectedValue) => {
    setLoading(true);
    setTaskDetails((prev) => ({ ...prev, designationID: selectedValue }));
    const response = await listDesignationTasks();

    const mainMemberResponse = await GetMainMemberDesignation(selectedValue);
    if (mainMemberResponse && mainMemberResponse.results) {
      setTaskDetails((prev) => ({
        ...prev,
        mainMemberUserID: mainMemberResponse.results.userId,
      }));
    }

    const designationTasks = response.results.filter(
      (task) => task.designationID === Number(selectedValue)
    );

    setDesignationTasks(designationTasks || []);
    setLoading(false);
    setCurrentStep(3);
  };

  const handleTitleChange = (title) => {
    setTaskDetails((prev) => ({ ...prev, title }));
  };

  const handleDesignationTaskChange = (selectedTask) => {
    const task = designationTasks.find((task) => task.name === selectedTask);
    if (task) {
      handleTitleChange(task.name);
      const durationInMinutes = task.duration || 0;
      const newDueDate = addMinutes(new Date(), durationInMinutes);
      setStartDate(getNextValidDate(newDueDate, 0));
    } else {
      handleTitleChange(selectedTask);
    }
  };

  const handleTaskDetailChange = (key, value) => {
    setTaskDetails((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePreviousStep = (step) => {
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const UserDetails = JSON.parse(localStorage.getItem("userDetails"));
    const data = {
      ...taskDetails,
      dueDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
      assignedBy: UserDetails.userId,
      createdBy: UserDetails.userId,
      attachments: uploadedFiles.map((file) => ({
        attachmentID: 0,
        taskID: 0,
        fileName: file.name,
        fileType: file.type,
        fileURL: file.url,
        attachmentDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        createdBy: UserDetails.userId,
        modifiedDateTime: format(new Date(), "yyyyMMddHHmmss"),
        createdDateTime: format(new Date(), "yyyyMMddHHmmss"),
      })),
    };

    try {
      const response = await InsertTaskAndReturn(data);
      if (response.status === "Success") {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: "success",
        });

        navigate("/app/view-task", { state: response.results });
      } else {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while saving the task",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="New Task"
        motherMenu="Tasks"
        motherMenuPath="/app/tasks"
        pageContent="tasks"
      />

      <div className="form-validation">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="chat-conversation">
              {currentStep >= 1 && (
                <div ref={stepRefs[1]}>
                  <StepOne
                    departments={departments}
                    handleDepartmentChange={handleDepartmentChange}
                    taskDetails={taskDetails}
                    currentStep={currentStep}
                    handlePreviousStep={handlePreviousStep}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {loading && currentStep === 2 && <Shimmer type="list" />}
              {currentStep >= 2 && !loading && (
                <div ref={stepRefs[2]}>
                  <StepTwo
                    designations={designations}
                    taskDetails={taskDetails}
                    handleDesignationChange={handleDesignationChange}
                    currentStep={currentStep}
                    handlePreviousStep={handlePreviousStep}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {loading && currentStep === 3 && <Shimmer type="list" />}
              {currentStep >= 3 && !loading && (
                <div ref={stepRefs[3]}>
                  <StepThree
                    designationTasks={designationTasks || []}
                    taskDetails={taskDetails}
                    handleTitleChange={handleTitleChange}
                    handleDesignationTaskChange={handleDesignationTaskChange}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    getNextValidDate={getNextValidDate}
                    currentStep={currentStep}
                    handlePreviousStep={handlePreviousStep}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {loading && currentStep === 4 && <Shimmer type="list" />}
              {currentStep >= 4 && !loading && (
                <div ref={stepRefs[4]}>
                  <StepFour
                    priorities={priorities}
                    taskDetails={taskDetails}
                    setTaskDetails={handleTaskDetailChange}
                    currentStep={currentStep}
                    handlePreviousStep={handlePreviousStep}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {currentStep >= 5 && (
                <div ref={stepRefs[5]}>
                  <StepFive
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    currentStep={currentStep}
                    handlePreviousStep={handlePreviousStep}
                    handleNextStep={handleNextStep}
                  />
                </div>
              )}
              {currentStep >= 6 && (
                <div ref={stepRefs[6]}>
                  <StepSix
                    locations={locations}
                    taskDetails={taskDetails}
                    setTaskDetails={handleTaskDetailChange}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    currentStep={currentStep}
                    handlePreviousStep={handlePreviousStep}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewTask;

import { getApiTasks } from "../../APIServiceSetup";

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get my task notifications with caching
export const getMyTaskNotifications = async (forceUpdate = false) => {
  const cacheKey = 'myTaskNotifications';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.get("/api/TaskNotification/MyList");
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to mark task notification as read
export const markTaskNotificationAsRead = async (notificationID) => {
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.post(`/api/TaskNotification/MarkAsRead/${notificationID}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task notification list with caching
export const getTaskNotificationList = async (forceUpdate = false) => {
  const cacheKey = 'taskNotificationList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.get("/api/TaskNotification/List");
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task notification by ID with caching
export const getTaskNotificationById = async (id, forceUpdate = false) => {
  const cacheKey = `taskNotificationById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.get(`/api/TaskNotification/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task notification with caching
export const getTaskNotification = async (forceUpdate = false) => {
  const cacheKey = 'taskNotification';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.get("/api/TaskNotification/Get");
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert task notification
export const insertTaskNotification = async (notificationData) => {
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.post("/api/TaskNotification/Insert", notificationData);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to update task notification
export const updateTaskNotification = async (notificationData) => {
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.put("/api/TaskNotification/Update", notificationData);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert or update task notification
export const insertOrUpdateTaskNotification = async (notificationData) => {
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.post("/api/TaskNotification/InsertOrUpdate", notificationData);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to delete task notification
export const deleteTaskNotification = async (id) => {
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.delete(`/api/TaskNotification/Delete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to count task notifications with caching
export const countTaskNotifications = async (forceUpdate = false) => {
  const cacheKey = 'countTaskNotifications';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.get("/api/TaskNotification/Count");
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to search task notifications with caching
export const searchTaskNotifications = async (searchParams, forceUpdate = false) => {
  const cacheKey = `searchTaskNotifications_${JSON.stringify(searchParams)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.post("/api/TaskNotification/Search", searchParams);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get paginated task notification list with caching
export const getPaginatedTaskNotificationList = async (paginationParams, forceUpdate = false) => {
  const cacheKey = `paginatedTaskNotificationList_${JSON.stringify(paginationParams)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.post("/api/TaskNotification/PaginatedList", paginationParams);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom task notification list with caching
export const getCustomTaskNotificationList = async (customParams, forceUpdate = false) => {
  const cacheKey = `customTaskNotificationList_${JSON.stringify(customParams)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.post("/api/TaskNotification/CustomList", customParams);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom task notification with caching
export const getCustomTaskNotification = async (customParams, forceUpdate = false) => {
  const cacheKey = `customTaskNotification_${JSON.stringify(customParams)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  const api_tasks = await getApiTasks();
  try {
    const response = await api_tasks.post("/api/TaskNotification/Custom", customParams);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

import { getApiSystem } from '../../APIServiceSetup';

const api_system = await getApiSystem();
const CACHE_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
let cache = {};

const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// FeedbackCategory Endpoints
export const FeedbackCategoryList = async (forceUpdate = false) => {
    const cacheKey = '/api/FeedbackCategory/List';
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get('/api/FeedbackCategory/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackCategoryInsert = async (category) => {
    try {
        const response = await api_system.post('/api/FeedbackCategory/Insert', category);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackCategoryUpdate = async (category) => {
    try {
        const response = await api_system.post('/api/FeedbackCategory/Update', category);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackCategoryCustomUpdate = async (query) => {
    try {
        const response = await api_system.post('/api/FeedbackCategory/CustomUpdate', { query });
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

export const FeedbackCategoryGetById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/FeedbackCategory/Get/${id}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/FeedbackCategory/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

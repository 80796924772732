import { getApiTasks } from "../../APIServiceSetup";

const api_tasks = await getApiTasks();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get the task comment list with caching
export const TaskCommentList = async (forceUpdate = false) => {
  const cacheKey = 'taskCommentList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskComment/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task comment by ID with caching
export const TaskCommentById = async (id, forceUpdate = false) => {
  const cacheKey = `taskCommentById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/TaskComment/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task comment with caching
export const TaskCommentGet = async (forceUpdate = false) => {
  const cacheKey = 'taskCommentGet';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskComment/Get');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert task comment
export const TaskCommentInsert = async (data) => {
  try {
    const response = await api_tasks.post('/api/TaskComment/Insert', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to update task comment
export const TaskCommentUpdate = async (data) => {
  try {
    const response = await api_tasks.post('/api/TaskComment/Update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert or update task comment
export const TaskCommentInsertOrUpdate = async (data) => {
  try {
    const response = await api_tasks.post('/api/TaskComment/InsertOrUpdate', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to delete task comment
export const TaskCommentDelete = async (data) => {
  try {
    const response = await api_tasks.post(`/api/TaskComment/Delete`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task comment count with caching
export const TaskCommentCount = async (forceUpdate = false) => {
  const cacheKey = 'taskCommentCount';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskComment/Count');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to search task comments with caching
export const TaskCommentSearch = async (query, forceUpdate = false) => {
  const cacheKey = `taskCommentSearch_${JSON.stringify(query)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskComment/Search', { params: { query } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get paginated task comment list with caching
export const TaskCommentPaginatedList = async (page, size, forceUpdate = false) => {
  const cacheKey = `taskCommentPaginatedList_${page}_${size}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskComment/PaginatedList', { params: { page, size } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom task comment list with caching
export const TaskCommentCustomList = async (params, forceUpdate = false) => {
  const cacheKey = `taskCommentCustomList_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskComment/CustomList', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom task comment with caching
export const TaskCommentCustom = async (params, forceUpdate = false) => {
  const cacheKey = `taskCommentCustom_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskComment/Custom', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task comment list view by task ID with caching
export const TaskCommentCommentListView = async (taskID, forceUpdate = false) => {
  const cacheKey = `taskCommentCommentListView_${taskID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/TaskComment/CommentListView/${taskID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

import React, { useEffect, useState, useRef, Fragment } from 'react';
import $ from 'jquery';
import Swal from 'sweetalert2';
import 'datatables.net-bs4';
import { Container, Row, Col, Card, Table, Button, Modal, Form, Badge, Toast, ToastContainer, ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Shimmer from '../../../components/Custom/Loading/Shimmer';

import { DepartmentListView, InsertDepartment } from '../../../../services/ccms/departmentManagement/department/department_endpoints';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../../layouts/PageTitle';

// import { addTaskToQueue } from '../../../../services/ccms/taskQueue';

const Departments = () => {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newDepartment, setNewDepartment] = useState({ name: '', description: '', isActive: true });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const tableRef = useRef();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await DepartmentListView();
        if (response.results) {
          console.debug('Departments:', response.results);
          setDepartments(response.results);
          setIsLoaded(true);
        } else {
          console.error('Error: response.results is undefined');
          setIsLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
        setIsLoaded(true);
      }
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    if (departments.length > 0) {
      $(tableRef.current).DataTable();
    }
  }, [departments]);

  const handleViewClick = (department) => {
    navigate('/app/department', { state: { department } });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDepartment(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    setToastMessage('Saving...');
    setShowToast(true);
    try {
      const response = await InsertDepartment(newDepartment);
      if (response.status === 'Success') {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'success'
        });
        setDepartments(prevDepartments => [...prevDepartments, response.data]);
        setShowModal(false);
        setNewDepartment({ name: '', description: '', isActive: true });
      } else {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'error'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the department',
        icon: 'error'
      });
    } finally {
      setIsSaving(false);
      setShowToast(false);
    }
  };

  const styles = {
    container: {
      padding: '20px',
    },
    card: {
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      transition: '0.3s',
      borderRadius: '5px',
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #e0e0e0',
    },
    cardBody: {
      padding: '20px',
    },
    table: {
      marginBottom: '0',
    },
    modalTitle: {
      color: '#007bff',
    },
    badgeActive: {
      backgroundColor: '#28a745',
    },
    badgeInactive: {
      backgroundColor: '#dc3545',
    },
    progressBar: {
      marginTop: '15px',
    },
    dataTableControls: {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dataTableLength: {
      marginRight: '20px',
    },
    dataTableSearch: {
      marginLeft: '20px',
    }
  };

  return (
    <Fragment>
      <PageTitle
        motherMenu="Departments"
        motherMenuPath="/app/departments"
        activeMenu="List"
        button={<Button variant="primary" size='sm' onClick={() => setShowModal(true)}>
        <i className='fa fa-plus-circle'></i> Department
        </Button>}
      />
      <Row className="mb-3"> {/* Add margin bottom to create space between search and table */}
        <Col>
          <Card className="shadow">
            <Card.Body>
              {isLoaded ? (
                <Table ref={tableRef} striped bordered hover responsive style={styles.table}>
                  <thead className="thead-primary">
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Active</th>
                    <th>Designations</th>
                    <th>Head</th>
                    <th>Members</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {departments.map((department, index) => (
                    <tr key={index}>
                      <td>{department.name || 'N/A'}</td>
                      <td>{department.description || 'N/A'}</td>
                      <td>
                        <Badge style={department?.isActive ? styles.badgeActive : styles.badgeInactive}>
                          {department?.isActive ? 'Active' : 'Inactive'}
                        </Badge>
                      </td>
                      <td>{department.designations || 'N/A'}</td>
                      <td>{department.departmentHead || 'N/A'}</td>
                      <td>{department.memberCount || 'N/A'}</td>
                      <td>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleViewClick(department)}
                        >
                          <i className="fas fa-eye"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </Table>
              ) : (                
                <Shimmer type="table" width={'100%'} />
              )}

            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDepartmentName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newDepartment.name}
                onChange={handleInputChange}
                placeholder="Enter department name"
              />
            </Form.Group>
            <Form.Group controlId="formDepartmentDescription" className='mt-4'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={newDepartment.description}
                onChange={handleInputChange}
                placeholder="Enter department description"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)} disabled={isSaving}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveClick} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
        {isSaving && <ProgressBar animated now={100} className="mt-3" />}
      </Modal>

      <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default Departments;

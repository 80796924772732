import { getApiTasks } from "../../APIServiceSetup";

const api_tasks = await getApiTasks();

const cache = {};
const CACHE_DURATION = 60 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get task status list with caching
export const TaskStatusList = async (forceUpdate = false) => {
  const cacheKey = 'taskStatusList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskStatus/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task status by ID with caching
export const TaskStatusById = async (id, forceUpdate = false) => {
  const cacheKey = `taskStatusById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/TaskStatus/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert task status
export const InsertTaskStatus = async (taskStatus) => {
  try {
    const response = await api_tasks.post('/api/TaskStatus/Insert', taskStatus);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to update task status
export const UpdateTaskStatus = async (taskStatus) => {
  try {
    const response = await api_tasks.post('/api/TaskStatus/Update', taskStatus);
    return response.data;
  } catch (error) {
    return error;
  }
};

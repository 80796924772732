import React, { useContext, useEffect, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { MenuList } from './Menu';
import { UserRightsContext } from '../../../context/UserRightsContext';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
  const userRights = useContext(UserRightsContext);

  const [state, setState] = useReducer(reducer, initialState);
  const [filteredMenu, setFilteredMenu] = useState([]);

  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");
    const toggleFunc = () => aaa.classList.toggle("menu-toggle");
    btn.addEventListener("click", toggleFunc);

    const handleheartBlast = document.querySelector('.heart');
    const heartBlast = () => handleheartBlast.classList.toggle("heart-blast");
    handleheartBlast.addEventListener('click', heartBlast);

    if (Array.isArray(userRights)) {
      setFilteredMenu(filterMenuItems(MenuList, userRights));
    }
  }, [userRights]);

  const filterMenuItems = (menuItems, userRights) => {
    const rightsNames = userRights.map(right => right);

    return menuItems.filter(item => {
      if (item.content) {
        item.content = filterMenuItems(item.content, rightsNames);
        return item.content.length > 0 || (!item.rights || item.rights.length === 0 || item.rights.some(right => rightsNames.includes(right)));
      }
      if (!item.rights || item.rights.length === 0) {
        return true;
      }
      return item.rights.some(right => rightsNames.includes(right));
    });
  };

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {filteredMenu.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>{data.title}</li>
              )
            } else {
              return (
                <li className={`${state.active === data.title ? 'mm-active' : ''}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ?
                    <Link to={"#"}
                      className="has-arrow"
                      onClick={() => { handleMenuActive(data.title) }}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                    :
                    <Link to={data.to} >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  }
                  <Collapse in={state.active === data.title ? true : false}>
                    <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                      {data.content && data.content.map((subData, subIndex) => {
                        return (
                          <li key={subIndex}
                            className={`${state.activeSubmenu === subData.title ? "mm-active" : ""}`}
                          >
                            {subData.content && subData.content.length > 0 ?
                              <>
                                <Link to={subData.to} className={subData.hasMenu ? 'has-arrow' : ''}
                                  onClick={() => { handleSubmenuActive(subData.title) }}
                                >
                                  {subData.title}
                                </Link>
                                <Collapse in={state.activeSubmenu === subData.title ? true : false}>
                                  <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                    {subData.content && subData.content.map((nestedData, nestedIndex) => {
                                      return (
                                        <li key={nestedIndex}>
                                          <Link to={nestedData.to} className={`${path === nestedData.to ? "mm-active" : ""}`}>{nestedData.title}</Link>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </Collapse>
                              </>
                              :
                              <Link to={subData.hasMenu ? '#' : subData.to}>
                                {subData.title}
                              </Link>
                            }
                          </li>
                        )
                      })}
                    </ul>
                  </Collapse>
                </li>
              )
            }
          })}
        </ul>

        <div className="plus-box" style={{ display: "none" }}>
          <div className="text-center">
            <h4 className="fs-18 font-w600 mb-3">Enable Workload Automation System</h4>
            <Link to={"#"} className="btn btn-primary btn-rounded">Learn more <i className="fas fa-caret-right"></i></Link>
          </div>
        </div>
        <div className="copyright">
          <p><strong>CCMS Task Management</strong> © 2024 All Rights Reserved</p>
          <p className="fs-12">Made with <span className="heart"></span> <a href="https://actschurch.co.za" target="_blank" rel="noreferrer">Acts Christian Church</a></p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;

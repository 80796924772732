import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FilteredTaskViewList, GetMyTasks } from '../../../../services/ccms/taskManagement/tasks/tasks_endpoints';  // Update the import paths accordingly
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import { UserRightsContext } from '../../../../context/UserRightsContext'; 
import { getTextColor } from '../../../../utilities/colorUtils';

import { addTaskToQueue } from '../../../../services/ccms/taskQueue';

const localizer = momentLocalizer(moment);

// Define South African public holidays
const publicHolidays = [
  { date: '2024-01-01', name: 'New Year\'s Day' },
  { date: '2024-03-21', name: 'Human Rights Day' },
  { date: '2024-04-19', name: 'Good Friday' },
  { date: '2024-04-22', name: 'Family Day' },
  { date: '2024-04-27', name: 'Freedom Day' },
  { date: '2024-05-01', name: 'Workers\' Day' },
  { date: '2024-06-16', name: 'Youth Day' },
  { date: '2024-08-09', name: 'National Women\'s Day' },
  { date: '2024-09-24', name: 'Heritage Day' },
  { date: '2024-12-16', name: 'Day of Reconciliation' },
  { date: '2024-12-25', name: 'Christmas Day' },
  { date: '2024-12-26', name: 'Day of Goodwill' },
];

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Filter out specific warnings
    if (error.message.includes('defaultProps will be removed')) {
      return;
    }
    console.log("Error logged: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

const CalendarPage = () => {
  const [tasks, setTasks] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const userRights = useContext(UserRightsContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    setUserDetails(storedUserDetails);
  }, []);

  const hasRequiredRights = useCallback((requiredRights) => {
    return requiredRights.every(right => userRights.includes(right));
  }, [userRights]);

  const fetchMyTasks = useCallback(async () => {
    setIsLoaded(true);
    const taskFilters = {
      userID: '' + userDetails.userId,
      startDate: 'NULL',
      endDate: 'NULL',
      search: 'NULL',
      priorityID: 'NULL',
      statusID: 'NULL',
      locationID: 'NULL',
      departmentID: 'NULL',
    };
    try {
      const taskData = await GetMyTasks(taskFilters, forceRefresh);
      setTasks(taskData.results);
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    } finally {
      setIsLoaded(false);
      setForceRefresh(false); // Reset force refresh after fetching data
    }
  }, [userDetails.userId, forceRefresh]);

  const fetchTasks = useCallback(async () => {
    setIsLoaded(true);
    const taskFilters = {
      taskID: 'NULL',
      startDate: 'NULL',
      endDate: 'NULL',
      search: 'NULL',
      priorityID: 'NULL',
      statusID: 'NULL',
      locationID: 'NULL',
      departmentID: 'NULL',
      assignedBy: 'NULL',
      createdBy: 'NULL',
    };
    try {
      const taskData = await FilteredTaskViewList(taskFilters, forceRefresh);
      setTasks(taskData.results);
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    } finally {
      setIsLoaded(false);
      setForceRefresh(false); // Reset force refresh after fetching data
    }
  }, [forceRefresh]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (hasRequiredRights(['TaskManagementAdmin'])) {
        await fetchTasks();
      } else {
        await fetchMyTasks();
      }
    };

    fetchInitialData();
    
  }, [fetchTasks, fetchMyTasks, hasRequiredRights, userDetails]);

  const handleSelectEvent = (task) => {
    if (task.isHoliday) {
      toast.info(`Holiday: ${task.title}`);
    } else {
      const selectedTask = tasks.find(t => t.taskID === task.id);
      navigate('/app/view-task', { state: selectedTask });
    }
  };

  const taskEvents = tasks.map(task => ({
    id: task.taskID,
    title: task.title,
    start: new Date(task.dueDate),
    end: new Date(task.dueDate),
    allDay: false,
    task,
    isHoliday: false,
  }));

  const holidayEvents = publicHolidays.map(holiday => ({
    id: holiday.date,
    title: holiday.name,
    start: new Date(holiday.date),
    end: new Date(holiday.date),
    allDay: true,
    isHoliday: true,
  }));

  const events = [...taskEvents, ...holidayEvents];

  return (
    <div className="calendar-page">
      <ToastContainer />
      {isLoaded ? (
        <Shimmer type="article" />
      ) : (
        <ErrorBoundary>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '75vh' }}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: event.isHoliday ? '#FFD700' : event.task.statusColor,
                color: getTextColor(event.isHoliday ? '#FFD700' : event.task.statusColor),
              },
            })}
            min={new Date(1970, 1, 1, 7, 0, 0)}
            max={new Date(1970, 1, 1, 18, 0, 0)}
            step={15}
            timeslots={4}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default CalendarPage;

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_KEY = 'leaveDocumentTypeCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in localStorage
const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

// Helper function to get data from cache
const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Component for "/api/LeaveDocumentType/CustomList"
export const LeaveDocumentTypeCustomList = async (query, forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveDocumentType/CustomList', { params: { query } });
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/CustomUpdate"
export const LeaveDocumentTypeCustomUpdate = async (leaveDocumentType) => {
    try {
        const response = await api_hr.post('/api/LeaveDocumentType/CustomUpdate', leaveDocumentType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/Delete"
export const DeleteLeaveDocumentType = async (leaveDocumentType) => {
    try {
        const response = await api_hr.delete('/api/LeaveDocumentType/Delete', { data: leaveDocumentType });
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on delete
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/Get/{id}"
export const GetLeaveDocumentTypeById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get(`/api/LeaveDocumentType/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/GetValue"
export const GetLeaveDocumentTypeValue = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveDocumentType/GetValue');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/Insert"
export const InsertLeaveDocumentType = async (leaveDocumentType) => {
    try {
        const response = await api_hr.post('/api/LeaveDocumentType/Insert', leaveDocumentType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on insert
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/List"
export const LeaveDocumentTypeList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveDocumentType/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/PaginatedList"
export const PaginatedLeaveDocumentTypeList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get('/api/LeaveDocumentType/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/Save"
export const SaveLeaveDocumentType = async (leaveDocumentType) => {
    try {
        const response = await api_hr.post('/api/LeaveDocumentType/Save', leaveDocumentType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on save
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocumentType/Update"
export const UpdateLeaveDocumentType = async (leaveDocumentType) => {
    try {
        const response = await api_hr.post('/api/LeaveDocumentType/Update', leaveDocumentType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

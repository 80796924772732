import { getApiLocations } from '../../APIServiceSetup';

const api_locations = await getApiLocations();

const cache = {};
const CACHE_DURATION = 60 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Component for "/api/Location/List"
export const LocationList = async (forceUpdate = false) => {
  const cacheKey = 'locationList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get('/api/Location/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/Location/{id}"
export const LocationById = async (id) => {
  try {
    const response = await api_locations.get(`/api/Location/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/Location/Insert"
export const InsertLocation = async (location) => {
  try {
    const response = await api_locations.post('/api/Location/Insert', location);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/Location/Update"
export const UpdateLocation = async (location) => {
  try {
    const response = await api_locations.post('/api/Location/Update', location);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/Location"
export const AllLocations = async (forceUpdate = false) => {
  const cacheKey = 'allLocations';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get('/api/Location');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Component for "/api/Location/SetActive/{locationId}/{isActive}"
export const SetLocationActive = async (locationId, isActive) => {
  try {
    const response = await api_locations.post(`/api/Location/SetActive/${locationId}/${isActive}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const GetByMemberId = async (memberId) => {
  try {
    const response = await api_locations.get(`/api/LocationLeader/GetByMemberId/${memberId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

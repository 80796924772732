// src/pages/policies/ViewPolicy.jsx

import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Tabs, Tab } from 'react-bootstrap';
import { UpdatePolicy, PolicyViewByPolicyID } from '../../../../../../services/ccms/hrManagement/policy'; // Imported PolicyViewByPolicyID
import { PolicyCategoryList } from '../../../../../../services/ccms/hrManagement/policyCategory';
import { PolicySignatureListViewByPolicyID } from '../../../../../../services/ccms/hrManagement/policySignature';
import FileUploader from '../../../../../components/Custom/Uploader/FileUploader';
import PageTitle from '../../../../../layouts/PageTitle';
import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import Swal from 'sweetalert2'; // Imported SweetAlert2
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const ViewPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation(); // To access navigation state
  const [policy, setPolicy] = useState(location.state?.policy || null);
  const [signatures, setSignatures] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [key, setKey] = useState('preview'); // Active tab key

  useEffect(() => {
    if (!policy) {
      // If policy is not passed via state, show error and redirect back
      toast.error('No policy data provided.');
      navigate('/app/policies');
    } else {
      // Fetch signatures and categories since we have the policy data
      fetchSignatures(policy.policyID);
      fetchPolicyCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSignatures = async (policyID) => {
    try {
      const signatureData = await PolicySignatureListViewByPolicyID(policyID); // Ensure correct property name
      setSignatures(signatureData.results);
    } catch (error) {
      toast.error('Error fetching policy signatures.');
    }
  };

  const fetchPolicyCategories = async () => {
    try {
      const categoryData = await PolicyCategoryList();
      setCategoryList(categoryData.results);
    } catch (error) {
      toast.error('Error fetching policy categories.');
    }
  };

  const fetchPolicyByID = async (policyID) => { // Added function to fetch policy by ID
    try {
      const policyData = await PolicyViewByPolicyID(policyID);
      setPolicy(policyData.results || policyData); // Adjust based on API response
      // After updating policy, refetch signatures
      fetchSignatures(policyID);
    } catch (error) {
      toast.error('Error fetching updated policy data.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPolicy(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileUploadSuccess = (name, url) => {
    const fullURL = url.startsWith('http') ? url : `${process.env.REACT_APP_BASE_URL}/${url}`;
    setPolicy(prev => ({
      ...prev,
      documentPath: fullURL, // Ensure it's a fully qualified URL
    }));
  };

  const handleSave = async () => {
    if (
      !policy.name ||
      !policy.description ||
      !policy.effectiveDate ||
      policy.categoryID === 'NULL' ||
      !policy.version ||
      !policy.documentPath
    ) {
      toast.error('Please fill in all required fields.');
      return;
    }

    // Show SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: 'Save Policy Changes',
      text: 'Are you sure you want to save the changes to this policy?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      setIsSaving(true);
      const updatedPolicy = {
        ...policy,
        modifiedDateTime: new Date().getTime(),
      };
      try {
        await UpdatePolicy(updatedPolicy);
        toast.success('Policy updated successfully!');
        // Refresh the policy data to reflect changes
        await fetchPolicyByID(policy.policyID);
      } catch (error) {
        toast.error('Failed to update policy. Please try again.');
      } finally {
        setIsSaving(false);
      }
    }
  };

  if (!policy) {
    return (
      <Fragment>
        <PageTitle
          motherMenu="Policies"
          motherMenuPath="/app/policies"
          activeMenu="View Policy"
        />
        <p>Policy not found.</p>
        <Button onClick={() => navigate('/app/policies')}>Back to Policies</Button>
        <ToastContainer />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <Button size="xs" variant="outline-primary" onClick={() => navigate('/app/policies')}>
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back
        </Button>
        <Button size="xs" onClick={async () => await fetchPolicyByID(policy.policyID)}>
          <FontAwesomeIcon icon={faSyncAlt} /> Sync
        </Button>
      </div>
      {/* <PageTitle
        motherMenu="Policies"
        motherMenuPath="/app/policies"
        activeMenu="View Policy"
      /> */}
      <ToastContainer /> {/* Toast notifications container */}

      <Form>
        <Row>
          {/* Policy Details Section */}
          <Col md={8}>
            <Card className="shadow mb-4">
              <Card.Body>
                <Form.Group controlId="formPolicyName">
                  <Form.Label>
                    Policy Name<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={policy.name}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                  />
                </Form.Group>

                <Form.Group controlId="formPolicyDescription" className="mt-3">
                  <Form.Label>
                    Description<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    value={policy.description}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                  />
                </Form.Group>

                <Form.Group controlId="formPolicyEffectiveDate" className="mt-3">
                  <Form.Label>
                    Effective Date<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="effectiveDate"
                    value={policy.effectiveDate ? new Date(policy.effectiveDate).toISOString().split('T')[0] : ''}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                  />
                </Form.Group>

                <Form.Group controlId="formPolicyCategory" className="mt-3">
                  <Form.Label>
                    Category<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="categoryID"
                    value={policy.categoryID}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                  >
                    <option value="NULL">Select Category</option>
                    {categoryList.map(category => (
                      <option key={category.categoryID} value={category.categoryID}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formPolicyVersion" className="mt-3">
                  <Form.Label>
                    Version<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="version"
                    value={policy.version}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                  />
                </Form.Group>

                <Form.Group controlId="formPolicyRequireSignatory" className="mt-3">
                  <Form.Check
                    type="checkbox"
                    name="requireSignatory"
                    label="Require Signatory"
                    checked={policy.requireSignatory}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  className="mt-3"
                  onClick={handleSave} // Updated to directly call handleSave without modal
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
              </Card.Body>
            </Card>
          </Col>

          {/* Sidebar Section with Tabs */}
          <Col md={4}>
            <Card className="shadow mb-4">
              <Card.Body> 
                {/* Tabs for Preview and Signatories */}
                <Tabs
                  id="sidebar-tabs"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mt-0"
                  variant="pills"
                >
                  <Tab eventKey="preview" title="Preview">
                    <div className="mt-3">
                      {/* FileUploader Component */}
                        <Form.Group controlId="formPolicyDocument" className="mt-2">
                        <Form.Label>
                            Upload Policy Document<span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                        <FileUploader
                            userId=''
                            name="documentPath" // Ensure the name matches your API's expected field
                            initialFileURL={policy.documentPath}
                            onSuccess={handleFileUploadSuccess} // Update DocumentPath
                            storageLocation='OrganisationPolicies'
                            required={true}
                        />
                        </Form.Group>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="signatories"
                    title={`Signatories (${signatures.length})`}
                  >
                    <div className="mt-3">
                      {!policy.requireSignatory ? (
                        <p>This policy does not require signatories.</p>
                      ) : (
                        signatures.length > 0 ? (
                          <ul>
                            {signatures.map(sig => (
                              <li key={sig.PolicySignatureID}>
                                {sig.firstName} {sig.surname}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <Shimmer type='empty' text='No Signatories yet.' />
                        )
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default ViewPolicy;

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Modal, Card, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
    initiateMultipartUpload,
    uploadPartWithRetry,
    completeMultipartUpload,
    abortMultipartUpload
} from '../../../../services/ccms/fileManagement/file'; // Adjust the import path accordingly

const MultiFileUploader = ({
  userId = null,
  onSuccess,
  onStart,
  name,
  initialFileURLs = [],
  storageLocation = 'EmployeeFiles',
  holderText = '',
}) => {
  const [files, setFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    if (initialFileURLs && initialFileURLs.length > 0) {
      const initialFiles = initialFileURLs.map((url, idx) => ({
        id: idx,
        name: '', // Name can be empty or extracted from URL if possible
        type: '',
        mimeType: '',
        progress: 100,
        uploading: false,
        uploaded: true,
        url,
        uploadId: '',
        uploadedParts: [],
      }));
      setFiles(initialFiles);
    }
  }, [initialFileURLs]);

  const getMimeType = (fileObj) => {
    if (fileObj.mimeType) {
      return fileObj.mimeType;
    } else {
      let extension = '';
      if (fileObj.name) {
        extension = fileObj.name.split('.').pop().toLowerCase();
      } else if (fileObj.url) {
        extension = fileObj.url.split('.').pop().toLowerCase();
      }

      const mimeTypes = {
        pdf: 'application/pdf',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        txt: 'text/plain',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        bmp: 'image/bmp',
        mp4: 'video/mp4',
        webm: 'video/webm',
        ogg: 'video/ogg',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        // Add more as needed
      };
      return mimeTypes[extension] || 'application/octet-stream';
    }
  };

  const renderFilePreview = (fileObj) => {
    if (!fileObj.url) return null;

    const mimeType = getMimeType(fileObj);

    // Office Documents
    if (
      [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ].includes(mimeType)
    ) {
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        fileObj.url
      )}`;
      return (
        <div style={{ width: '100%', height: 'auto' }}>
          <iframe
            src={officeViewerUrl}
            style={{ width: '100%', height: 'auto', border: 'none' }}
            onError={() => setLoadError(true)}
            title="Office Document Preview"
          ></iframe>
        </div>
      );
    }

    // PDFs
    if (mimeType === 'application/pdf' || mimeType === 'pdf') {
      return (
        <embed
          src={fileObj.url}
          type="application/pdf"
          width="100%"
          height="auto"
        />
      );
    }

    // Images
    if (mimeType.startsWith('image/')) {
      return (
        <img
          src={fileObj.url}
          alt="File Preview"
          style={{ width: '100%', height: 'auto' }}
        />
      );
    }

    // Video Files
    if (mimeType.startsWith('video/')) {
      return (
        <video
          controls
          style={{ width: '100%', height: 'auto' }}
          src={fileObj.url}
        />
      );
    }

    // Audio Files
    if (mimeType.startsWith('audio/')) {
      return (
        <audio
          controls
          style={{ width: '100%' }}
          src={fileObj.url}
        />
      );
    }

    // Text Files
    if (mimeType === 'text/plain') {
      return (
        <div style={{ width: '100%', height: 'auto' }}>
          <iframe
            src={fileObj.url}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Text File Preview"
          ></iframe>
        </div>
      );
    }

    // Unsupported file type
    return (
      <div>
        <FontAwesomeIcon icon={faFileAlt} size="3x" />
        <span>Unsupported File Type</span>
      </div>
    );
  };

  const renderModalContent = (fileObj) => {
    const mimeType = getMimeType(fileObj);

    // Office Documents
    if (
      [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ].includes(mimeType)
    ) {
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        fileObj.url
      )}`;
      return (
        <iframe
          src={officeViewerUrl}
          style={{ width: '100%', height: '500px', border: 'none' }}
          onError={() => setLoadError(true)}
          title="Office Document Preview"
        ></iframe>
      );
    }

    // PDFs
    if (mimeType === 'application/pdf' || mimeType === 'pdf') {
      return (
        <embed
          src={fileObj.url}
          type="application/pdf"
          width="100%"
          height="500px"
        />
      );
    }

    // Images
    if (mimeType.startsWith('image/')) {
      return (
        <img
          src={fileObj.url}
          alt="File Preview"
          style={{ width: '100%' }}
        />
      );
    }

    // Video Files
    if (mimeType.startsWith('video/')) {
      return (
        <video
          controls
          style={{ width: '100%' }}
          src={fileObj.url}
        />
      );
    }

    // Audio Files
    if (mimeType.startsWith('audio/')) {
      return (
        <audio
          controls
          style={{ width: '100%' }}
          src={fileObj.url}
        />
      );
    }

    // Text Files
    if (mimeType === 'text/plain') {
      return (
        <iframe
          src={fileObj.url}
          style={{ width: '100%', height: '500px', border: 'none' }}
          title="Text File Preview"
        ></iframe>
      );
    }

    // Unsupported file type
    return <p>Cannot preview this file type</p>;
  };

  const uploadFile = useCallback(
    async (fileObj) => {
      try {
        const selectedFile = fileObj.file;

        // Step 1: Initiate Multipart Upload
        const newUploadId = await initiateMultipartUpload(
          selectedFile.name,
          userId,
          storageLocation,
          '' + holderText
        );

        if (!newUploadId) {
          throw new Error('Failed to obtain UploadId from the server.');
        }

        // Update the file object with uploadId
        setFiles((prevFiles) =>
          prevFiles.map((f) => {
            if (f.id === fileObj.id) {
              return { ...f, uploadId: newUploadId };
            }
            return f;
          })
        );

        // Step 2: Split the file into chunks
        const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk
        const totalParts = Math.ceil(selectedFile.size / CHUNK_SIZE);
        const parts = [];

        // Step 3: Upload each part sequentially
        for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
          const start = (partNumber - 1) * CHUNK_SIZE;
          const end = partNumber * CHUNK_SIZE;
          const chunk = selectedFile.slice(start, end);

          // Upload the part with retry
          const ETag = await uploadPartWithRetry(
            newUploadId,
            partNumber,
            chunk,
            3
          );

          parts.push({ PartNumber: partNumber, ETag });

          // Update the uploaded parts and progress
          const progress = Math.round((parts.length / totalParts) * 100);
          setFiles((prevFiles) =>
            prevFiles.map((f) => {
              if (f.id === fileObj.id) {
                return { ...f, uploadedParts: [...parts], progress };
              }
              return f;
            })
          );
        }

        // Step 4: Complete Multipart Upload
        const uploadedFileURL = await completeMultipartUpload(newUploadId, parts);

        // Update the file object
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles.map((f) => {
            if (f.id === fileObj.id) {
              return {
                ...f,
                url: uploadedFileURL,
                progress: 100,
                uploading: false,
                uploaded: true,
                uploadId: '',
                uploadedParts: [],
              };
            }
            return f;
          });

          // If all files are uploaded, call onSuccess
          const allUploaded = updatedFiles.every((f) => f.uploaded);
          if (allUploaded && onSuccess) onSuccess(name, updatedFiles);

          return updatedFiles;
        });
      } catch (error) {
        // Handle upload error

        // Update the file object with error
        setFiles((prevFiles) =>
          prevFiles.map((f) => {
            if (f.id === fileObj.id) {
              return {
                ...f,
                uploading: false,
                progress: 0,
                error: 'File upload failed!',
              };
            }
            return f;
          })
        );

        // Abort the upload
        if (fileObj.uploadId) {
          try {
            await abortMultipartUpload(fileObj.uploadId);
          } catch (abortError) {
            console.error('Error aborting upload:', abortError);
          }
        }

        alert('File upload failed for ' + fileObj.name);
      }
    },
    [userId, onSuccess, name, storageLocation, holderText]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      if (onStart) onStart();

      // Map acceptedFiles to an array of file objects with unique IDs
      const newFiles = acceptedFiles.map((file, idx) => ({
        id: Date.now() + idx, // Unique ID
        file,
        name: file.name,
        type: file.name.split('.').pop().toLowerCase(),
        mimeType: file.type,
        progress: 0,
        uploading: true,
        uploaded: false,
        url: '',
        uploadId: '',
        uploadedParts: [],
        error: null,
      }));

      // Add newFiles to the files state
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // Start uploading each file
      newFiles.forEach((fileObj) => {
        uploadFile(fileObj);
      });
    },
    [onStart, uploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: '*/*', // Allow all file types
  });

  const handleNameChange = (id, newName) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file.id === id) {
          // Reattach the original extension if needed
          const extension = file.name.substring(file.name.lastIndexOf('.'));
          return { ...file, name: newName + extension };
        }
        return file;
      })
    );
  };

  const handleDeleteFile = (id) => {
    const fileObj = files.find((f) => f.id === id);
    if (fileObj.uploading && fileObj.uploadId) {
      // Abort the upload
      abortMultipartUpload(fileObj.uploadId)
        .then(() => {
          // Remove the file from the list
          setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
        })
        .catch((error) => {
          console.error('Error aborting upload:', error);
          // Still remove the file from the list
          setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
        });
    } else {
      // Remove the file from the list
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    }
  };

  const stripFileExtension = (filename) => {
    return filename.replace(/\.[^/.]+$/, '');
  };

  return (
    <div>
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{
          border: '2px dashed #cccccc',
          padding: '10px',
          borderRadius: '5px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <input {...getInputProps()} />
        <div>
          Drag and drop files here, or{' '}
          <span className="badge bg-primary" style={{ cursor: 'pointer' }}>
            click to select files
          </span>
        </div>
      </div>
      <div
        style={{
          maxHeight: '400px',
          overflowY: 'auto',
          marginTop: '20px',
        }}
      >
        <div className="row">
          {files.map((fileObj) => (
            <div key={fileObj.id} className="col-12 col-sm-6 col-md-6 col-lg-4">
              <Card
                style={{
                  marginBottom: '10px',
                  position: 'relative',
                  height: 'auto',
                }}
              >
                <Button
                  variant="danger"
                  size="xxs"
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    borderRadius: '50%',
                    zIndex: 1,
                  }}
                  onClick={() => handleDeleteFile(fileObj.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                {fileObj.uploaded ? (
                  <div
                    onClick={() => setPreviewFile(fileObj)}
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      height: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    {renderFilePreview(fileObj)}
                  </div>
                ) : (
                  <div style={{ padding: '20px' }}>
                    <CircularProgressbar
                      value={fileObj.progress}
                      text={`${fileObj.progress}%`}
                      styles={buildStyles({
                        pathColor: fileObj.uploading ? '#007bff' : '#cccccc',
                        textColor: '#000',
                        trailColor: '#f4f4f4',
                      })}
                    />
                    <div
                      style={{ marginTop: '10px', fontSize: '0.9em' }}
                    >
                      {stripFileExtension(fileObj.name)}
                    </div>
                  </div>
                )}
                {fileObj.uploaded && (
                  <Card.Body style={{ padding: '0.1em' }}>
                    <Form.Control
                      type="text"
                      value={stripFileExtension(fileObj.name)}
                      onChange={(e) =>
                        handleNameChange(fileObj.id, e.target.value)
                      }
                      style={{ fontSize: '0.8em', width: '100%', padding: '0px 0px 0 1em', margin: '0px', border: 'none' }}
                    />
                  </Card.Body>
                )}
              </Card>
            </div>
          ))}
        </div>
      </div>
      {previewFile && (
        <Modal
          show={true}
          onHide={() => setPreviewFile(null)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderModalContent(previewFile)}</Modal.Body>
        </Modal>
      )}
    </div>
  );
};

MultiFileUploader.propTypes = {
  userId: PropTypes.string,
  onSuccess: PropTypes.func,
  onStart: PropTypes.func,
  name: PropTypes.string.isRequired,
  initialFileURLs: PropTypes.array,
  storageLocation: PropTypes.string,
  holderText: PropTypes.string,
};

export default MultiFileUploader;

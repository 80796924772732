import React, { useContext, useEffect, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { MenuList } from './Menu';
import { UserRightsContext } from '../../../context/UserRightsContext';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const MobileMenuBar = ({ onClose }) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
  const userRights = useContext(UserRightsContext);

  const [state, setState] = useReducer(reducer, initialState);
  const [filteredMenu, setFilteredMenu] = useState([]);

  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");
    const toggleFunc = () => aaa.classList.toggle("menu-toggle");
    btn.addEventListener("click", toggleFunc);

    const handleheartBlast = document.querySelector('.heart');
    const heartBlast = () => handleheartBlast.classList.toggle("heart-blast");
    handleheartBlast.addEventListener('click', heartBlast);

    if (Array.isArray(userRights)) {
      setFilteredMenu(filterMenuItems(MenuList, userRights));
    }
  }, [userRights]);

  const filterMenuItems = (menuItems, userRights) => {
    const rightsNames = userRights.map(right => right);

    return menuItems.filter(item => {
      if (item.content) {
        item.content = filterMenuItems(item.content, rightsNames);
        return item.content.length > 0 || (!item.rights || item.rights.length === 0 || item.rights.every(right => rightsNames.includes(right)));
      }
      if (!item.rights || item.rights.length === 0) {
        return true;
      }
      return item.rights.every(right => rightsNames.includes(right));
    });
  };

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const menuStyles = {
    menuTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      padding: '10px 20px',
      color: '#333',
      backgroundColor: '#f8f9fa',
      borderBottom: '1px solid #ddd',
    },
    menuItem: {
      padding: '10px 20px',
      fontSize: '14px',
      color: '#007bff',
      display: 'flex',
      alignItems: 'center',
    },
    activeMenuItem: {
      backgroundColor: '#e9ecef',
      color: '#0056b3',
    },
    iconStyle: {
      marginRight: '10px',
    },
  };

  return (
    <PerfectScrollbar className="dlabnav-scroll">
      <ul className="metismenu" id="menu">
        {filteredMenu.map((data, index) => {
          let menuClass = data.classsChange;
          if (menuClass === "menu-title") {
            return (
              <li style={menuStyles.menuTitle} key={index}>{data.title}</li>
            )
          } else {
            return (
              <li 
                className={`${state.active === data.title ? 'mm-active' : ''}`} 
                key={index}
                style={state.active === data.title ? {...menuStyles.menuItem, ...menuStyles.activeMenuItem} : menuStyles.menuItem}
              >
                {data.content && data.content.length > 0 ? (
                  <Link to={"#"} className="has-arrow" onClick={() => handleMenuActive(data.title)}>
                    <span style={menuStyles.iconStyle}>{data.iconStyle}</span>
                    <span>{data.title}</span>
                  </Link>
                ) : (
                  <Link to={data.to} onClick={onClose}>
                    <span style={menuStyles.iconStyle}>{data.iconStyle}</span>
                    <span>{data.title}</span>
                  </Link>
                )}
              </li>
            )
          }
        })}
      </ul>
    </PerfectScrollbar>
  );
};

export default MobileMenuBar;

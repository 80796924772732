import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faRedo, faPlus, faSpinner, faPause, faTasks, faSort, faEye, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { getTextColor } from '../../../../utilities/colorUtils';
import { DateTimeConverter } from '../../../../utilities/convertDateTime';
import { wrap } from 'framer-motion';

const DashboardTaskSummary = ({ tasks, statusList }) => {
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = React.useState({});

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const toggleRow = (taskID) => {
    setExpandedRows((prev) => ({ ...prev, [taskID]: !prev[taskID] }));
  };

  // Filter statusList to include relevant statuses
  const filteredStatusList = statusList.filter(status => [-2, -1, 0, 1].includes(status.statusID));

  // Calculate the count of tasks for each status
  const statusCounts = filteredStatusList.map(status => ({
    ...status,
    tasks: tasks.filter(task => task.statusID === status.statusID)
  }));

  // Calculate overdue tasks
  const now = new Date();
  const overdueTasks = tasks.filter(task => {
    const dueDate = new Date(task.dueDate);
    return dueDate < now && ![3, 4].includes(task.statusID);
  });

  statusCounts.unshift({
    statusID: 'overdue',
    name: 'Overdue Tasks',
    color: '#FF0000',
    tasks: overdueTasks
  });

  const getIcon = (statusID) => {
    switch (statusID) {
      case -2:
        return faRedo;
      case 0:
        return faPlus;
      case 1:
        return faSpinner;
      case -1:
        return faPause;
      case 'overdue':
        return faExclamationCircle;
      default:
        return faTasks;
    }
  };

  const handleViewTask = (task) => {
    navigate('/app/view-task', { state: task });
  };

  const mobileColumns = [
    {
      name: 'Due Date',
      selector: row => <DateTimeConverter dateInput={row.dueDate} dateFormat="yyyy/MM/dd" />,
      sortable: true,
      width: '110px',
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: '',
      width: '40px',
      cell: row => (
        <Button variant="outline-primary" size="xxs" onClick={() => handleViewTask(row)}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const desktopColumns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Due Date',
      selector: row => row.dueDate,
      format: row => new Date(row.dueDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Priority',
      cell: row => (
        <span className="badge" style={{ backgroundColor: row.priorityColor, color: getTextColor(row.priorityColor) }}>
          {row.priorityName}
        </span>
      ),
      sortable: true,
      selector: row => row.priorityName,
    },
    {
      name: 'Created By',
      selector: row => row.createdByName,
      sortable: true,
    },
    {
      name: 'Status',
      cell: row => (
        <span className="badge" style={{ backgroundColor: row.statusColor, color: getTextColor(row.statusColor) }}>
          {row.statusName}
        </span>
      ),
      sortable: true,
      selector: row => row.statusName,
    },
    {
      name: '',
      cell: row => (
        <Button variant="primary" size="xs" onClick={() => handleViewTask(row)}>
          <FontAwesomeIcon icon={faEye} /> View
        </Button>
      ),
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p><strong>Priority:</strong> 
        <span className="badge" style={{ backgroundColor: data.priorityColor, color: getTextColor(data.priorityColor) }}>
          {data.priorityName}
        </span>
      </p>
      <p><strong>Created By:</strong> {data.createdByName}</p>
      <p><strong>Status:</strong> 
        <span className="badge" style={{ backgroundColor: data.statusColor, color: getTextColor(data.statusColor) }}>
          {data.statusName}
        </span>
      </p>
    </div>
  );

  return (
    <>
      {statusCounts.map(status => (
        status.tasks.length > 0 && (
          <div key={status.statusID} className="mb-4">
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={getIcon(status.statusID)} className="me-2" size="2x" style={{ color: status.color }} />
              <h4>{status.name}</h4>
            </div>
            <Card className="shadow">
              <Card.Body>
                <DataTable
                  columns={isMobile ? mobileColumns : desktopColumns}
                  data={status.tasks}
                  pagination
                  paginationPerPage={8}
                  highlightOnHover
                  sortIcon={<FontAwesomeIcon icon={faSort} />}
                  expandableRows={isMobile}
                  expandableRowsComponent={ExpandedComponent}
                  expandOnRowClicked={isMobile}
                />
              </Card.Body>
            </Card>
          </div>
        )
      ))}
    </>
  );
};

export default DashboardTaskSummary;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { getTextColor } from '../../../../utilities/colorUtils';
import { OverlayTrigger, Popover, Overlay } from 'react-bootstrap';

const TaskProgressBar = ({
  currentStage,
  taskID,
  onChange,
  stages,
  shadow = true,
  textColor = '#FFFFFF',
  viewOnly = false,
  markPassedStages = false,
  title = 'Task Progress',
  showPercentage = true,
  textSize = '10pt',
  pillWidth = '120px',
}) => {
  const [hoveredStage, setHoveredStage] = useState(null);

  // State variables for error popover
  const [errorStageID, setErrorStageID] = useState(null);
  const [errorTarget, setErrorTarget] = useState(null);

  // Refs for pills
  const pillRefs = useRef({});

  // Calculate percentage progress
  const progressPercentage = Math.round(
    ((currentStage + 1) / (stages.length - 2)) * 100
  );

  // Filter stages to display
  const stagesToDisplay = stages.filter((stage) => stage.ProgressStageID >= 0);

  const isStageSelectable = (stageID) =>
    stageID === currentStage + 1 && !viewOnly;

  const renderPopover = (stage) => (
    <Popover id={`popover-${stage.ProgressStageID}`}>
      <Popover.Header as="h3">{stage.Name}</Popover.Header>
      <Popover.Body>{stage.Description}</Popover.Body>
    </Popover>
  );

  const renderErrorPopover = (stage) => (
    <Popover id={`error-popover-${stage.ProgressStageID}`}>
      <Popover.Body>
        Stage <strong>{stage.Name}</strong> is not selectable or cannot jump stages.
      </Popover.Body>
    </Popover>
  );

  const handleClick = (e, stage) => {
    if (isStageSelectable(stage.ProgressStageID)) {
      onChange(stage.ProgressStageID, taskID);
      // Reset error popover
      setErrorStageID(null);
      setErrorTarget(null);
    } else {
      // Set errorStageID and errorTarget to display the error popover
      setErrorStageID(stage.ProgressStageID);
      setErrorTarget(pillRefs.current[stage.ProgressStageID]);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h3 style={styles.title}>{title}</h3>
        {showPercentage && (
          <h4 style={styles.percentage}>{progressPercentage}%</h4>
        )}
      </div>
      <div style={styles.progressBar}>
        {stagesToDisplay.map((stage) => {
          const isCurrent = stage.ProgressStageID === currentStage;
          const isPassed = markPassedStages && stage.ProgressStageID < currentStage;
          const isHovered = hoveredStage === stage.ProgressStageID;
          const isSelectable = isStageSelectable(stage.ProgressStageID);

          const pillStyle = {
            ...styles.pill,
            backgroundColor: stage.Color,
            margin: '0 1.5px',
            opacity: isCurrent || isPassed || isHovered ? 1 : 0.1,
            flex: isCurrent || isHovered ? 3 : 1,
            width: pillWidth,
            boxShadow: shadow ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
            color: getTextColor(stage.Color),
            cursor: isSelectable ? 'pointer' : 'default',
          };

          return (
            <OverlayTrigger
              key={stage.ProgressStageID}
              placement="top"
              overlay={renderPopover(stage)}
              delay={{ show: 250, hide: 400 }}
            >
              <div
                ref={(ref) => (pillRefs.current[stage.ProgressStageID] = ref)}
                style={pillStyle}
                onClick={(e) => handleClick(e, stage)}
                onMouseEnter={() => setHoveredStage(stage.ProgressStageID)}
                onMouseLeave={() => setHoveredStage(null)}
              >
                <span style={{ ...styles.pillText, fontSize: textSize }}>
                  {stage.Name}
                </span>
                {/* Error Popover */}
                {errorStageID === stage.ProgressStageID && errorTarget && (
                  <Overlay
                    show={true}
                    target={errorTarget}
                    placement="top"
                    container={pillRefs.current[stage.ProgressStageID]}
                    containerPadding={20}
                    onHide={() => setErrorStageID(null)}
                  >
                    {renderErrorPopover(stage)}
                  </Overlay>
                )}
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
};

TaskProgressBar.propTypes = {
  currentStage: PropTypes.number.isRequired,
  taskID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      ProgressStageID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      Description: PropTypes.string,
      Color: PropTypes.string.isRequired,
    })
  ).isRequired,
  shadow: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  textColor: PropTypes.string,
  viewOnly: PropTypes.bool,
  markPassedStages: PropTypes.bool,
  title: PropTypes.string,
  showPercentage: PropTypes.bool,
  textSize: PropTypes.string,
  pillWidth: PropTypes.string,
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '10px',
  },
  title: {
    margin: 0,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  percentage: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 'normal',
  },
  progressBar: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    borderRadius: '8px',
  },
  pill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    transition: 'flex 0.3s, opacity 0.2s',
    position: 'relative',
  },
  pillText: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontWeight: 'bold',
    transition: 'opacity 0.2s',
  },
};

export default TaskProgressBar;

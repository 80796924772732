import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const StepOne = ({
  departments,
  handleDepartmentChange,
  taskDetails,
  currentStep,
  handlePreviousStep,
  handleNextStep,
}) => {
  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    handleDepartmentChange(value);
  };

  const validateAndProceed = () => {
    if (taskDetails.departmentID) {
      handleNextStep();
    }
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Choose a Department</h4>
          <div className="form-group">
            <label>Please choose a department</label>
            <select
              className="form-control"
              name="departmentID"
              value={taskDetails.departmentID || ''}
              onChange={handleChange}
              onInput={handleChange}
            >
              <option value="">Please choose a department</option>
              {departments.map(department => (
                <option key={department.departmentID} value={department.departmentID}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <button className="btn btn-primary btn-sm" onClick={validateAndProceed}>
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default StepOne;

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/PolicySignature/CustomList"
export const PolicySignatureCustomList = async (query, forceUpdate = false) => {
    const cacheKey = `/api/PolicySignature/CustomList?query=${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/PolicySignature/CustomList', { params: { query } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/CustomUpdate"
export const PolicySignatureCustomUpdate = async (policySignature) => {
    try {
        const response = await api_hr.post('/api/PolicySignature/CustomUpdate', policySignature);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/Delete"
export const DeletePolicySignature = async (policySignature) => {
    try {
        const response = await api_hr.delete('/api/PolicySignature/Delete', { data: policySignature });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/Get/{id}"
export const GetPolicySignatureById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/PolicySignature/Get/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/PolicySignature/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/GetValue"
export const GetPolicySignatureValue = async (forceUpdate = false) => {
    const cacheKey = '/api/PolicySignature/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/PolicySignature/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/Insert"
export const InsertPolicySignature = async (policySignature) => {
    try {
        const response = await api_hr.post('/api/PolicySignature/Insert', policySignature);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/List"
export const PolicySignatureList = async (forceUpdate = false) => {
    const cacheKey = '/api/PolicySignature/List';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/PolicySignature/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/PaginatedList"
export const PaginatedPolicySignatureList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/PolicySignature/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/PolicySignature/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/Save"
export const SavePolicySignature = async (policySignature) => {
    try {
        const response = await api_hr.post('/api/PolicySignature/Save', policySignature);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/Update"
export const UpdatePolicySignature = async (policySignature) => {
    try {
        const response = await api_hr.post('/api/PolicySignature/Update', policySignature);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/ListView"
export const PolicySignatureListView = async (forceUpdate = false) => {
    const cacheKey = '/api/PolicySignature/ListView';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/PolicySignature/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/ListViewByUserId"
export const PolicySignatureListViewByUserId = async (userId, forceUpdate = false) => {
    const cacheKey = `/api/PolicySignature/ListViewByUserId/${userId}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/PolicySignature/ListViewByUserId/${userId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/PolicySignature/ListViewByPolicyID"
export const PolicySignatureListViewByPolicyID = async (policyID, forceUpdate = false) => {
    const cacheKey = `/api/PolicySignature/ListViewByPolicyID/${policyID}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/PolicySignature/ListViewByPolicyID/${policyID}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import { getTextColor } from '../../../../../utilities/colorUtils';
import { TaskProgressStageList, InsertTaskProgressStage, UpdateTaskProgressStage } from '../../../../../services/ccms/taskManagement/taskProgressStage';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const TaskStages = () => {
    const [stages, setStages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedStage, setSelectedStage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // For insert vs update control

    useEffect(() => {
        fetchStages();
    }, []);

    const fetchStages = async () => {
        setLoading(true);
        try {
            const response = await TaskProgressStageList();
            setStages(response.results);
        } catch (error) {
            console.error('Error fetching task progress stages:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (stage) => {
        setSelectedStage(stage);
        setIsEditing(true);
        setShowModal(true);
    };

    const handleAddClick = () => {
        setSelectedStage({ name: '', description: '', color: '#000000', icon: '' });
        setIsEditing(false);
        setShowModal(true);
    };

    const handleSave = async () => {
        setUpdating(true);
        const action = isEditing ? 'Updating' : 'Adding';
        toast.info(`${action} task progress stage...`);

        try {
            const response = isEditing 
                ? await UpdateTaskProgressStage(selectedStage) 
                : await InsertTaskProgressStage(selectedStage);

            if (response.status === 'Success') {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'success',
                });
                await fetchStages();
                toast.success(`Task progress stage ${isEditing ? 'updated' : 'added'} successfully`);
            } else {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error(`${action} task progress stage failed:`, error);
            Swal.fire({
                title: 'Error',
                text: `An error occurred while ${action.toLowerCase()} the task progress stage.`,
                icon: 'error',
            });
        } finally {
            setUpdating(false);
            setShowModal(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedStage(null);
    };

    const columns = [
        { name: 'ID', selector: row => row.progressStageID, sortable: true, width: '100px' },
        { name: 'Name', selector: row => row.name, sortable: true, width: '200px' },
        { name: 'Color', cell: row => (
            <div style={{ backgroundColor: row.color, color: getTextColor(row.color), padding: '5px 10px', borderRadius: '5px' }}>
                {row.color}
            </div>
        ), sortable: true, width: '120px' },
        { name: 'Icon', cell: row => <FontAwesomeIcon icon={row.icon} />, width: '80px' },
        { name: 'Description', selector: row => row.description, sortable: true },
        { name: '', cell: row => (
            <Button variant="warning" size="sm" onClick={() => handleEditClick(row)}>
                <FontAwesomeIcon icon={faPencilAlt} /> Edit
            </Button>
        ), width: '120px' },
    ];

    if (loading) {
        return <Shimmer type="sections" />;
    }

    return (
        <Fragment>
            <Row className="mb-3">
                <Col>
                    <Button variant="success" onClick={handleAddClick}>
                        <FontAwesomeIcon icon={faPlus} /> Add New Stage
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <DataTable
                        columns={columns}
                        data={stages}
                        pagination
                        highlightOnHover
                    />
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Edit Task Progress Stage' : 'Add Task Progress Stage'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedStage && (
                        <Form>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={selectedStage.name} 
                                    onChange={(e) => setSelectedStage({ ...selectedStage, name: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={selectedStage.description} 
                                    onChange={(e) => setSelectedStage({ ...selectedStage, description: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="color">
                                <Form.Label>Color</Form.Label>
                                <Form.Control 
                                    type="color" 
                                    value={selectedStage.color} 
                                    onChange={(e) => setSelectedStage({ ...selectedStage, color: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="icon">
                                <Form.Label>Icon</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={selectedStage.icon} 
                                    onChange={(e) => setSelectedStage({ ...selectedStage, icon: e.target.value })} 
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleSave} 
                        disabled={updating} 
                        size="sm"
                    >
                        {updating ? 'Saving...' : <><FontAwesomeIcon icon={faSave} /> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default TaskStages;

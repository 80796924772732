// EmploymentForm.js

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

const EmploymentForm = ({ userId, onSave, employeeTypes }) => {
  const [formData, setFormData] = useState({
    employeeNo: '',
    jobTitle: '',
    startDate: '',
    endDate: '',
    typeID: '',
  });

  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.employeeNo) tempErrors.employeeNo = 'Employee Number is required';
    if (!formData.jobTitle) tempErrors.jobTitle = 'Job Title is required';
    if (!formData.startDate) tempErrors.startDate = 'Start Date is required';
    if (formData.endDate && formData.endDate < formData.startDate)
      tempErrors.endDate = 'End Date cannot be before Start Date';
    if (!formData.typeID) tempErrors.typeID = 'Employment Type is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent form submission
    if (!validate()) return;

    setIsSaving(true);
    try {
      const newEmployee = {
        userId: userId,
        employeeNo: formData.employeeNo,
        jobTitle: formData.jobTitle,
        startDate: formData.startDate,
        endDate: formData.endDate || null,
        typeID: parseInt(formData.typeID),
        statusID: 0, // Set statusID = 0
        modifiedDateTime: format(new Date(), 'yyyyMMddHHmmss'),
        createdDateTime: format(new Date(), 'yyyyMMddHHmmss'),
      };

      if (onSave) {
        await onSave(newEmployee);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the employee',
        icon: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="pt-3">
      <div className="settings-form">
        <h4 className="text-primary">Employment Information</h4>
        <Form onSubmit={handleSave}>
          <div className="row">
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">Employee Number</label>
              <input
                type="text"
                className="form-control"
                name="employeeNo"
                value={formData.employeeNo}
                onChange={handleChange}
              />
              {errors.employeeNo && <small className="text-danger">{errors.employeeNo}</small>}
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">Job Title</label>
              <input
                type="text"
                className="form-control"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
              />
              {errors.jobTitle && <small className="text-danger">{errors.jobTitle}</small>}
            </div>
          </div>

          <div className="row">
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">Start Date</label>
              <input
                type="date"
                className="form-control"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
              />
              {errors.startDate && <small className="text-danger">{errors.startDate}</small>}
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">End Date</label>
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
              {errors.endDate && <small className="text-danger">{errors.endDate}</small>}
            </div>
          </div>

          <div className="row">
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">Employment Type</label>
              <select
                className="form-control"
                name="typeID"
                value={formData.typeID}
                onChange={handleChange}
              >
                <option value="">Select Employment Type</option>
                {employeeTypes.map((type) => (
                  <option key={type.typeID} value={type.typeID}>
                    {type.name}
                  </option>
                ))}
              </select>
              {errors.typeID && <small className="text-danger">{errors.typeID}</small>}
            </div>
          </div>

          <Button className="btn btn-primary" type="submit" disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default EmploymentForm;

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../../../../components/Custom/Loading/Shimmer';
import {
  LeaveDocumentListView,
  SaveLeaveDocument,
  DeleteLeaveDocument,
} from '../../../../../../../services/ccms/hrManagement/leaveDocument';
import { LeaveDocumentTypeList } from '../../../../../../../services/ccms/hrManagement/leaveDocumentType';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import FileUploader from '../../../../../../components/Custom/Uploader/FileUploader';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const LeaveDocuments = ({ leaveID, userId }) => {
  const [documents, setDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileData, setFileData] = useState({
    LeaveID: leaveID,
    TypeID: '',
    Description: '',
    FileURL: '',
    FileName: '',
  });
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const [docsResponse, typesResponse] = await Promise.all([
          LeaveDocumentListView(leaveID),
          LeaveDocumentTypeList(),
        ]);
        setDocuments(docsResponse.results);
        setDocumentTypes(typesResponse.results);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [leaveID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFileData({ ...fileData, [name]: value });
  };

  // Updated function to accept two parameters: fieldName and uploadedFileURL
  const handleFileUploadSuccess = (fieldName, uploadedFileURL) => {
    setFileData({ ...fileData, [fieldName]: uploadedFileURL });
  };

  const handleUploadDocument = async () => {
    // Check required fields
    if (!fileData.TypeID || !fileData.FileURL) {
      MySwal.fire({
        icon: 'warning',
        title: 'Required Fields Missing',
        text: 'Please select a document type and upload a file.',
      });
      return;
    }

    try {
      setIsUploading(true);

      MySwal.fire({
        title: 'Uploading...',
        text: 'Please wait while the document is being uploaded.',
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Get DocumentType's name
      const selectedType = documentTypes.find(
        (type) => type.typeID === parseInt(fileData.TypeID)
      );
      const documentTypeName = selectedType ? selectedType.name : 'Document';

      // Get current date in 'yyyy-MM-dd' format
      const currentDate = new Date().toISOString().split('T')[0];

      // Construct FileName
      const fileName = `${documentTypeName} - ${currentDate}`;

      const documentData = {
        DocumentID: 0, // As per model
        LeaveID: fileData.LeaveID,
        TypeID: fileData.TypeID,
        FileName: fileName,
        FileURL: fileData.FileURL,
        UploadDate: new Date().toISOString(),
        Description: fileData.Description,
      };

      await SaveLeaveDocument(documentData);

      MySwal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Document uploaded successfully.',
      });

      setShowModal(false);
      // Refresh document list
      const docsResponse = await LeaveDocumentListView(leaveID);
      setDocuments(docsResponse.results);
      // Reset form data
      setFileData({
        LeaveID: leaveID,
        TypeID: '',
        Description: '',
        FileURL: '',
        FileName: '',
      });
    } catch (error) {
      console.error('Error uploading document:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to upload document.',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveDocument = async (document) => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to remove this document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it',
    });

    if (result.isConfirmed) {
      try {
        // Assuming UpdateLeaveDocument handles deletion with an IsDeleted flag
        console.log('Removing document:', document);
        await DeleteLeaveDocument(document);

        MySwal.fire({
          icon: 'success',
          title: 'Removed!',
          text: 'Document removed successfully.',
        });

        // Refresh document list
        const docsResponse = await LeaveDocumentListView(leaveID);
        setDocuments(docsResponse.results);
      } catch (error) {
        console.error('Error removing document:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to remove document.',
        });
      }
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const mobileColumns = [
    {
      name: 'Type',
      selector: (row) => row.typeName,
      sortable: true,
    },
    {
      name: 'File Name',
      selector: (row) => row.fileName,
      sortable: true,
    },
    {
      name: '',
      cell: (row) => (
        <Button variant="danger" size="sm" onClick={() => handleRemoveDocument(row)}>
          <i className="fa fa-trash"></i>
        </Button>
      ),
      ignoreRowClick: true,
    },
  ];

  const desktopColumns = [
    {
      name: 'Type',
      selector: (row) => row.typeName,
      sortable: true,
    },
    {
      name: 'File Name',
      selector: (row) => row.fileName,
      sortable: true,
    },
    {
      name: 'Uploaded On',
      selector: (row) => new Date(row.uploadDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: '',
      cell: (row) => (
        <Button variant="danger" size="sm" onClick={() => handleRemoveDocument(row)}>
          <i className="fa fa-trash"></i> Remove
        </Button>
      ),
      ignoreRowClick: true,
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p>
        <strong>Uploaded On:</strong> {new Date(data.uploadDate).toLocaleDateString()}
      </p>
      <p>
        <strong>Description:</strong> {data.description}
      </p>
    </div>
  );

  if (!isLoaded) {
    return <Shimmer type="table" width={'100%'} />;
  }

  return (
    <>
      <h4 className="text-primary">Leave Documents</h4>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" size="sm" onClick={() => setShowModal(true)}>
          <i className="fa fa-upload"></i> Upload
        </Button>
      </div>
      <DataTable
        columns={isMobile ? mobileColumns : desktopColumns}
        data={documents}
        pagination
        expandableRows={isMobile}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={isMobile}
      />

      {/* Modal for uploading new document */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group mb-3">
              <label>Document Type</label>
              <select
                className="form-control"
                name="TypeID"
                value={fileData.TypeID}
                onChange={handleChange}
              >
                <option value="">Select Document Type</option>
                {documentTypes.map((type) => (
                  <option key={type.typeID} value={type.typeID}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Description</label>
              <textarea
                className="form-control"
                name="Description"
                value={fileData.Description}
                onChange={handleChange}
              />
            </div>
            <FileUploader
              userId={userId}
              name="FileURL" // Ensure the name matches the state field you want to update
              initialFileURL={fileData.FileURL}
              onSuccess={handleFileUploadSuccess}
              storageLocation="LeaveDocuments"
            />
            <div className="mt-3">
              <button
                className="btn btn-primary btn-sm"
                onClick={handleUploadDocument}
                disabled={isUploading}
              >
                {isUploading ? 'Uploading...' : 'Upload'}{' '}
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaveDocuments;

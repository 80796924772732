import React from 'react';

// Helper function to get the text color
export const getTextColor = (bgColor) => {
  const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  };

  const rgb = hexToRgb(bgColor);

  if (!rgb) {
    return 'Invalid color';
  }

  const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
  return luminance > 0.5 ? 'black' : 'white';
};

// TextColor component
export const TextColor = ({ bgColor, text }) => {
  const textColor = getTextColor(bgColor);

  return (
    <span style={{ color: textColor }}>
      {text}
    </span>
  );
};
// src/redux/store.js
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import PostsReducer from './reducers/PostsReducer';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todo: todoReducers,
});

const middleware = applyMiddleware(thunk);

export const store = createStore(rootReducer, composeEnhancers(middleware));

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_KEY = 'leaveTypeCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in localStorage
const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

// Helper function to get data from cache
const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Component for "/api/LeaveType/CustomList"
export const LeaveTypeCustomList = async (query, forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveType/CustomList', { params: { query } });
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/CustomUpdate"
export const LeaveTypeCustomUpdate = async (leaveType) => {
    try {
        const response = await api_hr.post('/api/LeaveType/CustomUpdate', leaveType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/Delete"
export const DeleteLeaveType = async (leaveType) => {
    try {
        const response = await api_hr.delete('/api/LeaveType/Delete', { data: leaveType });
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on delete
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/Get/{id}"
export const GetLeaveTypeById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get(`/api/LeaveType/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/GetValue"
export const GetLeaveTypeValue = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveType/GetValue');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/Insert"
export const InsertLeaveType = async (leaveType) => {
    try {
        const response = await api_hr.post('/api/LeaveType/Insert', leaveType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on insert
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/List"
export const LeaveTypeList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/LeaveType/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/PaginatedList"
export const PaginatedLeaveTypeList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get('/api/LeaveType/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/Save"
export const SaveLeaveType = async (leaveType) => {
    try {
        const response = await api_hr.post('/api/LeaveType/Save', leaveType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on save
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveType/Update"
export const UpdateLeaveType = async (leaveType) => {
    try {
        const response = await api_hr.post('/api/LeaveType/Update', leaveType);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import FileUploader from '../../../../../components/Custom/Uploader/FileUploader';
import FilePreview from '../../../../../components/Custom/Uploader/FilePreview';
import {
  PolicySignatureListViewByUserId,
  SavePolicySignature,
  UpdatePolicySignature,
} from '../../../../../../services/ccms/hrManagement/policySignature';
import { PolicyListView } from '../../../../../../services/ccms/hrManagement/policy';

const PoliciesTab = ({ userId }) => {
  const [policies, setPolicies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredPolicies, setFilteredPolicies] = useState([]);
  const [policyList, setPolicyList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showUploaderModal, setShowUploaderModal] = useState(false);
  const [editingPolicy, setEditingPolicy] = useState(null);
  const [policySignatureData, setPolicySignatureData] = useState({
    policyID: '',
    signatureDate: '',
    signedBy: '',
    fileURL: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [previewFileURL, setPreviewFileURL] = useState(null); // For FilePreview
  const [searchTerm, setSearchTerm] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const loggedOnUserId = localStorage.getItem('userID');

  useEffect(() => {
    fetchPolicySignatures();
    fetchPolicies();
  }, [userId]);

  // Fetch the list of policies for dropdown
  const fetchPolicies = async () => {
    try {
      const response = await PolicyListView();
      if (response.status === 'Success') {
        setPolicyList(response.results);
      } else {
        setPolicyList([]);
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while fetching policies', 'error');
    }
  };

  const fetchPolicySignatures = async () => {
    try {
      const response = await PolicySignatureListViewByUserId(userId);
      if (response.status === 'Success') {
        const uniqueCategories = Array.from(
          new Set(
            response.results.map((policy) => policy.policyCategoryID)
          )
        ).map((id) => {
          const category = response.results.find((policy) => policy.policyCategoryID === id);
          return {
            policyCategoryID: category.policyCategoryID,
            policyCategoryName: category.policyCategoryName,
          };
        });

        setCategories(uniqueCategories);
        setPolicies(response.results);
        setFilteredPolicies(response.results);
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while fetching policies', 'error');
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    filterPolicies(searchTerm, categoryId);
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    filterPolicies(term, selectedCategory);
  };

  const filterPolicies = (term, categoryId) => {
    const filtered = policies.filter((policy) => {
      const matchesTerm =
        policy.policyName.toLowerCase().includes(term) ||
        policy.policyCategoryName.toLowerCase().includes(term) ||
        policy.policyVersion.toLowerCase().includes(term);

      const matchesCategory = categoryId === '' || policy.policyCategoryID === parseInt(categoryId);

      return matchesTerm && matchesCategory;
    });
    setFilteredPolicies(filtered);
  };

  const handleFileUploadSuccess = (name, url) => {
    setPolicySignatureData((prev) => ({ ...prev, [name]: url }));
    setIsUploading(false);
  };

  const handleFileUploadStart = () => {
    setIsUploading(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPolicySignatureData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSavePolicySignature = async () => {
    if (!policySignatureData.fileURL) {
      setAlertMessage('Please upload a file.');
      return;
    }

    setIsSaving(true);
    const now = new Date();
    const requestData = {
      ...policySignatureData,
      userId,
      signedBy: loggedOnUserId,
      signatureDate: policySignatureData.signatureDate || now.toISOString(),
      createdDateTime: now.toISOString(),
      modifiedDateTime: now.toISOString(),
    };

    try {
      const response = editingPolicy
        ? await UpdatePolicySignature({
            ...requestData,
            policySignatureID: editingPolicy.policySignatureID,
          })
        : await SavePolicySignature(requestData);

      if (response.status === 'Success') {
        Swal.fire(
          'Success',
          editingPolicy
            ? 'Policy signature updated successfully'
            : 'Policy signed successfully',
          'success'
        );
        setShowUploaderModal(false);
        setEditingPolicy(null);
        setPolicySignatureData({
          policyID: '',
          signatureDate: '',
          signedBy: '',
          fileURL: '',
        });
        fetchPolicySignatures();
      } else {
        Swal.fire(
          'Error',
          response.message || 'An error occurred while saving the policy signature',
          'error'
        );
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while saving the policy signature', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditPolicy = (policy) => {
    setEditingPolicy(policy);
    setPolicySignatureData({
      policyID: policy.policyID,
      signatureDate: policy.signatureDate.split('T')[0],
      signedBy: policy.signedBy,
      fileURL: policy.fileURL,
    });
    setShowUploaderModal(true);
  };

  const handlePreview = (fileURL) => {
    setPreviewFileURL(fileURL);
  };

  const handleClosePreview = () => {
    setPreviewFileURL(null);
  };

  const handleModalClose = () => {
    setShowUploaderModal(false);
    setEditingPolicy(null);
    setPolicySignatureData({
      policyID: '',
      signatureDate: '',
      signedBy: '',
      fileURL: '',
    });
    setAlertMessage(null);
  };

  // Define columns for DataTable
  const columns = [
    {
      name: 'Policy Name',
      selector: (row) => row.policyName,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => row.policyCategoryName,
      sortable: true,
    },
    {
      name: 'Version',
      selector: (row) => row.policyVersion,
      sortable: true,
    },
    {
      name: 'Signature Date',
      selector: (row) => new Date(row.signatureDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <Button variant="link" size="xxs" onClick={() => handlePreview(row.fileURL)}>
            <FontAwesomeIcon icon={faEye} /> Preview
          </Button>
          {/* <Button variant="link" size="sm" onClick={() => handleEditPolicy(row)}>
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button> */}
        </>
      ),
    },
  ];

  return (
    <div className="pt-3">
      <div className="settings-form">
        <h4 className="text-primary">Employee Policy Signatures</h4>

        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="mb-0">Manage signed policies for the employee.</p>
          <Button variant="primary" onClick={() => setShowUploaderModal(true)}>
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Signed Policy
          </Button>
        </div>

        <Form className="d-flex mb-4">
          <Form.Control
            type="text"
            placeholder="Search policies"
            className="me-2"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Form.Select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.policyCategoryID} value={category.policyCategoryID}>
                {category.policyCategoryName}
              </option>
            ))}
          </Form.Select>
        </Form>

        {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}

        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={filteredPolicies}
            pagination
            highlightOnHover
            pointerOnHover
            noDataComponent="No signed policies found."
          />
        </div>

        {/* File Preview Component */}
        {previewFileURL && (
          <FilePreview fileURL={previewFileURL} onClose={handleClosePreview} />
        )}

        {/* Policy Signature Modal */}
        <Modal
          show={showUploaderModal}
          onHide={handleModalClose}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {editingPolicy ? 'Edit Policy Signature' : 'Upload Signed Policy'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* File Uploader */}
              <Form.Group>
                <Form.Label>
                  Signed Policy Document <span className="text-danger">*</span>
                </Form.Label>
                <FileUploader
                  userId={userId}
                  name="fileURL"
                  initialFileURL={policySignatureData.fileURL}
                  onSuccess={handleFileUploadSuccess}
                  onStart={handleFileUploadStart}
                  storageLocation="EmployeePolicies"
                />
                {isUploading && (
                  <div className="mt-2">
                    <Spinner animation="border" size="sm" /> Uploading...
                  </div>
                )}
              </Form.Group>

              {/* Form Fields for Policy Signature Data */}
              <Form.Group className="mt-4">
                <Form.Label>
                  Select Policy <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="policyID"
                  value={policySignatureData.policyID}
                  onChange={handleInputChange}
                  isInvalid={!policySignatureData.policyID && alertMessage}
                >
                  <option value="">-- Select Policy --</option>
                  {policyList.map((policy) => (
                    <option key={policy.policyID} value={policy.policyID}>
                      {policy.name} ({policy.categoryName})
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a policy.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Signature Date</Form.Label>
                <Form.Control
                  type="date"
                  name="signatureDate"
                  value={policySignatureData.signatureDate}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Signed By</Form.Label>
                <Form.Control
                  type="text"
                  name="signedBy"
                  value={policySignatureData.signedBy}
                  placeholder="Enter signed by"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleModalClose}
              disabled={isSaving || isUploading}
            >
              <FontAwesomeIcon icon={faTimes} className="me-2" /> Close
            </Button>
            <Button
              variant="primary"
              onClick={handleSavePolicySignature}
              disabled={
                isSaving || isUploading || !policySignatureData.fileURL || !policySignatureData.policyID
              }
            >
              {isSaving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Saving...
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSave} className="me-2" />{' '}
                  {editingPolicy ? 'Save Changes' : 'Upload Policy'}
                </>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PoliciesTab;
import React, { useState, useEffect, Fragment } from 'react';
import { Button, Form, Row, Col, ListGroup, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { EmailTemplateListForApp, UpdateEmailTemplate } from '../../../../../services/ccms/communicationManagement/emailTemplate';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import EditorComponent from './EditorComponent';

const EmailTemplates = () => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        fetchEmailTemplates();
    }, []);

    const fetchEmailTemplates = async () => {
        setLoading(true);
        try {
            const response = await EmailTemplateListForApp();
            setTemplates(response.results);
        } catch (error) {
            console.error('Error fetching email templates:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleTemplateClick = (template) => {
        setSelectedTemplate({
            ...template,
            decodedTemplate: atob(template.template)
        });
    };

    const handleUpdate = async () => {
        setUpdating(true);
        toast.info('Updating email template...');
        try {
            const updatedTemplate = {
                ...selectedTemplate,
                template: btoa(selectedTemplate.decodedTemplate)
            };
            const response = await UpdateEmailTemplate(updatedTemplate);
            if (response.status === 'Success') {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'success',
                });
                await fetchEmailTemplates();
                toast.success('Email template updated successfully');
            } else {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error updating email template:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while updating the email template.',
                icon: 'error',
            });
        } finally {
            setUpdating(false);
        }
    };

    const handleClose = () => {
        setSelectedTemplate(null);
    };

    return (
        <Fragment>
            <Row>
                <Col md={4}>
                    {loading ? (
                        <Shimmer type="list" />
                    ) : (
                        <Card className="shadow-sm">
                            <Card.Body>
                                <ListGroup>
                                    {templates.map((template) => (
                                        <ListGroup.Item
                                            key={template.templateID}
                                            onClick={() => handleTemplateClick(template)}
                                            action
                                        >
                                            <h5>{template.name}</h5>
                                            <p className="text-muted">{template.description}</p>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
                <Col md={8}>
                    {selectedTemplate && (
                        <Card className="shadow-sm">
                            <Card.Body>
                                <Form>
                                    <Form.Group controlId="templateID" className='mb-3'>
                                        <Form.Label>Template ID</Form.Label>
                                        <Form.Control type="text" readOnly value={selectedTemplate.templateID} />
                                    </Form.Group>
                                    <Form.Group controlId="name" className='mb-3'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" readOnly value={selectedTemplate.name} />
                                    </Form.Group>
                                    <Form.Group controlId="description" className='mb-3'>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedTemplate.description}
                                            onChange={(e) =>
                                                setSelectedTemplate({
                                                    ...selectedTemplate,
                                                    description: e.target.value,
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="template" className='mb-3'>
                                        <Form.Label>Template (HTML)</Form.Label>
                                        <EditorComponent
                                            initialContent={selectedTemplate.decodedTemplate}
                                            onContentChange={(content) =>
                                                setSelectedTemplate({
                                                    ...selectedTemplate,
                                                    decodedTemplate: content,
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <Button variant="secondary" onClick={handleClose} size="sm" className="mr-2">
                                        <FontAwesomeIcon icon={faTimes} /> Close
                                    </Button>
                                    <Button variant="primary" onClick={handleUpdate} disabled={updating} size="sm">
                                        {updating ? 'Updating...' : <><FontAwesomeIcon icon={faSave} /> Update</>}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
        </Fragment>
    );
};

export default EmailTemplates;

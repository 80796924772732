import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  LineElement, 
  PointElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';

// Register the necessary components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const TasksPerUserGraph = ({ members }) => {
  const data = {
    labels: members.map(user => `${user.firstName} ${user.surname}`),
    datasets: [
      {
        label: 'Total Tasks',
        data: members.map(user => user.totalTasks),
        backgroundColor: '#8884d8',
      },
    ],
  };

  const options = {
    indexAxis: 'y', // This option makes the bar chart horizontal
    scales: {
      x: { beginAtZero: true },
      y: { stacked: true },
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: '400px' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default TasksPerUserGraph;

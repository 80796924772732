import { useEffect, useState } from 'react';
import { DepartmentList, DesignationsList } from './departmentManagement/department/department_endpoints';
import { FilteredTaskViewList, GetMyTasks, GetAssignedUser } from './taskManagement/tasks/tasks_endpoints';
import { getConfig } from '../../configs/pubConfig'; // Adjust path as necessary

const useCacheRefresher = () => {
  const [interval, setIntervalDuration] = useState(2 * 60000); // Default to 1 minute

  useEffect(() => {
    const fetchConfig = async () => {
      const config = await getConfig();
      const cacheDuration = config.CACHE_REFRESHER_DURATION || 2; // Default to 1 minute if not provided
      const cacheDurationInMs = cacheDuration * 60000; // Convert minutes to milliseconds
      setIntervalDuration(cacheDurationInMs); // Convert minutes to milliseconds   
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const refreshCache = async () => {
      // try {
      //   // Refresh other caches
      //   // await Promise.allSettled([
      //   //   DepartmentList(true),
      //   //   DesignationsList(true),
      //   //   GetMyTasks(),
      //   // ]);

      //   // Fetch filtered task list and their assigned users
      //   // const filteredTaskResult = await FilteredTaskViewList(true);

      //   // if (filteredTaskResult && filteredTaskResult.results) {
      //   //   //const taskIDs = filteredTaskResult.results.map(task => task.taskID);

      //   //   // Fetch assigned users for each task
      //   //   // const assignedUserPromises = taskIDs.map(taskID => GetAssignedUser(taskID, true));
      //   //   // const assignedUsers = await Promise.allSettled(assignedUserPromises);

      //   //   // Check if any tasks have statusID 4 and remove the cache for those tasks
      //   //   filteredTaskResult.results.forEach((task, index) => {
      //   //     if (task.statusID === 4) {
      //   //       const cacheKeyAssigned = `AssignedUser_${task.taskID}_Assigned`;
      //   //       localStorage.removeItem(cacheKeyAssigned);
      //   //       console.log(`Cache for taskID ${task.taskID} with statusID 4 has been removed.`);
      //   //     }
      //   //   });
      //   }
      // } catch (error) {
      //   console.error('Background cache refresh error:', error);
      // }
    };

    const intervalId = setInterval(refreshCache, interval);

    return () => clearInterval(intervalId);
  }, [interval]);
};

export default useCacheRefresher;

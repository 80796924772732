import { getApiTasks } from "../../APIServiceSetup";

const api_tasks = await getApiTasks();

const cache = {};
const CACHE_DURATION = 60 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get task priority list with caching
export const TaskPriorityList = async (forceUpdate = false) => {
  const cacheKey = 'taskPriorityList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get('/api/TaskPriority/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task priority by ID with caching
export const TaskPriorityById = async (id, forceUpdate = false) => {
  const cacheKey = `taskPriorityById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_tasks.get(`/api/TaskPriority/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert task priority
export const InsertTaskPriority = async (taskPriority) => {
  try {
    const response = await api_tasks.post('/api/TaskPriority/Insert', taskPriority);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to update task priority
export const UpdateTaskPriority = async (taskPriority) => {
  try {
    const response = await api_tasks.post('/api/TaskPriority/Update', taskPriority);
    return response.data;
  } catch (error) {
    return error;
  }
};

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_KEY = 'employeeFileCategoryCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in localStorage
const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

// Helper function to get data from cache
const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Component for "/api/EmployeeFileCategory/CustomList"
export const EmployeeFileCategoryCustomList = async (query, forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileCategory/CustomList', { params: { query } });
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/CustomUpdate"
export const EmployeeFileCategoryCustomUpdate = async (employeeFileCategory) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileCategory/CustomUpdate', employeeFileCategory);
        localStorage.removeItem(CACHE_KEY); // Clear cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/Delete"
export const DeleteEmployeeFileCategory = async (employeeFileCategory) => {
    try {
        const response = await api_hr.delete('/api/EmployeeFileCategory/Delete', { data: employeeFileCategory });
        localStorage.removeItem(CACHE_KEY); // Clear cache on delete
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/Get/{id}"
export const GetEmployeeFileCategoryById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get(`/api/EmployeeFileCategory/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/GetValue"
export const GetEmployeeFileCategoryValue = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileCategory/GetValue');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/Insert"
export const InsertEmployeeFileCategory = async (employeeFileCategory) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileCategory/Insert', employeeFileCategory);
        localStorage.removeItem(CACHE_KEY); // Clear cache on insert
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/List"
export const EmployeeFileCategoryList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileCategory/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/PaginatedList"
export const PaginatedEmployeeFileCategoryList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get('/api/EmployeeFileCategory/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/Save"
export const SaveEmployeeFileCategory = async (employeeFileCategory) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileCategory/Save', employeeFileCategory);
        localStorage.removeItem(CACHE_KEY); // Clear cache on save
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeFileCategory/Update"
export const UpdateEmployeeFileCategory = async (employeeFileCategory) => {
    try {
        const response = await api_hr.post('/api/EmployeeFileCategory/Update', employeeFileCategory);
        localStorage.removeItem(CACHE_KEY); // Clear cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

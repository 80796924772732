
// Helper function to pad numbers to 2 digits
const pad = (num) => String(num).padStart(2, '0');

// 1. Numeric Datetime: yyyyMMddHHmmss
export const numericDatetime = (date = new Date()) => {
    return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
    // Example: 20241024153045
};

// 2. Full DateTime Format: Custom format using tokens
export const fullDateTimeFormat = (date = new Date(), format = 'dd-MM-yyyy HH:mm:ss') => {
    const tokens = {
        yyyy: date.getFullYear(),
        MM: pad(date.getMonth() + 1),
        dd: pad(date.getDate()),
        HH: pad(date.getHours()),
        mm: pad(date.getMinutes()),
        ss: pad(date.getSeconds()),
        tt: date.getHours() >= 12 ? 'PM' : 'AM',
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss|tt/g, (match) => tokens[match]);
    // Example: 24-10-2024 15:30:45
};

// 3. Get Month and Year: e.g., October 2024
export const getMonthYear = (date = new Date()) => {
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString(undefined, options);
    // Example: October 2024
};

// 4. Short Date Format: e.g., 24 Oct 2024
export const shortDateFormat = (date = new Date()) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
    // Example: 24 Oct 2024
};

// 5. Time Format: e.g., 03:30 PM
export const timeFormat = (date = new Date()) => {
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleTimeString(undefined, options);
    // Example: 03:30 PM
};

// 6. ISO String: e.g., 2024-10-24T13:30:45.678Z
export const isoString = (date = new Date()) => {
    return date.toISOString();
    // Example: 2024-10-24T13:30:45.678Z
};

// 7. Start of the Day: Sets time to 00:00:00
export const startOfDay = (date = new Date()) => {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);
    return start;
    // Example: Thu Oct 24 2024 00:00:00 GMT+0200
};

// 8. End of the Day: Sets time to 23:59:59
export const endOfDay = (date = new Date()) => {
    const end = new Date(date);
    end.setHours(23, 59, 59, 999);
    return end;
    // Example: Thu Oct 24 2024 23:59:59 GMT+0200
};

// 9. Time Difference in Minutes
export const timeDifferenceInMinutes = (start, end) => {
    const diff = Math.abs(end - start) / 1000 / 60;
    return Math.round(diff);
    // Example: 45 (if the difference is 45 minutes)
};

// 10. Add Days to a Date
export const addDays = (date = new Date(), days = 1) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
    // Example: Adds 1 day to Oct 24, 2024 → Fri Oct 25 2024
};

// 11. Check if Date is in the Past
export const isPastDate = (date) => {
    return date < new Date();
    // Example: true (if the date is before today)
};

// 12. Check if Two Dates are the Same Day
export const isSameDay = (date1, date2) => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
    // Example: true (if both dates are Oct 24, 2024)
};

// 13. Get Day of the Week: e.g., Thursday
export const getDayOfWeek = (date = new Date()) => {
    const options = { weekday: 'long' };
    return date.toLocaleDateString(undefined, options);
    // Example: Thursday
};

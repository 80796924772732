// import { getApiTasks } from "../../APIServiceSetup";
// import { encryptData, decryptData } from "../../../../views/components/Custom/Crypto";
// import moment from 'moment';

// const api_tasks = await getApiTasks();




// const cache = {};
// const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

// // Utility function to check and set cache
// const checkCache = (cacheKey) => {
//   const now = Date.now();
//   if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_TIMEOUT) {
//     return cache[cacheKey].data;
//   }
//   return null;
// };

// const setCache = (cacheKey, data) => {
//   cache[cacheKey] = { data, timestamp: Date.now() };
// };

// // Utility function to filter cached data based on filters for FilteredTaskViewList
// const applyFilters = (data, filters) => {
//     // Handle empty results gracefully
//     if (!data || !data.results || data.results.length === 0) {
//       return data; 
//     }
  
//     // Check if all filters are "NULL"
//     const allFiltersNull = Object.values(filters).every(value => value === "NULL");
//     if (allFiltersNull) {
//       return data; // No filtering needed
//     }
  
//     // Convert filter date strings to Date objects (only if needed)
//     const startDate = filters.startDate !== "NULL" ? moment(filters.startDate, 'YYYY-MM-DD').toDate() : null;
//     const endDate = filters.endDate !== "NULL" ? moment(filters.endDate, 'YYYY-MM-DD').toDate() : null;
  
//     const filteredData = data.results.filter(item => {
//       return (
//         // Task ID filter (parse to number if needed)
//         (filters.taskID === "NULL" || item.taskID === parseInt(filters.taskID)) &&
  
//         // Date filters (using explicit parsing and null checks)
//         (!startDate || new Date(item.dueDate) >= startDate) &&
//         (!endDate || new Date(item.dueDate) <= endDate) &&
  
//         // Search filter (case-insensitive)
//         (filters.search === "NULL" || 
//           item.title.toLowerCase().includes(filters.search.toLowerCase()) || 
//           item.description.toLowerCase().includes(filters.search.toLowerCase())) &&
  
//         // Other filters (parse to numbers where needed)
//         (filters.priorityID === "NULL" || item.priorityID === parseInt(filters.priorityID)) &&
//         (filters.statusID === "NULL" || item.statusID === parseInt(filters.statusID)) &&
//         (filters.locationID === "NULL" || item.locationID === parseInt(filters.locationID)) &&
//         (filters.departmentID === "NULL" || item.departmentID === parseInt(filters.departmentID)) &&
  
//         // Assigned By and Created By filters (no parsing needed for strings)
//         (filters.assignedBy === "NULL" || item.assignedBy === filters.assignedBy) &&
//         (filters.createdBy === "NULL" || item.createdBy === filters.createdBy)
//       );
//     });
  
//     // Update the results in the data object and return
//     return {
//       ...data, 
//       results: filteredData,
//     };
//   };

// // Utility function to filter cached data based on filters for GetMyTasks
// const applyMyTaskFilters = (data, filters) => {
//     // Handle empty results gracefully
//     if (!data || !data.results || data.results.length === 0) {
//       return data; 
//     }
  
//     // Check if all filters are "NULL"
//     const allFiltersNull = Object.values(filters).every(value => value === "NULL");
//     if (allFiltersNull) {
//       return data; // No filtering needed
//     }
    
//     // Convert filter date strings to Date objects (only if needed)
//     const startDate = filters.startDate !== "NULL" ? moment(filters.startDate, 'YYYY-MM-DD').toDate() : null;
//     const endDate = filters.endDate !== "NULL" ? moment(filters.endDate, 'YYYY-MM-DD').toDate() : null;
  
//     const filteredData = data.results.filter(item => {
//       return (
//         // // User ID filter (should always be present in applyMyTaskFilters)
//         // item.userID === filters.userID &&
  
//         // Date filters (using explicit parsing and null checks)
//         (!startDate || new Date(item.dueDate) >= startDate) &&
//         (!endDate || new Date(item.dueDate) <= endDate) &&
  
//         // Search filter (case-insensitive)
//         (filters.search === "NULL" || 
//           item.title.toLowerCase().includes(filters.search.toLowerCase()) || 
//           item.description.toLowerCase().includes(filters.search.toLowerCase())) &&
  
//         // Other filters (parse to numbers where needed)
//         (filters.priorityID === "NULL" || item.priorityID === parseInt(filters.priorityID)) &&
//         (filters.statusID === "NULL" || item.statusID === parseInt(filters.statusID)) &&
//         (filters.locationID === "NULL" || item.locationID === parseInt(filters.locationID)) &&
//         (filters.departmentID === "NULL" || item.departmentID === parseInt(filters.departmentID))
//       );
//     });
  
//     // Update the results in the data object and return
//     return {
//       ...data, // Maintain other properties of the data object (e.g., pagination info)
//       results: filteredData,
//     };
//   };

// // Function to get filtered task view list with caching
// export const FilteredTaskViewList = async (TaskFilters, forceUpdate = false) => {
//   const defaultFilters = {
//     taskID: "NULL",
//     startDate: "NULL",
//     endDate: "NULL",
//     search: "NULL",
//     priorityID: "NULL",
//     statusID: "NULL",
//     locationID: "NULL",
//     departmentID: "NULL",
//     assignedBy: "NULL",
//     createdBy: "NULL"
//   };

//   const filters = { ...defaultFilters, ...TaskFilters };

//   const cacheKey = 'FilteredTaskViewList';
//   let cachedData = checkCache(cacheKey);
  
//   if (!forceUpdate && cachedData) {
//     return applyFilters(cachedData, filters);
//   }

//   try {
//     const response = await api_tasks.post('/api/Task/GetFilteredList', { ...filters, fetchAll: true });
//     if (response.data && response.data.results) {
//       setCache(cacheKey, response.data);
//       return applyFilters(response.data, filters);
//     } else {
//       console.error('No results found in response:', response.data);
//       return [];
//     }
//   } catch (error) {
//     console.error('Error fetching filtered task view list:', error);
//     return error;
//   }
// };

// // Function to get task list
// export const TasksList = async () => {
//   try {
//     const response = await api_tasks.get('/api/Task/List');
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// // Function to get task by ID
// export const TasksById = async (id) => {
//   try {
//     const response = await api_tasks.get(`/api/Task/${id}`);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// // Function to insert task
// export const InsertTasks = async (tasks) => {
//   try {
//     const response = await api_tasks.post('/api/Task/Insert', tasks);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// // Function to insert task and return
// export const InsertTaskAndReturn = async (tasks) => {
//   try {
//     const response = await api_tasks.post('/api/Task/InsertTaskAndReturn', tasks);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// // Function to update task
// export const UpdateTasks = async (tasks) => {
//   try {
//     const response = await api_tasks.post('/api/Task/Update', tasks);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// // Function to Create my task
// export const CreateMyTask = async (tasks) => {
//   try {
//     const response = await api_tasks.post('/api/Task/CreateMyTask', tasks);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// // Function to save task
// export const SaveTasks = async (tasks) => {
//   try {
//     const response = await api_tasks.post('/api/Task/Save', tasks);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// // Function to get task view list with caching
// export const TaskViewList = async (TaskFilters, forceUpdate = false) => {
//   const cacheKey = 'TaskViewList';
//   let cachedData = checkCache(cacheKey);
  
//   if (!forceUpdate && cachedData) {
//     return applyFilters(cachedData, TaskFilters);
//   }

//   try {
//     const response = await api_tasks.post('/api/Task/GetTaskListView', { fetchAll: true });
//     if (response.data && response.data.results) {
//       setCache(cacheKey, response.data);
//       return applyFilters(response.data, TaskFilters);
//     } else {
//       console.error('No results found in response:', response.data);
//       return [];
//     }
//   } catch (error) {
//     console.error('Error fetching task view list:', error);
//     return error;
//   }
// };

// // Function to get filtered campus task view list with caching
// export const FilteredCampusTaskViewList = async (TaskFilters, forceUpdate = false) => {
//       const defaultFilters = {
//         taskID: "NULL",
//         startDate: "NULL",
//         endDate: "NULL",
//         search: "NULL",
//         priorityID: "NULL",
//         statusID: "NULL",
//         locationID: "NULL",
//         departmentID: "NULL",
//         assignedBy: "NULL",
//         createdBy: "NULL"
//       };
    
//       const filters = { ...defaultFilters, ...TaskFilters };

//   const cacheKey = 'FilteredCampusTaskViewList';
//   let cachedData = checkCache(cacheKey);
  
//   if (!forceUpdate && cachedData) {
//     return applyFilters(cachedData, TaskFilters);
//   }

//   try {
//     const response = await api_tasks.post('/api/Task/GetFilteredList', {  ...filters, fetchAll: true });
//     if (response.data && response.data.results) {
//       setCache(cacheKey, response.data);
//       return applyFilters(response.data, TaskFilters);
//     } else {
//       console.error('No results found in response:', response.data);
//       return [];
//     }
//   } catch (error) {
//     console.error('Error fetching filtered campus task view list:', error);
//     return error;
//   }
// };

// // Function to get my tasks with caching
// export const GetMyTasks = async (TaskFilters, forceUpdate = false) => {
//     // Retrieve user details from localStorage
//     const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
//     const userID = userDetails.userID || "NULL";
  
//     const defaultFilters = {
//       userID: '' + userID,
//       startDate: "NULL",
//       endDate: "NULL",
//       search: "NULL",
//       priorityID: "NULL",
//       statusID: "NULL",
//       locationID: "NULL",
//       departmentID: "NULL"
//     };
  
//     const filters = { ...defaultFilters, ...TaskFilters };
  
//     const cacheKey = 'GetMyTasks';
//     let cachedData = checkCache(cacheKey);
    
//     if (!forceUpdate && cachedData) {
//       return applyMyTaskFilters(cachedData, filters);
//     }
  
//     try {
//       const response = await api_tasks.post('/api/Task/GetMyTasks', { ...filters, fetchAll: true });
//       if (response.data && response.data.results) {
//         setCache(cacheKey, response.data);
//         return applyMyTaskFilters(response.data, filters);
//       } else {
//         console.error('No results found in response:', response.data);
//         return [];
//       }
//     } catch (error) {
//       console.error('Error fetching my tasks:', error);
//       return error;
//     }
//   };

// // Function to get my created tasks with caching
// export const GetMyCreatedTasks = async (TaskFilters, forceUpdate = false) => {
//   const cacheKey = 'GetMyCreatedTasks';
//   let cachedData = checkCache(cacheKey);
  
//   if (!forceUpdate && cachedData) {
//     return applyFilters(cachedData, TaskFilters);
//   }

//   try {
//     const response = await api_tasks.post('/api/Task/GetMyCreatedTasks', { fetchAll: true });
//     if (response.data && response.data.results) {
//       setCache(cacheKey, response.data);
//       return applyFilters(response.data, TaskFilters);
//     } else {
//       console.error('No results found in response:', response.data);
//       return [];
//     }
//   } catch (error) {
//     console.error('Error fetching my created tasks:', error);
//     return error;
//   }
// };

// // // Function to get assigned user with caching
// // export const GetAssignedUser = async (taskID, forceUpdate = false) => {
// //     const cacheKey = `AssignedUser_${taskID}`;
// //     let cachedData = checkCache(cacheKey);
    
// //     if (!forceUpdate && cachedData) {
// //       return cachedData;
// //     }
  
// //     try {
// //       const response = await api_tasks.get('/api/Task/AssignedUser/' + taskID);
// //       setCache(cacheKey, response.data);
// //       return response.data;
// //     } catch (error) {
// //       console.error('Error fetching assigned user:', error);
// //       return error;
// //     }
// //   };

// // Utility functions for cache handling
// const cacheAssigned = {};
// const CACHE_TIMEOUT_ASSIGNED = 60 * 60 * 1000; // 5 minutes in milliseconds

// // Utility function to check and set cache
// const checkCacheAssigned = (cacheKeyAssigned) => {
//   const now = Date.now();
//   if (cacheAssigned[cacheKeyAssigned] && (now - cacheAssigned[cacheKeyAssigned].timestamp) < CACHE_TIMEOUT_ASSIGNED) {
//     return cacheAssigned[cacheKeyAssigned].data;
//   }
//   return null;
// };

// const setCacheAssigned = (cacheKeyAssigned, data) => {
//   cacheAssigned[cacheKeyAssigned] = { data, timestamp: Date.now() };
// };

// // Function to get assigned user with in-memory caching
// // export const GetAssignedUser = async (taskID, forceUpdate = false) => {
// //   const cacheKeyAssigned = `AssignedUser_${taskID}_Assigned`;
// //   let cachedDataAssigned = checkCacheAssigned(cacheKeyAssigned);
  
// //   if (!forceUpdate && cachedDataAssigned) {
// //     return cachedDataAssigned;
// //   }

// //   try {
// //     const response = await api_tasks.get('/api/Task/AssignedUser/' + taskID);
// //     setCacheAssigned(cacheKeyAssigned, response.data);
// //     return response.data;
// //   } catch (error) {
// //     console.error('Error fetching assigned user:', error);
// //     return error;
// //   }
// // };

// // Function to get assigned user with encrypted caching and expiration
// export const GetAssignedUser = async (taskID, forceUpdate = false) => {
//   const cacheKeyAssigned = `AssignedUser_${taskID}_Assigned`;
//   const CACHE_EXPIRATION = 10 * 24 * 60 * 60 * 1000; // 10 Days expiration time

//   // Check if the key is related to "AssignedUser_" only
//   if (!cacheKeyAssigned.startsWith('AssignedUser_')) {
//     return null; // Skip non-AssignedUser keys (just for safety, although GetAssignedUser should only handle assigned user keys)
//   }

//   // Check if encrypted data is available in cache
//   const storedEncryptedData = localStorage.getItem(cacheKeyAssigned);

//   if (!forceUpdate && storedEncryptedData) {
//     try {
//       // Parse and decrypt the stored data
//       const decryptedData = decryptData(storedEncryptedData);
//       const { data, timestamp } = decryptedData;

//       // Ensure the decrypted data contains a valid timestamp
//       if (!timestamp) {
//         console.warn(`Cache for ${cacheKeyAssigned} does not have a timestamp.`);
//         return data; // No timestamp, assume no expiration
//       }

//       const now = Date.now();

//       // Check if the cache has expired
//       if (now - timestamp > CACHE_EXPIRATION) {
//         console.log(`Cache for ${cacheKeyAssigned} has expired and will be removed.`);
//         localStorage.removeItem(cacheKeyAssigned); // Remove expired cache
//       } else {
//         // If the cache is still valid, return the cached data
//         return data;
//       }
//     } catch (error) {
//       console.error('Error decrypting data or checking expiration:', error);
//       return null; // Return null without removing the cache in case of decryption error
//     }
//   }

//   try {
//     // Fetch fresh data from the API when forceUpdate or no valid stored data
//     const response = await api_tasks.get('/api/Task/AssignedUser/' + taskID);

//     // Prepare the data with a timestamp
//     const dataToStore = {
//       data: response.data,
//       timestamp: Date.now() // Set the current time
//     };

//     // Encrypt and store the result in localStorage
//     const encryptedData = encryptData(dataToStore);
//     localStorage.setItem(cacheKeyAssigned, encryptedData);

//     return response.data;
//   } catch (error) {
//     console.error('Error fetching assigned user:', error);
//     return error;
//   }
// };

// // Function to mark task as viewed
// export const MarkViewView = async (taskModal) => {
//   try {
//     const response = await api_tasks.post('/api/Task/ViewTask', taskModal);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

import { getApiTasks } from "../../APIServiceSetup";
import { encryptData, decryptData } from "../../../../views/components/Custom/Crypto";
import moment from 'moment';

const api_tasks = await getApiTasks();
const CACHE_EXPIRATION = 10 * 24 * 60 * 60 * 1000; // 10 Days expiration time

const cache = {};
  const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

  // Utility function to check and set cache
  const checkCache = (cacheKey) => {
    const now = Date.now();
    if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_TIMEOUT) {
      return cache[cacheKey].data;
    }
    return null;
  };

  const setCache = (cacheKey, data) => {
    cache[cacheKey] = { data, timestamp: Date.now() };
  };


// Utility to get and decrypt local storage data
const getLocalStorageData = (key) => {
  const storedEncryptedData = localStorage.getItem(key);
  if (!storedEncryptedData) return null;

  try {
    const decryptedData = decryptData(storedEncryptedData);
    const { data, timestamp } = decryptedData;
    const now = Date.now();

    if (!timestamp || now - timestamp > CACHE_EXPIRATION) {
      localStorage.removeItem(key);
      return null;
    }
    return data;
  } catch (error) {
    console.error(`Error decrypting data for ${key}:`, error);
    localStorage.removeItem(key);
    return null;
  }
};

// Utility to set and encrypt local storage data
const setLocalStorageData = (key, data) => {
  const dataToStore = {
    data,
    timestamp: Date.now(),
  };
  const encryptedData = encryptData(dataToStore);
  localStorage.setItem(key, encryptedData);
};

// Function to update task data in local storage based on taskID inside updatedTaskData
export const updateTaskInLocalStorage = (updatedTaskData) => {
  const { taskID } = updatedTaskData; // Extract taskID from updatedTaskData

  // Define the keys that store task-related data in local storage
  const storageKeys = [
    'FilteredTaskViewList',
    'TaskViewList',
    'GetMyTasks',
    'GetMyCreatedTasks',
    'FilteredCampusTaskViewList'
  ];

  storageKeys.forEach((key) => {
    const storedData = getLocalStorageData(key);
    // If there is data for the key, update the task if found
    if (storedData) {
      const taskIndex = storedData.findIndex(task => task.taskID === taskID);

      // If task is found, update the task data
      if (taskIndex !== -1) {
        storedData[taskIndex] = updatedTaskData;

        // Update the local storage with the modified data
        setLocalStorageData(key, storedData);
      }
    }
  });
};

// Function to selectively insert new task data into relevant local storage keys
export const insertTaskIntoLocalStorage = (newTaskData) => {
  const taskID = newTaskData.TaskID;

  // Insert into "GetMyCreatedTasks" if the task is created by the user
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  if (newTaskData.CreatedBy === userDetails.userID) {
    const createdTasks = getLocalStorageData('GetMyCreatedTasks') || [];
    createdTasks.push(newTaskData);
    setLocalStorageData('GetMyCreatedTasks', createdTasks);
  }

  // Insert into "FilteredTaskViewList" if the task matches certain filters
  const filteredTasks = getLocalStorageData('FilteredTaskViewList') || [];
  if (matchesFilters(newTaskData)) {  // Assuming you have a function to match filters
    filteredTasks.push(newTaskData);
    setLocalStorageData('FilteredTaskViewList', filteredTasks);
  }

  // Insert into "TaskViewList"
  const taskViewList = getLocalStorageData('TaskViewList') || [];
  taskViewList.push(newTaskData);
  setLocalStorageData('TaskViewList', taskViewList);

  // Insert into "GetMyTasks" if the task is assigned to the user
  const myTasks = getLocalStorageData('GetMyTasks') || [];
  if (isTaskAssignedToUser(newTaskData, userDetails.userID)) { // Assuming a function to check task assignment
    myTasks.push(newTaskData);
    setLocalStorageData('GetMyTasks', myTasks);
  }

  // Insert into "FilteredCampusTaskViewList" if it belongs to the user's campus
  const filteredCampusTasks = getLocalStorageData('FilteredCampusTaskViewList') || [];
  if (belongsToUserCampus(newTaskData)) {  // Assuming a function to check if the task is in user's campus
    filteredCampusTasks.push(newTaskData);
    setLocalStorageData('FilteredCampusTaskViewList', filteredCampusTasks);
  }
};

// Placeholder function to check if task matches certain filters (implement your own logic)
const matchesFilters = (taskData) => {
  // Add logic to match task with filters if needed
  return true;  // For now, assume all tasks match
};

// Placeholder function to check if the task is assigned to the current user
const isTaskAssignedToUser = (taskData, userID) => {
  return taskData.TaskAssignedUsers && taskData.TaskAssignedUsers.some(user => user.userID === userID);
};

// Placeholder function to check if task belongs to the user's campus (implement your own logic)
const belongsToUserCampus = (taskData) => {
  // Add logic to check if the task is in the user's campus
  return true;  // For now, assume all tasks belong to the user's campus
};


// Apply filters for `FilteredTaskViewList`
const applyFilters = (data, filters) => {
  if (!data || data.length === 0) return data;

  const allFiltersNull = Object.values(filters).every(value => value === "NULL");
  if (allFiltersNull) return data;

  const startDate = filters.startDate !== "NULL" ? moment(filters.startDate, 'YYYY-MM-DD').toDate() : null;
  const endDate = filters.endDate !== "NULL" ? moment(filters.endDate, 'YYYY-MM-DD').toDate() : null;

  return data.filter(item => (
    (filters.taskID === "NULL" || item.taskID === parseInt(filters.taskID)) &&
    (!startDate || new Date(item.dueDate) >= startDate) &&
    (!endDate || new Date(item.dueDate) <= endDate) &&
    (filters.search === "NULL" || item.title.toLowerCase().includes(filters.search.toLowerCase()) || item.description.toLowerCase().includes(filters.search.toLowerCase())) &&
    (filters.priorityID === "NULL" || item.priorityID === parseInt(filters.priorityID)) &&
    (filters.statusID === "NULL" || item.statusID === parseInt(filters.statusID)) &&
    (filters.locationID === "NULL" || item.locationID === parseInt(filters.locationID)) &&
    (filters.departmentID === "NULL" || item.departmentID === parseInt(filters.departmentID)) &&
    (filters.assignedBy === "NULL" || item.assignedBy === filters.assignedBy) &&
    (filters.createdBy === "NULL" || item.createdBy === filters.createdBy)
  ));
};

// Apply filters for `GetMyTasks`
const applyMyTaskFilters = (data, filters) => {
  if (!data || data.length === 0) return data;

  const allFiltersNull = Object.values(filters).every(value => value === "NULL");
  if (allFiltersNull) return data;

  const startDate = filters.startDate !== "NULL" ? moment(filters.startDate, 'YYYY-MM-DD').toDate() : null;
  const endDate = filters.endDate !== "NULL" ? moment(filters.endDate, 'YYYY-MM-DD').toDate() : null;

  return data.filter(item => (
    (!startDate || new Date(item.dueDate) >= startDate) &&
    (!endDate || new Date(item.dueDate) <= endDate) &&
    (filters.search === "NULL" || item.title.toLowerCase().includes(filters.search.toLowerCase()) || item.description.toLowerCase().includes(filters.search.toLowerCase())) &&
    (filters.priorityID === "NULL" || item.priorityID === parseInt(filters.priorityID)) &&
    (filters.statusID === "NULL" || item.statusID === parseInt(filters.statusID)) &&
    (filters.locationID === "NULL" || item.locationID === parseInt(filters.locationID)) &&
    (filters.departmentID === "NULL" || item.departmentID === parseInt(filters.departmentID))
  ));
};

// Function to get filtered task view list with local storage
export const FilteredTaskViewList = async (TaskFilters, forceUpdate = false) => {
  const defaultFilters = {
    taskID: "NULL",
    startDate: "NULL",
    endDate: "NULL",
    search: "NULL",
    priorityID: "NULL",
    statusID: "NULL",
    locationID: "NULL",
    departmentID: "NULL",
    assignedBy: "NULL",
    createdBy: "NULL"
  };

  const filters = { ...defaultFilters, ...TaskFilters };
  const storageKey = 'FilteredTaskViewList';
  let storedData = getLocalStorageData(storageKey);

  if (!forceUpdate && storedData) {
    return { status: "Success", message: "Cached data", results: applyFilters(storedData, filters) };
  }

  try {
    const response = await api_tasks.post('/api/Task/GetFilteredList', { ...filters });
    if (response.data && response.data.results) {
      setLocalStorageData(storageKey, response.data.results);  // Store only results
      return response.data; // Return as expected (response.data)
    } else {
      return { status: "Failed", message: "No results found", results: [] };
    }
  } catch (error) {
    console.error('Error fetching filtered task view list:', error);
    return { status: "Failed", message: "Error fetching data", results: [] };
  }
};

// Function to get task by ID
export const TasksById = async (id) => {
  try {
    const response = await api_tasks.get(`/api/Task/${id}`);
    return response.data;  // No change, returns as-is
  } catch (error) {
    return { status: "Failed", message: error.message };
  }
};

// Function to insert task
export const InsertTasks = async (tasks) => {
  try {
    const response = await api_tasks.post('/api/Task/Insert', tasks);
    return response.data;  // No change, returns as-is
  } catch (error) {
    return { status: "Failed", message: error.message };
  }
};

// Function to insert task and return
export const InsertTaskAndReturn = async (tasks) => {
  try {
    const response = await api_tasks.post('/api/Task/InsertTaskAndReturn', tasks);
    return response.data;  // No change, returns as-is
  } catch (error) {
    return { status: "Failed", message: error.message };
  }
};

// Function to update task
export const UpdateTasks = async (tasks) => {
  try {
    const response = await api_tasks.post('/api/Task/Update', tasks);
    return response.data;  // No change, returns as-is
  } catch (error) {
    return { status: "Failed", message: error.message };
  }
};

// Function to Create my task
export const CreateMyTask = async (tasks) => {
  try {
    const response = await api_tasks.post('/api/Task/CreateMyTask', tasks);
    
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to save task
export const SaveTasks = async (tasks) => {
  try {
    const response = await api_tasks.post('/api/Task/Save', tasks);
    if (response.data && response.data.results) {
      const updatedTaskData = response.data.results;

      // Update the local storage with the updated task data
      updateTaskInLocalStorage(updatedTaskData);
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get task view list with local storage
export const TaskViewList = async (TaskFilters, forceUpdate = false) => {
  const storageKey = 'TaskViewList';
  let storedData = getLocalStorageData(storageKey);

  if (!forceUpdate && storedData) {
    return { status: "Success", message: "Cached data", results: applyFilters(storedData, TaskFilters) };
  }

  try {
    const response = await api_tasks.post('/api/Task/GetTaskListView', { fetchAll: true });
    if (response.data && response.data.results) {
      setLocalStorageData(storageKey, response.data.results);  // Store only results
      return response.data; // Return as expected (response.data)
    } else {
      console.error('No results found in response:', response.data);
      return { status: "Failed", message: "No results found", results: [] };
    }
  } catch (error) {
    console.error('Error fetching task view list:', error);
    return { status: "Failed", message: "Error fetching data", results: [] };
  }
};

// Function to get filtered campus task view list with local storage
export const FilteredCampusTaskViewList = async (TaskFilters, forceUpdate = false) => {
  const defaultFilters = {
    taskID: "NULL",
    startDate: "NULL",
    endDate: "NULL",
    search: "NULL",
    priorityID: "NULL",
    statusID: "NULL",
    locationID: "NULL",
    departmentID: "NULL",
    assignedBy: "NULL",
    createdBy: "NULL"
  };

  const filters = { ...defaultFilters, ...TaskFilters };
  const storageKey = 'FilteredCampusTaskViewList';
  let storedData = getLocalStorageData(storageKey);

  if (!forceUpdate && storedData) {
    return { status: "Success", message: "Cached data", results: applyFilters(storedData, filters) };
  }

  try {
    const response = await api_tasks.post('/api/Task/GetFilteredList', { ...filters });
    if (response.data && response.data.results) {
      setLocalStorageData(storageKey, response.data.results);  // Store only results
      return response.data; // Return as expected (response.data)
    } else {
      console.error('No results found in response:', response.data);
      return { status: "Failed", message: "No results found", results: [] };
    }
  } catch (error) {
    console.error('Error fetching filtered campus task view list:', error);
    return { status: "Failed", message: "Error fetching data", results: [] };
  }
};

// Function to get my tasks with local storage
export const GetMyTasks = async (TaskFilters, forceUpdate = false) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const userID = userDetails.userID || "NULL";

  const defaultFilters = {
    userID: '' + userID,
    startDate: "NULL",
    endDate: "NULL",
    search: "NULL",
    priorityID: "NULL",
    statusID: "NULL",
    locationID: "NULL",
    departmentID: "NULL"
  };

  const filters = { ...defaultFilters, ...TaskFilters };
  const storageKey = 'GetMyTasks';
  let storedData = getLocalStorageData(storageKey);

  if (!forceUpdate && storedData) {
    return { status: "Success", message: "Cached data", results: applyMyTaskFilters(storedData, filters) };
  }

  try {
    const response = await api_tasks.post('/api/Task/GetMyTasks', { ...filters, fetchAll: true });
    if (response.data && response.data.results) {
      setLocalStorageData(storageKey, response.data.results);  // Store only results
      return response.data; // Return as expected (response.data)
    } else {
      console.error('No results found in response:', response.data);
      return { status: "Failed", message: "No results found", results: [] };
    }
  } catch (error) {
    console.error('Error fetching my tasks:', error);
    return { status: "Failed", message: "Error fetching data", results: [] };
  }
};

// Function to get my created tasks with local storage
export const GetMyCreatedTasks = async (TaskFilters, forceUpdate = false) => {
  const defaultFilters = {
    taskID: "NULL",
    startDate: "NULL",
    endDate: "NULL",
    search: "NULL",
    priorityID: "NULL",
    statusID: "NULL",
    locationID: "NULL",
    departmentID: "NULL",
    assignedBy: "NULL",
    createdBy: "NULL"
  };

  const filters = { ...defaultFilters, ...TaskFilters };
  const storageKey = 'GetMyCreatedTasks';
  let storedData = getLocalStorageData(storageKey);

  if (!forceUpdate && storedData) {
    return { status: "Success", message: "Cached data", results: applyFilters(storedData, filters) };
  }

  try {
    const response = await api_tasks.post('/api/Task/GetMyCreatedTasks',{ ...filters});
    if (response.data && response.data.results) {
      setLocalStorageData(storageKey, response.data.results);  // Store only results
      return response.data; // Return as expected (response.data)
    } else {
      console.error('No results found in response:', response.data);
      return { status: "Failed", message: "No results found", results: [] };
    }
  } catch (error) {
    console.error('Error fetching my created tasks:', error);
    return { status: "Failed", message: "Error fetching data", results: [] };
  }
};

// Function to get assigned user with encrypted caching and expiration
export const GetAssignedUser = async (taskID, forceUpdate = false) => {
  const cacheKeyAssigned = `AssignedUser_${taskID}_Assigned`;
  const CACHE_EXPIRATION = 10 * 24 * 60 * 60 * 1000; // 10 Days expiration time

  // Check if the key is related to "AssignedUser_" only
  if (!cacheKeyAssigned.startsWith('AssignedUser_')) {
    return null; // Skip non-AssignedUser keys (just for safety, although GetAssignedUser should only handle assigned user keys)
  }

  // Check if encrypted data is available in cache
  const storedEncryptedData = localStorage.getItem(cacheKeyAssigned);

  if (!forceUpdate && storedEncryptedData) {
    try {
      // Parse and decrypt the stored data
      const decryptedData = decryptData(storedEncryptedData);
      const { data, timestamp } = decryptedData;

      // Ensure the decrypted data contains a valid timestamp
      if (!timestamp) {
        console.warn(`Cache for ${cacheKeyAssigned} does not have a timestamp.`);
        return data; // No timestamp, assume no expiration
      }

      const now = Date.now();

      // Check if the cache has expired
      if (now - timestamp > CACHE_EXPIRATION) {
        console.log(`Cache for ${cacheKeyAssigned} has expired and will be removed.`);
        localStorage.removeItem(cacheKeyAssigned); // Remove expired cache
      } else {
        // If the cache is still valid, return the cached data
        return data;
      }
    } catch (error) {
      console.error('Error decrypting data or checking expiration:', error);
      return null; // Return null without removing the cache in case of decryption error
    }
  }

  try {
    // Fetch fresh data from the API when forceUpdate or no valid stored data
    const response = await api_tasks.get('/api/Task/AssignedUser/' + taskID);

    // Prepare the data with a timestamp
    const dataToStore = {
      data: response.data,
      timestamp: Date.now() // Set the current time
    };

    // Encrypt and store the result in localStorage
    const encryptedData = encryptData(dataToStore);
    localStorage.setItem(cacheKeyAssigned, encryptedData);

    return response.data;
  } catch (error) {
    console.error('Error fetching assigned user:', error);
    return error;
  }
};

// Function to mark task as viewed
export const MarkViewView = async (taskModal) => {
  try {
    const response = await api_tasks.post('/api/Task/ViewTask', taskModal);

    if (response.data && response.data.results) {
      const updatedTaskData = response.data.results;

      // Update the local storage with the updated task data
      updateTaskInLocalStorage(updatedTaskData);
    }

    return response.data;  // Return as expected (response.data)
  } catch (error) {
    return { status: "Failed", message: error.message };
  }
};

// Function to get task reports with caching on cookies: /api/Task/TaskReports
export const TaskReports = async (TaskFilters, forceUpdate = false) => {
  try {
    const response = await api_tasks.post('/api/Task/TaskReports', { ...TaskFilters});
    return response.data;
  } catch (error) {
    console.error('Error fetching task reports:', error);
    return error;
  }
};

// Function to get task reports with caching on cookies: /api/Task/TaskReports/{offset}/{limit}
export const TaskReportsWithPagination = async (TaskFilters, offset, limit, forceUpdate = false) => {
  const defaultFilters = {
    search: "NULL",
    taskID: "NULL",
    userID: "NULL",
    startDate: "NULL",
    endDate: "NULL",
    priorityID: "NULL",
    statusID: "NULL",
    locationID: "NULL",
    departmentID: "NULL",
    assignedBy: "NULL",
    createdBy: "NULL",
  };

  const filters = { ...defaultFilters, ...TaskFilters };

  const cacheKey = `TaskReports_${offset}_${limit}`;
  let cachedData = checkCache(cacheKey);

  if (!forceUpdate && cachedData) {
    return applyFilters(cachedData, TaskFilters);
  }

  try {
    const response = await api_tasks.post(`/api/Task/TaskReports/${offset}/${limit}`, { ...filters });
    if (response.data && response.data.results) {
      setCache(cacheKey, response.data);
      return applyFilters(response.data, TaskFilters);
    } else {
      console.error('No results found in response:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching task reports:', error);
    return error;
  }
};

// Function to get all task assignments with caching on cookies: /api/Task/UserAllAssigments/{lastModifiedDateTime}
export const UserAllAssigments = async (lastModifiedDateTime, forceUpdate = false) => {
  const cacheKey = `UserAllAssigments_${lastModifiedDateTime}`;
  let cachedData = checkCache(cacheKey);

  if (!forceUpdate && cachedData) {
    return cachedData;
  }

  try {
    const response = await api_tasks.get(`/api/Task/UserAllAssigments/${lastModifiedDateTime}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user all assignments:', error);
    return error;
  }
};

// Function to Update Progress Stage: /api/Task/UpdateProgressStage/{taskID}/{stageID}
export const UpdateProgressStage = async (taskID, stageID) => {
  try {
    const response = await api_tasks.post(`/api/Task/UpdateProgressStage/${taskID}/${stageID}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get user's active task count by userId: /api/Task/GetUserTaskCount/{userId}
export const GetUserTaskCount = async (userId) => {
  try {
    const response = await api_tasks.get(`/api/Task/GetUserTaskCount/${userId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


        // import { getApiTasks } from "../../APIServiceSetup";
        // import { encryptData, decryptData } from "../../../../views/components/Custom/Crypto";
        // import moment from 'moment';

        // const api_tasks = await getApiTasks();
        // const CACHE_EXPIRATION = 10 * 24 * 60 * 60 * 1000; // 10 Days expiration time
        // const storageKey = 'taskModelData';  // Single key for storing all tasks

        // // Utility to get and decrypt local storage data
        // const getLocalStorageData = () => {
        //   const storedEncryptedData = localStorage.getItem(storageKey);
        //   if (!storedEncryptedData) return [];

        //   try {
        //     const decryptedData = decryptData(storedEncryptedData);
        //     const { data, timestamp } = decryptedData;
        //     const now = Date.now();

        //     if (!timestamp || now - timestamp > CACHE_EXPIRATION) {
        //       console.log(`Data for ${storageKey} has expired.`);
        //       localStorage.removeItem(storageKey);
        //       return [];
        //     }
            
        //     return data;
        //   } catch (error) {
        //     console.error(`Error decrypting data for ${storageKey}:`, error);
        //     localStorage.removeItem(storageKey);
        //     return [];
        //   }
        // };

        // const getLocalStorageDataForFunction = (functionName) => {
        //   const storedEncryptedData = localStorage.getItem(storageKey);

        //   if (!storedEncryptedData) return [];

        //   try {
        //     const decryptedData = decryptData(storedEncryptedData);
        //     console.log('Decrypted data:', decryptedData);
        //     const { data, timestamp } = decryptedData;
        //     const now = Date.now();

        //     if (!timestamp || now - timestamp > CACHE_EXPIRATION) {
        //       console.log(`Data for ${storageKey} has expired.`);
        //       localStorage.removeItem(storageKey);
        //       return [];
        //     }

        //     // Filter data based on the function name and the flags in each task
        //     return data.filter(task => {
        //       switch (functionName) {
        //         case 'FilteredCampusTaskViewList':
        //           return task.isFilteredCampusTaskViewList;
        //         case 'FilteredTaskViewList':
        //           return task.isFilteredTaskViewList;
        //         case 'GetMyTasks':
        //           return task.isMyTasks;
        //         case 'GetMyCreatedTasks':
        //           return task.isMyCreatedTasks;
        //         default:
        //           return false;
        //       }
        //     });
        //   } catch (error) {
        //     console.error(`Error decrypting data for ${storageKey}:`, error);
        //     localStorage.removeItem(storageKey);
        //     return [];
        //   }
        // };

        // // Utility to set and encrypt local storage data
        // const setLocalStorageData = (newData, flags) => {
        //   const existingData = getLocalStorageData(); // Get existing data from local storage
          
        //   console.log('Existing data:', existingData); // Log the existing data
        //   console.log('New data:', newData); // Log the newData to check its structure

        //   let mergedData = [...existingData]; // Copy existing data into mergedData

        //   // Iterate through the new data and either update the existing task or add it if it doesn't exist
        //   newData.forEach((newTask, index) => {
        //     console.log(`New task at index ${index}:`, newTask); // Log the entire task to inspect its structure
        //     console.log('taskID:', newTask.taskID); // Log the taskID to check if it's present

        //     // Find if the task already exists in mergedData by its taskID
        //     const taskIndex = mergedData.findIndex(task => task.index === newTask.taskID); // Check if the task already exists

        //     // Create the new structure with index as taskID, data as the task, and flags
        //     const taskWithFlags = { 
        //       index: newTask.taskID, 
        //       data: { ...newTask },  // Wrap the task data inside the `data` property
        //       ...flags // Add the flags (isMyTasks, isFilteredTaskViewList, etc.)
        //     };

        //     if (taskIndex !== -1) {
        //       // Update the existing task but keep other unchanged fields
        //       mergedData[taskIndex] = {
        //         ...mergedData[taskIndex],  // Keep any previous flags or data
        //         ...taskWithFlags  // Override only the new task data and flags
        //       };
        //       console.log(`Updating existing task with taskID: ${newTask.taskID}`);
        //     } else {
        //       console.log(`Adding new task with taskID: ${newTask.taskID}`);
        //       mergedData.push(taskWithFlags); // Add new task with the structure
        //     }
        //   });

        //   const dataToStore = {
        //     data: mergedData, // Store merged data with each task having the new structure
        //     timestamp: Date.now(), // Update timestamp
        //   };

        //   console.log('Data to store:', dataToStore); // Log the final data to be stored
        //   const encryptedData = encryptData(dataToStore); // Encrypt the data
        //   localStorage.setItem(storageKey, encryptedData); // Store in localStorage
        // };


        // // Function to update task in local storage
        // export const updateTaskInLocalStorage = (updatedTaskData) => {
        //   const storedData = getLocalStorageData();
        //   const taskIndex = storedData.findIndex(task => task.TaskID === updatedTaskData.TaskID);

        //   if (taskIndex !== -1) {
        //     storedData[taskIndex] = updatedTaskData; // Update existing task
        //   } else {
        //     storedData.push(updatedTaskData); // Insert new task if not found
        //   }

        //   setLocalStorageData(storedData); // Save the updated array back to localStorage
        // };

        // // Function to insert a new task into local storage
        // export const insertTaskIntoLocalStorage = (newTaskData) => {
        //   const storedData = getLocalStorageData();
        //   storedData.push(newTaskData); // Add new task to the array
        //   setLocalStorageData(storedData); // Save the updated array back to localStorage
        // };

        // // Function to apply filters on tasks
        // const applyFilters = (filters = {}, storedData = [], ignoreUserID = false) => {
        //   const allFiltersNull = Object.values(filters).every(value => value === "NULL");

        //   if (allFiltersNull) return storedData; // Return all data if no filters are applied

        //   const startDate = filters.startDate !== "NULL" ? moment(filters.startDate, 'YYYY-MM-DD').toDate() : null;
        //   const endDate = filters.endDate !== "NULL" ? moment(filters.endDate, 'YYYY-MM-DD').toDate() : null;

        //   return storedData.filter(item => {
        //     const task = item.data; // Access the task data
        //     return (
        //       (ignoreUserID || filters.userID === "NULL" || task.userID === filters.userID) &&
        //       (filters.taskID === "NULL" || task.taskID === parseInt(filters.taskID)) &&
        //       (!startDate || new Date(task.dueDate) >= startDate) &&
        //       (!endDate || new Date(task.dueDate) <= endDate) &&
        //       (filters.search === "NULL" || task.title.toLowerCase().includes(filters.search.toLowerCase()) || task.description.toLowerCase().includes(filters.search.toLowerCase())) &&
        //       (filters.priorityID === "NULL" || task.priorityID === parseInt(filters.priorityID)) &&
        //       (filters.statusID === "NULL" || task.statusID === parseInt(filters.statusID)) &&
        //       (filters.locationID === "NULL" || task.locationID === parseInt(filters.locationID)) &&
        //       (filters.departmentID === "NULL" || task.departmentID === parseInt(filters.departmentID)) &&
        //       (filters.assignedBy === "NULL" || task.assignedBy === filters.assignedBy) &&
        //       (filters.createdBy === "NULL" || task.createdBy === filters.createdBy)
        //     );
        //   });
        // };



        // // Function to get filtered task view list
        // export const FilteredTaskViewList = async (TaskFilters, forceUpdate = false) => {
        //   const defaultFilters = {
        //     taskID: "NULL",
        //     startDate: "NULL",
        //     endDate: "NULL",
        //     search: "NULL",
        //     priorityID: "NULL",
        //     statusID: "NULL",
        //     locationID: "NULL",
        //     departmentID: "NULL",
        //     assignedBy: "NULL",
        //     createdBy: "NULL",
        //   };

        //   const filters = { ...defaultFilters, ...TaskFilters };

        //   if (!forceUpdate) {
        //     const storedData = getLocalStorageDataForFunction('FilteredTaskViewList'); // Fetch stored data once
        //     console.log('Stored data:', storedData);
        //     if (storedData.length > 0) {
        //       const filteredData = applyFilters(filters, storedData); // Pass stored data to applyFilters
        //       if (filteredData.length > 0) {
        //         return { status: "Success", message: "Cached data", results: filteredData };
        //       }
        //     }
        //   }

        //   // Fetch from API if no cached data or forceUpdate
        //   try {
        //     const response = await api_tasks.post('/api/Task/GetFilteredList', { ...filters });
        //     if (response.data && response.data.results) {
        //       setLocalStorageData(response.data.results, { isFilteredTaskViewList: true }); // Store with flag
        //       return response.data;
        //     } else {
        //       return { status: "Failed", message: "No results found", results: [] };
        //     }
        //   } catch (error) {
        //     return { status: "Failed", message: "Error fetching data", results: [] };
        //   }
        // };


        // // Function to get task by ID
        // export const TasksById = async (id) => {
        //   try {
        //     const response = await api_tasks.get(`/api/Task/${id}`);
        //     return response.data;
        //   } catch (error) {
        //     return { status: "Failed", message: error.message };
        //   }
        // };

        // // Function to insert task
        // export const InsertTasks = async (tasks) => {
        //   try {
        //     const response = await api_tasks.post('/api/Task/Insert', tasks);
        //     return response.data;
        //   } catch (error) {
        //     return { status: "Failed", message: error.message };
        //   }
        // };

        // // Function to insert task and return
        // export const InsertTaskAndReturn = async (tasks) => {
        //   try {
        //     const response = await api_tasks.post('/api/Task/InsertTaskAndReturn', tasks);
        //     insertTaskIntoLocalStorage(response.data.results); // Insert into local storage
        //     return response.data;
        //   } catch (error) {
        //     return { status: "Failed", message: error.message };
        //   }
        // };

        // // Function to update task
        // export const UpdateTasks = async (tasks) => {
        //   try {
        //     const response = await api_tasks.post('/api/Task/Update', tasks);
        //     return response.data;
        //   } catch (error) {
        //     return { status: "Failed", message: error.message };
        //   }
        // };

        // // Function to Create my task
        // export const CreateMyTask = async (tasks) => {
        //   try {
        //     const response = await api_tasks.post('/api/Task/CreateMyTask', tasks);
        //     insertTaskIntoLocalStorage(response.data.results); // Insert into local storage
        //     return response.data;
        //   } catch (error) {
        //     return { status: "Failed", message: error.message };
        //   }
        // };

        // // Function to save task
        // export const SaveTasks = async (tasks) => {
        //   try {
        //     const response = await api_tasks.post('/api/Task/Save', tasks);
        //     if (response.data && response.data.results) {
        //       updateTaskInLocalStorage(response.data.results); // Update local storage
        //     }
        //     return response.data;
        //   } catch (error) {
        //     return { status: "Failed", message: error.message };
        //   }
        // };

        // // Function to get task view list with local storage
        // export const TaskViewList = async (TaskFilters, forceUpdate = false) => {
        //   if (!forceUpdate) {
        //     const filteredData = applyFilters(TaskFilters);
        //     if (filteredData.length > 0) {
        //       return { status: "Success", message: "Cached data", results: filteredData };
        //     }
        //   }

        //   try {
        //     const response = await api_tasks.post('/api/Task/GetTaskListView');
        //     if (response.data && response.data.results) {
        //       setLocalStorageData(response.data.results); // Store all task data
        //       return response.data;
        //     } else {
        //       return { status: "Failed", message: "No results found", results: [] };
        //     }
        //   } catch (error) {
        //     return { status: "Failed", message: "Error fetching data", results: [] };
        //   }
        // };

        // // Function to get filtered campus task view list
        // export const FilteredCampusTaskViewList = async (TaskFilters, forceUpdate = false) => {
        //   const filters = { ...TaskFilters };

        //   if (!forceUpdate) {
        //     const storedData = getLocalStorageDataForFunction('FilteredCampusTaskViewList'); // Fetch stored data once
        //     if (storedData.length > 0) {
        //       const filteredData = applyFilters(filters, storedData); // Pass stored data to applyFilters
        //       if (filteredData.length > 0) {
        //         return { status: "Success", message: "Cached data", results: filteredData };
        //       }
        //     }
        //   }

        //   // Fetch from API if no cached data or forceUpdate
        //   try {
        //     const response = await api_tasks.post('/api/Task/GetFilteredList', { ...filters });
        //     if (response.data && response.data.results) {
        //       setLocalStorageData(response.data.results, { isFilteredCampusTaskViewList: true }); // Store with flag
        //       return response.data;
        //     } else {
        //       return { status: "Failed", message: "No results found", results: [] };
        //     }
        //   } catch (error) {
        //     return { status: "Failed", message: "Error fetching data", results: [] };
        //   }
        // };


        // // Function to get my tasks with local storage
        // export const GetMyTasks = async (TaskFilters, forceUpdate = false) => {
        //   const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
        //   const userID = userDetails.userID || "NULL";

        //   const defaultFilters = {
        //     userID: '' + userID, 
        //     startDate: "NULL",
        //     endDate: "NULL",
        //     search: "NULL",
        //     priorityID: "NULL",
        //     statusID: "NULL",
        //     locationID: "NULL",
        //     departmentID: "NULL"
        //   };
        //   const filters = { ...defaultFilters, ...TaskFilters };

        //   if (!forceUpdate) {
        //     const storedData = getLocalStorageDataForFunction('GetMyTasks'); // Fetch stored data once
        //     if (storedData.length > 0) {
        //       const filteredData = applyFilters(filters, storedData, true); // Ignore userID in filters
        //       if (filteredData.length > 0) {
        //         return { status: "Success", message: "Cached data", results: filteredData };
        //       }
        //     }
        //   }

        //   // Fetch from API if no cached data or forceUpdate
        //   try {
        //     const response = await api_tasks.post('/api/Task/GetMyTasks', { ...filters });
        //     if (response.data && response.data.results) {
        //       setLocalStorageData(response.data.results, { isMyTasks: true }); // Store with flag
        //       return response.data;
        //     } else {
        //       return { status: "Failed", message: "No results found", results: [] };
        //     }
        //   } catch (error) {
        //     return { status: "Failed", message: "Error fetching data", results: [] };
        //   }
        // };


        // // Function to get my created tasks with local storage
        // export const GetMyCreatedTasks = async (TaskFilters, forceUpdate = false) => {
        //   if (!forceUpdate) {
        //     const storedData = getLocalStorageDataForFunction('GetMyCreatedTasks'); // Fetch stored data once
        //     if (storedData.length > 0) {
        //       const filteredData = applyFilters(TaskFilters, storedData); // Pass stored data to applyFilters
        //       if (filteredData.length > 0) {
        //         return { status: "Success", message: "Cached data", results: filteredData };
        //       }
        //     }
        //   }

        //   // Fetch from API if no cached data or forceUpdate
        //   try {
        //     const response = await api_tasks.post('/api/Task/GetMyCreatedTasks');
        //     if (response.data && response.data.results) {
        //       setLocalStorageData(response.data.results, { isMyCreatedTasks: true }); // Store with flag
        //       return response.data;
        //     } else {
        //       return { status: "Failed", message: "No results found", results: [] };
        //     }
        //   } catch (error) {
        //     return { status: "Failed", message: "Error fetching data", results: [] };
        //   }
        // };



        // // Function to get assigned user with encrypted caching and expiration
        // export const GetAssignedUser = async (taskID, forceUpdate = false) => {
        //   const cacheKeyAssigned = `AssignedUser_${taskID}_Assigned`;
        //   const CACHE_EXPIRATION = 10 * 24 * 60 * 60 * 1000; // 10 Days expiration time

        //   // Check if the key is related to "AssignedUser_" only
        //   if (!cacheKeyAssigned.startsWith('AssignedUser_')) {
        //     return null; // Skip non-AssignedUser keys (just for safety, although GetAssignedUser should only handle assigned user keys)
        //   }

        //   // Check if encrypted data is available in cache
        //   const storedEncryptedData = localStorage.getItem(cacheKeyAssigned);

        //   if (!forceUpdate && storedEncryptedData) {
        //     try {
        //       // Parse and decrypt the stored data
        //       const decryptedData = decryptData(storedEncryptedData);
        //       const { data, timestamp } = decryptedData;

        //       // Ensure the decrypted data contains a valid timestamp
        //       if (!timestamp) {
        //         console.warn(`Cache for ${cacheKeyAssigned} does not have a timestamp.`);
        //         return data; // No timestamp, assume no expiration
        //       }

        //       const now = Date.now();

        //       // Check if the cache has expired
        //       if (now - timestamp > CACHE_EXPIRATION) {
        //         console.log(`Cache for ${cacheKeyAssigned} has expired and will be removed.`);
        //         localStorage.removeItem(cacheKeyAssigned); // Remove expired cache
        //       } else {
        //         // If the cache is still valid, return the cached data
        //         return data;
        //       }
        //     } catch (error) {
        //       console.error('Error decrypting data or checking expiration:', error);
        //       return null; // Return null without removing the cache in case of decryption error
        //     }
        //   }

        //   try {
        //     // Fetch fresh data from the API when forceUpdate or no valid stored data
        //     const response = await api_tasks.get('/api/Task/AssignedUser/' + taskID);

        //     // Prepare the data with a timestamp
        //     const dataToStore = {
        //       data: response.data,
        //       timestamp: Date.now() // Set the current time
        //     };

        //     // Encrypt and store the result in localStorage
        //     const encryptedData = encryptData(dataToStore);
        //     localStorage.setItem(cacheKeyAssigned, encryptedData);

        //     return response.data;
        //   } catch (error) {
        //     console.error('Error fetching assigned user:', error);
        //     return error;
        //   }
        // };

        // // Function to mark task as viewed
        // export const MarkViewView = async (taskModal) => {
        //   try {
        //     const response = await api_tasks.post('/api/Task/ViewTask', taskModal);
        //     if (response.data && response.data.results) {
        //       updateTaskInLocalStorage(response.data.results); // Update the task in localStorage
        //     }
        //     return response.data;
        //   } catch (error) {
        //     return { status: "Failed", message: error.message };
        //   }
        // };

import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

// In-memory cache object
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// Component for "/api/LeaveDocument/CustomList"
export const LeaveDocumentCustomList = async (query, forceUpdate = false) => {
    const cacheKey = `/api/LeaveDocument/CustomList?query=${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/LeaveDocument/CustomList', { params: { query } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/Get/{id}"
export const GetLeaveDocumentById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/LeaveDocument/Get/${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/LeaveDocument/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/List"
export const LeaveDocumentList = async (forceUpdate = false) => {
    const cacheKey = '/api/LeaveDocument/List';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/LeaveDocument/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/ListView/{leaveID}"
export const LeaveDocumentListView = async (leaveID, forceUpdate = false) => {
    const cacheKey = `/api/LeaveDocument/ListView/${leaveID}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get(`/api/LeaveDocument/ListView/${leaveID}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/PaginatedList"
export const PaginatedLeaveDocumentList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `/api/LeaveDocument/PaginatedList?page=${page}&pageSize=${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/LeaveDocument/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/CustomUpdate"
export const LeaveDocumentCustomUpdate = async (leaveDocument) => {
    try {
        const response = await api_hr.post('/api/LeaveDocument/CustomUpdate', leaveDocument);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/Insert"
export const InsertLeaveDocument = async (leaveDocument) => {
    try {
        const response = await api_hr.post('/api/LeaveDocument/Insert', leaveDocument);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/Save"
export const SaveLeaveDocument = async (leaveDocument) => {
    try {
        const response = await api_hr.post('/api/LeaveDocument/Save', leaveDocument);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/Update"
export const UpdateLeaveDocument = async (leaveDocument) => {
    try {
        const response = await api_hr.post('/api/LeaveDocument/Update', leaveDocument);
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/Delete"
export const DeleteLeaveDocument = async (leaveDocument) => {
    try {
        const response = await api_hr.post('/api/LeaveDocument/Delete', { data: leaveDocument });
        clearRelevantCache(); // Invalidate cache for related endpoints
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/LeaveDocument/GetValue"
export const GetLeaveDocumentValue = async (forceUpdate = false) => {
    const cacheKey = '/api/LeaveDocument/GetValue';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_hr.get('/api/LeaveDocument/GetValue');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Helper function to clear relevant caches after updates or deletions
const clearRelevantCache = () => {
    cache = {}; // Clear all caches or selectively clear relevant caches
};

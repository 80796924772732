import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Nav, Tab } from 'react-bootstrap';

import TaskCard from './TaskCard';
import TaskComments from './TaskComments';
import TaskFiles from './TaskFiles';

import Shimmer from '../../../../components/Custom/Loading/Shimmer';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { MarkViewView } from '../../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faFile } from '@fortawesome/free-solid-svg-icons';
import { TaskProgressStageList } from '../../../../../services/ccms/taskManagement/taskProgressStage';
import { UpdateProgressStage } from '../../../../../services/ccms/taskManagement/tasks/tasks_endpoints';

const MySwal = withReactContent(Swal);

const ViewTask = () => {
  const location = useLocation();
  const [taskModelView, setTaskModelView] = useState(location.state || {});
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stagesLoading, setStagesLoading] = useState(true);
  const [error, setError] = useState(null);

  // New state variable to track if MarkViewView has been called successfully
  const [hasMarkedView, setHasMarkedView] = useState(false);

  // Fetch task details
  useEffect(() => {
    const markView = async () => {
      try {
        const response = await MarkViewView(taskModelView);
        if (response.status === 'Success') {
          setTaskModelView(response.results);
          setHasMarkedView(true); // Mark as successfully executed
        } else {
          // setError('Failed to load task data');
          setTaskModelView(taskModelView);
          setHasMarkedView(true); // Mark as successfully executed
        }
      } catch (err) {
        setError('Failed to load task data', err);
      } finally {
        setLoading(false);
      }
    };

    // Only call markView if taskID exists and hasMarkedView is false
    if (taskModelView.taskID && !hasMarkedView) {
      markView();
    } else {
      setLoading(false);
    }
  }, [taskModelView.taskID, hasMarkedView]);

  // Fetch stages list
  useEffect(() => {
    const fetchStages = async () => {
      try {
        const stagesResponse = await TaskProgressStageList();
        if (stagesResponse.status === 'Success') {
          // Map the stages data to match expected property names
          const mappedStages = stagesResponse.results.map((stage) => ({
            ProgressStageID: stage.progressStageID,
            Name: stage.name,
            Description: stage.description,
            Color: stage.color,
            Icon: stage.icon, // Include if needed
            // Add other properties if required
          }));
          setStages(mappedStages);
        } else {
          setError('Failed to load stages data');
        }
      } catch (err) {
        setError('Failed to load stages data');
      } finally {
        setStagesLoading(false);
      }
    };

    fetchStages();
  }, []);

  // Handle stage change
  const handleStageChange = async (newStageID, taskID) => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to change the task stage?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'No, keep it',
    });

    if (result.isConfirmed) {
      try {
        const response = await UpdateProgressStage(taskID, newStageID);
        if (response.status === 'Success') {
          // Update taskModelView with new progress stage
          setTaskModelView((prev) => ({
            ...prev,
            progressStageID: newStageID,
          }));
          toast.success('Task stage updated successfully!');
        } else {
          setError('Failed to update task progress stage');
          toast.error('Failed to update task progress stage');
        }
      } catch (err) {
        setError('Failed to update task progress stage');
        toast.error('Failed to update task progress stage');
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      toast.info('Task stage change canceled');
    }
  };

  return (
    <Fragment>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading || stagesLoading ? (
        <Shimmer type="page" />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <TaskCard task={taskModelView} />
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1"></div>
                <Tab.Container defaultActiveKey="comments">
                  <Nav variant="pills" className="nav-pills mb-4 light">
                    <Nav.Item>
                      <Nav.Link eventKey="comments">
                        <FontAwesomeIcon icon={faComment} size="sm" /> Comments
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="files">
                        <FontAwesomeIcon icon={faFile} size="sm" /> Files
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="comments">
                      <Row>
                        <Col>
                          <TaskComments
                            taskID={taskModelView.taskID}
                            currentStage={taskModelView.progressStageID}
                            stages={stages}
                            onStageChange={handleStageChange}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="files">
                      <Row>
                        <Col>
                          <TaskFiles taskID={taskModelView.taskID} />
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default ViewTask;

import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";

const StepSix = ({
  locations,
  taskDetails,
  setTaskDetails,
  handleSubmit,
  isSubmitting,
  currentStep,
  handlePreviousStep,
}) => {
  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaskDetails(name, value);
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
      >
        <div className="card-body">
          <h4>Finalize Task Details</h4>
          <div className="form-group mb-4">
            <label>Please choose the campus</label>
            <select
              className="form-control"
              name="locationID"
              value={taskDetails.locationID || ''}
              onChange={handleChange}
              onInput={handleChange}
            >
              <option value="">Please choose a location</option>
              {locations.map(location => (
                <option key={location.locationID} value={location.locationID}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mb-4">
            <label>Enter details for the assignee</label>
            <textarea
              className="form-control"
              name="description"
              rows={10}
              value={taskDetails.description || ''}
              onChange={handleChange}
              onInput={handleChange}
            ></textarea>
          </div>
          <div className="mt-3">
            <button className="btn btn-secondary btn-sm" onClick={() => handlePreviousStep(5)}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button
              className="btn btn-success btn-sm ml-2"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : <><FontAwesomeIcon icon={faSave} /> Submit</>}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default StepSix;

// import React, { useState, useEffect } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import DatePicker from 'react-datepicker';
// import Swal from 'sweetalert2';
// import { format } from 'date-fns';
// import 'react-datepicker/dist/react-datepicker.css';
// import { LocationList } from "../../../../../services/ccms/locationManagement/location/location_endpoints";
// import { DepartmentList } from "../../../../../services/ccms/departmentManagement/department/department_endpoints";
// import { TaskPriorityList } from "../../../../../services/ccms/taskManagement/taskPriority/task_priority_endpoints";
// import { CreateMyTask } from "../../../../../services/ccms/taskManagement/tasks/tasks_endpoints";
// import { addTaskToQueue } from '../../../../../services/ccms/taskQueue';

// const TaskModal = ({ show, onClose, initialData = {}, onTaskCreated }) => {
//   const [taskData, setTaskData] = useState({
//     title: initialData.title || '',
//     dueDate: initialData.dueDate || new Date(),
//     description: initialData.description || '',
//     locationID: initialData.locationID || '',
//     departmentID: initialData.departmentID || '',
//     statusID: initialData.statusID || 0, // Default to 0 for new tasks
//     priorityID: initialData.priorityID || '',
//     createdBy: initialData.createdBy || '',
//   });
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [locations, setLocations] = useState([]);
//   const [departments, setDepartments] = useState([]);
//   const [priorities, setPriorities] = useState([]);

//   useEffect(() => {
//     // Fetch locations, departments, and priorities
//     const fetchLocations = async () => {
//       const response = await LocationList();
//       setLocations(response.results || []);
//     };

//     const fetchDepartments = async () => {
//       const response = await DepartmentList();
//       setDepartments(response.results || []);
//     };

//     const fetchPriorities = async () => {
//       const response = await TaskPriorityList();
//       setPriorities(response.results || []);
//     };

//       fetchLocations();
//       fetchDepartments();
//       fetchPriorities();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setTaskData({ ...taskData, [name]: value });
//   };

//   const handleDateChange = (date) => {
//     setTaskData({ ...taskData, dueDate: date });
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     try {
//       const UserDetails = JSON.parse(localStorage.getItem('userDetails'));
//       const data = {
//           ...taskData,
//           dueDate: format(taskData.dueDate, "yyyy-MM-dd'T'HH:mm:ss"),
//           createdBy: UserDetails ? UserDetails.userId : '',
//       };

//       const response = await CreateMyTask(data);
//       console.log('CreateMyTask response:', response);
//       if (response.status === "Success") {
//           Swal.fire('Success', 'Task created successfully', 'success');
//           if (onTaskCreated) {
//               onTaskCreated(); // Call the callback function to refresh data
//           }
//           onClose();
//       } else {
//           Swal.fire('Error', response.message || 'An error occurred while saving the task', 'error');
//       }
//   } catch (error) {
//       Swal.fire('Error', 'An error occurred while saving the task', 'error');
//   } finally {
//       setIsSubmitting(false);
//   }
// };

//   return (
//     <Modal show={show} onHide={onClose}>
//       <Form onSubmit={handleFormSubmit}>
//         <Modal.Header closeButton>
//           <Modal.Title>Create My Task</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form.Group className='mb-3'>
//             <Form.Label>Title</Form.Label>
//             <Form.Control
//               type="text"
//               size='sm'
//               name="title"
//               value={taskData.title}
//               onChange={handleInputChange}
//               required
//             />
//           </Form.Group>
//           <Form.Group className='mb-3'>
//             <Form.Label>Due Date</Form.Label> <br></br>
//             <DatePicker
//               selected={taskData.dueDate}
//               onChange={handleDateChange}
//               className="form-control"
//               showTimeSelect
//               dateFormat="yyyy-MM-dd HH:mm"
//               required
//             />
//           </Form.Group>
//           <Form.Group className='mb-3'>
//             <Form.Label>Priority</Form.Label>
//             <Form.Control
//               as="select"
//               name="priorityID"
//               size='sm'
//               value={taskData.priorityID}
//               onChange={handleInputChange}
//               required
//             >
//               <option value="">Select Priority</option>
//               {priorities.map((priority) => (
//                 <option key={priority.priorityID} value={priority.priorityID}>
//                   {priority.name}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group>
//           <Form.Group className='mb-3'>
//             <Form.Label>Location</Form.Label>
//             <Form.Control
//               as="select"
//               name="locationID"
//               size='sm'
//               value={taskData.locationID}
//               onChange={handleInputChange}
//               required
//             >
//               <option value="">Select Location</option>
//               {locations.map((location) => (
//                 <option key={location.locationID} value={location.locationID}>
//                   {location.name}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group>
//           <Form.Group className='mb-3'>
//             <Form.Label>Department</Form.Label>
//             <Form.Control
//               as="select"
//               name="departmentID"
//               size='sm'
//               value={taskData.departmentID}
//               onChange={handleInputChange}
//               required
//             >
//               <option value="">Select Department</option>
//               {departments.map((department) => (
//                 <option key={department.departmentID} value={department.departmentID}>
//                   {department.name}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group>
//           <Form.Group className='mb-3'>
//             <Form.Label>Description</Form.Label>
//             <Form.Control
//               as="textarea"
//               name="description"
//               value={taskData.description}
//               onChange={handleInputChange}
//               rows="4"
//               required
//             />
//           </Form.Group>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" size='sm' onClick={onClose}>
//             Cancel
//           </Button>
//           <Button type="submit" variant="primary" size='sm' disabled={isSubmitting}>
//             {isSubmitting ? 'Saving...' : 'Save Task'}
//           </Button>
//         </Modal.Footer>
//       </Form>
//     </Modal>
//   );
// };

// export default TaskModal;

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import MultiFileUploader from '../../../../components/Custom/Uploader/MultiFileUploader'; // Adjust import if needed
import { LocationList } from '../../../../../services/ccms/locationManagement/location/location_endpoints';
import { DepartmentList } from '../../../../../services/ccms/departmentManagement/department/department_endpoints';
import { TaskPriorityList } from '../../../../../services/ccms/taskManagement/taskPriority/task_priority_endpoints';
import { CreateMyTask } from '../../../../../services/ccms/taskManagement/tasks/tasks_endpoints';

import {numericDatetime} from '../../../../../utilities/DateTimeManipulator';

const TaskModal = ({ show, onClose, initialData = {}, onTaskCreated }) => {
  const [taskData, setTaskData] = useState({
    title: initialData.title || '',
    dueDate: initialData.dueDate || new Date(),
    description: initialData.description || '',
    locationID: initialData.locationID || '',
    departmentID: initialData.departmentID || '',
    statusID: initialData.statusID || 0, // Default to 0 for new tasks
    priorityID: initialData.priorityID || '',
    createdBy: initialData.createdBy || '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // Fetch locations, departments, and priorities
  useEffect(() => {
    const fetchLocations = async () => {
      const response = await LocationList();
      setLocations(response.results || []);
    };

    const fetchDepartments = async () => {
      const response = await DepartmentList();
      setDepartments(response.results || []);
    };

    const fetchPriorities = async () => {
      const response = await TaskPriorityList();
      setPriorities(response.results || []);
    };

    fetchLocations();
    fetchDepartments();
    fetchPriorities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData({ ...taskData, [name]: value });
  };

  const handleDateChange = (date) => {
    setTaskData({ ...taskData, dueDate: date });
  };

  const handleFilesChange = (name, files) => {
    console.log('Uploaded files:', files); // Log the file list
    setUploadedFiles(files);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const UserDetails = JSON.parse(localStorage.getItem('userDetails'));
      const data = {
        ...taskData,
        dueDate: format(taskData.dueDate, "yyyy-MM-dd'T'HH:mm:ss"),
        createdBy: UserDetails ? UserDetails.userId : '',
        attachments: uploadedFiles.map((file) => ({
          attachmentID: 0, // Set to 0 for new attachments
          taskID: 0, // Set to 0 for new attachments
          fileName: file.name,
          fileType: file.type,
          fileURL: file.url,
          attachmentDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
          createdBy: UserDetails ? UserDetails.userId : '',
          modifiedDateTime: numericDatetime(new Date()), // Example: 20241024153045
          createdDateTime: numericDatetime(new Date()), // Example: 20241024153045
        })),
      };

      const response = await CreateMyTask(data);
      console.log('CreateMyTask response:', response);

      if (response.status === 'Success') {
        Swal.fire('Success', 'Task created successfully', 'success');
        if (onTaskCreated) {
          onTaskCreated(); // Refresh parent data
        }
        onClose();
      } else {
        Swal.fire('Error', response.message || 'An error occurred while saving the task', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while saving the task', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create My Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              name="title"
              value={taskData.title}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Due Date</Form.Label>
            <DatePicker
              selected={taskData.dueDate}
              onChange={handleDateChange}
              className="form-control"
              showTimeSelect
              dateFormat="yyyy-MM-dd HH:mm"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Priority</Form.Label>
            <Form.Control
              as="select"
              name="priorityID"
              size="sm"
              value={taskData.priorityID}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Priority</option>
              {priorities.map((priority) => (
                <option key={priority.priorityID} value={priority.priorityID}>
                  {priority.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              as="select"
              name="locationID"
              size="sm"
              value={taskData.locationID}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Location</option>
              {locations.map((location) => (
                <option key={location.locationID} value={location.locationID}>
                  {location.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Department</Form.Label>
            <Form.Control
              as="select"
              name="departmentID"
              size="sm"
              value={taskData.departmentID}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Department</option>
              {departments.map((department) => (
                <option key={department.departmentID} value={department.departmentID}>
                  {department.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={taskData.description}
              onChange={handleInputChange}
              rows="4"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Attachments</Form.Label>
            <MultiFileUploader
              userId="" // Example user ID
              storageLocation="TaskFiles"
              onSuccess={handleFilesChange} // Receive file list updates
              holderText={numericDatetime(new Date())}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" size="sm" disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Save Task'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TaskModal;

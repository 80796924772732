import { getApiHr } from '../../APIServiceSetup';

const api_hr = await getApiHr();
const CACHE_KEY = 'employeeTypeCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in localStorage
const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

// Helper function to get data from cache
const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Component for "/api/EmployeeType/CustomList"
export const EmployeeTypeCustomList = async (query, forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeType/CustomList', { params: { query } });
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/CustomUpdate"
export const EmployeeTypeCustomUpdate = async (employeeType) => {
    try {
        const response = await api_hr.post('/api/EmployeeType/CustomUpdate', employeeType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/Delete"
export const DeleteEmployeeType = async (employeeType) => {
    try {
        const response = await api_hr.delete('/api/EmployeeType/Delete', { data: employeeType });
        localStorage.removeItem(CACHE_KEY); // Clear cache on delete
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/Get/{id}"
export const GetEmployeeTypeById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get(`/api/EmployeeType/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/GetValue"
export const GetEmployeeTypeValue = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeType/GetValue');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/Insert"
export const InsertEmployeeType = async (employeeType) => {
    try {
        const response = await api_hr.post('/api/EmployeeType/Insert', employeeType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on insert
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/List"
export const EmployeeTypeList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_hr.get('/api/EmployeeType/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/PaginatedList"
export const PaginatedEmployeeTypeList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_hr.get('/api/EmployeeType/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/Save"
export const SaveEmployeeType = async (employeeType) => {
    try {
        const response = await api_hr.post('/api/EmployeeType/Save', employeeType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on save
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/EmployeeType/Update"
export const UpdateEmployeeType = async (employeeType) => {
    try {
        const response = await api_hr.post('/api/EmployeeType/Update', employeeType);
        localStorage.removeItem(CACHE_KEY); // Clear cache on update
        return response.data;
    } catch (error) {
        return error;
    }
};

import { getApiSystem } from '../../APIServiceSetup';

const api_system = await getApiSystem();
const CACHE_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
let cache = {};

// Helper function to check if cache is valid
const isCacheValid = (cacheKey) => {
    const cachedData = cache[cacheKey];
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

// Helper function to set data in cache
const setCache = (cacheKey, data) => {
    cache[cacheKey] = {
        data,
        timestamp: new Date().getTime(),
    };
};

// FeedbackCategoryRecipient Endpoints

// Component for "/api/FeedbackCategoryRecipient/List"
export const FeedbackCategoryRecipientList = async (forceUpdate = false) => {
    const cacheKey = '/api/FeedbackCategoryRecipient/List';
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get('/api/FeedbackCategoryRecipient/List');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/FeedbackCategoryRecipient/Insert"
export const FeedbackCategoryRecipientInsert = async (recipient) => {
    try {
        const response = await api_system.post('/api/FeedbackCategoryRecipient/Save', recipient);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/FeedbackCategoryRecipient/Update"
export const FeedbackCategoryRecipientUpdate = async (recipient) => {
    try {
        const response = await api_system.post('/api/FeedbackCategoryRecipient/Update', recipient);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/FeedbackCategoryRecipient/Save"
export const FeedbackCategoryRecipientSave = async (recipient) => {
    try {
        const response = await api_system.post('/api/FeedbackCategoryRecipient/Save', recipient);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/FeedbackCategoryRecipient/Get/{id}"
export const FeedbackCategoryRecipientGetById = async (id, forceUpdate = false) => {
    const cacheKey = `/api/FeedbackCategoryRecipient/Get/${id}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/FeedbackCategoryRecipient/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/FeedbackCategoryRecipient/ListView"
export const FeedbackCategoryRecipientListView = async (forceUpdate = false) => {
    const cacheKey = '/api/FeedbackCategoryRecipient/ListView';
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get('/api/FeedbackCategoryRecipient/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/FeedbackCategoryRecipient/ListView/{userId}"
export const FeedbackCategoryRecipientListViewByUser = async (userId, forceUpdate = false) => {
    const cacheKey = `/api/FeedbackCategoryRecipient/ListView/${userId}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/FeedbackCategoryRecipient/ListView/${userId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// Component for "/api/FeedbackCategoryRecipient/ListViewByCategoryID/{categoryID}"
export const FeedbackCategoryRecipientListViewByCategory = async (categoryID, forceUpdate = false) => {
    const cacheKey = `/api/FeedbackCategoryRecipient/ListViewByCategoryID/${categoryID}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return cache[cacheKey].data;
    }

    try {
        const response = await api_system.get(`/api/FeedbackCategoryRecipient/ListViewByCategoryID/${categoryID}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return error;
    }
};

// component for "/api/FeedbackCategoryRecipient/Delete"
export const FeedbackCategoryRecipientDelete = async (data) => {
    try {
        const response = await api_system.post('/api/FeedbackCategoryRecipient/Delete', data);
        clearRelevantCache();
        return response.data;
    } catch (error) {
        return error;
    }
};

// Clear the relevant cache (you can selectively clear specific keys if needed)
const clearRelevantCache = () => {
    cache = {}; // Clear all caches
};

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import OverallStatusCounts from './OverallStatusCounts';
import MonitoringTabs from './MonitoringTabs'; 
import { UserRightsContext } from '../../../../context/UserRightsContext';
import { TaskStatusList } from '../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import { FilteredTaskViewList, GetAssignedUser } from '../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import Shimmer from '../../../components/Custom/Loading/Shimmer';

const Monitoring = () => {
  const [statusList, setStatusList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [members, setMembers] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const userRights = useContext(UserRightsContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoaded(false);
      try {
        const [statuses, tasks] = await Promise.all([fetchStatusData(), fetchTasks()]);
        setStatusList(statuses);
        setTaskList(tasks.updatedTasks);
        setMembers(tasks.memberData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchInitialData();
  }, []);

  const fetchStatusData = async () => {
    const response = await TaskStatusList();
    return response.results;
  };

  const fetchTasks = async () => {
    const taskFilters = {
      taskID: "NULL",
      startDate: 'NULL',
      endDate: 'NULL',
      search: "NULL",
      priorityID: "NULL",
      statusID: 'NULL',
      locationID: "NULL",
      departmentID: 'NULL',
      assignedBy: "NULL",
      createdBy: "NULL"
    };
    const taskData = await FilteredTaskViewList(taskFilters, false);

    // Fetch all assigned users in parallel
    const userPromises = taskData.results.map(task =>
      GetAssignedUser(task.taskID).catch(() => ({ results: [] })) // Handle failed requests gracefully
    );
    const users = await Promise.all(userPromises);

    // Map tasks with assigned users
    const updatedTasks = taskData.results.map((task, index) => ({
      ...task,
      TaskAssignedUsers: users[index]?.results || []
    }));

    // Collect and process all assigned users
    const allAssignedUsers = updatedTasks.flatMap(task =>
      task.TaskAssignedUsers.map(user => ({
        userId: user.userId,
        firstName: user.firstName,
        surname: user.surname,
        avatar: user.avatar,
        taskID: task.taskID,
        dueDate: task.dueDate
      }))
    );

    const memberData = createDistinctUsers(allAssignedUsers);
    return { updatedTasks, memberData };
  };

  const createDistinctUsers = (users) => {
    const distinctUsersMap = {};

    users.forEach(user => {
      if (!distinctUsersMap[user.userId]) {
        distinctUsersMap[user.userId] = {
          userId: user.userId,
          firstName: user.firstName,
          surname: user.surname,
          avatar: user.avatar,
          totalTasks: 0,
          totalDue: 0
        };
      }
      distinctUsersMap[user.userId].totalTasks += 1;

      if (new Date(user.dueDate) < new Date()) {
        distinctUsersMap[user.userId].totalDue += 1;
      }
    });

    return Object.values(distinctUsersMap);
  };

  return (
    <div>
      {isLoaded ? (
        <>
          <OverallStatusCounts tasks={taskList} statusList={statusList} />
          <MonitoringTabs tasks={taskList} members={members} />
        </>
      ) : (
        <Shimmer type="page" />
      )}
    </div>
  );
};

export default Monitoring;

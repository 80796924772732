// StepDisclaimer.js
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, Alert, ListGroup, Badge } from 'react-bootstrap';
import { GetUserTaskCount } from '../../../../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import LoadingText from '../../../../../../components/Custom/Loading/LoadingText';

const StepDisclaimer = ({
  handleNextStep,
  leaveApplication,
  currentStep,
  handlePendingTasksCount,
}) => {
  const stepRef = useRef(null);
  const [tasks, setTasks] = useState(0);
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    fetchCurrentTasks();
  }, []);

  const fetchCurrentTasks = async () => {
    setLoading(true);
    try {
     var userId = localStorage.getItem('userID');
      const response = await GetUserTaskCount(userId);
      if (response.status === 'Success') {
        setTasks(response.results);
        handlePendingTasksCount(response.results.length);
      } else {
        setTasks(0);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setTasks(0);
    }
    setLoading(false);
  };

  const handleAgreementChange = (e) => {
    setAgreed(e.target.checked);
  };

  const handleProceed = () => {
    if (agreed) handleNextStep();
  };

  return (
    <div className="chat-message" ref={stepRef}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="card shadow mb-4 mx-auto position-relative"
        style={{ maxWidth: '800px' }}
      >
        <div className="card-body">
          <h4 className="mb-4 text-center">Important Information Before You Proceed</h4>
          <Alert variant="danger">
            <p className="mb-0 text-center">
              Please read the following carefully before applying for leave:
            </p>
          </Alert>
          <ListGroup variant="flush" className="mb-4">
            {/* <ListGroup.Item>
              <strong>Note:</strong> Submitting a leave application does <u>not</u> guarantee approval.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>HR Review:</strong> HR will assess your eligibility based on factors such as available leave days, ongoing major events, outstanding tasks, resource availability, and more. Please refer to the <a href="/policies/leave-policy" target="_blank">Leave Policy document</a> for details.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Pending Tasks:</strong> You currently have <strong>{tasks.length}</strong> task(s) assigned to you. 
              Ensure that all tasks are <u>completed</u> or <u>reassigned to another team member</u> before your leave starts.
            </ListGroup.Item> */}

            <ListGroup.Item>
    <strong>Impact on Team Workflow:</strong> Your absence may affect ongoing projects and deadlines. Ensure your duties are properly assigned and communicated.
  </ListGroup.Item>
  <ListGroup.Item>
    <strong>Leave Eligibility Criteria:</strong> Your eligibility will be evaluated based on leave balance, attendance, and operational priorities.
  </ListGroup.Item>
  <ListGroup.Item>
    <strong>Submission of Leave Documents:</strong> Ensure relevant documents are uploaded with your application to avoid delays.
  </ListGroup.Item>
  <ListGroup.Item>
    <strong>Pending Tasks:</strong> You have {loading ? <LoadingText text='Counting' isLoading={loading} /> : tasks} task(s) assigned to you. Ensure they are completed or reassigned before your leave.
  </ListGroup.Item>
  <ListGroup.Item>
    <strong>Approval Timeline:</strong> Apply for leave well in advance to allow sufficient time for review.
  </ListGroup.Item>
  <ListGroup.Item>
    <strong>Return from Leave:</strong> Notify HR and your manager upon return and address any pending tasks immediately.
  </ListGroup.Item>
  <ListGroup.Item>
    <strong>Compliance with Company Policies:</strong> All leave applications must adhere to company policies. Unauthorized leave will be treated as absence without permission.
  </ListGroup.Item>
          </ListGroup>
          <Form.Group controlId="formAgreement" className="mb-4">
            <Form.Check
              type="checkbox"
              label="I have read and understood the above information."
              checked={agreed}
              onChange={handleAgreementChange}
            />
          </Form.Group>
          <div className="text-center">
            <Button
              variant="primary"
              onClick={handleProceed}
              disabled={!agreed}
            >
              Proceed <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default StepDisclaimer;

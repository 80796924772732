import React, { useState, useEffect } from "react";
import { Button, Modal, Form, OverlayTrigger, Popover } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FeedbackInsert } from '../../../../../services/ccms/system/feedback';
import { FeedbackCategoryList } from '../../../../../services/ccms/system/feedbackCategory';
import { FeedbackTypeList } from '../../../../../services/ccms/system/feedbackType';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faPaperPlane, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const MySwal = withReactContent(Swal);

const FeedbackModal = ({ show, handleClose }) => {
  // State
  const [feedback, setFeedback] = useState({
    UserId: '',
    TypeID: '',
    CategoryID: '',
    Title: '',
    Message: '',
    Attachment: '',
    IsResolved: false,
    IsAnonymous: false,
    Created: new Date().toISOString(),
    App: 'Task Management',
  });

  const [typeList, setTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isSaving, setIsSaving] = useState(false); // For disabling save button

  // Load types and categories on component mount
  useEffect(() => {
    async function loadData() {
      try {
        const [types, categories] = await Promise.all([
          FeedbackTypeList(),
          FeedbackCategoryList()
        ]);
        setTypeList(types.results || []);  // Default to empty array if response is null
        setCategoryList(categories.results || []);  // Default to empty array if response is null
      } catch (error) {
        // Handle error silently for type or category fetch
        setTypeList([]);
        setCategoryList([]);
      }
    }

    loadData();

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      setFeedback({ ...feedback, UserId: userDetails.userId });
    }
  }, []);

  // Handle form change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFeedback((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true); // Disable save button

    try {
      const response = await FeedbackInsert(feedback);
      if (response.status === "Success") {
        MySwal.fire({
          title: "Feedback Saved",
          text: "Your feedback has been successfully saved!",
          icon: "success",
        });
        handleClose(); // Close the modal
      }
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "There was an error saving your feedback.",
        icon: "error",
      });
    } finally {
      setIsSaving(false); // Re-enable save button
    }
  };

  // Popover for anonymous info
  const anonymousPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Anonymous Submission</Popover.Header>
      <Popover.Body>
        Your personal info will not be shared with anyone when you submit anonymously.
      </Popover.Body>
    </Popover>
  );

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Submit Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Type Dropdown */}
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Select
              name="TypeID"
              value={feedback.typeID}
              onChange={handleChange}
              required
            >
              <option value="">Select a type...</option>
              {typeList.length > 0 ? (
                typeList.map((type) => (
                  <option key={type.typeID} value={type.typeID}>
                    {type.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>No types available</option>
              )}
            </Form.Select>
          </Form.Group>

          {/* Category Dropdown */}
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Select
              name="CategoryID"
              value={feedback.categoryID}
              onChange={handleChange}
              required
            >
              <option value="">Select a category...</option>
              {categoryList.length > 0 ? (
                categoryList.map((category) => (
                  <option key={category.categoryID} value={category.categoryID}>
                    {category.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>No categories available</option>
              )}
            </Form.Select>
          </Form.Group>

          {/* Title Input */}
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter feedback title"
              name="Title"
              value={feedback.Title}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {/* Message Textarea */}
          <Form.Group className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={5} // Makes the textarea bigger
              placeholder="Enter feedback message"
              name="Message"
              value={feedback.Message}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {/* Submit Anonymously Switch with Popover */}
          <Form.Group className="mb-3 d-flex align-items-center">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Submit anonymously"
              name="IsAnonymous"
              checked={feedback.IsAnonymous}
              onChange={handleChange}
            />
            <OverlayTrigger trigger="click" placement="right" overlay={anonymousPopover}>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="ms-2"
                size="lg"
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </Form.Group>

          {/* Save and Close Buttons */}
          <div className="d-flex justify-content-between">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSaving}>
              {isSaving ? "Saving..." : (
                <>
                  <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                  Send Feedback
                </>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { ProgressBar, Modal, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faImage, faVideo, faFileAlt, faFileAudio } from '@fortawesome/free-solid-svg-icons';
import {
    initiateMultipartUpload,
    uploadPartWithRetry,
    completeMultipartUpload,
    abortMultipartUpload
} from '../../../../services/ccms/fileManagement/file'; // Adjust the import path accordingly

const FileUploader = ({
  userId = null,
  onSuccess,
  onStart,
  name,
  initialFileURL = '',
  storageLocation = 'EmployeeFiles', // Default storage location
  holderText = '',
}) => {
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(initialFileURL);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploadId, setUploadId] = useState('');
  const [uploadedParts, setUploadedParts] = useState([]);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    setFile(null);
    setFileURL(initialFileURL);
    setProgress(0);
    setUploading(false);
    setShowModal(false);
    setUploadId('');
    setUploadedParts([]);
  }, [initialFileURL]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setFileURL(''); // Reset fileURL as a new file is being uploaded

      if (onStart) onStart();

      setUploading(true);

      try {
        // Step 1: Initiate Multipart Upload
        const newUploadId = await initiateMultipartUpload(selectedFile.name, userId, storageLocation, '' + holderText);

        if (!newUploadId) {
          throw new Error('Failed to obtain UploadId from the server.');
        }

        setUploadId(newUploadId);

        // Step 2: Split the file into chunks
        const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk
        const totalParts = Math.ceil(selectedFile.size / CHUNK_SIZE);
        const parts = [];

        // Step 3: Upload each part sequentially
        for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
          const start = (partNumber - 1) * CHUNK_SIZE;
          const end = partNumber * CHUNK_SIZE;
          const chunk = selectedFile.slice(start, end);

          // Upload the part with retry
          const ETag = await uploadPartWithRetry(newUploadId, partNumber, chunk, 3);

          parts.push({ PartNumber: partNumber, ETag });

          // Update the uploaded parts
          setUploadedParts([...parts]);

          // Update overall progress
          setProgress(Math.round((parts.length / totalParts) * 100));
        }

        // Step 4: Complete Multipart Upload
        const uploadedFileURL = await completeMultipartUpload(newUploadId, parts);
        setFileURL(uploadedFileURL);
        setProgress(100);
        setUploading(false);
        setUploadId('');
        setUploadedParts([]);

        if (onSuccess) onSuccess(name, uploadedFileURL);
      } catch (error) {
        setUploading(false);
        setProgress(0);

        // Step 5: Abort Multipart Upload in case of failure
        if (uploadId) {
          try {
            await abortMultipartUpload(uploadId);
          } catch (abortError) {
            console.error('Error aborting upload:', abortError);
          }
        }

        alert('File upload failed!');
      }
    },
    [uploadId, userId, onSuccess, onStart, name, storageLocation]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: '*/*', // Allow all file types
  });

  const handlePreviewClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Function to determine MIME type
  const getMimeType = () => {
    let extension = '';
    if (file && file.type) {
      return file.type;
    } else if (fileURL) {
      extension = fileURL.split('.').pop().toLowerCase();
    }

    const mimeTypes = {
      pdf: 'application/pdf',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ppt: 'application/vnd.ms-powerpoint',
      pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      txt: 'text/plain',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      bmp: 'image/bmp',
      mp4: 'video/mp4',
      webm: 'video/webm',
      ogg: 'video/ogg',
      mov: 'video/quicktime',
      mp3: 'audio/mpeg',
      wav: 'audio/wav',
      // Add more as needed
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  const renderFilePreview = () => {
    if (!fileURL) return null;

    const mimeType = getMimeType();

    // Office Documents
    if (
      [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ].includes(mimeType)
    ) {
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        fileURL
      )}`;
      return (
        <div onClick={handlePreviewClick} style={{ cursor: 'pointer' }}>
          <iframe
            src={officeViewerUrl}
            style={{ width: '100%', height: '200px', border: 'none' }}
            onError={() => setLoadError(true)}
            title="Office Document Preview"
          ></iframe>
        </div>
      );
    }

    // PDFs
    if (mimeType === 'application/pdf' || mimeType === 'pdf') {
      return (
        <div onClick={handlePreviewClick} style={{ cursor: 'pointer' }}>
          <embed
            src={fileURL}
            type="application/pdf"
            width="100%"
            height="200px"
          />
        </div>
      );
    }

    // Images
    if (mimeType.startsWith('image/')) {
      return (
        <img
          src={fileURL}
          alt="File Preview"
          style={{ maxHeight: '200px', width: 'auto', cursor: 'pointer' }}
          onClick={handlePreviewClick}
        />
      );
    }

    // Video Files
    if (mimeType.startsWith('video/')) {
      return (
        <video
          controls
          style={{ maxHeight: '200px', width: 'auto', cursor: 'pointer' }}
          src={fileURL}
          onClick={handlePreviewClick}
        />
      );
    }

    // Audio Files
    if (mimeType.startsWith('audio/')) {
      return (
        <audio
          controls
          style={{ width: '100%', cursor: 'pointer' }}
          src={fileURL}
        />
      );
    }

    // Text Files
    if (mimeType === 'text/plain') {
      return (
        <div onClick={handlePreviewClick} style={{ cursor: 'pointer' }}>
          <iframe
            src={fileURL}
            style={{ width: '100%', height: '200px', border: 'none' }}
            title="Text File Preview"
          ></iframe>
        </div>
      );
    }

    // Unsupported file type
    return (
      <div>
        <FontAwesomeIcon icon={faFilePdf} size="3x" />
        <span>Unsupported File Type</span>
      </div>
    );
  };

  // Render content inside the modal
  const renderModalContent = () => {
    const mimeType = getMimeType();

    // Office Documents
    if (
      [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ].includes(mimeType)
    ) {
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        fileURL
      )}`;
      return (
        <iframe
          src={officeViewerUrl}
          style={{ width: '100%', height: '500px', border: 'none' }}
          onError={() => setLoadError(true)}
          title="Office Document Preview"
        ></iframe>
      );
    }

    // PDFs
    if (mimeType === 'application/pdf' || mimeType === 'pdf') {
      return (
        <embed
          src={fileURL}
          type="application/pdf"
          width="100%"
          height="500px"
        />
      );
    }

    // Images
    if (mimeType.startsWith('image/')) {
      return (
        <img
          src={fileURL}
          alt="File Preview"
          style={{ width: '100%' }}
        />
      );
    }

    // Video Files
    if (mimeType.startsWith('video/')) {
      return (
        <video
          controls
          style={{ width: '100%' }}
          src={fileURL}
        />
      );
    }

    // Audio Files
    if (mimeType.startsWith('audio/')) {
      return (
        <audio
          controls
          style={{ width: '100%' }}
          src={fileURL}
        />
      );
    }

    // Text Files
    if (mimeType === 'text/plain') {
      return (
        <iframe
          src={fileURL}
          style={{ width: '100%', height: '500px', border: 'none' }}
          title="Text File Preview"
        ></iframe>
      );
    }

    // Unsupported file type
    return <p>Cannot preview this file type</p>;
  };

  return (
    <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #cccccc', padding: '20px', borderRadius: '5px', textAlign: 'center' }}>
      <input {...getInputProps()} />
      {uploading ? (
        <div>
          <Spinner animation="border" />
          <div style={{ marginTop: '10px' }}>Uploading...</div>
        </div>
      ) : (
        <div>
          {fileURL ? (
            <>
              <div>{renderFilePreview()}</div>
              <div>
                <Button variant="link" onClick={() => setFile(null)}>
                  Change File
                </Button>
              </div>
            </>
          ) : (
            <div>
              Drag and drop a file here, or{' '}
              <span className='badge bg-primary' style={{ cursor: 'pointer' }}>
                click to select one
              </span>
            </div>
          )}
        </div>
      )}
      {fileURL && (
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {renderModalContent()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {uploading && <ProgressBar now={progress} label={`${progress}%`} style={{ marginTop: '10px', height:'2em' }} />}
    </div>
  );
};

FileUploader.propTypes = {
  userId: PropTypes.string,
  onSuccess: PropTypes.func,
  onStart: PropTypes.func,
  name: PropTypes.string.isRequired,
  initialFileURL: PropTypes.string,
  storageLocation: PropTypes.string, // Added propType for storageLocation
};

export default FileUploader;

import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, Card, InputGroup, FormControl } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import { UserRightsContext } from '../../../../../context/UserRightsContext';
import { useMediaQuery } from 'react-responsive';

// Page title
import PageTitle from "../../../../layouts/PageTitle";

// API Services
import { LeaveListViewForUser } from '../../../../../services/ccms/hrManagement/leaveRequest';
import { LeaveStatusList } from '../../../../../services/ccms/hrManagement/leaveStatus';
import { LeaveTypeList } from '../../../../../services/ccms/hrManagement/leaveType';
import { DateTimeConverter } from '../../../../../utilities/convertDateTime';
import { TextColor } from '../../../../../utilities/colorUtils';

const Leaves = () => {
  const [statusList, setStatusList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const userRights = useContext(UserRightsContext);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    statusID: 'NULL',
    typeID: 'NULL',
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    setUserDetails(storedUserDetails);
  }, []);

  useEffect(() => {
    if (userDetails) {
      fetchInitialData();
    }
  }, [userDetails]);

  const fetchInitialData = useCallback(async () => {
    setIsLoaded(false);
    await fetchLeaveStatusList();
    await fetchLeaveTypeList();
    await fetchLeaves();
    setIsLoaded(true);
  }, [userDetails]);

  const fetchLeaveStatusList = async () => {
    const response = await LeaveStatusList();
    setStatusList(response.results);
  };

  const fetchLeaveTypeList = async () => {
    const response = await LeaveTypeList();
    setTypeList(response.results);
  };

  const fetchLeaves = useCallback(async () => {
    const leaveData = await LeaveListViewForUser(userDetails.userId);
    setLeaveList(leaveData.results);
    setFilteredLeaves(leaveData.results); // Initialize filtered list
  }, [userDetails]);

  const applyFilters = useCallback(() => {
    const filtered = leaveList.filter((leave) => {
      const matchesSearch = 
        leave.name?.toLowerCase().includes(searchTerm) ||
        leave.surname?.toLowerCase().includes(searchTerm) ||
        leave.cellphone?.toLowerCase().includes(searchTerm) ||
        leave.typeName?.toLowerCase().includes(searchTerm);

      const matchesStatus = 
        filters.statusID === 'NULL' || leave.statusID === parseInt(filters.statusID);

      const matchesType = 
        filters.typeID === 'NULL' || leave.typeID === parseInt(filters.typeID);

      return matchesSearch && matchesStatus && matchesType;
    });

    setFilteredLeaves(filtered);
  }, [leaveList, searchTerm, filters]);

  useEffect(() => {
    applyFilters(); // Apply filters whenever search term or filters change
  }, [searchTerm, filters, applyFilters]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleStatusChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      statusID: e.target.value,
    }));
  };

  const handleTypeChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      typeID: e.target.value,
    }));
  };

  const handleNewLeaveClick = () => {
    navigate('/app/leaves/new-leave');
  };

  const handleOpenLeave = (leave) => {
    navigate('/app/leaves/view-leave', { state: leave });
  };

  const mobileColumns = [
    {
      name: 'Name',
      selector: (row) => `${row.name} ${row.surname}`,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Type',
      selector: (row) => row.typeName,
      sortable: true,
    },
    {
      name: '',
      width: '30px',
      cell: (row) => (
        <Button variant="outline-primary" size="xxs" onClick={() => handleOpenLeave(row)}>
          <i className="fas fa-eye"></i>
        </Button>
      ),
      ignoreRowClick: true,
    },
  ];

  const desktopColumns = [
    {
      name: 'Name',
      selector: (row) => `${row.name || 'N/A'} ${row.surname || 'N/A'}`,
      sortable: true,
    },
    {
      name: 'Cellphone',
      selector: (row) => row.cellphone || 'N/A',
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => (
        <span className="badge" style={{ backgroundColor: row.typeColor }}>
          <TextColor bgColor={row.typeColor} text={row.typeName} />
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: (row) => <DateTimeConverter dateInput={row.startDate} dateFormat="yyyy/MM/dd" />,
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => <DateTimeConverter dateInput={row.endDate} dateFormat="yyyy/MM/dd" />,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (
        <span className="badge" style={{ backgroundColor: row.statusColor }}>
          <TextColor bgColor={row.statusColor} text={row.statusName} />
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Number of Days',
      selector: (row) => row.numberOfDays || 'N/A',
      sortable: true,
    },
    {
      name: '',
      cell: (row) => (
        <Button variant="outline-primary" size="xs" onClick={() => handleOpenLeave(row)}>
          <i className="fas fa-eye"></i> View
        </Button>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <Fragment>
      <PageTitle
        motherMenu="Leaves"
        motherMenuPath="/app/leaves"
        activeMenu="List"
        button={
          <Button variant="primary" size="sm" onClick={handleNewLeaveClick}>
            <i className="fa fa-plus-circle"></i> New Leave
          </Button>
        }
      />
      <div className="mt-1">
        <Card className="shadow">
          <Card.Body>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Search</Form.Label>
                  <FormControl
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control as="select" value={filters.statusID} onChange={handleStatusChange}>
                    <option value="NULL">Any Status</option>
                    {statusList.map((status) => (
                      <option key={status.statusID} value={status.statusID}>
                        {status.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <Form.Control as="select" value={filters.typeID} onChange={handleTypeChange}>
                    <option value="NULL">All Types</option>
                    {typeList.map((type) => (
                      <option key={type.typeID} value={type.typeID}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <Card className="shadow">
        <Card.Body>
          <div className="table-responsive">
            {isLoaded ? (
              <DataTable
                columns={isMobile ? mobileColumns : desktopColumns}
                data={filteredLeaves}
                pagination
                expandableRows={isMobile}
                expandableRowsComponent={({ data }) => (
                  <div>
                    <p><strong>Name:</strong> {data.name} {data.surname}</p>
                    {/* Add more expanded details as needed */}
                  </div>
                )}
              />
            ) : (
              <Shimmer type="table" width="100%" />
            )}
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Leaves;

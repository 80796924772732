import { useNavigate } from "react-router-dom";
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    refreshToken as refreshTokenService, // Import your refresh token function
} from '../../services/AuthService';

;

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, navigate) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(dispatch, response.data.expiresIn * 1000, navigate);
            dispatch(confirmedSignupAction(response.data));
            navigate('/app/dashboard');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function logoutAndClearLocalStorage(navigate) {
    return (dispatch) => {
        localStorage.removeItem('userDetails');
        localStorage.removeItem('token');
        // localStorage.removeItem('userID');
        dispatch(Logout(navigate));
    };
}

export function Logout(navigate) {
    navigate('/auth/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return async (dispatch) => {
        dispatch({ type: LOADING_TOGGLE_ACTION, payload: true });
        try {
            const response = await login(email, password);
            const result = response.data;
            if (result.status === 'Success') {
                const tokenDetails = {
                    userId: result.results.userId,
                    apiKey: result.results.apiKey,
                    startDate: result.results.startDate,
                    expiresIn: (new Date(result.results.expiryDate).getTime() - new Date().getTime()) / 1000,
                    memberID: result.results.memberID,
                    isActive: result.results.isActive,
                    firstName: result.results.firstName,
                    surname: result.results.surname,
                    cellphone: result.results.cellphone,
                    email: result.results.email,
                    avatar: result.results.avatar
                };
                saveTokenInLocalStorage(tokenDetails);
                runLogoutTimer(dispatch, (new Date(result.results.expiryDate).getTime() - new Date().getTime()), navigate);
                dispatch({ type: LOGIN_CONFIRMED_ACTION, payload: tokenDetails });
                
                navigate('/app/dashboard');
            } else {
                const errorMessage = formatError(response.data);
                dispatch({ type: LOGIN_FAILED_ACTION, payload: errorMessage });
            }
        } catch (error) {
            const errorMessage = formatError(error);
            dispatch(loginFailedAction(errorMessage));
        } finally {
            dispatch({ type: LOADING_TOGGLE_ACTION, payload: false });
        }
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}



export const REFRESH_TOKEN_SUCCESS = '[Auth action] refresh token success';
export const REFRESH_TOKEN_FAILED = '[Auth action] refresh token failed';

// Add the refreshToken action
export const refreshToken = () => {
    return async (dispatch) => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logoutAndClearLocalStorage());
            return Promise.reject('No token found');
        }
        try {
            const newToken = await refreshTokenService(); // Call AuthService to refresh token
            localStorage.setItem('token', newToken);
            dispatch({
                type: LOGIN_CONFIRMED_ACTION,
                payload: {
                    token: newToken,
                    userDetails: JSON.parse(localStorage.getItem('userDetails'))
                }
            });
            return newToken;
        } catch (error) {
            dispatch(logoutAndClearLocalStorage());
            return Promise.reject(error);
        }
    };
};
import CryptoJS from 'crypto-js';

const encryptionKey = 'HIDING_INFO_FROM_NORMAL_USERS'; 

// Function to encrypt data
export const encryptData = (data) => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  } catch (error) {
    console.error('Error encrypting data:', error);
    return null;
  }
};

// Function to decrypt data
export const decryptData = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error('Error decrypting data:', error);
    return null;
  }
};
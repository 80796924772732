import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import TasksTable from './TasksTable';
import MembersTable from './MembersTable';
import GraphsSection from './GraphsSection';

const MonitoringTabs = ({ tasks, members }) => {
  const tabStyles = {
    tabContainer: {
      borderBottom: 'none',
      padding: '10px 20px',
      backgroundColor: '#f8f9fa',
      borderRadius: '10px',
    },
    tabLink: {
      color: '#6c757d',
      padding: '12px 20px',
      margin: '0 5px',
      borderRadius: '8px',
      fontWeight: '500',
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
    },
    tabLinkActive: {
      color: '#ffffff',
      backgroundColor: '#007bff',
      borderColor: '#007bff',
      boxShadow: '0 4px 8px rgba(0, 123, 255, 0.3)',
      fontWeight: '600',
    },
    tabLinkHover: {
      backgroundColor: '#e9ecef',
      color: '#007bff',
    },
    tabContent: {
      border: '1px solid #dee2e6',
      borderTop: 'none',
      padding: '20px',
      backgroundColor: '#ffffff',
      borderRadius: '0 0 8px 8px',
    },
  };

  return (
    <Tabs
      defaultActiveKey="graphs"
      id="monitoring-tabs"
      className="modern-tabs"
      style={tabStyles.tabContainer}
      variant="pills"
    >
      <Tab
        eventKey="graphs"
        title="Overview"
        tabClassName="tab-link"
        style={tabStyles.tabLink}
      >
        <div style={tabStyles.tabContent}>
          <GraphsSection tasks={tasks} members={members} />
        </div>
      </Tab>
      <Tab
        eventKey="tasks"
        title="Tasks"
        tabClassName="tab-link"
        style={tabStyles.tabLink}
      >
        <div style={tabStyles.tabContent}>
          <TasksTable tasks={tasks} />
        </div>
      </Tab>
      <Tab
        eventKey="members"
        title="Members"
        tabClassName="tab-link"
        style={tabStyles.tabLink}
      >
        <div style={tabStyles.tabContent}>
          <MembersTable members={members} tasks={tasks} />
        </div>
      </Tab>
    </Tabs>
  );
};

export default MonitoringTabs;

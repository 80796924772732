import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const PageTitle = ({ motherMenu, motherMenuPath, activeMenu, button }) => {
  const [referrer, setReferrer] = useState('');

  useEffect(() => {
    // Check and set the referrer on component mount
    setReferrer(document.referrer);
  }, []);

  return (
    <div className="row page-titles mx-0 align-items-center shadow">
      <div className="col d-flex align-items-center">
        <ol className="breadcrumb m-0">
          {/* {referrer && (
            <li className="breadcrumb-item">
              <a href={referrer}>
                <i className="fa fa-arrow-left"></i>
              </a>
            </li>
          )} */}
          <li className="breadcrumb-item">
            <Link to={motherMenuPath}>{motherMenu}</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="#">{activeMenu}</Link>
          </li>
        </ol>
      </div>
      {button && (
        <div className="col-auto">
          {button}
        </div>
      )}
    </div>
  );
};

export default PageTitle;

import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Modal, Form, ProgressBar } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { GetByDepartmentID } from '../../../../../services/ccms/departmentManagement/department/department_endpoints';
import { InsertDesignation, UpdateDesignation } from '../../../../../services/ccms/departmentManagement/designation/designation_endpoints';
import { insertDesignationTask, saveDesignationTask, deleteDesignationTask, getDesignationTaskById } from '../../../../../services/ccms/departmentManagement/designationTask';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FriendlyDuration } from '../../../../../utilities/convertNumbers';
import DurationPicker from '../../../../components/Custom/Select/DurationPicker';  // Assuming the file path

const DesignationsCard = ({ departmentID }) => {
  const [designations, setDesignations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDesignationLoaded, setIsDesignationLoaded] = useState(false);
  const [designationModal, setDesignationModal] = useState({ name: '', description: '', departmentID: departmentID, isActive: true });
  const [isEditMode, setIsEditMode] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isTasksLoading, setIsTasksLoading] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskModal, setTaskModal] = useState({ name: '', description: '', duration: { months: 0, weeks: 0, days: 0, hours: 0, minutes: 0 }, isActive: true, designationID: null });
  const [isTaskEditMode, setIsTaskEditMode] = useState(false);
  const [isTaskSaving, setIsTaskSaving] = useState(false);

  const loadDesignations = async () => {
    try {
      const response = await GetByDepartmentID(departmentID);
      if (response.results) {
        setDesignations(response.results);
      } else {
        console.error('Error: response.results is undefined');
      }
    } catch (error) {
      console.error('Error loading designations:', error);
    } finally {
      setIsDesignationLoaded(true);
    }
  };

  useEffect(() => {
    loadDesignations();
  }, [departmentID]);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setIsEditMode(false);
    setDesignationModal({ name: '', description: '', departmentID: departmentID, isActive: true });
    setTasks([]); // Clear tasks when modal is closed
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDesignationModal({ ...designationModal, [name]: value });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      let response;
      if (isEditMode) {
        response = await UpdateDesignation(designationModal);
      } else {
        response = await InsertDesignation(designationModal);
      }

      if (response.status === 'Success') {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'success',
        });
        await loadDesignations(); // Refresh the designations data
        handleClose();
      } else {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the designation',
        icon: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const openEditModal = async (designation) => {
    setDesignationModal(designation);
    setIsEditMode(true);
    setShowModal(true);
    await loadTasks(designation.designationID); // Load tasks for the designation
  };

  const handleTaskShow = (designationID) => {
    setTaskModal({ name: '', description: '', duration: { months: 0, weeks: 0, days: 0, hours: 0, minutes: 0 }, isActive: true, designationID });
    setShowTaskModal(true);
  };

  const handleTaskClose = () => {
    setShowTaskModal(false);
    setIsTaskEditMode(false);
    setTaskModal({ name: '', description: '', duration: { months: 0, weeks: 0, days: 0, hours: 0, minutes: 0 }, isActive: true, designationID: null });
  };

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setTaskModal({ ...taskModal, [name]: value });
  };

  const handleDurationChange = (name, value) => {
    setTaskModal((prevTaskModal) => ({
      ...prevTaskModal,
      duration: {
        ...prevTaskModal.duration,
        [name]: value,
      },
    }));
  };

  const durationToMinutes = ({ months = 0, weeks = 0, days = 0, hours = 0, minutes = 0 }) => {
    const hoursInADay = 8;
    const daysInAWeek = 5;
    const daysInAMonth = 4 * daysInAWeek;

    return (
      minutes +
      hours * 60 +
      days * hoursInADay * 60 +
      weeks * daysInAWeek * hoursInADay * 60 +
      months * daysInAMonth * hoursInADay * 60
    );
  };

  const handleTaskSave = async () => {
    setIsTaskSaving(true);
    try {
      const durationInMinutes = durationToMinutes(taskModal.duration);
      const taskToSave = { ...taskModal, duration: durationInMinutes };

      let response;
      if (isTaskEditMode) {
        response = await saveDesignationTask(taskToSave);
      } else {
        response = await insertDesignationTask(taskToSave);
      }

      if (response.status === 'Success') {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'success',
        });
        loadTasks(taskModal.designationID);
        handleTaskClose();
      } else {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the task',
        icon: 'error',
      });
    } finally {
      setIsTaskSaving(false);
    }
  };

  const loadTasks = async (designationID) => {
    setIsTasksLoading(true);
    try {
      const response = await getDesignationTaskById(designationID);
      if (response.results) {
        setTasks(response.results);
      } else {
        console.error('Error: response.results is undefined');
      }
    } catch (error) {
      console.error('Error loading tasks:', error);
    } finally {
      setIsTasksLoading(false);
    }
  };

  const handleEditTask = (task) => {
    setTaskModal(task);
    setIsTaskEditMode(true);
    setShowTaskModal(true);
  };

  const handleDeleteTask = async (taskID) => {
    try {
      const response = await deleteDesignationTask(taskID);
      if (response.status === 'Success') {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'success',
        });
        loadTasks(taskModal.designationID);
      } else {
        Swal.fire({
          title: response.title,
          text: response.message,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the task',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <Card className="my-3 shadow">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5>Designations</h5>
          <Button size='sm' variant="outline-primary" onClick={handleShow}><i className='fas fa-plus'></i> Add</Button>
        </Card.Header>
        <Card.Body>
          <PerfectScrollbar style={{ maxHeight: '500px' }} options={{ suppressScrollX: true }}>
            {isDesignationLoaded ? (
              designations.length > 0 ? (
                designations.map(item => (
                  <Row key={item.designationID}>
                    <Col md={10}>
                      <h6>{item.name}</h6>
                      <p className="text-muted">{item.description}</p>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end align-items-center">
                      <Button variant="outline-secondary" size="sm" onClick={() => openEditModal(item)}>
                        <i className="fas fa-edit"></i>
                      </Button>
                    </Col>
                    <Col md={12}>
                      <hr></hr>
                    </Col>
                  </Row>
                ))
              ) : (
                <Shimmer type='empty' text='No designations in the department' />
              )
            ) : (
              <Shimmer type='list' />
            )}
          </PerfectScrollbar>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? 'Edit Designation' : 'Add Designation'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDesignationName">
              <Form.Label>Designation Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={designationModal.name}
                onChange={handleChange}
                placeholder="Enter designation name"
              />
            </Form.Group>
            <Form.Group controlId="formDesignationDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={designationModal.description}
                onChange={handleChange}
                placeholder="Enter description"
              />
            </Form.Group>
            <Form.Control type="hidden" name="departmentID" value={departmentID} />
            <Form.Control type="hidden" name="isActive" value={true} />
          </Form>

          {isEditMode && (
            <div className="mt-4">
              <h6>Designation Tasks</h6>
              <Button size='sm' variant="outline-primary" onClick={() => handleTaskShow(designationModal.designationID)}>
                <i className="fas fa-plus"></i> Add Task
              </Button>
              {isTasksLoading ? (
                <Shimmer type='list' />
              ) : (
                <ul className="list-group mt-2">
                  {tasks.map(task => (
                    <li key={task.DTaskID} className="list-group-item d-flex justify-content-between align-items-center">
                      <span>{task.name} (<FriendlyDuration duration={task.duration} />)</span>
                      <div>
                        <Button variant="outline-secondary" size="sm" onClick={() => handleEditTask(task)}>
                          <i className="fas fa-edit"></i>
                        </Button>
                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteTask(task.DTaskID)}>
                          <i className="fas fa-trash"></i>
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isSaving}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTaskModal} onHide={handleTaskClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isTaskEditMode ? 'Edit Task' : 'Add Task'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTaskName">
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={taskModal.name}
                onChange={handleTaskChange}
                placeholder="Enter task name"
              />
            </Form.Group>
            <Form.Group controlId="formTaskDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={taskModal.description}
                onChange={handleTaskChange}
                placeholder="Enter description"
              />
            </Form.Group>
            <Form.Group controlId="formTaskDuration" className="mt-3">
              <Form.Label>Duration</Form.Label>
              <DurationPicker
                duration={taskModal.duration}
                onDurationChange={handleDurationChange}
              />
            </Form.Group>
            <Form.Control type="hidden" name="designationID" value={taskModal.designationID} />
            <Form.Control type="hidden" name="isActive" value={true} />
          </Form>
          {isTaskSaving && (
            <ProgressBar animated now={100} label="Saving changes..." className="mt-3" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTaskClose} disabled={isTaskSaving}>
            Close
          </Button>
          <Button variant="primary" onClick={handleTaskSave} disabled={isTaskSaving}>
            {isTaskSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DesignationsCard;

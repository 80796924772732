import React from 'react';
import { Bar } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ChartDataLabels);

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OverdueTasksGraph = ({ overdueTasksByUser }) => {
  if (!overdueTasksByUser || Object.keys(overdueTasksByUser).length === 0) {
    return <div>No overdue tasks</div>;
  }

  const data = {
    labels: Object.keys(overdueTasksByUser), // User names
    datasets: [
      {
        label: 'Overdue Tasks',
        data: Object.values(overdueTasksByUser), // Count of overdue tasks
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function(value) {
          return value; // Display the value on top of each bar
        },
        font: {
          weight: 'bold',
        },
        color: '#444',
      }
    }
  };

  return (
    <div style={{ height: '400px' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default OverdueTasksGraph;

import React, { lazy, Suspense, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';  // Import Bootstrap Modal and Button
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './redux/selectors/AuthSelectors';
import Index from './views';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { UserRightsProvider } from './context/UserRightsContext';
import axios from 'axios';
import packageJson from '../package.json';  // Import the whole package.json

const ForgotPassword = lazy(() => import('./views/pages/auth/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./views/pages/auth/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentVersion = packageJson.version;  // Get the version from package.json
  
  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, [dispatch, navigate]);

  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const response = await axios.get('/meta.json');
        const latestVersion = response.data.version;

        if (latestVersion !== currentVersion) {
          setNewVersionAvailable(true);  // Set the state when a new version is available
        }
      } catch (error) {
        console.error('Error checking for updates:', error);
      }
    };

    checkForUpdates();
  }, [currentVersion]);

  // Function to force a hard reload of the app
  const refreshApp = () => {
    // Clear LocalStorage
    localStorage.clear();
    window.location.reload(true);  // true forces the browser to reload the page from the server
  };

  // Function to determine the appropriate reload message
  const getReloadMessage = () => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    if (isMac) {
      return "Press Cmd + R to refresh.";
    } else {
      return "Press Ctrl + F5 to refresh.";
    }
  };

  const routeblog = (
    <Routes>
      <Route path='/auth/login' element={<Login />} />
      <Route path='/auth/forgot-password' element={<ForgotPassword />} />
    </Routes>
  );

  return (
    <div className="App">
      {props.isAuthenticated ? (
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }>
          <UserRightsProvider>
            <Index /> 
          </UserRightsProvider>
        </Suspense>
      ) : (
        <div className="vh-100">
          <Suspense fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }>
            <UserRightsProvider>
              {routeblog}
            </UserRightsProvider>
          </Suspense>
        </div>
      )}

      {/* Modal for new version available notification */}
      <Modal
        show={newVersionAvailable}
        onHide={() => {}}  // Disable closing the modal by clicking outside
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>New Version Available</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>A new version of this application is available. You need to update to continue using the app.</p>
          <p>{getReloadMessage()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size='sm' onClick={() => setNewVersionAvailable(false)}> 
            Skip
          </Button>
          <Button variant="primary" size='sm' onClick={refreshApp}>
            Update Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
});

export default withRouter(connect(mapStateToProps)(App));

// src/pages/policies/Policies.jsx

import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Form, Row, Col, Card, FormControl, Modal, FormGroup, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import { useMediaQuery } from 'react-responsive';
import FileUploader from '../../../../components/Custom/Uploader/FileUploader';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';
import PageTitle from "../../../../layouts/PageTitle";
import { PolicyListView, SavePolicy } from '../../../../../services/ccms/hrManagement/policy';
import { PolicyCategoryList } from '../../../../../services/ccms/hrManagement/policyCategory';
import { DateTimeConverter } from '../../../../../utilities/convertDateTime';
import { TextColor } from '../../../../../utilities/colorUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import PDFSigner from '../../../../components/Custom/Uploader/PDFSigner';
import FilePreview from '../../../../components/Custom/Uploader/FilePreview';

const Policies = () => {
  const [policyList, setPolicyList] = useState([]);
  const [filteredPolicies, setFilteredPolicies] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('ALL');
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  // Removed showModal and newPolicy states as "New Policy" has been removed
  // const [showModal, setShowModal] = useState(false);
  // const [newPolicy, setNewPolicy] = useState({ ... });

  const [isSaving, setIsSaving] = useState(false);

  // State variables for policy preview and signing
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [showSignModal, setShowSignModal] = useState(false);
  const [signedPolicyFile, setSignedPolicyFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = useCallback(async () => {
    setIsLoaded(false);
    try {
      await fetchPolicies();
      await fetchPolicyCategories();
      setIsLoaded(true);
    } catch (error) {
      toast.error('Failed to load policies. Please try again later.');
      setIsLoaded(true);
    }
  }, []);

  const fetchPolicies = async () => {
    const policyData = await PolicyListView();
    setPolicyList(policyData.results);
    setFilteredPolicies(policyData.results);
  };

  const fetchPolicyCategories = async () => {
    const categoryData = await PolicyCategoryList();
    setCategoryList(categoryData.results);
  };

  // Debounced search handler
  const debouncedFilter = useMemo(
    () =>
      debounce((searchValue, categoryValue) => {
        filterPolicies(searchValue, categoryValue);
      }, 300),
    [policyList]
  );

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    debouncedFilter(searchValue, selectedCategory);
  };

  const handleCategoryChange = (e) => {
    const categoryValue = e.target.value;
    setSelectedCategory(categoryValue);
    filterPolicies(searchTerm, categoryValue);
  };

  const filterPolicies = (searchValue, categoryValue) => {
    let filteredList = policyList;

    if (categoryValue !== 'ALL') {
      filteredList = filteredList.filter(policy => policy.categoryID === parseInt(categoryValue));
    }

    if (searchValue) {
      filteredList = filteredList.filter(policy =>
        policy.name?.toLowerCase().includes(searchValue) ||
        policy.description?.toLowerCase().includes(searchValue) ||
        policy.categoryName?.toLowerCase().includes(searchValue)
      );
    }

    setFilteredPolicies(filteredList);
  };

  // Removed handleNewPolicyClick and handleSavePolicy as "New Policy" has been removed
  // const handleNewPolicyClick = () => { ... };
  // const handleSavePolicy = async () => { ... };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewPolicy((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleRequireSignatoryChange = (e) => {
  //   setNewPolicy((prev) => ({
  //     ...prev,
  //     requireSignatory: e.target.checked,
  //   }));
  // };

  // const handleFileUploadSuccess = (name, url) => {
  //   setNewPolicy((prev) => ({
  //     ...prev,
  //     documentPath: url,
  //   }));
  // };

  // Function to handle policy preview
  const handlePreviewPolicy = (policy) => {
    setSelectedPolicy(policy);
    // No need to setShowPreviewModal as FilePreview handles its own modal
  };

  // Function to handle policy signing
  const handleSignPolicy = (policy) => {
    setSelectedPolicy(policy);
    setShowSignModal(true);
  };

  // Function to handle signed PDF
  const handleSignComplete = (signedFile) => {
    setShowSignModal(false);
    setSignedPolicyFile(signedFile);
    setShowUploadModal(true);
  };

  const handleUploadSuccess = async (name, url) => {
    toast.success('Signed policy uploaded successfully!');
    setShowUploadModal(false);

    // Optionally, update the policy status on your server to reflect that it's signed
    // You would need to implement an API call to update the policy status
    // Example:
    // await UpdatePolicySignatureStatus(selectedPolicy.policyID, url);
    // fetchPolicies();
  };

  const desktopColumns = useMemo(() => [
    {
      name: 'Policy Name',
      selector: row => row.name || 'N/A',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Category',
      selector: row =>
        <span className='badge' style={{ backgroundColor: row.categoryColor }}>
          <TextColor bgColor={row.categoryColor} text={row.categoryName} />
        </span>,
      sortable: true,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Effective Date',
      selector: row => <DateTimeConverter dateInput={row.effectiveDate} dateFormat="yyyy/MM/dd" />,
      sortable: true,
      center: true,
    },
    {
      name: 'Version',
      selector: row => row.version || 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Require Signatory',
      selector: row => row.requireSignatory ? 'Yes' : 'No',
      sortable: true,
      center: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          {row.requireSignatory ? (
            <>
              <Button
                variant="outline-primary"
                size="xs"
                onClick={() => handlePreviewPolicy(row)}
                aria-label={`Preview policy ${row.name}`}
                style={{ marginRight: '5px' }}
              >
                <FontAwesomeIcon icon={faEye} /> Preview
              </Button>
              {/* <Button
                variant="outline-success"
                size="xs"
                onClick={() => handleSignPolicy(row)}
                aria-label={`Sign policy ${row.name}`}
              >
                <FontAwesomeIcon icon={faPen} /> Sign
              </Button> */}
            </>
          ) : (
            <Button
              variant="outline-primary"
              size="xs"
              onClick={() => handlePreviewPolicy(row)}
              aria-label={`Preview policy ${row.name}`}
            >
              <FontAwesomeIcon icon={faEye} /> Preview
            </Button>
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px',
    },
  ], [handlePreviewPolicy, handleSignPolicy]);

  const handleCloseModal = () => {
    setShowSignModal(false);
    setShowUploadModal(false);
    setSelectedPolicy(null);
    setSignedPolicyFile(null);
  };

  return (
    <Fragment>
      <PageTitle
        motherMenu="Policies"
        motherMenuPath="/app/policies"
        activeMenu="List"
        // button={
        //   <Button variant="primary" size="sm" onClick={handleNewPolicyClick}>
        //     <i className='fa fa-plus-circle'></i> New Policy
        //   </Button>
        // }
      />
      <ToastContainer />
      <div className="mt-1">
        <Card className="shadow">
          <Card.Body>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Form.Label>Search</Form.Label>
                  <FormControl
                    placeholder="Search by name, description, or category"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    aria-label="Search policies"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Form.Label>Filter by Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    aria-label="Filter policies by category"
                  >
                    <option value="ALL">All Categories</option>
                    {categoryList.map(category => (
                      <option key={category.categoryID} value={category.categoryID}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Control>
                </FormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <Card className="shadow">
        <Card.Body>
          <div className="table-responsive">
            {isLoaded ? (
              <DataTable
                columns={desktopColumns}
                data={filteredPolicies}
                pagination
                highlightOnHover
                pointerOnHover
                responsive
                noHeader
                striped
              />
            ) : (
              <Shimmer type="table" width={'100%'} />
            )}
          </div>
        </Card.Body>
      </Card>

      {/* FilePreview Popup */}
      {selectedPolicy && (
        <FilePreview
          fileURL={selectedPolicy.documentPath}
          onClose={handleCloseModal}
        />
      )}

      {/* Signing Modal */}
      <Modal
        show={showSignModal}
        onHide={handleCloseModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign Policy: {selectedPolicy?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPolicy ? (
            <PDFSigner
              fileURL={selectedPolicy.documentPath}
              onSignComplete={handleSignComplete}
            />
          ) : (
            <Spinner animation="border" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Upload Signed Policy Modal */}
      <Modal
        show={showUploadModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Signed Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUploader
            userId=""
            name="signedPolicy"
            file={signedPolicyFile}
            onSuccess={handleUploadSuccess}
            storageLocation="SignedPolicies"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Policies;
